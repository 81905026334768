<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        class="flex-container-column"
        magic="{{mgc.Forgalomlista}}"
    >
        <div class="flex-container-row">
            <div class="example-container mat-elevation-z8 Table1TableContainerProps">
                <mat-table
                    #table
                    [dataSource]="dataSource"
                    class="container_border"
                    matSort
                    matSortDisableClear
                    (matSortChange)="tableService.sortData($event)"
                >
                    <ng-container
                        magic="{{mgc.Column2}}"
                        matColumnDef="{{mgc.Column2}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column2)"
                            mat-sort-header
                        > datum </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column2)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                magic="{{mgc.datum}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.datum}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.datum}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.datum}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.datum, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column3}}"
                        matColumnDef="{{mgc.Column3}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column3)"
                            mat-sort-header
                        > aru_kozp_id </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column3)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                type='number'
                                                magic="{{mgc.aru_kozp_id}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.aru_kozp_id}}"
                                                mgFormat
                                                rangevalidator
                                            >
                                            <mgError
                                                magic={{mgc.aru_kozp_id}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.aru_kozp_id}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.aru_kozp_id, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column4}}"
                        matColumnDef="{{mgc.Column4}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column4)"
                            mat-sort-header
                        > arunev </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column4)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                magic="{{mgc.arunev}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.arunev}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.arunev}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.arunev}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.arunev, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column5}}"
                        matColumnDef="{{mgc.Column5}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column5)"
                            mat-sort-header
                        > isbn </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column5)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                magic="{{mgc.isbn}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.isbn}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.isbn}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.isbn}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.isbn, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column6}}"
                        matColumnDef="{{mgc.Column6}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column6)"
                            mat-sort-header
                        > szerzo </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column6)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                magic="{{mgc.szerzo}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.szerzo}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.szerzo}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.szerzo}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.szerzo, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column7}}"
                        matColumnDef="{{mgc.Column7}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column7)"
                            mat-sort-header
                        > eredeti_kiado </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column7)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                magic="{{mgc.eredeti_kiado}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.eredeti_kiado}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.eredeti_kiado}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.eredeti_kiado}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.eredeti_kiado, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column8}}"
                        matColumnDef="{{mgc.Column8}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column8)"
                            mat-sort-header
                        > afa </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column8)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                type='number'
                                                magic="{{mgc.afa}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.afa}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.afa}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.afa}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.afa, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column9}}"
                        matColumnDef="{{mgc.Column9}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column9)"
                            mat-sort-header
                        > boltszam </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column9)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                magic="{{mgc.boltszam}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.boltszam}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.boltszam}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.boltszam}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.boltszam, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column10}}"
                        matColumnDef="{{mgc.Column10}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10)"
                            mat-sort-header
                        > boltnev </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                magic="{{mgc.boltnev}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.boltnev}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.boltnev}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.boltnev}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.boltnev, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column11}}"
                        matColumnDef="{{mgc.Column11}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column11)"
                            mat-sort-header
                        > beszallito </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column11)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                magic="{{mgc.beszallito}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.beszallito}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.beszallito}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.beszallito}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.beszallito, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column12}}"
                        matColumnDef="{{mgc.Column12}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column12)"
                            mat-sort-header
                        > forgalom </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column12)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                type='number'
                                                magic="{{mgc.forgalom}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.forgalom}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.forgalom}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.forgalom}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.forgalom, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column13}}"
                        matColumnDef="{{mgc.Column13}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column13)"
                            mat-sort-header
                        > brlistaaron </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column13)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                type='number'
                                                magic="{{mgc.brlistaaron}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.brlistaaron}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.brlistaaron}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.brlistaaron}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.brlistaaron, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column14}}"
                        matColumnDef="{{mgc.Column14}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column14)"
                            mat-sort-header
                        > brfogyaron </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column14)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                type='number'
                                                magic="{{mgc.brfogyaron}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.brfogyaron}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.brfogyaron}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.brfogyaron}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.brfogyaron, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column15}}"
                        matColumnDef="{{mgc.Column15}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column15)"
                            mat-sort-header
                        > nefogyaron </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column15)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                type='number'
                                                magic="{{mgc.nefogyaron}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.nefogyaron}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.nefogyaron}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.nefogyaron}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.nefogyaron, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column16}}"
                        matColumnDef="{{mgc.Column16}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column16)"
                            mat-sort-header
                        > nebeszaron </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column16)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                type='number'
                                                magic="{{mgc.nebeszaron}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.nebeszaron}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.nebeszaron}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.nebeszaron}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.nebeszaron, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row
                        *matRowDef="let row; columns: displayedColumns;"
                        class="table_row"
                        [ngClass]="{ 'selected': selection.isSelected(row)}"
                        [magicRow]="row?.rowId"
                        (click)="tableService.selectRow(row.rowId)"
                    >
                    </mat-row>
                </mat-table>
                <mat-paginator
                    #paginator
                    [pageSize]="10"
                    [pageSizeOptions]="[5, 10, 20]"
                    [showFirstLastButtons]="true"
                    (page)="tableService.mgOnPaginateChange($event)"
                >
                </mat-paginator>
            </div>
        </div>
    </div>
</div>
