<form name="screenFormGroup" novalidate [formGroup]="screenFormGroup">
  <div class="urlap, form-style" magic="{{ mgc.Login }}" style="width: initial">
    <h2>
      {{ mg.getValue("MG_FORMNAME") }}
    </h2>
    <div class="flex-container-row">
      <mat-form-field>
        <div>
          <input
            id="mat-input-0"
            matInput
            magic="{{ mgc.vUser }}"
            [(ngModel)]="vUser"
            placeholder="Felhasználónév"
            formControlName="{{ mgc.vUser }}"
            mgFormat
          />
 <!--*ngIf="value"
          <button *ngIf="vUser" matSuffix mat-icon-button aria-label="Clear" (click)="vUser=''">
            x
          </button>-->
          <mgError magic="{{ mgc.vUser }}"> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <mat-form-field>
        <div>
          <input
            id="mat-input-1"
            matInput
            magic="{{ mgc.vPass }}"
            [attr.required]=""
            type="password"
            placeholder="Add meg a jelszavad"
            formControlName="{{ mgc.vPass }}"
            mgFormat
          />
          <mgError magic="{{ mgc.vPass }}"> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <button
        mat-raised-button
        color="primary"
        magic="{{ mgc.BTLogin }}"
        (click)="BTLogin_click()"
      >
        Bejelentkezés
      </button>
    </div>
  </div>
</form>
