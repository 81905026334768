import { Component } from "@angular/core";

import { FormGroup } from "@angular/forms";
import {
  MgFormControlsAccessor,
  MgControlName,
} from "./Szerzovalasztasarumegtekint.mg.controls.g";

import { TaskBaseMagicComponent, magicProviders } from "@magic-xpa/angular";

import { MagicModalInterface } from "@magic-xpa/angular";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "mga-Szerzovalasztasarumegtekint",
  providers: [...magicProviders],
  templateUrl: "./Szerzovalasztasarumegtekint.component.html",
})
export class Szerzovalasztasarumegtekint
  extends TaskBaseMagicComponent
  implements MagicModalInterface {
  mgc = MgControlName;
  mgfc: MgFormControlsAccessor;
  faWindowClose = faWindowClose;
  createFormControlsAccessor(formGroup: FormGroup) {
    this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
  }
  private static readonly formName: string = "Szerző választás";
  private static readonly showTitleBar: boolean = true;
  private static readonly x: number = 0;
  private static readonly y: number = 0;
  private static readonly width: string = "300px";
  private static readonly height: string = "300px";
  private static readonly isCenteredToWindow: boolean = true;
  private static readonly shouldCloseOnBackgroundClick = true;
  X() {
    return Szerzovalasztasarumegtekint.x;
  }
  Y() {
    return Szerzovalasztasarumegtekint.y;
  }
  Width(): string {
    return Szerzovalasztasarumegtekint.width;
  }
  Height(): string {
    return Szerzovalasztasarumegtekint.height;
  }
  IsCenteredToWindow() {
    return Szerzovalasztasarumegtekint.isCenteredToWindow;
  }
  FormName() {
    return Szerzovalasztasarumegtekint.formName;
  }
  ShowTitleBar() {
    return Szerzovalasztasarumegtekint.showTitleBar;
  }
  ShouldCloseOnBackgroundClick() {
    return Szerzovalasztasarumegtekint.shouldCloseOnBackgroundClick;
  }
}
