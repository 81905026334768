import { Component } from "@angular/core";

import { FormGroup } from "@angular/forms";
import {
  MgFormControlsAccessor,
  MgControlName,
} from "./Forgalomlista2Ovrl.mg.controls.g";

import {
  BaseMatTableMagicComponent,
  matMagicProviders,
} from "@magic-xpa/angular-material-core";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

import {
  CdkDragStart,
  CdkDropList,
  moveItemInArray,
} from "@angular/cdk/drag-drop";
import { PageEvent } from "@angular/material/paginator";

import { MagicModalInterface } from "@magic-xpa/angular";
import { registerLocaleData } from "@angular/common";
import hu from "@angular/common/locales/hu";
import { mgAdd } from "@magic-xpa/cli";

@Component({
  selector: "mga-Forgalomlista2Ovrl",
  providers: [...matMagicProviders],
  templateUrl: "./Forgalomlista2Ovrl.component.html",
  styleUrls: ["./Forgalomlista2.component.scss"],
})
export class Forgalomlista2Ovrl
  extends BaseMatTableMagicComponent
  implements MagicModalInterface {
  mgc = MgControlName;
  mgfc: MgFormControlsAccessor;
  form_kiiras = "";
  faWindowClose = faWindowClose;

  createFormControlsAccessor(formGroup: FormGroup) {
    this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
    registerLocaleData(hu);
  }

  displayedColumns = [
    "Column10485765",
    "Column10485766",
    "Column10485767",
    "Column10485768",
    "Column10485774",
    "Column10485779",
    "Column10485788",
    "Column10485782",
  ];

  previousIndex: number;
  columns: any[] = [
    { field: "Column10485768", name: "Forgalom", mezo: "forgalom" },
    { field: "Column10485774", name: "Listaáron", mezo: "brlistaaron" },
    { field: "Column10485779", name: "Készlet", mezo: "keszlet" },
    { field: "Column10485788", name: "Készlet listaáron", mezo: "klt_brlistaaron" },
    { field: "Column10485782", name: "altip", mezo: "altip" },
  ]; //hosszú távon generáljuk23456789101112131415161718

  private static readonly formName: string = "Forgalomlista2Ovrl";
  private static readonly showTitleBar: boolean = true;
  private static readonly x: number = 0;
  private static readonly y: number = 0;
  private static readonly width: string = "300px";
  private static readonly height: string = "300px";
  private static readonly isCenteredToWindow: boolean = true;
  private static readonly shouldCloseOnBackgroundClick = true;
  X() {
    return Forgalomlista2Ovrl.x;
  }
  Y() {
    return Forgalomlista2Ovrl.y;
  }
  Width(): string {
    return Forgalomlista2Ovrl.width;
  }
  Height(): string {
    return Forgalomlista2Ovrl.height;
  }
  IsCenteredToWindow() {
    return Forgalomlista2Ovrl.isCenteredToWindow;
  }
  FormName() {
    return Forgalomlista2Ovrl.formName;
  }
  ShowTitleBar() {
    return Forgalomlista2Ovrl.showTitleBar;
  }
  ShouldCloseOnBackgroundClick() {
    return Forgalomlista2Ovrl.shouldCloseOnBackgroundClick;
  }

  setDisplayedColumns() {
    this.columns.forEach((colunm, index) => {
      colunm.index = index;
      this.displayedColumns[index] = colunm.field;
    });
    //console.log(this.displayedColumns);
  }

  dragStarted(event: CdkDragStart, index: number) {
    this.previousIndex = index;
  }

  console(x: any = "") {
    //bt_mind_pm,bt_valszthato  (click)="console(dataSource)"
    //debugger;
    //console.log(this.dataSource);
  }

  // MatPaginator Inputs
  //length = 100;
  //pageEvent: PageEvent;
  /*this.paginator_pageSize = this.mg.getCustomProperty(
      this.mgc.Forgalomlista2Ovrl,
      "paginator_pageSize"
    )
      ? this.mg.getCustomProperty(this.mgc.Forgalomlista2Ovrl, "paginator_pageSize")
      : this.paginator_pageSize;

      //console.log(event);

      */

  paginator_pageSize: number = 50;
  paginator_pageSizeOptions: string = "20,50,100";
  GETpageSize(): number {
    this.paginator_pageSize = this.mg.getValue(this.mgc.paginator_pageSize)
      ? Number(this.mg.getValue(this.mgc.paginator_pageSize))
      : this.paginator_pageSize;
    return this.paginator_pageSize;
  }

  SETpageSize(event: PageEvent) {
    this.tableService.mgOnPaginateChange(event);
    this.mg.setValueToControl(
      this.mgc.paginator_pageSize,
      Number(event.pageSize),
      false
    );
  }

  pageSizeOptions(): number[] {
    //console.log(this.mg.getCustomProperty(this.mgc.Forgalomlista2Ovrl,'paginator_pageSizeOptions'));
    this.paginator_pageSizeOptions = this.mg.getCustomProperty(
      this.mgc.Forgalomlista2Ovrl,
      "paginator_pageSizeOptions"
    )
      ? this.mg.getCustomProperty(
          this.mgc.Forgalomlista2Ovrl,
          "paginator_pageSizeOptions"
        )
      : this.paginator_pageSizeOptions;
    return this.paginator_pageSizeOptions.split(",").map((str) => +str);
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    //this.paginator_pagesizeOptions=this.mg.getCustomProperty('Forgalomlista2Ovrl','paginator_pageSizeOptions');
    //this.pageSizeOptions=this.paginator_pagesizeOptions.split(',').map(str => +str);
    //static paginator_pageSizeOptions : string = this.mg.getCustomProperty(this.mgc.StartProgram,'paginator_pageSizeOptions');
    //this.paginatorClick();//nincs értéke itt még
    //this.pageSize = 100;
    //this.pageSizeOptions = this.paginatorPageSizeSet();//[50, 100, 200];//this.paginator_pagesizeOptions.split(',').map(str => +str);//
    //console.log("");
  }

  // MatPaginator Output

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      //this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  dropListDropped(event: CdkDropList, index: number) {
    if (event) {
      moveItemInArray(this.columns, this.previousIndex, index);
      this.setDisplayedColumns();
    }
  }
}
