import {
  FormControl,
  FormGroup
} from "@angular/forms";
import {
  MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
  Beszallitokiadoi = "Beszallitokiadoi",
  Labelkiadok = "Labelkiadok",
  MG_B_QUIT = "MG_B_QUIT",
  kiadok = "kiadok",
  Ujkiadonev = "Ujkiadonev",
  Ujkiado = "Ujkiado",
  ment = "ment",
  MG_FORMNAME = "MG_FORMNAME",
  Kiadosorrend = "Kiadosorrend",
  lkedvencek = "lkedvencek",
  lkiadok = "lkiadok"
}
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) { }

  get kiadok(): FormControl {
    return this.fg.controls[MgControlName.kiadok] as FormControl;
  }

  get Ujkiadonev(): FormControl {
    return this.fg.controls[MgControlName.Ujkiadonev] as FormControl;
  }

  get Ujkiado(): FormControl {
    return this.fg.controls[MgControlName.Ujkiado] as FormControl;
  }

  get MG_FORMNAME(): FormControl {
    return this.fg.controls[MgControlName.MG_FORMNAME] as FormControl;
  }

  get Kiadosorrend(): FormControl {
    return this.fg.controls[MgControlName.Kiadosorrend] as FormControl;
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
  }
}
