import { Component } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
  MgFormControlsAccessor,
  MgControlName,
} from "./ForgKeszlSF.mg.controls.g";
import { TaskBaseMagicComponent, magicProviders } from "@magic-xpa/angular";
import {
  faBook,
  faUser,
  faWrench,
  faStream,
  faTag,
  faGraduationCap,
  faChartPie,
  faUpload,
  faCaretDown,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "mga-ForgKeszlSF",
  providers: [...magicProviders],
  templateUrl: '../../../templates/_form.html',//'/app/templates/_form.html', //'/src/templates/_form.html', //"./ForgKeszlSF.component.html",
  styleUrls: ["./ForgKeszlSF.component.scss"],
})
export class ForgKeszlSF extends TaskBaseMagicComponent {
  mgc = MgControlName;
  mgfc: MgFormControlsAccessor;
  form = this.mgc.ForgKeszlSF;
  form_kiiras= "A Líra központi rendszerből lekért forgalom és készlet adatok tájékoztató jellegűek, a hivatalos adatok a fogyásjelentésben találhatók.";

  faBook = faBook;
  faUser = faUser;
  faWrench = faWrench;
  faStream = faStream;
  faTag = faTag;
  faGraduationCap = faGraduationCap;
  faChartPie = faChartPie;
  faUpload = faUpload;
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;

  createFormControlsAccessor(formGroup: FormGroup) {
    this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
  }

  element: HTMLInputElement;
  refresh(id: string, magic): void {
    this.element = document.getElementById(id) as HTMLInputElement;
    this.mg.setValueToControl(magic, this.element.value, true);
    //console.log("NG:"+this.element.value);
    //console.log("MG:"+this.mg.getValue(this.mgc.ISBN));
    //console.log("MG_new:"+this.mg.getValue(magic));
    console.log("futtatas");
  }

  public get_mgparameter(id: string): string {
    //return this.mg.getCustomProperty(this.mgc.Ujcikk,'tree');
    return this.mg.getCustomProperty(this.mgc.ForgKeszlSF, id);
  }

  //tree01=this.mg.getCustomProperty('Ujcikk','TREE01');//this.TREE_DATA;//
  public mask_10 = {
    guide: true,
    showMask: true,
    mask: [
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
    ],
  };
  public mask_13 = {
    guide: true,
    showMask: true,
    mask: [
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
    ],
  };
  public mask = this.mask_10;

  public subjects = this.mgc.subjects;
  public interests = this.mgc.interests;
  public educations = this.mgc.educations;

  public treerefresh(id = ""): void {
    this.element = document.getElementById("subjects") as HTMLInputElement;
    this.mg.setValueToControl(this.mgc.subjects, this.element.value, true);

    this.element = document.getElementById("interests") as HTMLInputElement;
    this.mg.setValueToControl(this.mgc.interests, this.element.value, true);

    this.element = document.getElementById("educations") as HTMLInputElement;
    this.mg.setValueToControl(this.mgc.educations, this.element.value, true);
  }

  public checkValue(event: any) {
    console.log(event);
  }

  public onChecked(obj: any, isChecked: boolean) {
    console.log(obj, isChecked); // {}, true || false
  }

  public contributorsArray: Number[] = [];
  public contributorsType: string[] = [
    "Szerző|A01",
    "Forgatókönyv|A03",
    "Librettó|A04",
    "Tervező, designer|A11",
    "Illusztrátor|A12",
    "Fényképész, fotós|A13",
    "Előszó szerzője|A15",
    "Utószó szerzője|A19",
    "Jegyzetek|A20",
    "Előszó szerzője|A23",
    "Bevezető szerzője|A24",
    "Bevezetés és jegyzetek|A29",
    "Függelék|A33",
    "Rajz|A35",
    "Borítótervező|A36",
    "Térkép|A39",
    "Riporter|A43",
    "Szerkesztő|B01",
    "Lektor|B02",
    "Adaptáció készítője|B05",
    "Fordító|B06",
    "Sorozatszerkesztő|B09",
    "Szerkesztette és fordította|B10",
    "Főszerkesztő|B11",
    "Felelős szerkesztő|B16",
    "Sajtó alá rendezte|B18",
    "Összeállította|C01",
    "Válogatás|C02",
    "Producer|D01",
    "Rendezte|D02",
    "Felolvasó|E07",
    "Közreműködő|Z99",
  ];

  public csakszamok = {
    "0": { pattern: new RegExp("[0-9]") },
    A: { pattern: new RegExp("[0-9a-zA-Z]") },
  };

  public contributorsAdd() {
    this.contributorsArray.push(this.contributorsArray.length + 1);
    //console.log("szerző hozzáadása");
  }
  public contributorsClick() {
    let result: string[] = [];
    let contributorName: string;
    let contributorType: string;

    this.contributorsArray.forEach((element) => {
      contributorName = (<HTMLInputElement>(
        document.getElementById("contributorName" + element)
      )).value;
      contributorType = (<HTMLInputElement>(
        document.getElementById("contributorType" + element)
      )).value;
      result.push(contributorName + contributorType);
    });

    //(<HTMLInputElement>document.getElementById("contributors")).value=result;
    let contributors: HTMLInputElement; // = document.getElementById("contributors");
    //this.contributors=document.getElementById("contributors");
    //console.log(result);
  }

  public sectionClick(i: number, label?: string) {
    if(label!=='Excel') this.form_array[i].collapsed = !this.form_array[i].collapsed;
    //this.treerefresh();
  }

  public theCheckbox = true;

  public form_array = [
    {
      //s01: alapadatok
      id: "section_01",
      type: "section", //olyan tipus ami több elemett csoportosít
      icon: this.faBook,
      label: "Paraméterek|", //elem kiírás szövege
      perm: "",
      collapsed: false,
      description: "",
      magic: "",
      visible: "visible",
      value: [
        {
          //column_01_01
          id: "column_01_01",
          type: "column",
          label: "column_01",
          perm: "",
          description: "",
          magic: "",
          icon: "",
          visible: "visible",
          value: [
            {
              //sablon
              id: "section_01_02",
              type: "section",
              label: "Sablon betöltése",
              perm: "",
              description: "",
              magic: "",
              icon: "",
              visible: this.mgc.Sablon,
              value: [
                {
                  id: "section_01_02_01",
                  type: "tamplate_load",
                  label: "Sablon",
                  perm: "",
                  description: "",
                  magic: "",
                  icon: "",
                  visible: this.mgc.Sablon,
                  value: "",
                  disable: "",
                },
              ],
            },
            {
              //Beszállító
              id: "section_01_01",
              type: "section", //olyan tipus ami több elemett csoportosít
              label: "Cikk adatok", //elem kiírás szövege
              perm: "",
              description: "",
              magic: "",
              icon: "",
              visible: "visible",
              value: [
                {
                  id: "section_01_01_01",
                  type: "subform", //"beszallito",
                  label: "Beszállító", //this.mg.getValue(this.mgc.Edit1),
                  perm: "",
                  description: "",
                  magic: this.mgc.CikkSF,
                  icon: "",
                  value: "",
                  visible: "visible",
                  disable: "",
                },
              ],
            },
          ],
        },
        {
          //column_01_02
          id: "column_01_02",
          type: "column",
          label: "column_02",
          perm: "",
          description: "",
          magic: "",
          icon: "",
          visible: "visible",
          value: [
            {
              //Időszak
              id: "section_01_04",
              type: "section", //olyan tipus ami több elemett csoportosít
              label: "Időszak", //elem kiírás szövege
              perm: "",
              description: "",
              magic: "",
              icon: "",
              visible: "Datumtol",
              value: [
                {
                  id: "section_01_04_01",
                  type: "date_between",
                  label: "Időszak",
                  perm: "",
                  description: "",
                  magic: ["Datumtol", "Datumig"],
                  icon: "",
                  value: "",
                  visible: "Datumtol",
                  disable: "",
                },
              ],
            },
            {
              //Bolt
              id: "section_01_03",
              type: "section", //olyan tipus ami több elemett csoportosít
              label: "Bolt", //elem kiírás szövege
              perm: "",
              description: "",
              magic: "",
              icon: "",
              visible: "visible",
              value: [
                {
                  id: "section_01_03_01",
                  type: "subform",
                  label: "Típus", //this.mg.getValue(this.mgc.Edit1),
                  perm: "",
                  description: "",
                  magic: this.mgc.multiselect,
                  icon: "",
                  value: "",
                  visible: "visible",
                  disable: "",
                },
                {
                  id: "section_01_03_02",
                  type: "label_list_zoom",
                  label: "",
                  perm: "",
                  description: "",
                  magic: ["boltAzon", "boltNev", "boltvg"],
                  icon: "",
                  value: "",
                  visible: "visible",
                  disable: "",
                },
              ],
            },
            {
              //Megjelenítés
              id: "section_01_05",
              type: "section", //olyan tipus ami több elemett csoportosít
              label: "Megjelenítés", //elem kiírás szövege
              perm: "",
              description: "",
              magic: "",
              icon: "",
              visible: "visible",
              value: [
                {
                  id: "section_01_05_01",
                  type: "tab3",
                  label: "",
                  perm: "",
                  description: "",
                  magic: "",
                  icon: "",
                  value: "",
                  visible: "visible",
                  disable: "",
                },
                {
                  id: "section_01_05_03",
                  type: "button_click0",//becsukja az ablakot
                  label: "Lekérdezés",
                  description: "",
                  magic: this.mgc.Lekerdezes,
                  icon: "",
                  value: "",
                  visible: "visible", //this.mg.getVisible(this.mgc.Lekerdezes),
                  disable: this.mgc.Lekerdezes,
                },
                {
                  id: "section_01_05_04",
                  type: "button_click0",
                  label: "Excel",
                  description: "",
                  magic: this.mgc.Excel,
                  icon: "",
                  value: "",
                  visible: this.mgc.Excel,
                  disable: this.mgc.Excel,
                },
              ],
            },
            {
              //Sablon
              id: "section_01_06",
              id_ch: "section_01_06_ch",
              type: "section_ch",
              label: "Sablon mentése",
              perm: "",
              description: "",
              magic: "",
              magic_ch: this.mgc.Sablonment,
              icon: "",
              visible: this.mgc.Sablonment,
              value: [
                {
                  id: "section_01_06_01",
                  type: "text",
                  label: "Sablon neve",
                  perm: "",
                  description: "",
                  magic: this.mgc.nev,
                  icon: "",
                  visible: this.mgc.nev,
                  value: "",
                  disable: this.mgc.nev,
                },
                {
                  id: "section_01_06_02",
                  type: "textarea",
                  label: "Megjegyzés",
                  perm: "",
                  description: "",
                  magic: this.mgc.megjegyzes,
                  icon: "",
                  visible: this.mgc.megjegyzes,
                  value: "",
                  disable: this.mgc.megjegyzes,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "section_08",
      type: "section",
      icon: this.faUpload,
      label: "Eredmény|",
      perm: "",
      collapsed: false,
      description: "",
      magic: "",
      visible: "visible",
      value: [
        {
          id: "result",
          type: "subform_lista",
          label: "", //this.mg.getValue(this.mgc.Edit1),
          perm: "",
          description: "",
          magic: this.mgc.ListaSF,
          icon: "",
          value: "",
          visible: "visible",
          disable: "",
        },
      ],
    },
  ];

  get_text(x: string, y: number): string {
    //szövegek szétszedése a | karakter mentén, ezzel a főszöveg és a hozzátartozó leírás külön kezelhető
    return x.split("|")[y];
  }
  getCollapsed(x: string): boolean {
    //összezártság lekérdezése
    return this.form_array[x].collapsed;
  }
}
