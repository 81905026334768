<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        style="display: flex; flex-direction: column"
        [magic]="mgc.Forgalomlistasave"
    >
        <div style="display: flex; flex-direction: row">
            <button
                mat-raised-button
                color="primary"
                [magic]="mgc.btn_Print_Data"
            >
                Export
            </button>
            <label
                [magic]="mgc.Label10485762"
                class="lable_overflow"
            >
                Összesen
            </label>
            <label [magic]="mgc.eladas">
                {{mg.getValue(mgc.eladas) }}
            </label>
        </div>
        <div style="display: flex; flex-direction: row">
            <div class="example-container mat-elevation-z8 MG_T01TableContainerProps">
                <mat-table
                    #table
                    [dataSource]="dataSource"
                    class="container_border"
                    matSort
                    matSortDisableClear
                    (matSortChange)="tableService.sortData($event)"
                >
                    <ng-container
                        [magic]="mgc.Column10485765"
                        [matColumnDef]="mgc.Column10485765"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485765)"
                        >
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485765)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <button
                                    mat-raised-button
                                    color="primary"
                                    [magic]="mgc.bt_valszthato"
                                    [rowId]="row.rowId"
                                    [style.visibility]="mg.getVisible(mgc.bt_valszthato, row.rowId)"
                                >
                                    {{mg.getValue(mgc.bt_valszthato, row.rowId)}}
                                </button>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column10485766"
                        [matColumnDef]="mgc.Column10485766"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485766)"
                        >
                            Név
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485766)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <label
                                        [magic]="mgc.NEV"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.NEV, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column10485767"
                        [matColumnDef]="mgc.Column10485767"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485767)"
                        >
                            Azonosító
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485767)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <label
                                        [magic]="mgc.ID"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.ID, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column10485768"
                        [matColumnDef]="mgc.Column10485768"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485768)"
                            mat-sort-header
                        >
                            Dátum
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485768)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <label
                                        [magic]="mgc.datum"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.datum, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column10485769"
                        [matColumnDef]="mgc.Column10485769"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485769)"
                            mat-sort-header
                        >
                            Árukód
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485769)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <label
                                        [magic]="mgc.aru_kozp_id"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.aru_kozp_id, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column10485770"
                        [matColumnDef]="mgc.Column10485770"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485770)"
                            mat-sort-header
                        >
                            Áru név
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485770)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <label
                                        [magic]="mgc.arunev"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.arunev, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column10485771"
                        [matColumnDef]="mgc.Column10485771"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485771)"
                            mat-sort-header
                        >
                            ISBN
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485771)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <label
                                        [magic]="mgc.isbn"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.isbn, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column10485772"
                        [matColumnDef]="mgc.Column10485772"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485772)"
                            mat-sort-header
                        >
                            Szerző
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485772)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <label
                                        [magic]="mgc.szerzo"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.szerzo, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column10485773"
                        [matColumnDef]="mgc.Column10485773"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485773)"
                            mat-sort-header
                        >
                            Eredeti kiadó
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485773)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <label
                                        [magic]="mgc.eredeti_kiado"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.eredeti_kiado, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column10485774"
                        [matColumnDef]="mgc.Column10485774"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485774)"
                            mat-sort-header
                        >
                            ÁFA
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485774)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <label
                                        [magic]="mgc.afa"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.afa, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column10485775"
                        [matColumnDef]="mgc.Column10485775"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485775)"
                            mat-sort-header
                        >
                            Boltszám
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485775)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <label
                                        [magic]="mgc.boltszam"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.boltszam, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column10485776"
                        [matColumnDef]="mgc.Column10485776"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485776)"
                            mat-sort-header
                        >
                            Bolt név
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485776)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <label
                                        [magic]="mgc.boltnev"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.boltnev, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column10485777"
                        [matColumnDef]="mgc.Column10485777"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485777)"
                            mat-sort-header
                        >
                            Beszállító
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485777)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <label
                                        [magic]="mgc.beszallito"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.beszallito, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column10485778"
                        [matColumnDef]="mgc.Column10485778"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485778)"
                            mat-sort-header
                        >
                            Forgalom
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485778)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <label
                                        [magic]="mgc.forgalom"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.forgalom, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column10485779"
                        [matColumnDef]="mgc.Column10485779"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485779)"
                            mat-sort-header
                        >
                            Listaáron
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485779)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <label
                                        [magic]="mgc.brlistaaron"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.brlistaaron, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column10485780"
                        [matColumnDef]="mgc.Column10485780"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485780)"
                            mat-sort-header
                        >
                            Bt. Elad. áron
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485780)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <label
                                        [magic]="mgc.brfogyaron"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.brfogyaron, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column10485781"
                        [matColumnDef]="mgc.Column10485781"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485781)"
                            mat-sort-header
                        >
                            Nt. Elad. áron
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485781)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <label
                                        [magic]="mgc.nefogyaron"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.nefogyaron, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        [magic]="mgc.Column10485782"
                        [matColumnDef]="mgc.Column10485782"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485782)"
                            mat-sort-header
                        >
                            Nt. Besz. áron
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485782)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                style="display: flex; flex-direction: row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <label
                                        [magic]="mgc.nebeszaron"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.nebeszaron, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row
                        *matRowDef="let row; columns: displayedColumns;"
                        class="table_row"
                        [ngClass]="{ 'selected': selection.isSelected(row)}"
                        [magicRow]="row?.rowId"
                        (click)="row? tableService.selectRow(row.rowId) : null"
                    >
                    </mat-row>
                </mat-table>
                <mat-paginator
                    #paginator
                    [pageSize]="10"
                    [pageSizeOptions]="[5, 10, 20]"
                    (page)="tableService.mgOnPaginateChange($event)"
                >
                </mat-paginator>
            </div>
        </div>
    </div>
</div>
