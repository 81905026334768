import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    TextInput = "TextInput",
        Label3 = "Label3",
        result = "result",
        Label4 = "Label4",
        b = "b",
}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get result(): FormControl {
        return this.fg.controls[MgControlName.result] as FormControl;
    }

    get b(): FormControl {
        return this.fg.controls[MgControlName.b] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}