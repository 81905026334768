<div novalidate [formGroup]="screenFormGroup">
  <div class="flex-container-column" magic="{{ mgc.UploadImage }}">
    <div #overlayheader class="my-overlay-header">
      <span>{{ mg.getValue("MG_FORMNAME") }}</span>
      <button
        class="input_button"
        magic="{{ mgc.MG_B_QUIT }}"
        [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)"
      >
        <fa-icon [icon]="faWindowClose"></fa-icon>
      </button>
    </div>
    <div class="flex-container-row">
      <label magic="{{ mgc.Label13 }}" class="lable_overflow">
        vImageID:
      </label>
      <mat-form-field>
        <div>
          <input
            matInput
            type="number"
            magic="{{ mgc.vImageID }}"
            formControlName="{{ mgc.vImageID }}"
            mgFormat
          />
          <mgError magic="{{ mgc.vImageID }}"> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <label magic="{{ mgc.Label14 }}" class="lable_overflow">
        vImageDate:
      </label>
      <mat-form-field magic="{{ mgc.vImageDate }}" [eventsOnly]="true">
        <input
          matInput
          [matDatepicker]="vImageDate"
          magic="{{ mgc.vImageDate }}"
          formControlName="{{ mgc.vImageDate }}"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="vImageDate"
          *ngIf="mg.checkIsReadOnly(mgc.vImageDate)"
        >
        </mat-datepicker-toggle>
        <mat-datepicker #vImageDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <label magic="{{ mgc.Label15 }}" class="lable_overflow">
        vImageTime:
      </label>
      <mat-form-field>
        <div>
          <input
            matInput
            type="time"
            step="1"
            magic="{{ mgc.vImageTime }}"
            formControlName="{{ mgc.vImageTime }}"
          />
          <mgError magic="{{ mgc.vImageTime }}"> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <label magic="{{ mgc.Label16 }}" class="lable_overflow">
        vDescription:
      </label>
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.vDescription }}"
            formControlName="{{ mgc.vDescription }}"
            mgFormat
          />
          <mgError magic="{{ mgc.vDescription }}"> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <label magic="{{ mgc.Label17 }}" class="lable_overflow">
        vImagePath:
      </label>
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.vImagePath }}"
            formControlName="{{ mgc.vImagePath }}"
            mgFormat
          />
          <mgError magic="{{ mgc.vImagePath }}"> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <label magic="{{ mgc.Label18 }}" class="lable_overflow">
        vServerFile:
      </label>
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.vServerFile }}"
            formControlName="{{ mgc.vServerFile }}"
            mgFormat
          />
          <mgError magic="{{ mgc.vServerFile }}"> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <label magic="{{ mgc.Label19 }}" class="lable_overflow">
        vFileName:
      </label>
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.vFileName }}"
            formControlName="{{ mgc.vFileName }}"
            mgFormat
          />
          <mgError magic="{{ mgc.vFileName }}"> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <label magic="{{ mgc.Label20 }}" class="lable_overflow">
        vFileNameU:
      </label>
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.vFileNameU }}"
            formControlName="{{ mgc.vFileNameU }}"
            mgFormat
          />
          <mgError magic="{{ mgc.vFileNameU }}"> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <label magic="{{ mgc.Label21 }}" class="lable_overflow">
        vFileType:
      </label>
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.vFileType }}"
            formControlName="{{ mgc.vFileType }}"
            mgFormat
          />
          <mgError magic="{{ mgc.vFileType }}"> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <label magic="{{ mgc.Label22 }}" class="lable_overflow">
        vDestinationFileName:
      </label>
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.vDestinationFileName }}"
            formControlName="{{ mgc.vDestinationFileName }}"
            mgFormat
          />
          <mgError magic="{{ mgc.vDestinationFileName }}"> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <mat-form-field [style.visibility]="mg.getVisible(mgc.vBlob64FromClient)">
        <div>
          <input
            matInput
            magic="{{ mgc.vBlob64FromClient }}"
            formControlName="{{ mgc.vBlob64FromClient }}"
          />
          <mgError magic="{{ mgc.vBlob64FromClient }}"> </mgError>
        </div>
      </mat-form-field>
    </div>
  </div>
</div>
