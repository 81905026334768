<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        style="display: flex; flex-direction: column"
        magic="{{mgc.KTmshivo}}"
    >
        <div style="display: flex; flex-direction: row">
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.languagesI}}"
                        formControlName="{{mgc.languagesI}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.languagesI}}> </mgError>
                </div>
            </mat-form-field>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.languagesA}}"
                        formControlName="{{mgc.languagesA}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.languagesA}}> </mgError>
                </div>
            </mat-form-field>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.languages}}"
                        formControlName="{{mgc.languages}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.languages}}> </mgError>
                </div>
            </mat-form-field>
            <mat-card>
                <magic-subform magic="{{mgc.SFlanguages}}">
                </magic-subform>
            </mat-card>
        </div>
    </div>
</div>