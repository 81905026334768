<div novalidate [formGroup]="screenFormGroup">
  <div class="flex-container-column" magic="{{ mgc.texteditor }}">
    <!--overlay ablak elemek-->
    <div #overlayheader class="my-overlay-header">
      <span>{{ mg.getValue("MG_FORMNAME") }}</span>
      <button
        class="input_button"
        magic="{{ mgc.MG_B_QUIT }}"
        [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)"
      >
        <fa-icon [icon]="faWindowClose"></fa-icon>
      </button>
    </div>

    <!--body-->
    <div class="flex-container-row">
      <div id="quill" style="max-width: 1000px;">
        <quill-editor
          [styles]="editorStyle"
          placeholder=""
          magic="{{ mgc.output }}"
          formControlName="{{ mgc.output }}"
        >
        </quill-editor>
      </div>
    </div>

    <!--footer-->
    <div class="flex-container-row">
      <button
        mat-raised-button
        color="primary"
        magic="{{ mgc.MG_B_OK }}"
        [matTooltip]="mg.getTitle(mgc.MG_B_OK)"
      >
        OK
      </button>
    </div>
  </div>
</div>
