import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    TMPVArs = "TMPVArs",
        Table10485761 = "Table10485761",
        Column10485769 = "Column10485769",
        Varname = "Varname",
        Column10485770 = "Column10485770",
        Value = "Value",
        Column10485771 = "Column10485771",
        Message = "Message",
        MG_B_QUIT = "MG_B_QUIT",
        MG_B_OK = "MG_B_OK",
        MG_FORMNAME = "MG_FORMNAME",
}
export enum MgCustomProperties {}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get Varname(): FormControl {
        return this.getTableChildFormControl(MgControlName.Varname);
    }

    get Value(): FormControl {
        return this.getTableChildFormControl(MgControlName.Value);
    }

    get Message(): FormControl {
        return this.getTableChildFormControl(MgControlName.Message);
    }

    get MG_FORMNAME(): FormControl {
        return this.fg.controls[MgControlName.MG_FORMNAME] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}