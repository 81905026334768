import { FormControl, FormGroup } from "@angular/forms";
import { MagicServices } from "@magic-xpa/angular";
export enum MgControlName {
  Forgalomlista = "Forgalomlista",
  btn_Print_Data = "btn_Print_Data",
  Label10485762 = "Label10485762",
  MG_T01 = "MG_T01",
  Column10485765 = "Column10485765",
  bt_valszthato = "bt_valszthato",
  Column10485766 = "Column10485766",
  NEV = "NEV",
  Column10485767 = "Column10485767",
  ID = "ID",
  Column10485768 = "Column10485768",
  datum = "datum",
  Column10485769 = "Column10485769",
  aru_kozp_id = "aru_kozp_id",
  Column10485770 = "Column10485770",
  arunev = "arunev",
  Column10485771 = "Column10485771",
  isbn = "isbn",
  Column10485772 = "Column10485772",
  szerzo = "szerzo",
  Column10485773 = "Column10485773",
  eredeti_kiado = "eredeti_kiado",
  Column10485774 = "Column10485774",
  afa = "afa",
  Column10485775 = "Column10485775",
  boltszam = "boltszam",
  Column10485776 = "Column10485776",
  boltnev = "boltnev",
  Column10485777 = "Column10485777",
  beszallito = "beszallito",
  Column10485778 = "Column10485778",
  forgalom = "forgalom",
  Column10485779 = "Column10485779",
  brlistaaron = "brlistaaron",
  Column10485780 = "Column10485780",
  brfogyaron = "brfogyaron",
  Column10485781 = "Column10485781",
  nefogyaron = "nefogyaron",
  Column10485782 = "Column10485782",
  nebeszaron = "nebeszaron",
  Column10485799 = "Column10485799",
  keszlet = "keszlet",
  Column10485807 = "Column10485807",
  klt_brlistaaron = "klt_brlistaaron",
  eladas_sz = "eladas_sz",
  brlistaaron_sz = "brlistaaron_sz",
  brfogyaron_sz = "brfogyaron_sz",
  nefogyaron_sz = "nefogyaron_sz",
  nebeszaron_sz = "nebeszaron_sz",
  klt_brlistaaron_sz = "klt_brlistaaron_sz",
  keszlet_sz = "keszlet_sz",

  MG_SETUP_DEVIZA = "MG_SETUP_DEVIZA",
  MG_SETUP_DB = "MG_SETUP_DB",
  paginator_pageSize = "paginator_pageSize",
}
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) {}

  get eladas_sz(): FormControl {
    return this.fg.controls[MgControlName.eladas_sz] as FormControl;
  }
  get brlistaaron_sz(): FormControl {
    return this.fg.controls[MgControlName.brlistaaron_sz] as FormControl;
  }
  get brfogyaron_sz(): FormControl {
    return this.fg.controls[MgControlName.brfogyaron_sz] as FormControl;
  }
  get nefogyaron_sz(): FormControl {
    return this.fg.controls[MgControlName.nefogyaron_sz] as FormControl;
  }
  get nebeszaron_sz(): FormControl {
    return this.fg.controls[MgControlName.nebeszaron_sz] as FormControl;
  }
  get keszlet_sz(): FormControl {
    return this.fg.controls[MgControlName.keszlet_sz] as FormControl;
  }

  get NEV(): FormControl {
    return this.getTableChildFormControl(MgControlName.NEV);
  }

  get paginator_pageSize(): FormControl {
    return this.getTableChildFormControl(MgControlName.paginator_pageSize);
  }

  get ID(): FormControl {
    return this.getTableChildFormControl(MgControlName.ID);
  }

  get datum(): FormControl {
    return this.getTableChildFormControl(MgControlName.datum);
  }

  get aru_kozp_id(): FormControl {
    return this.getTableChildFormControl(MgControlName.aru_kozp_id);
  }

  get arunev(): FormControl {
    return this.getTableChildFormControl(MgControlName.arunev);
  }

  get isbn(): FormControl {
    return this.getTableChildFormControl(MgControlName.isbn);
  }

  get szerzo(): FormControl {
    return this.getTableChildFormControl(MgControlName.szerzo);
  }

  get eredeti_kiado(): FormControl {
    return this.getTableChildFormControl(MgControlName.eredeti_kiado);
  }

  get afa(): FormControl {
    return this.getTableChildFormControl(MgControlName.afa);
  }

  get boltszam(): FormControl {
    return this.getTableChildFormControl(MgControlName.boltszam);
  }

  get boltnev(): FormControl {
    return this.getTableChildFormControl(MgControlName.boltnev);
  }

  get beszallito(): FormControl {
    return this.getTableChildFormControl(MgControlName.beszallito);
  }

  get forgalom(): FormControl {
    return this.getTableChildFormControl(MgControlName.forgalom);
  }

  get brlistaaron(): FormControl {
    return this.getTableChildFormControl(MgControlName.brlistaaron);
  }

  get brfogyaron(): FormControl {
    return this.getTableChildFormControl(MgControlName.brfogyaron);
  }

  get nefogyaron(): FormControl {
    return this.getTableChildFormControl(MgControlName.nefogyaron);
  }

  get nebeszaron(): FormControl {
    return this.getTableChildFormControl(MgControlName.nebeszaron);
  }

  get keszlet(): FormControl {
    return this.getTableChildFormControl(MgControlName.keszlet);
  }

  get MG_SETUP_DEVIZA(): FormControl {
    return this.getTableChildFormControl(MgControlName.MG_SETUP_DEVIZA);
  }

  get MG_SETUP_DB(): FormControl {
    return this.getTableChildFormControl(MgControlName.MG_SETUP_DB);
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(
      this.magicServices.tableService.getSelectedRow()
    ).controls[name] as FormControl;
  }
}
