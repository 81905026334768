import { Component } from "@angular/core";

import { FormGroup } from "@angular/forms";
import {
  MgFormControlsAccessor,
  MgControlName,
} from "./Boltvalasztas.mg.controls.g";

import {
  BaseMatTableMagicComponent,
  matMagicProviders,
} from "@magic-xpa/angular-material-core";

import { MagicModalInterface } from "@magic-xpa/angular";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "mga-Boltvalasztas",
  providers: [...matMagicProviders],
  templateUrl: "./Boltvalasztas.component.html",
  styleUrls: ["./BoltvalasztasWC5.component.scss"]
})
export class Boltvalasztas
  extends BaseMatTableMagicComponent
  implements MagicModalInterface {
  mgc = MgControlName;
  mgfc: MgFormControlsAccessor;
  faWindowClose = faWindowClose;
  MG_D_SEARCH='';
  onEnter() {
  }
  createFormControlsAccessor(formGroup: FormGroup) {
    this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
  }
  private static readonly formName: string = "Bolt választás";
  private static readonly showTitleBar: boolean = true;
  private static readonly x: number = 0;
  private static readonly y: number = 0;
  private static readonly width: string = "300px";
  private static readonly height: string = "300px";
  private static readonly isCenteredToWindow: boolean = true;
  private static readonly shouldCloseOnBackgroundClick = true;
  X() {
    return Boltvalasztas.x;
  }
  Y() {
    return Boltvalasztas.y;
  }
  Width(): string {
    return Boltvalasztas.width;
  }
  Height(): string {
    return Boltvalasztas.height;
  }
  IsCenteredToWindow() {
    return Boltvalasztas.isCenteredToWindow;
  }
  FormName() {
    return Boltvalasztas.formName;
  }
  ShowTitleBar() {
    return Boltvalasztas.showTitleBar;
  }
  ShouldCloseOnBackgroundClick() {
    return Boltvalasztas.shouldCloseOnBackgroundClick;
  }

  displayedColumns = [
    "MG_T01C001",
    "MG_T01C002",
    "MG_T01C003",
    "MG_T01C004",
    "MG_T01C005",
    "MG_T01C006",
  ];
}
