import { FormControl, FormGroup } from "@angular/forms";
import { MagicServices } from "@magic-xpa/angular";
export enum MgControlName {
  submode="submode",
  Ujcikk2 = "Ujcikk2",
  Label117 = "Label117",
  pubtn = "pubtn",
  puref = "puref",
  puname = "puname",
  BeszallitoSF = "BeszallitoSF",
  Label117a = "Label117a",
  publisherBTN = "publisherBTN",
  publisherRef = "publisherRef",
  publisherName = "publisherName",
  Label64 = "Label64",
  isbn = "isbn",
  isbnis13 = "isbnis13",
  isbn13 = "isbn13",
  Label65 = "Label65",
  barcode = "barcode",
  Label67 = "Label67",
  title = "title",
  Label68 = "Label68",
  subTitle = "subTitle",
  Label69 = "Label69",
  originalTitle = "originalTitle",
  Label70 = "Label70",
  publicTitle = "publicTitle",
  Label71 = "Label71",
  Label72 = "Label72",
  publisher = "publisher",
  Label73 = "Label73",
  publishingPlace = "publishingPlace",
  Label74 = "Label74",
  publishingYear = "publishingYear",
  Label75 = "Label75",
  publishingDate = "publishingDate",
  Label76 = "Label76",
  editionNumber = "editionNumber",
  Label77 = "Label77",
  seriesEnabled = "seriesEnabled",
  seriesEnabled2 = "seriesEnabled2",
  Label78 = "Label78",
  seriesTitle = "seriesTitle",
  seriesTitle2 = "seriesTitle2",
  Label79 = "Label79",
  issn = "issn",
  Label80 = "Label80",
  volumeNumber = "volumeNumber",
  Label81 = "Label81",
  Radio_contributor = "Radio_contributor",
  Label82 = "Label82",
  contributors = "contributors",
  contributorsForm = "contributorsForm",
  DiscountCategory = "DiscountCategory",
  productType = "productType",
  Label66 = "Label66",
  customsTariffNumber = "customsTariffNumber",
  productTab = "productTab",
  Label84 = "Label84",
  productFormCode = "productFormCode",
  Label85 = "Label85",
  numberOfPages = "numberOfPages",
  Label86 = "Label86",
  productFormDetail = "productFormDetail",
  Label87 = "Label87",
  bookwidth = "bookwidth",
  Label88 = "Label88",
  bookheight = "bookheight",
  Label89 = "Label89",
  bookthickness = "bookthickness",
  Label90 = "Label90",
  bookweight = "bookweight",
  illustrations = "illustrations",
  Label92 = "Label92",
  numberOfIllustrations = "numberOfIllustrations",
  Label93 = "Label93",
  audioBookType = "audioBookType",
  Label94 = "Label94",
  numberOfDiscs = "numberOfDiscs",
  Label95 = "Label95",
  sizeOfAudioBook = "sizeOfAudioBook",
  Label96 = "Label96",
  duration = "duration",
  Label97 = "Label97",
  primarySubject = "primarySubject",
  Label98 = "Label98",
  subjects = "subjects",
  Label99b = "Label99b",
  PrimarySubj = "PrimarySubj",
  Label110 = "Label110",
  oldCategory = "oldCategory",
  Label99i = "Label99i",
  interests = "interests",
  Label100 = "Label100",
  educations = "educations",
  Label101p = "Label101p",
  price = "price",
  Label102 = "Label102",
  taxRate = "taxRate",
  Label103 = "Label103",
  miscIdentifier = "miscIdentifier",
  blurTabControl = "blurTabControl",
  Label10485877 = "Label10485877",
  blurb = "blurb",
  Label101b = "Label101b",
  blurb2 = "blurb2",
  btnEditBlurb2 = "btnEditBlurb2",
  Label105 = "Label105",
  slogan = "slogan",
  Label106 = "Label106",
  links = "links",
  linksForm = "linksForm",
  imagesForm = "imagesForm",
  Label108 = "Label108",
  tmp_control = "tmp_control",
  Label109 = "Label109",
  cTree2 = "cTree2",
  coverFile = "coverFile",
  samplePageFile = "samplePageFile",
  BTNSEND = "BTNSEND",
  MG_B_QUIT = "MG_B_QUIT",
  subjects2="subjects2",
  interests2="interests2",
  educations2="educations2",
  frissitvg = "frissitvg",
  hozzaad = "hozzaad",
  SelectedCountTmp = "SelectedCountTmp",
  valaszttmpvg = "valaszttmpvg",
  SelectedCount = "SelectedCount",
  valasztvg = "valasztvg",
  treerefresh = "treerefresh",
  Edit10485761 = "Edit10485761",
  Label104 = "Label104",
  Label10485813 = "Label10485813",
  BontasTipus = "BontasTipus",
  evhonap = "evhonap",
  bolt = "bolt",
  beszallito = "beszallito",
  cikk = "cikk",
  mennyiseg = "mennyiseg",
  eladasiAr = "eladasiAr",
  listaar = "listaar",
  beszerzesiAr = "beszerzesiAr",
  Keszlet = "Keszlet",
  NullasKeszlet = "NullasKeszlet",
  EgyszeruOsszetett = "EgyszeruOsszetett",
  Sablon = "Sablon",
  Ujragyujt = "Ujragyujt",
  ListaSF = "ListaSF",
  Lekerdezes = "Lekerdezes",
  EANgeneralas = "EANgeneralas",
  languages = "languages",
  languages2 = "languages2",
  SFlanguages = "SFlanguages",
  subjectsNB = "subjectsNB",
  BTNCHK="BTNCHK",
  SFSpecial="SFSpecial",
  specialtext="specialtext"
}
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) {}

  get puref(): FormControl {
    return this.fg.controls[MgControlName.puref] as FormControl;
  }

  get puname(): FormControl {
    return this.fg.controls[MgControlName.puname] as FormControl;
  }

  get publisherRef(): FormControl {
    return this.fg.controls[MgControlName.publisherRef] as FormControl;
  }

  get publisherName(): FormControl {
    return this.fg.controls[MgControlName.publisherName] as FormControl;
  }

  get isbn(): FormControl {
    return this.fg.controls[MgControlName.isbn] as FormControl;
  }

  get isbnis13(): FormControl {
    return this.fg.controls[MgControlName.isbnis13] as FormControl;
  }

  get isbn13(): FormControl {
    return this.fg.controls[MgControlName.isbn13] as FormControl;
  }

  get barcode(): FormControl {
    return this.fg.controls[MgControlName.barcode] as FormControl;
  }

  get title(): FormControl {
    return this.fg.controls[MgControlName.title] as FormControl;
  }

  get subTitle(): FormControl {
    return this.fg.controls[MgControlName.subTitle] as FormControl;
  }

  get originalTitle(): FormControl {
    return this.fg.controls[MgControlName.originalTitle] as FormControl;
  }

  get publicTitle(): FormControl {
    return this.fg.controls[MgControlName.publicTitle] as FormControl;
  }

  get languages(): FormControl {
    return this.fg.controls[MgControlName.languages] as FormControl;
  }

  get publisher(): FormControl {
    return this.fg.controls[MgControlName.publisher] as FormControl;
  }

  get publishingPlace(): FormControl {
    return this.fg.controls[MgControlName.publishingPlace] as FormControl;
  }

  get publishingYear(): FormControl {
    return this.fg.controls[MgControlName.publishingYear] as FormControl;
  }

  get publishingDate(): FormControl {
    return this.fg.controls[MgControlName.publishingDate] as FormControl;
  }

  get editionNumber(): FormControl {
    return this.fg.controls[MgControlName.editionNumber] as FormControl;
  }

  get seriesEnabled(): FormControl {
    return this.fg.controls[MgControlName.seriesEnabled] as FormControl;
  }

  get seriesEnabled2(): FormControl {
    return this.fg.controls[MgControlName.seriesEnabled2] as FormControl;
  }

  get seriesTitle(): FormControl {
    return this.fg.controls[MgControlName.seriesTitle] as FormControl;
  }

  get seriesTitle2(): FormControl {
    return this.fg.controls[MgControlName.seriesTitle2] as FormControl;
  }

  get issn(): FormControl {
    return this.fg.controls[MgControlName.issn] as FormControl;
  }

  get volumeNumber(): FormControl {
    return this.fg.controls[MgControlName.volumeNumber] as FormControl;
  }

  get Radio_contributor(): FormControl {
    return this.fg.controls[MgControlName.Radio_contributor] as FormControl;
  }

  get contributors(): FormControl {
    return this.fg.controls[MgControlName.contributors] as FormControl;
  }

  get DiscountCategory(): FormControl {
    return this.fg.controls[MgControlName.DiscountCategory] as FormControl;
  }

  get productType(): FormControl {
    return this.fg.controls[MgControlName.productType] as FormControl;
  }

  get customsTariffNumber(): FormControl {
    return this.fg.controls[MgControlName.customsTariffNumber] as FormControl;
  }

  get productFormCode(): FormControl {
    return this.fg.controls[MgControlName.productFormCode] as FormControl;
  }

  get numberOfPages(): FormControl {
    return this.fg.controls[MgControlName.numberOfPages] as FormControl;
  }

  get productFormDetail(): FormControl {
    return this.fg.controls[MgControlName.productFormDetail] as FormControl;
  }

  get bookwidth(): FormControl {
    return this.fg.controls[MgControlName.bookwidth] as FormControl;
  }

  get bookheight(): FormControl {
    return this.fg.controls[MgControlName.bookheight] as FormControl;
  }

  get bookthickness(): FormControl {
    return this.fg.controls[MgControlName.bookthickness] as FormControl;
  }

  get bookweight(): FormControl {
    return this.fg.controls[MgControlName.bookweight] as FormControl;
  }

  get illustrations(): FormControl {
    return this.fg.controls[MgControlName.illustrations] as FormControl;
  }

  get numberOfIllustrations(): FormControl {
    return this.fg.controls[MgControlName.numberOfIllustrations] as FormControl;
  }

  get audioBookType(): FormControl {
    return this.fg.controls[MgControlName.audioBookType] as FormControl;
  }

  get numberOfDiscs(): FormControl {
    return this.fg.controls[MgControlName.numberOfDiscs] as FormControl;
  }

  get sizeOfAudioBook(): FormControl {
    return this.fg.controls[MgControlName.sizeOfAudioBook] as FormControl;
  }

  get duration(): FormControl {
    return this.fg.controls[MgControlName.duration] as FormControl;
  }

  get primarySubject(): FormControl {
    return this.fg.controls[MgControlName.primarySubject] as FormControl;
  }

  get subjects(): FormControl {
    return this.fg.controls[MgControlName.subjects] as FormControl;
  }

  get oldCategory(): FormControl {
    return this.fg.controls[MgControlName.oldCategory] as FormControl;
  }

  get interests(): FormControl {
    return this.fg.controls[MgControlName.interests] as FormControl;
  }

  get educations(): FormControl {
    return this.fg.controls[MgControlName.educations] as FormControl;
  }

  get price(): FormControl {
    return this.fg.controls[MgControlName.price] as FormControl;
  }

  get taxRate(): FormControl {
    return this.fg.controls[MgControlName.taxRate] as FormControl;
  }

  get miscIdentifier(): FormControl {
    return this.fg.controls[MgControlName.miscIdentifier] as FormControl;
  }

  get blurb(): FormControl {
    return this.fg.controls[MgControlName.blurb] as FormControl;
  }

  get blurb2(): FormControl {
    return this.fg.controls[MgControlName.blurb2] as FormControl;
  }

  get slogan(): FormControl {
    return this.fg.controls[MgControlName.slogan] as FormControl;
  }

  get links(): FormControl {
    return this.fg.controls[MgControlName.links] as FormControl;
  }

  get tmp_control(): FormControl {
    return this.fg.controls[MgControlName.tmp_control] as FormControl;
  }

  get cTree2(): FormControl {
    return this.fg.controls[MgControlName.cTree2] as FormControl;
  }

  get subjects2(): FormControl {
    return this.fg.controls[MgControlName.subjects2] as FormControl;
  }
  get interests2(): FormControl {
    return this.fg.controls[MgControlName.interests2] as FormControl;
  }
  get educations2(): FormControl {
    return this.fg.controls[MgControlName.educations2] as FormControl;
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(
      this.magicServices.tableService.getSelectedRow()
    ).controls[name] as FormControl;
  }
}
