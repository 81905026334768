import {
    Component
} from '@angular/core';

import {
    FormGroup
} from "@angular/forms";
import {
    MgFormControlsAccessor,
    MgControlName
} from "./Login.mg.controls.g";


import {
    TaskBaseMagicComponent,
    magicProviders
} from "@magic-xpa/angular";


import {
    MagicModalInterface
} from "@magic-xpa/angular";

@Component({
    selector: 'mga-Login',
    providers: [...magicProviders],
    templateUrl: './Login.component.html',
    styleUrls: ['./Login.component.scss']
})
export class Login extends TaskBaseMagicComponent implements MagicModalInterface {

    mgc = MgControlName;
    mgfc: MgFormControlsAccessor;
    vUser = '';
    createFormControlsAccessor(formGroup: FormGroup) {
        this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
    }
    private static readonly formName: string = "Login";
    private static readonly showTitleBar: boolean = true;
    private static readonly x: number = 0;
    private static readonly y: number = 0;
    private static readonly width: string = "300px";
    private static readonly height: string = "300px";
    private static readonly isCenteredToWindow: boolean = true;
    private static readonly shouldCloseOnBackgroundClick = true;
    X() {
        return Login.x;
    }
    Y() {
        return Login.y;
    }
    Width(): string {
        return Login.width;
    }
    Height(): string {
        return Login.height;
    }
    IsCenteredToWindow() {
        return Login.isCenteredToWindow;
    }
    FormName() {
        return Login.formName;
    }
    ShowTitleBar() {
        return Login.showTitleBar;
    }
    ShouldCloseOnBackgroundClick() {
        return Login.shouldCloseOnBackgroundClick;
    }
    BTLogin_click()
    {
        var name = document.getElementById('mat-input-0') as HTMLInputElement;
        var pass = document.getElementById('mat-input-1') as HTMLInputElement;
        //name.focus(); name.blur();
        //pass.focus(); pass.blur();
        //debugger;
        name.click();
        pass.click();
    }
}