import { FormControl, FormGroup } from "@angular/forms";
import { MagicServices } from "@magic-xpa/angular";
export enum MgControlName {
  bt_mind_pm = "bt_mind_pm",
  Keszletlista2 = "Keszletlista2",
  btn_Print_Data = "btn_Print_Data",
  Label10485762 = "Label10485762",
  eladas_sz = "eladas_sz",
  MG_T01 = "MG_T01",
  Column10485765 = "Column10485765",
  bt_valszthato = "bt_valszthato",
  Column10485766 = "Column10485766",
  NEV = "NEV",
  Column10485767 = "Column10485767",
  ID = "ID",
  Column10485768 = "Column10485768",
  forgalom = "forgalom",
  Column10485774 = "Column10485774",
  brlistaaron = "brlistaaron",
  brlistaaron_sz = "brlistaaron_sz",
  Column10485779 = "Column10485779",
  keszlet = "keszlet",
  keszlet_sz = "keszlet_sz",
  Column10485782 = "Column10485782",
  altip = "altip",

  MG_SETUP_DEVIZA = "MG_SETUP_DEVIZA",
  MG_SETUP_DB = "MG_SETUP_DB",
  paginator_pageSize = "paginator_pageSize",
}
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) {}

  get paginator_pageSize(): FormControl {
    return this.getTableChildFormControl(MgControlName.paginator_pageSize);
  }

  get bt_valszthato(): FormControl {
    return this.fg.controls[MgControlName.bt_valszthato] as FormControl;
  }

  get altip(): FormControl {
    return this.fg.controls[MgControlName.altip] as FormControl;
  }

  get eladas_sz(): FormControl {
    return this.fg.controls[MgControlName.eladas_sz] as FormControl;
  }

  get NEV(): FormControl {
    return this.getTableChildFormControl(MgControlName.NEV);
  }

  get ID(): FormControl {
    return this.getTableChildFormControl(MgControlName.ID);
  }

  get forgalom(): FormControl {
    return this.getTableChildFormControl(MgControlName.forgalom);
  }

  get brlistaaron(): FormControl {
    return this.getTableChildFormControl(MgControlName.brlistaaron);
  }

  get brlistaaron_sz(): FormControl {
    return this.getTableChildFormControl(MgControlName.brlistaaron_sz);
  }

  get keszlet(): FormControl {
    return this.getTableChildFormControl(MgControlName.keszlet);
  }

  get keszlet_sz(): FormControl {
    return this.getTableChildFormControl(MgControlName.keszlet_sz);
  }

  get MG_SETUP_DEVIZA(): FormControl {
    return this.getTableChildFormControl(MgControlName.MG_SETUP_DEVIZA);
  }

  get MG_SETUP_DB(): FormControl {
    return this.getTableChildFormControl(MgControlName.MG_SETUP_DB);
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(
      this.magicServices.tableService.getSelectedRow()
    ).controls[name] as FormControl;
  }
}
