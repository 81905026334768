<div novalidate [formGroup]="screenFormGroup">
  <div magic="{{ mgc.UjcikkLinks }}">
    <div magic="{{ mgc.Table1 }}" class="container_border" style="width: 100%">
      <table>
        <tr class="table_row">
          <th *ngFor="
            let item of [
              { label: 'Könyvismertető, könyvtrailer link', width: '40%' },
              { label: 'Megjegyzés', width: '50%' },
              { label: '', width: '10%' }
            ]
          " style.width="{{ item.width }}">
            <div class="input_text" style="display: flex; min-width: 150px;">
              <label>
                {{ item.label }}
              </label>
            </div>
          </th>
        </tr>
        <ng-container *ngFor="let row of task.Records.list; index as i">
          <tr *ngIf="mg.ifRowCreated(row) && !mg.isDataviewEmpty()" id="row"
            [formGroup]="mg.getFormGroupByRow(row.rowId)" class="table_row"
            [ngClass]="{ selected: mg.isRowSelected(mgc.Table1, row.rowId) }"
            [class.selected]="tableService.getSelectedRow() === row.rowId" (click)="tableService.selectRow(row.rowId)"
            [magicRow]="row?.rowId">
            <td>
              <div class="input_text" style="display: flex" *ngIf="mg.isRowInRowEditing(row)">
                <!-- ROW editing mod -->
                <input *ngIf="mg.isRowInRowEditing(row)" magic="{{ mgc.link }}" [rowId]="row.rowId"
                  [required]="mg.getMustInput(mgc.link, row.rowId)" formControlName="{{ mgc.link }}"
                  class="form-control text" style="width: 80%; margin-left: 5px;" />
                <mgError magic="{{ mgc.link }}" [rowId]="row.rowId"> </mgError>
              </div>
              <div class="input_text" *ngIf="!mg.isRowInRowEditing(row)">
                <!--nincs row editingben-->
                <label *ngIf="!mg.isRowInRowEditing(row)" magic="{{ mgc.link }}" [rowId]="row.rowId">
                  {{ mg.getValue(mgc.link, row.rowId) }}
                </label>
              </div>
            </td>
            <td>
              <div class="input_text" style="display: flex" *ngIf="mg.isRowInRowEditing(row)">
                <!-- ROW editing mod -->
                <input *ngIf="mg.isRowInRowEditing(row)" magic="{{ mgc.megjegyzes }}" [rowId]="row.rowId"
                  [required]="mg.getMustInput(mgc.megjegyzes, row.rowId)" formControlName="{{ mgc.megjegyzes }}"
                  class="form-control text" style="width: 80%; margin-left: 5px;" />
                <mgError magic="{{ mgc.megjegyzes }}" [rowId]="row.rowId">
                </mgError>
              </div>
              <div class="input_text" *ngIf="!mg.isRowInRowEditing(row)">
                <!--nincs row editingben-->
                <label *ngIf="!mg.isRowInRowEditing(row)" magic="{{ mgc.megjegyzes }}" [rowId]="row.rowId">
                  {{ mg.getValue(mgc.megjegyzes, row.rowId) }}
                </label>
              </div>
            </td>
            <td>
              <button *ngIf="!mg.isRowInRowEditing(row)" mat-raised-button color="primary"
                magic="{{ mgc.EnterRowEditing }}" [rowId]="row.rowId">
                Szerkesztés
              </button>
              <button *ngIf="mg.isRowInRowEditing(row)" mat-raised-button color="primary"
                magic="{{ mgc.SaveRowEditing }}" [rowId]="row.rowId" [style.visibility]="
                  mg.getVisible(mgc.SaveRowEditing, row.rowId)
                " [disabled]="mg.isDisabled(mgc.SaveRowEditing, row.rowId)">
                Mentés
              </button>
              <button *ngIf="mg.isRowInRowEditing(row)" mat-raised-button color="primary"
                magic="{{ mgc.CancelRowEditing }}" [rowId]="row.rowId">
                Mégsem
              </button>
              <a href="javascript:;" magic="{{ mgc.DeleteRecord }}" [rowId]="row.rowId"
                [style.visibility]="mg.getVisible(mgc.DeleteRecord, row.rowId)"
                style="margin-left: 10px; color: #ec6b2c">
                <fa-icon [icon]="faTimes"></fa-icon>
              </a>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
    <!--
    <div style="text-align: center">
      <a
        href="javascript:;"
        magic="{{ mgc.Create }}"
        [style.visibility]="mg.getVisible(mgc.Create)"
        [attr.disabled]="mg.isDisabled(mgc.Create)"
        style="margin-left: 10px; color: #041c2b"
      >
        Új
      </a>
    </div>
    -->
    <div class="flex-container-row">
      <button mat-raised-button color="primary" magic="{{ mgc.Create }}" [style.visibility]="mg.getVisible(mgc.Create)"
        [attr.disabled]="mg.isDisabled(mgc.Create)">
        Új
      </button>
      <button style="display: none" mat-raised-button color="primary" magic="{{ mgc.New }}"
        [style.visibility]="mg.getVisible(mgc.Create)" [attr.disabled]="mg.isDisabled(mgc.Create)">
        Új(ablak)
      </button>
    </div>
  </div>
</div>
