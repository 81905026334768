import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    Placeholder = "Placeholder",
        Label10485802 = "Label10485802",
        mezo = "mezo",
        Table10485792 = "Table10485792",
        Column10485795 = "Column10485795",
        kulfoldi = "kulfoldi",
        Column10485796 = "Column10485796",
        nev = "nev",
        belfoldinev = "belfoldinev",
        Column10485797 = "Column10485797",
        EnterRowEditing = "EnterRowEditing",
        SaveRowEditing = "SaveRowEditing",
        CancelRowEditing = "CancelRowEditing",
}
export enum MgCustomProperties {}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get mezo(): FormControl {
        return this.fg.controls[MgControlName.mezo] as FormControl;
    }

    get kulfoldi(): FormControl {
        return this.getTableChildFormControl(MgControlName.kulfoldi);
    }

    get nev(): FormControl {
        return this.getTableChildFormControl(MgControlName.nev);
    }

    get belfoldinev(): FormControl {
        return this.getTableChildFormControl(MgControlName.belfoldinev);
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}