import {
    Component
} from '@angular/core';

import {
    FormGroup
} from "@angular/forms";
import {
    MgFormControlsAccessor,
    MgControlName
} from "./Forgalmak2.mg.controls.g";


import {
    TaskBaseMagicComponent,
    magicProviders
} from "@magic-xpa/angular";


import {
    MagicModalInterface
} from "@magic-xpa/angular";

@Component({
    selector: 'mga-Forgalmak2',
    providers: [...magicProviders],
    templateUrl: './Forgalmak2.component.html'
})
export class Forgalmak2 extends TaskBaseMagicComponent implements MagicModalInterface {

    mgc = MgControlName;
    mgfc: MgFormControlsAccessor;
    createFormControlsAccessor(formGroup: FormGroup) {
        this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
    }
    private static readonly formName: string = "Forgalmak2";
    private static readonly showTitleBar: boolean = true;
    private static readonly x: number = 0;
    private static readonly y: number = 0;
    private static readonly width: string = "300px";
    private static readonly height: string = "300px";
    private static readonly isCenteredToWindow: boolean = true;
    private static readonly shouldCloseOnBackgroundClick = true;
    X() {
        return Forgalmak2.x;
    }
    Y() {
        return Forgalmak2.y;
    }
    Width(): string {
        return Forgalmak2.width;
    }
    Height(): string {
        return Forgalmak2.height;
    }
    IsCenteredToWindow() {
        return Forgalmak2.isCenteredToWindow;
    }
    FormName() {
        return Forgalmak2.formName;
    }
    ShowTitleBar() {
        return Forgalmak2.showTitleBar;
    }
    ShouldCloseOnBackgroundClick() {
        return Forgalmak2.shouldCloseOnBackgroundClick;
    }
}