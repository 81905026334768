import { FormControl, FormGroup } from "@angular/forms";
import { MagicServices } from "@magic-xpa/angular";
export enum MgControlName {
  Beszallitovalasztas = "Beszallitovalasztas",
  Table10485763 = "Table10485763",
  Column10485764 = "Column10485764",
  beszallitonev = "beszallitonev",
  Column10485765 = "Column10485765",
  irsz = "irsz",
  Column10485766 = "Column10485766",
  varos = "varos",
  Column10485767 = "Column10485767",
  cim = "cim",
  Column10485768 = "Column10485768",
  kivalasztva = "kivalasztva",
  Label10485761 = "Label10485761",
  RNEV = "RNEV",
  KivalasztNYG = "KivalasztNYG",
  MegsemNYG = "MegsemNYG",
  MG_FORMNAME = "MG_FORMNAME",
  MG_B_QUIT="MG_B_QUIT",
}
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) {}

  get beszallitonev(): FormControl {
    return this.getTableChildFormControl(MgControlName.beszallitonev);
  }

  get irsz(): FormControl {
    return this.getTableChildFormControl(MgControlName.irsz);
  }

  get varos(): FormControl {
    return this.getTableChildFormControl(MgControlName.varos);
  }

  get cim(): FormControl {
    return this.getTableChildFormControl(MgControlName.cim);
  }

  get kivalasztva(): FormControl {
    return this.getTableChildFormControl(MgControlName.kivalasztva);
  }

  get RNEV(): FormControl {
    return this.fg.controls[MgControlName.RNEV] as FormControl;
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(
      this.magicServices.tableService.getSelectedRow()
    ).controls[name] as FormControl;
  }
}
