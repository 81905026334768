<!-- BeszallitoSF -->
<div novalidate [formGroup]="screenFormGroup">
  <div class="flex-container-column" magic="{{ mgc.BeszallitoSF }}">
    <div class="flex-container-row">
      <div>
        <mat-checkbox magic="{{ mgc.Mind }}" formControlName="{{ mgc.Mind }}">
          Mind
        </mat-checkbox>
      </div>
    </div>
  </div>
  <div class="card szint2">
    <div class="label_list_zoom">
      <div class="default_elements">
        <input
          type="text"
          class="form-control text"
          magic="{{ mgc.puref_N }}"
          formControlName="{{ mgc.puref_N }}"
          mgFormat
          style="width: 20%"
        />
        <mgError magic="{{ mgc.puref_N }}"> </mgError>
        <input
          type="text"
          class="form-control text"
          magic="{{ mgc.punevek }}"
          formControlName="{{ mgc.punevek }}"
          mgFormat
          style="width: 80%"
          [style.display]="
            mg.getVisible(mgc.punevek) == 'visible' ? 'initial' : 'none'
          "
        />
        <mgError
          magic="{{ mgc.punevek }}"
          [style.display]="
            mg.getVisible(mgc.punevek) == 'visible' ? 'initial' : 'none'
          "
        >
        </mgError>
        <magic-subform
          magic="{{ mgc.PurefSF }}"
          style="width: 80%"
          [style.display]="
            mg.getVisible(mgc.PurefSF) == 'visible' ? 'initial' : 'none'
          "
        >
        </magic-subform>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          magic="{{ mgc.MG_B_SEARCH }}"
          [style.display]="
            mg.getVisible(mgc.MG_B_SEARCH) == 'visible' ? 'initial' : 'none'
          "
          [matTooltip]="mg.getTitle(mgc.MG_B_SEARCH)"
        >
          ...
        </button>
      </div>
    </div>
  </div>
</div>
