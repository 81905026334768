import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    testVariables = "testVariables",
        Subform10485773 = "Subform10485773",
        Label10485777 = "Label10485777",
        puref_N = "puref_N",
        RNEV = "RNEV",
        Label5 = "Label5",
        masodik = "masodik",
        Label9 = "Label9",
        talalt = "talalt",
        Label6 = "Label6",
        harmadik = "harmadik",
        pu_nev = "pu_nev",
        punevbt = "punevbt",
}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get puref_N(): FormControl {
        return this.fg.controls[MgControlName.puref_N] as FormControl;
    }

    get RNEV(): FormControl {
        return this.fg.controls[MgControlName.RNEV] as FormControl;
    }

    get masodik(): FormControl {
        return this.fg.controls[MgControlName.masodik] as FormControl;
    }

    get talalt(): FormControl {
        return this.fg.controls[MgControlName.talalt] as FormControl;
    }

    get harmadik(): FormControl {
        return this.fg.controls[MgControlName.harmadik] as FormControl;
    }

    get pu_nev(): FormControl {
        return this.fg.controls[MgControlName.pu_nev] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}