<div novalidate [formGroup]="screenFormGroup">
  <div class="flex-container-column" magic="{{ mgc.Zoomtest }}">
    <div #overlayheader class="my-overlay-header">
      <span>{{ mg.getValue("MG_FORMNAME") }}</span>
      <button
        class="input_button"
        magic="{{ mgc.MG_B_QUIT }}"
        [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)"
      >
        <fa-icon [icon]="faWindowClose"></fa-icon>
      </button>
    </div>
    <div class="flex-container-row">
      <label magic="{{ mgc.Label10485761 }}" class="lable_overflow"> ID </label>
      <mat-form-field>
        <div>
          <input
            matInput
            type="number"
            magic="{{ mgc.ID }}"
            formControlName="{{ mgc.ID }}"
            mgFormat
          />
          <mgError magic="{{ mgc.ID }}"> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <label magic="{{ mgc.Label10485763 }}" class="lable_overflow">
        azon
      </label>
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.azon }}"
            formControlName="{{ mgc.azon }}"
            mgFormat
          />
          <mgError magic="{{ mgc.azon }}"> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <label magic="{{ mgc.Label10485765 }}" class="lable_overflow">
        Nev
      </label>
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.Nev }}"
            formControlName="{{ mgc.Nev }}"
            mgFormat
          />
          <mgError magic="{{ mgc.Nev }}"> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <label magic="{{ mgc.blb }}" class="lable_overflow">
        **UnSupported Control**
      </label>
    </div>
  </div>
</div>
