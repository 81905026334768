import { Component } from "@angular/core";

import { FormGroup } from "@angular/forms";
import {
  MgFormControlsAccessor,
  MgControlName,
} from "./Kiadovalasztas.mg.controls.g";

import { TaskBaseMagicComponent, magicProviders } from "@magic-xpa/angular";

import { MagicModalInterface } from "@magic-xpa/angular";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "mga-Kiadovalasztas",
  providers: [...magicProviders],
  templateUrl: "./Kiadovalasztas.component.html",
  styleUrls: ["./Kiadovalasztas.component.scss"],
})
export class Kiadovalasztas
  extends TaskBaseMagicComponent
  implements MagicModalInterface {
  mgc = MgControlName;
  mgfc: MgFormControlsAccessor;
  faWindowClose = faWindowClose;
  MG_D_SEARCH='';
  onEnter() {
  }
  createFormControlsAccessor(formGroup: FormGroup) {
    this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
  }
  private static readonly formName: string = "Kiadó választás";
  private static readonly showTitleBar: boolean = true;
  private static readonly x: number = 0;
  private static readonly y: number = 0;
  private static readonly width: string = "200px";
  private static readonly height: string = "300px";
  private static readonly isCenteredToWindow: boolean = true;
  private static readonly shouldCloseOnBackgroundClick = true;
  X() {
    return Kiadovalasztas.x;
  }
  Y() {
    return Kiadovalasztas.y;
  }
  Width(): string {
    return Kiadovalasztas.width;
  }
  Height(): string {
    return Kiadovalasztas.height;
  }
  IsCenteredToWindow() {
    return Kiadovalasztas.isCenteredToWindow;
  }
  FormName() {
    return Kiadovalasztas.formName;
  }
  ShowTitleBar() {
    return Kiadovalasztas.showTitleBar;
  }
  ShouldCloseOnBackgroundClick() {
    return Kiadovalasztas.shouldCloseOnBackgroundClick;
  }
}
