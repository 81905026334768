import { FormControl, FormGroup } from "@angular/forms";
import { MagicServices } from "@magic-xpa/angular";
export enum MgControlName {
  Zoomtest = "Zoomtest",
  Label10485761 = "Label10485761",
  ID = "ID",
  Label10485763 = "Label10485763",
  azon = "azon",
  Label10485765 = "Label10485765",
  Nev = "Nev",
  blb = "blb",
  MG_FORMNAME = "MG_FORMNAME",
  MG_B_QUIT="MG_B_QUIT",
}
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) {}

  get ID(): FormControl {
    return this.fg.controls[MgControlName.ID] as FormControl;
  }

  get azon(): FormControl {
    return this.fg.controls[MgControlName.azon] as FormControl;
  }

  get Nev(): FormControl {
    return this.fg.controls[MgControlName.Nev] as FormControl;
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(
      this.magicServices.tableService.getSelectedRow()
    ).controls[name] as FormControl;
  }
}
