import {
  Component
} from '@angular/core';

import {
  FormGroup
} from "@angular/forms";
import {
  MgFormControlsAccessor,
  MgControlName
} from "./ShowImagesTableOvrl.mg.controls.g";


import {
  TaskBaseMagicComponent,
  magicProviders
} from "@magic-xpa/angular";

import { faTimes,faList } from '@fortawesome/free-solid-svg-icons';

import {
  MagicModalInterface
} from "@magic-xpa/angular";

@Component({
  selector: 'mga-ShowImagesTableOvrl',
  providers: [...magicProviders],
  templateUrl: './ShowImagesTableOvrl.component.html',
  styleUrls: ['./ShowImagesTable.component.scss']
})
export class ShowImagesTableOvrl extends TaskBaseMagicComponent implements MagicModalInterface {

  mgc = MgControlName;
  mgfc: MgFormControlsAccessor;
  faTimes = faTimes;
  faList=faList;
  createFormControlsAccessor(formGroup: FormGroup) {
      this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
  }
  private static readonly formName: string = "ShowImagesTableOvrl";
  private static readonly showTitleBar: boolean = true;
  private static readonly x: number = 0;
  private static readonly y: number = 0;
  private static readonly width: string = "300px";
  private static readonly height: string = "300px";
  private static readonly isCenteredToWindow: boolean = true;
  private static readonly shouldCloseOnBackgroundClick = true;
  X() {
      return ShowImagesTableOvrl.x;
  }
  Y() {
      return ShowImagesTableOvrl.y;
  }
  Width(): string {
      return ShowImagesTableOvrl.width;
  }
  Height(): string {
      return ShowImagesTableOvrl.height;
  }
  IsCenteredToWindow() {
      return ShowImagesTableOvrl.isCenteredToWindow;
  }
  FormName() {
      return ShowImagesTableOvrl.formName;
  }
  ShowTitleBar() {
      return ShowImagesTableOvrl.showTitleBar;
  }
  ShouldCloseOnBackgroundClick() {
      return ShowImagesTableOvrl.shouldCloseOnBackgroundClick;
  }
}
