<div novalidate [formGroup]="screenFormGroup">
  <div class="flex-container-column" magic="{{ mgc.BoltTipusMSWC }}">
    <div class="flex-container-row">
      <div>
        <mat-checkbox magic="{{ mgc.Mind }}" formControlName="{{ mgc.Mind }}">
          Mind
        </mat-checkbox>
      </div>
    </div>
    <div class="flex-container-row">
      <div>
        <mat-selection-list
          multiple
          formControlName="{{ mgc.vector }}"
          magic="{{ mgc.vector }}"
          [tabIndex]="screenFormGroup.get(mgc.vector).disabled ? -1 : 0"
        >
          <mat-list-option
            *ngFor="let o of mg.getItemListValues(mgc.vector)"
            [value]="o.index"
          >
            {{ o.displayValue }}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
  </div>
</div>
