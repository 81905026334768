import { Component } from "@angular/core";

import { FormGroup } from "@angular/forms";
import {
  MgFormControlsAccessor,
  MgControlName,
} from "./Szerzovalasztas.mg.controls.g";

import {
  BaseMatTableMagicComponent,
  matMagicProviders,
} from "@magic-xpa/angular-material-core";

import { MagicModalInterface } from "@magic-xpa/angular";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "mga-Szerzovalasztas",
  providers: [...matMagicProviders],
  templateUrl: "./Szerzovalasztas.component.html",
  styleUrls: ["Szerzovalasztas.component.scss"],
})
export class Szerzovalasztas
  extends BaseMatTableMagicComponent
  implements MagicModalInterface {
  mgc = MgControlName;
  mgfc: MgFormControlsAccessor;
  faWindowClose = faWindowClose;
  MG_D_SEARCH='';
  createFormControlsAccessor(formGroup: FormGroup) {
    this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
  }

  onEnter() {
    var button = document.getElementById("szerzovalasztas_zoom") as HTMLInputElement;
    button.click();
    //console.log("enter ütöttem");
    // : HTMLButtonElement
  }

  tabledots = [
    {
      header: "Szerző név",
      magic: this.mgc.Column10485765,
      magic2: this.mgc.MG_T01C001D001,
      type: "text",
      column: "MG_T01C001D001",
      display: "",
    },
    {
      header: "Termékek száma",
      magic: this.mgc.Column10485766,
      magic2: this.mgc.MG_T01C002D001,
      type: "text",
      column: "MG_T01C002D001",
      display: "",
    },
    {
      header: "Kiválaszt",
      magic: this.mgc.Column10485767,
      magic2: this.mgc.MG_T01C003D001,
      type: "text",
      column: "MG_T01C003D001",
      display: "",
    },
  ];
  private static readonly formName: string = "Szerző választás";
  private static readonly showTitleBar: boolean = true;
  private static readonly x: number = 0;
  private static readonly y: number = 0;
  private static readonly width: string = "300px";
  private static readonly height: string = "300px";
  private static readonly isCenteredToWindow: boolean = true;
  private static readonly shouldCloseOnBackgroundClick = true;
  X() {
    return Szerzovalasztas.x;
  }
  Y() {
    return Szerzovalasztas.y;
  }
  Width(): string {
    return Szerzovalasztas.width;
  }
  Height(): string {
    return Szerzovalasztas.height;
  }
  IsCenteredToWindow() {
    return Szerzovalasztas.isCenteredToWindow;
  }
  FormName() {
    return Szerzovalasztas.formName;
  }
  ShowTitleBar() {
    return Szerzovalasztas.showTitleBar;
  }
  ShouldCloseOnBackgroundClick() {
    return Szerzovalasztas.shouldCloseOnBackgroundClick;
  }

  displayedColumns = ["Column10485765", "Column10485766", "Column10485767"];
}
