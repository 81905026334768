<!--pureSF3-->
<div novalidate [formGroup]="screenFormGroup">
  <div class="flex-container-column" magic="{{ mgc.multiselectBesz }}">
    <div class="flex-container-row">
      <div>
        <mat-checkbox magic="{{ mgc.Mind }}" formControlName="{{ mgc.Mind }}">
          Mind
        </mat-checkbox>
      </div>
      <mat-form-field>
        <div>
          <input
            matInput
            type="number"
            magic="{{ mgc.vecsize }}"
            formControlName="{{ mgc.vecsize }}"
            mgFormat
          />
          <mgError magic="{{ mgc.vecsize }}"> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <div>
        <mat-selection-list
          multiple
          formControlName="{{ mgc.vector }}"
          magic="{{ mgc.vector }}"
          [tabIndex]="screenFormGroup.get(mgc.vector).disabled ? -1 : 0"
        >
          <mat-list-option
            *ngFor="let o of mg.getItemListValues(mgc.vector)"
            [value]="o.index"
          >
            {{ o.displayValue }}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
    <div class="flex-container-row">
      <button
        mat-raised-button
        color="primary"
        magic="{{ mgc.MG_B_OK }}"
        [matTooltip]="mg.getTitle(mgc.MG_B_OK)"
        id="pureSF3_zoom"
      >
        OK
      </button>
      <button
        mat-raised-button
        color="primary"
        magic="{{ mgc.btn_Quit }}"
        [matTooltip]="mg.getTitle(mgc.btn_Quit)"
      >
        Mégsem
      </button>
    </div>
  </div>
</div>
