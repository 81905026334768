<div #overlayheader class="urlap, form-style, my-overlay-header" style="min-width: 200px;">
  <span>{{ mg.getValue("MG_FORMNAME") }}</span>
  <button class="input_button" [style.visibility]="mg.getVisible(mgc.MG_B_QUIT)"
    [style.display]="mg.getVisible(mgc.MG_B_QUIT) == 'visible' ? 'flex' : 'none'" magic="{{ mgc.MG_B_QUIT }}"
    [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)">
    <fa-icon [icon]="faWindowClose"></fa-icon>
  </button>
</div>
<div novalidate [formGroup]="screenFormGroup">
  <div style="display: flex; flex-direction: column" [magic]="mgc.TMPVArs">
    <div style="display: flex; flex-direction: row; overflow: auto; max-height: 400px;">
      <div [magic]="mgc.Table10485761" class="container_border">
        <table>
          <th [style.visibility]="mg.getVisible(mgc.Column10485769)" style="min-width: 100px;">
            <label>
              Név
            </label>
          </th>
          <th [style.visibility]="mg.getVisible(mgc.Column10485770)" style="min-width: 350px;">
            <label>
              Érték
            </label>
          </th>
          <th [style.visibility]="mg.getVisible(mgc.Column10485771)" style="min-width: 350px;">
            <label>
              Üzenet
            </label>
          </th>
          <ng-container *ngFor="let row of task.Records.list">
            <tr *ngIf="mg.ifRowCreated(row) && !mg.isDataviewEmpty()" id="row"
              [formGroup]="mg.getFormGroupByRow(row.rowId)" class="table_row"
              [ngClass]="{ 'selected': mg.isRowSelected(mgc.Table10485761, row.rowId)}"
              [class.selected]="tableService.getSelectedRow() === row.rowId" (click)="tableService.selectRow(row.rowId)"
              [magicRow]="row?.rowId">
              <td [style.visibility]="mg.getVisible(mgc.Column10485769)">
                <div [formGroup]="mg.getFormGroupByRow(row.rowId)" style="display: flex; flex-direction: row">
                  <mat-form-field [style.visibility]="mg.getVisible(mgc.Varname, row.rowId)"
                    *ngIf="mg.isRowInRowEditing(row)">
                    <div>
                      <input *ngIf="mg.isRowInRowEditing(row)" matInput [magic]="mgc.Varname" [rowId]="row.rowId"
                        [formControlName]="mgc.Varname" mgFormat>
                      <mgError [magic]=mgc.Varname [rowId]="row.rowId"> </mgError>
                    </div>
                  </mat-form-field>
                  <label *ngIf="!mg.isRowInRowEditing(row)" [magic]="mgc.Varname" [rowId]="row.rowId"
                    [style.visibility]="mg.getVisible(mgc.Varname, row.rowId)">
                    {{mg.getValue(mgc.Varname, row.rowId)
                    }}
                  </label>
                </div>
              </td>
              <td [style.visibility]="mg.getVisible(mgc.Column10485770)">
                <div [formGroup]="mg.getFormGroupByRow(row.rowId)" style="display: flex; flex-direction: row">
                  <mat-form-field [style.visibility]="mg.getVisible(mgc.Value, row.rowId)"
                    *ngIf="mg.isRowInRowEditing(row)">
                    <div>
                      <input *ngIf="mg.isRowInRowEditing(row)" matInput [magic]="mgc.Value" [rowId]="row.rowId"
                        [formControlName]="mgc.Value" mgFormat>
                      <mgError [magic]=mgc.Value [rowId]="row.rowId"> </mgError>
                    </div>
                  </mat-form-field>
                  <label *ngIf="!mg.isRowInRowEditing(row)" [magic]="mgc.Value" [rowId]="row.rowId"
                    [style.visibility]="mg.getVisible(mgc.Value, row.rowId)">
                    {{mg.getValue(mgc.Value, row.rowId)
                    }}
                  </label>
                </div>
              </td>
              <td [style.visibility]="mg.getVisible(mgc.Column10485771)">
                <div [formGroup]="mg.getFormGroupByRow(row.rowId)" style="display: flex; flex-direction: row">
                  <mat-form-field [style.visibility]="mg.getVisible(mgc.Message, row.rowId)"
                    *ngIf="mg.isRowInRowEditing(row)">
                    <div>
                      <input *ngIf="mg.isRowInRowEditing(row)" matInput [magic]="mgc.Message" [rowId]="row.rowId"
                        [formControlName]="mgc.Message" mgFormat>
                      <mgError [magic]=mgc.Message [rowId]="row.rowId"> </mgError>
                    </div>
                  </mat-form-field>
                  <label *ngIf="!mg.isRowInRowEditing(row)" [magic]="mgc.Message" [rowId]="row.rowId"
                    [style.visibility]="mg.getVisible(mgc.Message, row.rowId)">
                    {{mg.getValue(mgc.Message, row.rowId)
                    }}
                  </label>
                </div>
              </td>
            </tr>
          </ng-container>
        </table>
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <button mat-raised-button color="primary" [magic]="mgc.MG_B_QUIT" [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)">
        Mégsem
      </button>
      <button mat-raised-button color="primary" [magic]="mgc.MG_B_OK" [matTooltip]="mg.getTitle(mgc.MG_B_OK)">
        OK
      </button>
    </div>
  </div>
</div>
