<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        class="flex-container-column"
        magic="{{mgc.Keszlet2}}"
    >
        <div class="flex-container-row">
            <mat-card>
                <magic-subform magic="{{mgc.Subform}}">
                </magic-subform>
            </mat-card>
        </div>
        <div class="flex-container-row">
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.Lekerdezes}}"
                [disabled]="mg.isDisabled(mgc.Lekerdezes )"
            >
                \Lekérdezés
            </button>
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.Excel}}"
                [disabled]="mg.isDisabled(mgc.Excel )"
            >
                \Excel
            </button>
        </div>
    </div>
</div>