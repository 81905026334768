import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    Cikk = "Cikk",
        Label10485761 = "Label10485761",
        vonalkod = "vonalkod",
        Label10485763 = "Label10485763",
        aru_nev_szures = "aru_nev_szures",
        Label10485765 = "Label10485765",
        tipus = "tipus",
        Label10485766 = "Label10485766",
        kategoriak = "kategoriak",
        katvg = "katvg",
        Label10485768 = "Label10485768",
        szerzo = "szerzo",
        szerzovg = "szerzovg",
        Label10485770 = "Label10485770",
        kiado = "kiado",
        kiadovg = "kiadovg",
        Label10485772 = "Label10485772",
        sorozat = "sorozat",
        sorozatvg = "sorozatvg",
        Label10485774 = "Label10485774",
        isbn = "isbn",
        Label10485777 = "Label10485777",
        kiadas_eve_tol = "kiadas_eve_tol",
        Label10485778 = "Label10485778",
        kiadas_eve_ig = "kiadas_eve_ig",
        Label10485781 = "Label10485781",
        eredeti_cim = "eredeti_cim",
        Label10485783 = "Label10485783",
        nyelv = "nyelv",
        Label10485784 = "Label10485784",
        Aru_recno = "Aru_recno",
        aru_nev = "aru_nev",
        aruvg = "aruvg",
        Label10485790 = "Label10485790",
        beszallito = "beszallito",
        HNEV = "HNEV",
        beszallitovg = "beszallitovg",
        Label10485788 = "Label10485788",
        status = "status",
        Label10485792 = "Label10485792",
        KIM = "KIM",
        reszletes = "reszletes",
        beszallito_lista = "beszallito_lista",
        beszallito_lista_torles = "beszallito_lista_torles",
        beszallitotmp = "beszallitotmp",
        Lekerdezes = "Lekerdezes",
        Excel = "Excel",
}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get vonalkod(): FormControl {
        return this.fg.controls[MgControlName.vonalkod] as FormControl;
    }

    get aru_nev_szures(): FormControl {
        return this.fg.controls[MgControlName.aru_nev_szures] as FormControl;
    }

    get tipus(): FormControl {
        return this.fg.controls[MgControlName.tipus] as FormControl;
    }

    get kategoriak(): FormControl {
        return this.fg.controls[MgControlName.kategoriak] as FormControl;
    }

    get szerzo(): FormControl {
        return this.fg.controls[MgControlName.szerzo] as FormControl;
    }

    get kiado(): FormControl {
        return this.fg.controls[MgControlName.kiado] as FormControl;
    }

    get sorozat(): FormControl {
        return this.fg.controls[MgControlName.sorozat] as FormControl;
    }

    get isbn(): FormControl {
        return this.fg.controls[MgControlName.isbn] as FormControl;
    }

    get kiadas_eve_tol(): FormControl {
        return this.fg.controls[MgControlName.kiadas_eve_tol] as FormControl;
    }

    get kiadas_eve_ig(): FormControl {
        return this.fg.controls[MgControlName.kiadas_eve_ig] as FormControl;
    }

    get eredeti_cim(): FormControl {
        return this.fg.controls[MgControlName.eredeti_cim] as FormControl;
    }

    get nyelv(): FormControl {
        return this.fg.controls[MgControlName.nyelv] as FormControl;
    }

    get Aru_recno(): FormControl {
        return this.fg.controls[MgControlName.Aru_recno] as FormControl;
    }

    get aru_nev(): FormControl {
        return this.fg.controls[MgControlName.aru_nev] as FormControl;
    }

    get beszallito(): FormControl {
        return this.fg.controls[MgControlName.beszallito] as FormControl;
    }

    get HNEV(): FormControl {
        return this.fg.controls[MgControlName.HNEV] as FormControl;
    }

    get status(): FormControl {
        return this.fg.controls[MgControlName.status] as FormControl;
    }

    get KIM(): FormControl {
        return this.fg.controls[MgControlName.KIM] as FormControl;
    }

    get reszletes(): FormControl {
        return this.fg.controls[MgControlName.reszletes] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}