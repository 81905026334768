<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        class="flex-container-column"
        magic="{{mgc.Forgalmak}}"
    >
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label1}}"
                class="lable_overflow"
            >
                Idoszak:
            </label>
            <mat-form-field
                magic="{{mgc.Datumtol}}"
                [eventsOnly]=true
            >
                <input
                    matInput
                    [matDatepicker]="Datumtol"
                    magic="{{mgc.Datumtol}}"
                    formControlName="{{mgc.Datumtol}}"
                >
                <mat-datepicker-toggle
                    matSuffix
                    [for]="Datumtol"
                    *ngIf="mg.checkIsReadOnly(mgc.Datumtol)"
                >
                </mat-datepicker-toggle>
                <mat-datepicker #Datumtol></mat-datepicker>
            </mat-form-field>
            <label
                magic="{{mgc.Label4}}"
                class="lable_overflow"
            >
                -
            </label>
            <mat-form-field
                magic="{{mgc.Datumig}}"
                [eventsOnly]=true
            >
                <input
                    matInput
                    [matDatepicker]="Datumig"
                    magic="{{mgc.Datumig}}"
                    formControlName="{{mgc.Datumig}}"
                >
                <mat-datepicker-toggle
                    matSuffix
                    [for]="Datumig"
                    *ngIf="mg.checkIsReadOnly(mgc.Datumig)"
                >
                </mat-datepicker-toggle>
                <mat-datepicker #Datumig></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label6}}"
                class="lable_overflow"
            >
                Beszallito:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        type='number'
                        magic="{{mgc.beszallito}}"
                        formControlName="{{mgc.beszallito}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.beszallito}}> </mgError>
                </div>
            </mat-form-field>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.HNEV}}"
                        formControlName="{{mgc.HNEV}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.HNEV}}> </mgError>
                </div>
            </mat-form-field>
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.beszallitovg}}"
                [disabled]="mg.isDisabled(mgc.beszallitovg )"
            >
                6
            </button>
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.beszallito_lista}}"
                [disabled]="mg.isDisabled(mgc.beszallito_lista )"
            >
                beszallito lista
            </button>
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.beszallito_lista_torles}}"
                [disabled]="mg.isDisabled(mgc.beszallito_lista_torles )"
            >
                beszallito lista torles
            </button>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label8}}"
                class="lable_overflow"
            >
                Konyv:
            </label>
            <label
                magic="{{mgc.Label11}}"
                class="lable_overflow"
            >
                aru nev:
            </label>
            <label
                magic="{{mgc.Label15}}"
                class="lable_overflow"
            >
                isbn:
            </label>
            <label
                magic="{{mgc.Label18}}"
                class="lable_overflow"
            >
                szerzo:
            </label>
            <label
                magic="{{mgc.Label20}}"
                class="lable_overflow"
            >
                kiado:
            </label>
            <label
                magic="{{mgc.Label22}}"
                class="lable_overflow"
            >
                sorozat:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        type='number'
                        magic="{{mgc.Aru_recno}}"
                        formControlName="{{mgc.Aru_recno}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.Aru_recno}}> </mgError>
                </div>
            </mat-form-field>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.aru_nev_szures}}"
                        formControlName="{{mgc.aru_nev_szures}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.aru_nev_szures}}> </mgError>
                </div>
            </mat-form-field>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.isbn}}"
                        formControlName="{{mgc.isbn}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.isbn}}> </mgError>
                </div>
            </mat-form-field>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.szerzo}}"
                        formControlName="{{mgc.szerzo}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.szerzo}}> </mgError>
                </div>
            </mat-form-field>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.kiado}}"
                        formControlName="{{mgc.kiado}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.kiado}}> </mgError>
                </div>
            </mat-form-field>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.sorozat}}"
                        formControlName="{{mgc.sorozat}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.sorozat}}> </mgError>
                </div>
            </mat-form-field>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.aru_nev}}"
                        formControlName="{{mgc.aru_nev}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.aru_nev}}> </mgError>
                </div>
            </mat-form-field>
            <label
                magic="{{mgc.Label17}}"
                class="lable_overflow"
            >
                kiadas eve:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.kiadas_eve_tol}}"
                        formControlName="{{mgc.kiadas_eve_tol}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.kiadas_eve_tol}}> </mgError>
                </div>
            </mat-form-field>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.kiadas_eve_ig}}"
                        formControlName="{{mgc.kiadas_eve_ig}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.kiadas_eve_ig}}> </mgError>
                </div>
            </mat-form-field>
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.kiadovg}}"
            >
                6
            </button>
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.sorozatvg}}"
            >
                6
            </button>
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.aruvg}}"
            >
                6
            </button>
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.szerzovg}}"
            >
                6
            </button>
            <mat-card>
                <magic-subform magic="{{mgc.beszallitotmp}}">
                </magic-subform>
            </mat-card>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label24}}"
                class="lable_overflow"
            >
                kategoriak:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.kategoriak}}"
                        formControlName="{{mgc.kategoriak}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.kategoriak}}> </mgError>
                </div>
            </mat-form-field>
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.katvg}}"
            >
                6
            </button>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label26}}"
                class="lable_overflow"
            >
                bolt tipus:
            </label>
            <div>
                <mat-form-field>
                    <mat-select
                        magic="{{mgc.bolt_tipus}}"
                        formControlName="{{mgc.bolt_tipus}}"
                    >
                        <mat-option
                            *ngFor="let o of mg.getItemListValues(mgc.bolt_tipus);"
                            [value]="o.index"
                        >
                            {{o.displayValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <mat-checkbox
                    magic="{{mgc.nagyker}}"
                    formControlName="{{mgc.nagyker}}"
                >
                    nagyker
                </mat-checkbox>
            </div>
            <div>
                <mat-checkbox
                    magic="{{mgc.online}}"
                    formControlName="{{mgc.online}}"
                >
                    online
                </mat-checkbox>
            </div>
            <div>
                <mat-checkbox
                    magic="{{mgc.nemonline}}"
                    formControlName="{{mgc.nemonline}}"
                >
                    nemonline?
                </mat-checkbox>
            </div>
            <div>
                <mat-form-field>
                    <mat-select
                        magic="{{mgc.LiraLira_kiskerRozsavolgyi}}"
                        formControlName="{{mgc.LiraLira_kiskerRozsavolgyi}}"
                    >
                        <mat-option
                            *ngFor="let o of mg.getItemListValues(mgc.LiraLira_kiskerRozsavolgyi);"
                            [value]="o.index"
                        >
                            {{o.displayValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="flex-container-row">
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.bolt_lista}}"
                [disabled]="mg.isDisabled(mgc.bolt_lista )"
            >
                bolt lista
            </button>
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.bolt_lista_torles}}"
                [disabled]="mg.isDisabled(mgc.bolt_lista_torles )"
            >
                bolt lista torles
            </button>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label27}}"
                class="lable_overflow"
            >
                bolt
            </label>
            <label magic="{{mgc.bolt}}">
                {{mg.getValue(mgc.bolt) }}
            </label>
            <label magic="{{mgc.bolt_nev}}">
                {{mg.getValue(mgc.bolt_nev) }}
            </label>
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.boltvg}}"
                [disabled]="mg.isDisabled(mgc.boltvg )"
            >
                6
            </button>
        </div>
        <div class="flex-container-row">
            <mat-card>
                <magic-subform magic="{{mgc.bolttmp}}">
                </magic-subform>
            </mat-card>
        </div>
        <div class="flex-container-row">
            <mat-card
                magic="{{mgc.Group48}}"
                class="container_border"
            >
                <div class="flex-container-row">
                    <div>
                        <mat-checkbox
                            magic="{{mgc.datum}}"
                            formControlName="{{mgc.datum}}"
                        >
                            datum?
                        </mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox
                            magic="{{mgc.bolt_0001}}"
                            formControlName="{{mgc.bolt_0001}}"
                        >
                            bolt?
                        </mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox
                            magic="{{mgc.qbeszallito}}"
                            formControlName="{{mgc.qbeszallito}}"
                        >
                            beszallito?
                        </mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox
                            magic="{{mgc.cikk}}"
                            formControlName="{{mgc.cikk}}"
                        >
                            cikk?
                        </mat-checkbox>
                    </div>
                </div>
                <div class="flex-container-row">
                    <div>
                        <mat-checkbox
                            magic="{{mgc.mennyiseg}}"
                            formControlName="{{mgc.mennyiseg}}"
                        >
                            mennyiseg?
                        </mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox
                            magic="{{mgc.eladasi_ar}}"
                            formControlName="{{mgc.eladasi_ar}}"
                        >
                            eladasi ar?
                        </mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox
                            magic="{{mgc.listaar}}"
                            formControlName="{{mgc.listaar}}"
                        >
                            listaar?
                        </mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox
                            magic="{{mgc.beszerzesi_ar}}"
                            formControlName="{{mgc.beszerzesi_ar}}"
                        >
                            beszerzesi ar?
                        </mat-checkbox>
                    </div>
                </div>
            </mat-card>
        </div>
        <div class="flex-container-row">
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.Lekerdezes}}"
            >
                lekerdezes
            </button>
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.Excel}}"
            >
                excel
            </button>
        </div>
    </div>
</div>