import { Component } from "@angular/core";

import { FormGroup } from "@angular/forms";
import {
  MgFormControlsAccessor,
  MgControlName,
} from "./Keszletlista2.mg.controls.g";

import {
  BaseMatTableMagicComponent,
  matMagicProviders,
} from "@magic-xpa/angular-material-core";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

import {
  CdkDragStart,
  CdkDropList,
  moveItemInArray,
} from "@angular/cdk/drag-drop";
import {PageEvent} from '@angular/material/paginator';

import { MagicModalInterface } from "@magic-xpa/angular";
import { registerLocaleData } from "@angular/common";
import hu from "@angular/common/locales/hu";

@Component({
  selector: "mga-Keszletlista2",
  providers: [...matMagicProviders],
  templateUrl: "./Keszletlista2.component.html",
  styleUrls: ["./Keszletlista2.component.scss"],
})
export class Keszletlista2
  extends BaseMatTableMagicComponent
  implements MagicModalInterface {
  mgc = MgControlName;
  mgfc: MgFormControlsAccessor;

  createFormControlsAccessor(formGroup: FormGroup) {
    this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
    registerLocaleData(hu);
  }

  displayedColumns = [
    "Column10485765",
    "Column10485766",
    "Column10485767",
    "Column10485768",
    "Column10485774",
    "Column10485779",
    "Column10485782"
  ];

  previousIndex: number;
  columns: any[] = [
    { field: "Column10485768", name: "Forgalom", mezo: "forgalom" },
    { field: "Column10485774", name: "Listaáron", mezo: "brlistaaron" },
    { field: "Column10485779", name: "Készlet", mezo: "keszlet" },
    { field: "Column10485782", name: "altip", mezo: "altip" },
  ]; //hosszú távon generáljuk23456789101112131415161718

  private static readonly formName: string = "Keszletlista2";
  private static readonly showTitleBar: boolean = true;
  private static readonly x: number = 0;
  private static readonly y: number = 0;
  private static readonly width: string = "300px";
  private static readonly height: string = "300px";
  private static readonly isCenteredToWindow: boolean = true;
  private static readonly shouldCloseOnBackgroundClick = true;
  X() {
    return Keszletlista2.x;
  }
  Y() {
    return Keszletlista2.y;
  }
  Width(): string {
    return Keszletlista2.width;
  }
  Height(): string {
    return Keszletlista2.height;
  }
  IsCenteredToWindow() {
    return Keszletlista2.isCenteredToWindow;
  }
  FormName() {
    return Keszletlista2.formName;
  }
  ShowTitleBar() {
    return Keszletlista2.showTitleBar;
  }
  ShouldCloseOnBackgroundClick() {
    return Keszletlista2.shouldCloseOnBackgroundClick;
  }

  setDisplayedColumns() {
    this.columns.forEach((colunm, index) => {
      colunm.index = index;
      this.displayedColumns[index] = colunm.field;
    });
    console.log(this.displayedColumns);
  }

  dragStarted(event: CdkDragStart, index: number) {
    this.previousIndex = index;
  }

  dropListDropped(event: CdkDropList, index: number) {
    if (event) {
      moveItemInArray(this.columns, this.previousIndex, index);
      this.setDisplayedColumns();
    }
  }

  paginator_pageSize: number = 50;
  paginator_pageSizeOptions: string = "20,50,100";
  GETpageSize(): number {
    this.paginator_pageSize = this.mg.getValue(this.mgc.paginator_pageSize)
      ? Number(this.mg.getValue(this.mgc.paginator_pageSize))
      : this.paginator_pageSize;
    return this.paginator_pageSize;
  }

  SETpageSize(event: PageEvent) {
    this.tableService.mgOnPaginateChange(event);
    this.mg.setValueToControl(
      this.mgc.paginator_pageSize,
      Number(event.pageSize),
      false
    );
  }

  pageSizeOptions(): number[] {
    this.paginator_pageSizeOptions = this.mg.getCustomProperty(
      this.mgc.Keszletlista2,
      "paginator_pageSizeOptions"
    )
      ? this.mg.getCustomProperty(
          this.mgc.Keszletlista2,
          "paginator_pageSizeOptions"
        )
      : this.paginator_pageSizeOptions;
    return this.paginator_pageSizeOptions.split(",").map((str) => +str);//ha magicből jött adat akkor azt használjuk, ha nem akkor az alapértéket
  }
}
