import { Component } from "@angular/core";

import { FormGroup } from "@angular/forms";
import {
  MgFormControlsAccessor,
  MgControlName,
} from "./Aruvalasztas.mg.controls.g";

import {
  BaseMatTableMagicComponent,
  matMagicProviders,
} from "@magic-xpa/angular-material-core";

import { MagicModalInterface } from "@magic-xpa/angular";

import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import {
  CdkDragStart,
  CdkDropList,
  moveItemInArray,
} from "@angular/cdk/drag-drop";

@Component({
  selector: "mga-Aruvalasztas",
  providers: [...matMagicProviders],
  templateUrl: "./Aruvalasztas.component.html",
  styleUrls: ['./AruValasztasLista2.scss']
})
export class Aruvalasztas
  extends BaseMatTableMagicComponent
  implements MagicModalInterface {
  mgc = MgControlName;
  mgfc: MgFormControlsAccessor;
  faWindowClose = faWindowClose;
  MG_D_SEARCH="";
  onEnter() {
  }

  createFormControlsAccessor(formGroup: FormGroup) {
    this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
  }

  private static readonly formName: string = "Áru választás";
  private static readonly showTitleBar: boolean = true;
  private static readonly x: number = 0;
  private static readonly y: number = 0;
  private static readonly width: string = "300px";
  private static readonly height: string = "300px";
  private static readonly isCenteredToWindow: boolean = true;
  private static readonly shouldCloseOnBackgroundClick = true;
  X() {
    return Aruvalasztas.x;
  }
  Y() {
    return Aruvalasztas.y;
  }
  Width(): string {
    return Aruvalasztas.width;
  }
  Height(): string {
    return Aruvalasztas.height;
  }
  IsCenteredToWindow() {
    return Aruvalasztas.isCenteredToWindow;
  }
  FormName() {
    //console.log(this.mg.getValue("MG_FORMNAME"));
    //return this.mg.getValue("MG_FORMNAME");
    return Aruvalasztas.formName;
  }
  ShowTitleBar() {
    return Aruvalasztas.showTitleBar;
  }
  ShouldCloseOnBackgroundClick() {
    return Aruvalasztas.shouldCloseOnBackgroundClick;
  }

  displayedColumns = [
    "Column10485773",
    "Column10485774",
    "Column10485775",
    "Column10485776",
    "Column10485777",
    "Column10485778",
    "Column10485779"
  ];

  previousIndex: number;
  columns: any[] = [
    { field: "Column10485773", name: "Cikkszám", mezo: "MG_T01C001D001" },
    { field: "Column10485774", name: "Cikk név", mezo: "MG_T01C002D001" },
    { field: "Column10485775", name: "ISBN", mezo: "MG_T01C003D001" },
    { field: "Column10485776", name: "Beszállító", mezo: "MG_T01C004D001" },
    { field: "Column10485777", name: "Szerző", mezo: "MG_T01C005D001" },
    { field: "Column10485778", name: "Cikk típus", mezo: "MG_T01C006D001" },
    { field: "Column10485779", name: "Kiválaszt", mezo: "MG_T01C007D001" },
  ]; //hosszú távon generáljuk

  setDisplayedColumns() {
    this.columns.forEach((colunm, index) => {
      colunm.index = index;
      this.displayedColumns[index] = colunm.field;
    });
    console.log(this.displayedColumns);
  }

  dragStarted(event: CdkDragStart, index: number) {
    this.previousIndex = index;
  }

  dropListDropped(event: CdkDropList, index: number) {
    if (event) {
      moveItemInArray(this.columns, this.previousIndex, index);
      this.setDisplayedColumns();
    }
  }
}
