import {
    Component
} from '@angular/core';

import {
    FormGroup
} from "@angular/forms";
import {
    MgFormControlsAccessor,
    MgControlName
} from "./Login2.mg.controls.g";


import {
    TaskBaseMagicComponent,
    magicProviders
} from "@magic-xpa/angular";


import {
    MagicModalInterface
} from "@magic-xpa/angular";

import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: 'mga-Login2',
    providers: [...magicProviders],
    templateUrl: './Login2.component.html',
    styleUrls: ['./Login2.component.scss']
})
export class Login2 extends TaskBaseMagicComponent implements MagicModalInterface {

    mgc = MgControlName;
    mgfc: MgFormControlsAccessor;
    faWindowClose = faWindowClose;
    createFormControlsAccessor(formGroup: FormGroup) {
        this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
    }
    private static readonly formName: string = "Login2";
    private static readonly showTitleBar: boolean = true;
    private static readonly x: number = 0;
    private static readonly y: number = 0;
    private static readonly width: string = "300px";
    private static readonly height: string = "300px";
    private static readonly isCenteredToWindow: boolean = true;
    private static readonly shouldCloseOnBackgroundClick = true;
    X() {
        return Login2.x;
    }
    Y() {
        return Login2.y;
    }
    Width(): string {
        return Login2.width;
    }
    Height(): string {
        return Login2.height;
    }
    IsCenteredToWindow() {
        return Login2.isCenteredToWindow;
    }
    FormName() {
        return Login2.formName;
    }
    ShowTitleBar() {
        return Login2.showTitleBar;
    }
    ShouldCloseOnBackgroundClick() {
        return Login2.shouldCloseOnBackgroundClick;
    }
}
