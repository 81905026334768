import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms'

import {
  MgFormControlsAccessor,
  MgControlName
} from "./UjcikkContributors.mg.controls.g";

import {
  TaskBaseMagicComponent,
  magicProviders
} from "@magic-xpa/angular";

import { faTimes, faList } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'mga-UjcikkContributors',
  providers: [...magicProviders],
  templateUrl: './UjcikkContributors.component.html',
  styleUrls: ['./UjcikkContributors.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class UjcikkContributors extends TaskBaseMagicComponent {
  mgc = MgControlName;
  mgfc: MgFormControlsAccessor;
  faTimes = faTimes;
  faList = faList;
  createFormControlsAccessor(formGroup: FormGroup) {
    this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
  }
  /*reactiveForm = new FormGroup({
    filterListaCtrl: new FormControl()
  })*/
  public filterListaCtrl: FormControl = new FormControl();
  filteredList: Array<mgList>;
  filterLista(obj: Array<mgList>, filter: string = '') {
    this.filteredList = obj.filter(obj_item => obj_item.displayValue.includes(filter));
    console.log('filterLista: OK');
    //console.log(this.filterListaCtrl.value);
    this.filterListaCtrl.valueChanges.subscribe(
      selectedValue => {
        this.filteredList = obj.filter(obj_item => obj_item.displayValue.toUpperCase().includes(String(selectedValue).toUpperCase()));
        //console.log('filterListaCtrl changed')
        //console.log(selectedValue)
      }
    )
  }
}

interface mgList {
  index: number,
  displayValue: string
}
