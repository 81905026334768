import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    testVariables = "testVariables",
        pu_nev = "pu_nev",
        punevbt = "punevbt",
        RNEV = "RNEV",
        Label10485774 = "Label10485774",
        puref_N = "puref_N",
        Label9 = "Label9",
        talalt = "talalt",
        Label10485776 = "Label10485776",
        sfexecmode = "sfexecmode",
        Label10485778 = "Label10485778",
        visszadob = "visszadob",
        Label10485780 = "Label10485780",
        vege = "vege",
}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get pu_nev(): FormControl {
        return this.fg.controls[MgControlName.pu_nev] as FormControl;
    }

    get RNEV(): FormControl {
        return this.fg.controls[MgControlName.RNEV] as FormControl;
    }

    get puref_N(): FormControl {
        return this.fg.controls[MgControlName.puref_N] as FormControl;
    }

    get talalt(): FormControl {
        return this.fg.controls[MgControlName.talalt] as FormControl;
    }

    get sfexecmode(): FormControl {
        return this.fg.controls[MgControlName.sfexecmode] as FormControl;
    }

    get visszadob(): FormControl {
        return this.fg.controls[MgControlName.visszadob] as FormControl;
    }

    get vege(): FormControl {
        return this.fg.controls[MgControlName.vege] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}