import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    Cikklista = "Cikklista",
        MG_T01 = "MG_T01",
        Column10485762 = "Column10485762",
        cikkazon = "cikkazon",
        Column10485763 = "Column10485763",
        isbn = "isbn",
        Column10485764 = "Column10485764",
        vonalkod = "vonalkod",
        Column10485765 = "Column10485765",
        arunev = "arunev",
        Column10485766 = "Column10485766",
        szerzo = "szerzo",
        Column10485767 = "Column10485767",
        eredeti_kiado = "eredeti_kiado",
        Column10485768 = "Column10485768",
        beszallito = "beszallito",
        Column10485769 = "Column10485769",
        kategoria = "kategoria",
        Column10485770 = "Column10485770",
        sorozat = "sorozat",
        Column10485771 = "Column10485771",
        kiadas_eve = "kiadas_eve",
        Column10485772 = "Column10485772",
        status = "status",
        Column10485773 = "Column10485773",
        keszlet = "keszlet",
        Column10485774 = "Column10485774",
        foglalt = "foglalt",
        Column10485775 = "Column10485775",
        fogy_ar = "fogy_ar",
}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get cikkazon(): FormControl {
        return this.getTableChildFormControl(MgControlName.cikkazon);
    }

    get isbn(): FormControl {
        return this.getTableChildFormControl(MgControlName.isbn);
    }

    get vonalkod(): FormControl {
        return this.getTableChildFormControl(MgControlName.vonalkod);
    }

    get arunev(): FormControl {
        return this.getTableChildFormControl(MgControlName.arunev);
    }

    get szerzo(): FormControl {
        return this.getTableChildFormControl(MgControlName.szerzo);
    }

    get eredeti_kiado(): FormControl {
        return this.getTableChildFormControl(MgControlName.eredeti_kiado);
    }

    get beszallito(): FormControl {
        return this.getTableChildFormControl(MgControlName.beszallito);
    }

    get kategoria(): FormControl {
        return this.getTableChildFormControl(MgControlName.kategoria);
    }

    get sorozat(): FormControl {
        return this.getTableChildFormControl(MgControlName.sorozat);
    }

    get kiadas_eve(): FormControl {
        return this.getTableChildFormControl(MgControlName.kiadas_eve);
    }

    get status(): FormControl {
        return this.getTableChildFormControl(MgControlName.status);
    }

    get keszlet(): FormControl {
        return this.getTableChildFormControl(MgControlName.keszlet);
    }

    get foglalt(): FormControl {
        return this.getTableChildFormControl(MgControlName.foglalt);
    }

    get fogy_ar(): FormControl {
        return this.getTableChildFormControl(MgControlName.fogy_ar);
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}