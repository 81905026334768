<div novalidate [formGroup]="screenFormGroup" class="startprogram">
  <table width="100%">
    <tr width="100%" style="display: flex">
      <td class="container" style="width: 120px; background-color: #fafafa">
        <img class="logo" src="assets/img/LogoLIRA.png" />
      </td>
      <td class="container" id="Menu" #Menu (click)="menuclick()" width="3%">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
      </td>
      <td class="navbar button" style="flex: 1">
        <button class="btn btn-default btn-md" type="button" routerLink="/">
          <fa-icon class="header_icon" [icon]="faHome"></fa-icon>
          <span class="header_text">Kezdőlap</span>
        </button>
        <h2>
          {{ mg.getValue(mgc.Appname) }}
        </h2>
      </td>
      <td class="navbar text" *ngIf="mg.getValue(mgc.gUserName)!=''">
        <h2>
          <span>Felhasználó: {{ mg.getValue(mgc.gUserName) }}</span>
          <button mat-raised-button color="primary" [magic]="mgc.btn_gZoom"
            [style.visibility]="mg.getVisible(mgc.btn_gZoom)" [disabled]="mg.isDisabled(mgc.btn_gZoom )">
            ...
          </button>
          <!--
          <button mat-raised-button color="primary" magic="{{ mgc.btn_gZoom }}"
            [style.visibility]="mg.getVisible(mgc.btn_gZoom)" [disabled]="mg.isDisabled(mgc.btn_gZoom)">
            ...
          </button>
          -->
        </h2>
      </td>
    </tr>
  </table>

  <div id="SideNav" #SideNav class="sidenav">
    <side-menu [source]="mg.getCustomProperty('StartProgram', 'Menu')"></side-menu>
    <div id="SideNavImprint" class="sidenavimprint" style="background-color: #041c2b;">
      <ul style="list-style-type: none; padding: 0;">
        <li style="padding: 0px 8px; list-style-type: none;">
          <span (click)="showContent('sidenavimprintContent', $event)"
            style="text-decoration: none; font-size: 16px; font-weight: 100; color: #fff; display: block; cursor: pointer;">
            Impresszum
          </span>
          <div (click)="showContent('sidenavimprintContent', $event)" class="sidenavimprintContent"
            id="sidenavimprintContent">
            <p>
              <span style="font-weight: bold;">Líra központi rendszer</span><br />
              <span>
                Verzió:
                <span style="font-weight: bold;">
                  {{this.version}} {{mg.getCustomProperty('StartProgram', 'Version')}}
                </span>
              </span><br />
              Tulajdonos: <span style="font-weight: bold;">Líra Könyv Zrt.</span><br />
              Cím: 1086 Budapest, Dankó u. 4-8.<br />
              Üzembe helyezve: 2020. március 1.<br />
              A fejlesztés a <br />
              VEKOP-2.1.1-15-2016-00119 <span style="font-size: 0.6rem;">"NoTorrent: Szerzői joggal védett
                művek jogszerű felhasználását, szolgáltatását, digitalizálását és az érdekeltek automatizált
                együttműködését
                innovatív módon lehetővé tevő kreatív ágazati nyílt infrastruktúra létrehozása" című kutatás fejlesztési
                projekt keretében valósult meg.</span>
            </p>
            <img style="max-width: 100%;" src="assets/img/szechenyi2020_final_felso.jpg" />
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div id="Main">
    <router-outlet magic="MainOutlet" class="MainOutletProps"> </router-outlet>
  </div>
</div>