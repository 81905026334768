import {
    Component
} from '@angular/core';

import {
    FormGroup
} from "@angular/forms";
import {
    MgFormControlsAccessor,
    MgControlName
} from "./Aruvalasztas.mg.controls.g";


	import {
		BaseMatTableMagicComponent,
		matMagicProviders
	} from "@magic-xpa/angular-material-core";
	import { faWindowClose } from "@fortawesome/free-solid-svg-icons";


@Component({
    selector: 'mga-Aruvalasztas',
    providers: [...matMagicProviders],
    templateUrl: './Aruvalasztas.component.html'
})
export class Aruvalasztas extends BaseMatTableMagicComponent {

    mgc = MgControlName;
    mgfc: MgFormControlsAccessor;faWindowClose = faWindowClose;
    createFormControlsAccessor(formGroup: FormGroup) {
        this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
    }

    displayedColumns = [
        'Column10485766',
        'Column10485767',
        'Column10485768',
        'Column10485769',
        'Column10485770',
        'Column10485771',
        'Column10485772',
    ];

}
