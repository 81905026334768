import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    WebClientTreememory = "WebClientTreememory",
        Table10485761 = "Table10485761",
        Column10485766 = "Column10485766",
        TmpId = "TmpId",
        Column10485767 = "Column10485767",
        KTFcs = "KTFcs",
        Column10485768 = "Column10485768",
        KTKodI = "KTKodI",
        Column10485769 = "Column10485769",
        KTMegnevezes = "KTMegnevezes",
}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get TmpId(): FormControl {
        return this.getTableChildFormControl(MgControlName.TmpId);
    }

    get KTFcs(): FormControl {
        return this.getTableChildFormControl(MgControlName.KTFcs);
    }

    get KTKodI(): FormControl {
        return this.getTableChildFormControl(MgControlName.KTKodI);
    }

    get KTMegnevezes(): FormControl {
        return this.getTableChildFormControl(MgControlName.KTMegnevezes);
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}