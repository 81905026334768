import { Component } from "@angular/core";

import { FormGroup } from "@angular/forms";
import {
  MgFormControlsAccessor,
  MgControlName,
} from "./Kategoria.mg.controls.g";

import {
  BaseMatTableMagicComponent,
  matMagicProviders,
} from "@magic-xpa/angular-material-core";

import { MagicModalInterface } from "@magic-xpa/angular";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "mga-Kategoria",
  providers: [...matMagicProviders],
  templateUrl: "./Kategoria.component.html",
  styleUrls: ["./Kategoria.component.scss"],
})
export class Kategoria
  extends BaseMatTableMagicComponent
  implements MagicModalInterface {
  mgc = MgControlName;
  mgfc: MgFormControlsAccessor;
  faWindowClose = faWindowClose;
  createFormControlsAccessor(formGroup: FormGroup) {
    this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
  }
  private static readonly formName: string = "Kategoria";
  private static readonly showTitleBar: boolean = true;
  private static readonly x: number = 0;
  private static readonly y: number = 0;
  private static readonly width: string = "300px";
  private static readonly height: string = "300px";
  private static readonly isCenteredToWindow: boolean = true;
  private static readonly shouldCloseOnBackgroundClick = true;
  X() {
    return Kategoria.x;
  }
  Y() {
    return Kategoria.y;
  }
  Width(): string {
    return Kategoria.width;
  }
  Height(): string {
    return Kategoria.height;
  }
  IsCenteredToWindow() {
    return Kategoria.isCenteredToWindow;
  }
  FormName() {
    return Kategoria.formName;
  }
  ShowTitleBar() {
    return Kategoria.showTitleBar;
  }
  ShouldCloseOnBackgroundClick() {
    return Kategoria.shouldCloseOnBackgroundClick;
  }

  displayedColumns = ["Column10485763", "Column10485764"];
}
