import { FormControl, FormGroup } from "@angular/forms";
import { MagicServices } from "@magic-xpa/angular";
export enum MgControlName {
  UploadImage = "UploadImage",
  Label13 = "Label13",
  vImageID = "vImageID",
  Label14 = "Label14",
  vImageDate = "vImageDate",
  Label15 = "Label15",
  vImageTime = "vImageTime",
  Label16 = "Label16",
  vDescription = "vDescription",
  Label17 = "Label17",
  vImagePath = "vImagePath",
  Label18 = "Label18",
  vServerFile = "vServerFile",
  Label19 = "Label19",
  vFileName = "vFileName",
  Label20 = "Label20",
  vFileNameU = "vFileNameU",
  Label21 = "Label21",
  vFileType = "vFileType",
  Label22 = "Label22",
  vDestinationFileName = "vDestinationFileName",
  vBlob64FromClient = "vBlob64FromClient",
  MG_FORMNAME = "MG_FORMNAME",
  MG_B_QUIT="MG_B_QUIT",
}
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) {}

  get vImageID(): FormControl {
    return this.fg.controls[MgControlName.vImageID] as FormControl;
  }

  get vImageDate(): FormControl {
    return this.fg.controls[MgControlName.vImageDate] as FormControl;
  }

  get vImageTime(): FormControl {
    return this.fg.controls[MgControlName.vImageTime] as FormControl;
  }

  get vDescription(): FormControl {
    return this.fg.controls[MgControlName.vDescription] as FormControl;
  }

  get vImagePath(): FormControl {
    return this.fg.controls[MgControlName.vImagePath] as FormControl;
  }

  get vServerFile(): FormControl {
    return this.fg.controls[MgControlName.vServerFile] as FormControl;
  }

  get vFileName(): FormControl {
    return this.fg.controls[MgControlName.vFileName] as FormControl;
  }

  get vFileNameU(): FormControl {
    return this.fg.controls[MgControlName.vFileNameU] as FormControl;
  }

  get vFileType(): FormControl {
    return this.fg.controls[MgControlName.vFileType] as FormControl;
  }

  get vDestinationFileName(): FormControl {
    return this.fg.controls[MgControlName.vDestinationFileName] as FormControl;
  }

  get vBlob64FromClient(): FormControl {
    return this.fg.controls[MgControlName.vBlob64FromClient] as FormControl;
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(
      this.magicServices.tableService.getSelectedRow()
    ).controls[name] as FormControl;
  }
}
