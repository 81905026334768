import {
    Component
} from '@angular/core';

import {
    FormGroup
} from "@angular/forms";
import {
    MgFormControlsAccessor,
    MgControlName
} from "./UjcikkLinks.mg.controls.g";


import {
    TaskBaseMagicComponent,
    magicProviders
} from "@magic-xpa/angular";

import { faTimes,faList } from '@fortawesome/free-solid-svg-icons';
@Component({
    selector: 'mga-UjcikkLinks',
    providers: [...magicProviders],
    templateUrl: './UjcikkLinks.component.html',
    styleUrls: ['./UjcikkLinks.component.scss']
})
export class UjcikkLinks extends TaskBaseMagicComponent {

    mgc = MgControlName;
    mgfc: MgFormControlsAccessor;
    faTimes = faTimes;
    faList=faList;
    createFormControlsAccessor(formGroup: FormGroup) {
        this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
    }
}
