<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        class="flex-container-column"
        magic="{{mgc.Keszletlista}}"
    >
        <div class="flex-container-row">
            <div class="example-container mat-elevation-z8 MG_T01TableContainerProps">
                <mat-table
                    #table
                    [dataSource]="dataSource"
                    class="container_border"
                    matSort
                    matSortDisableClear
                    (matSortChange)="tableService.sortData($event)"
                >
                    <ng-container
                        magic="{{mgc.Column10485762}}"
                        matColumnDef="{{mgc.Column10485762}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485762)"
                            mat-sort-header
                        > Árukód </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485762)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                type='number'
                                                magic="{{mgc.aru_kozp_id}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.aru_kozp_id}}"
                                                mgFormat
                                                rangevalidator
                                            >
                                            <mgError
                                                magic={{mgc.aru_kozp_id}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.aru_kozp_id}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.aru_kozp_id, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column10485763}}"
                        matColumnDef="{{mgc.Column10485763}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485763)"
                            mat-sort-header
                        > Áru név </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485763)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                magic="{{mgc.arunev}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.arunev}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.arunev}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.arunev}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.arunev, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column10485764}}"
                        matColumnDef="{{mgc.Column10485764}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485764)"
                            mat-sort-header
                        > ISBN </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485764)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                magic="{{mgc.isbn}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.isbn}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.isbn}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.isbn}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.isbn, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column10485765}}"
                        matColumnDef="{{mgc.Column10485765}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485765)"
                            mat-sort-header
                        > Szerző </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485765)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                magic="{{mgc.szerzo}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.szerzo}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.szerzo}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.szerzo}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.szerzo, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column10485766}}"
                        matColumnDef="{{mgc.Column10485766}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485766)"
                            mat-sort-header
                        > Eredeti kiadó </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485766)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                magic="{{mgc.eredeti_kiado}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.eredeti_kiado}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.eredeti_kiado}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.eredeti_kiado}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.eredeti_kiado, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column10485767}}"
                        matColumnDef="{{mgc.Column10485767}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485767)"
                            mat-sort-header
                        > ÁFA </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485767)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                type='number'
                                                magic="{{mgc.afa}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.afa}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.afa}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.afa}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.afa, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column10485768}}"
                        matColumnDef="{{mgc.Column10485768}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485768)"
                            mat-sort-header
                        > Boltszám </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485768)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                magic="{{mgc.boltszam}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.boltszam}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.boltszam}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.boltszam}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.boltszam, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column10485769}}"
                        matColumnDef="{{mgc.Column10485769}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485769)"
                            mat-sort-header
                        > Bolt név </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485769)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                magic="{{mgc.boltnev}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.boltnev}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.boltnev}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.boltnev}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.boltnev, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column10485770}}"
                        matColumnDef="{{mgc.Column10485770}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485770)"
                            mat-sort-header
                        > Beszállító </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485770)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                magic="{{mgc.beszallito}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.beszallito}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.beszallito}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.beszallito}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.beszallito, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column10485771}}"
                        matColumnDef="{{mgc.Column10485771}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485771)"
                            mat-sort-header
                        > Készlet </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485771)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                type='number'
                                                magic="{{mgc.forgalom}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.forgalom}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.forgalom}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.forgalom}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.forgalom, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column10485772}}"
                        matColumnDef="{{mgc.Column10485772}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485772)"
                            mat-sort-header
                        > Listaáron </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485772)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                type='number'
                                                magic="{{mgc.brlistaaron}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.brlistaaron}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.brlistaaron}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.brlistaaron}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.brlistaaron, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column10485773}}"
                        matColumnDef="{{mgc.Column10485773}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485773)"
                            mat-sort-header
                        > Nt. Besz. áron </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485773)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                type='number'
                                                magic="{{mgc.nebeszaron}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.nebeszaron}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.nebeszaron}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.nebeszaron}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.nebeszaron, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row
                        *matRowDef="let row; columns: displayedColumns;"
                        class="table_row"
                        [ngClass]="{ 'selected': selection.isSelected(row)}"
                        [magicRow]="row?.rowId"
                        (click)="tableService.selectRow(row.rowId)"
                    >
                    </mat-row>
                </mat-table>
                <mat-paginator
                    #paginator
                    [pageSize]="10"
                    [pageSizeOptions]="[5, 10, 20]"
                    [showFirstLastButtons]="true"
                    (page)="tableService.mgOnPaginateChange($event)"
                >
                </mat-paginator>
            </div>
        </div>
        <div class="flex-container-row">
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.btn_Print_Data}}"
            >
                Export
            </button>
        </div>
    </div>
</div>
