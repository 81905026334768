import {
    Component
} from '@angular/core';

import {
    FormGroup
} from "@angular/forms";
import {
    MgFormControlsAccessor,
    MgControlName
} from "./Cikklista.mg.controls.g";


	import {
		BaseMatTableMagicComponent,
		matMagicProviders
	} from "@magic-xpa/angular-material-core";
	import { faWindowClose } from "@fortawesome/free-solid-svg-icons";


import {
    MagicModalInterface
} from "@magic-xpa/angular";

@Component({
    selector: 'mga-Cikklista',
    providers: [...matMagicProviders],
    templateUrl: './Cikklista.component.html'
})
export class Cikklista extends BaseMatTableMagicComponent implements MagicModalInterface {

    mgc = MgControlName;
    mgfc: MgFormControlsAccessor;
    createFormControlsAccessor(formGroup: FormGroup) {
        this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
    }
    private static readonly formName: string = "Cikklista";
    private static readonly showTitleBar: boolean = true;
    private static readonly x: number = 0;
    private static readonly y: number = 0;
    private static readonly width: string = "300px";
    private static readonly height: string = "300px";
    private static readonly isCenteredToWindow: boolean = true;
    private static readonly shouldCloseOnBackgroundClick = true;
    X() {
        return Cikklista.x;
    }
    Y() {
        return Cikklista.y;
    }
    Width(): string {
        return Cikklista.width;
    }
    Height(): string {
        return Cikklista.height;
    }
    IsCenteredToWindow() {
        return Cikklista.isCenteredToWindow;
    }
    FormName() {
        return Cikklista.formName;
    }
    ShowTitleBar() {
        return Cikklista.showTitleBar;
    }
    ShouldCloseOnBackgroundClick() {
        return Cikklista.shouldCloseOnBackgroundClick;
    }

    displayedColumns = [
        'Column10485762',
        'Column10485763',
        'Column10485764',
        'Column10485765',
        'Column10485766',
        'Column10485767',
        'Column10485768',
        'Column10485769',
        'Column10485770',
        'Column10485771',
        'Column10485772',
        'Column10485773',
        'Column10485774',
        'Column10485775',
    ];

}
