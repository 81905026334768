import { FormControl, FormGroup } from "@angular/forms";
import { MagicServices } from "@magic-xpa/angular";
export enum MgControlName {
  Beszallitovalasztas = "Beszallitovalasztas",
  Group10485769 = "Group10485769",
  MG_FL004 = "MG_FL004",
  MG_FD004 = "MG_FD004",
  MG_D_SEARCH = "MG_D_SEARCH",
  MG_CB_SELECTED = "MG_CB_SELECTED",
  MG_B_SEARCH2DEF = "MG_B_SEARCH2DEF",
  MG_B_SEARCH = "MG_B_SEARCH",
  MG_T01 = "MG_T01",
  Column10485777 = "Column10485777",
  MG_T01C001D001 = "MG_T01C001D001",
  Column10485778 = "Column10485778",
  MG_T01C002D001 = "MG_T01C002D001",
  Column10485779 = "Column10485779",
  MG_T01C003D001 = "MG_T01C003D001",
  Column10485780 = "Column10485780",
  MG_T01C004D001 = "MG_T01C004D001",
  Column10485781 = "Column10485781",
  MG_T01C005D001 = "MG_T01C005D001",
  Column10485782 = "Column10485782",
  MG_T01C006D001 = "MG_T01C006D001",
  Column10485783 = "Column10485783",
  MG_T01C007D001 = "MG_T01C007D001",
  MG_FL001 = "MG_FL001",
  MG_FD001 = "MG_FD001",
  MG_FD003 = "MG_FD003",
  MG_FD006 = "MG_FD006",
  MG_FD005 = "MG_FD005",
  MG_FL002 = "MG_FL002",
  MG_FD002 = "MG_FD002",
  Label10485768 = "Label10485768",
  I_tmpID = "I_tmpID",
  MG_B_SELECT = "MG_B_SELECT",
  MG_B_QUIT = "MG_B_QUIT",
  MG_FORMNAME = "MG_FORMNAME",
  NEV="NEV",
  btn_Search2default="btn_Search2default",
}
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) {}

  get MG_FD004(): FormControl {
    return this.fg.controls[MgControlName.MG_FD004] as FormControl;
  }

  get MG_D_SEARCH(): FormControl {
    return this.fg.controls[MgControlName.MG_D_SEARCH] as FormControl;
  }

  get MG_CB_SELECTED(): FormControl {
    return this.fg.controls[MgControlName.MG_CB_SELECTED] as FormControl;
  }

  get MG_T01C001D001(): FormControl {
    return this.getTableChildFormControl(MgControlName.MG_T01C001D001);
  }

  get MG_T01C002D001(): FormControl {
    return this.getTableChildFormControl(MgControlName.MG_T01C002D001);
  }

  get MG_T01C003D001(): FormControl {
    return this.getTableChildFormControl(MgControlName.MG_T01C003D001);
  }

  get MG_T01C004D001(): FormControl {
    return this.getTableChildFormControl(MgControlName.MG_T01C004D001);
  }

  get MG_T01C005D001(): FormControl {
    return this.getTableChildFormControl(MgControlName.MG_T01C005D001);
  }

  get MG_T01C006D001(): FormControl {
    return this.getTableChildFormControl(MgControlName.MG_T01C006D001);
  }

  get MG_T01C007D001(): FormControl {
    return this.getTableChildFormControl(MgControlName.MG_T01C007D001);
  }

  get MG_FD001(): FormControl {
    return this.fg.controls[MgControlName.MG_FD001] as FormControl;
  }

  get MG_FD003(): FormControl {
    return this.fg.controls[MgControlName.MG_FD003] as FormControl;
  }

  get MG_FD006(): FormControl {
    return this.fg.controls[MgControlName.MG_FD006] as FormControl;
  }

  get MG_FD005(): FormControl {
    return this.fg.controls[MgControlName.MG_FD005] as FormControl;
  }

  get MG_FD002(): FormControl {
    return this.fg.controls[MgControlName.MG_FD002] as FormControl;
  }

  get I_tmpID(): FormControl {
    return this.fg.controls[MgControlName.I_tmpID] as FormControl;
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(
      this.magicServices.tableService.getSelectedRow()
    ).controls[name] as FormControl;
  }
}
