<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        class="flex-container-column"
        magic="{{mgc.Aruvalasztas}}"
    >
        <div class="flex-container-row">
            <div class="example-container mat-elevation-z8 Table10485765TableContainerProps">
                <mat-table
                    #table
                    [dataSource]="dataSource"
                    class="container_border"
                    matSort
                    matSortDisableClear
                    (matSortChange)="tableService.sortData($event)"
                >
                    <ng-container
                        magic="{{mgc.Column10485766}}"
                        matColumnDef="{{mgc.Column10485766}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                        >
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <label
                                        magic="{{mgc.azonosito}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.azonosito, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column10485767}}"
                        matColumnDef="{{mgc.Column10485767}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                        > aru nev </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <label
                                        magic="{{mgc.aru_nev}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.aru_nev, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column10485768}}"
                        matColumnDef="{{mgc.Column10485768}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                        > isbn </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <label
                                        magic="{{mgc.tematika}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.tematika, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column10485769}}"
                        matColumnDef="{{mgc.Column10485769}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                        > beszallito </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <label
                                        magic="{{mgc.beszallito}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.beszallito, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column10485770}}"
                        matColumnDef="{{mgc.Column10485770}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                        > szerzo </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <label
                                        magic="{{mgc.szerzo}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.szerzo, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column10485771}}"
                        matColumnDef="{{mgc.Column10485771}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header
                        > cikk tipus </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                magic="{{mgc.cikcsoptip_nev}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.cikcsoptip_nev}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.cikcsoptip_nev}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.cikcsoptip_nev}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.cikcsoptip_nev, row.rowId)
}}
                                    </label>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container
                        magic="{{mgc.Column10485772}}"
                        matColumnDef="{{mgc.Column10485772}}"
                    >
                        <mat-header-cell
                            *matHeaderCellDef
                            [style.visibility]="mg.getVisible(mgc.Column10485772)"
                        > </mat-header-cell>
                        <mat-cell
                            *matCellDef="let row"
                            [style.visibility]="mg.getVisible(mgc.Column10485772)"
                            magicMark="magicTableRowContainer"
                        >
                            <div
                                class="flex-container-row"
                                *ngIf="mg.ifRowCreated(row)"
                            >
                                <div [formGroup]="mg.getFormGroupByRow(row.rowId)">
                                    <mat-checkbox
                                        magic="{{mgc.kivalasztva}}"
                                        [rowId]="row.rowId"
                                        formControlName="{{mgc.kivalasztva}}"
                                    >
                                    </mat-checkbox>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row
                        *matRowDef="let row; columns: displayedColumns;"
                        class="table_row"
                        [ngClass]="{ 'selected': selection.isSelected(row)}"
                        [magicRow]="row?.rowId"
                        (click)="tableService.selectRow(row.rowId)"
                    >
                    </mat-row>
                </mat-table>
                <mat-paginator
                    #paginator
                    [pageSize]="10"
                    [pageSizeOptions]="[5, 10, 20]"
                    [showFirstLastButtons]="true"
                    (page)="tableService.mgOnPaginateChange($event)"
                >
                </mat-paginator>
            </div>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label10485761}}"
                class="lable_overflow"
            >
                tematika
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.tematika_nev}}"
                        formControlName="{{mgc.tematika_nev}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.tematika_nev}}> </mgError>
                </div>
            </mat-form-field>
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.KivalasztNYG}}"
            >
                Kivalaszt
            </button>
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.MegsemNYG}}"
            >
                Megsem
            </button>
            <label
                magic="{{mgc.Label10485763}}"
                class="lable_overflow"
            >
                Egyeb az:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.additional_ref}}"
                        formControlName="{{mgc.additional_ref}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.additional_ref}}> </mgError>
                </div>
            </mat-form-field>
        </div>
    </div>
</div>
