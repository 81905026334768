import { FormControl, FormGroup } from "@angular/forms";
import { MagicServices } from "@magic-xpa/angular";
export enum MgControlName {
  BoltTipusMSWC = "BoltTipusMSWC",
  Mind = "Mind",
  vector = "vector",
  MG_FORMNAME = "MG_FORMNAME",
}
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) {}

  get Mind(): FormControl {
    return this.fg.controls[MgControlName.Mind] as FormControl;
  }

  get vector(): FormControl {
    return this.fg.controls[MgControlName.vector] as FormControl;
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(
      this.magicServices.tableService.getSelectedRow()
    ).controls[name] as FormControl;
  }
}
