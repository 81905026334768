import { MatPaginatorIntl } from '@angular/material/paginator';

const huRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) { return `0 van ${length}`; }
  
  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

  return `${startIndex + 1}. - ${endIndex}. a ${length}-ból`;
}


export function getHuPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();
  
  paginatorIntl.itemsPerPageLabel = 'Elemek száma oldalanként:';
  paginatorIntl.nextPageLabel = 'Következő oldal';
  paginatorIntl.previousPageLabel = 'Előző oldal';
  paginatorIntl.firstPageLabel = 'Első oldal';
  paginatorIntl.lastPageLabel = 'Utolsó oldal';
  paginatorIntl.getRangeLabel = huRangeLabel;

  
  //[showFirstLastButtons]="showFirstLastButtons"
  
  return paginatorIntl;
}