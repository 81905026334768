import { Component } from "@angular/core";

import { FormGroup } from "@angular/forms";
import {
  MgFormControlsAccessor,
  MgControlName,
} from "./Cikktipusvalasztas.mg.controls.g";

import {
  BaseMatTableMagicComponent,
  matMagicProviders,
} from "@magic-xpa/angular-material-core";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "mga-Cikktipusvalasztas",
  providers: [...matMagicProviders],
  templateUrl: "./Cikktipusvalasztas.component.html",
  styleUrls: ["./Cikktipusvalasztas.component.scss"],
})
export class Cikktipusvalasztas extends BaseMatTableMagicComponent {
  mgc = MgControlName;
  mgfc: MgFormControlsAccessor;
  faWindowClose = faWindowClose;
  createFormControlsAccessor(formGroup: FormGroup) {
    this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
  }

  displayedColumns = ["Column10485762"];
}
