<ul>
  <li *ngFor="let item of _source">
    <span *ngIf="item.children" (click)="liToggle(item, $event)">
      <!-- Főmenü: -->
      {{ item.name }}
      <side-menu [source]="item.children" [class.onOff]="item.isOpen"></side-menu>
    </span>
    <ng-template [ngIf]="item.url.indexOf('http') !== -1">
      <a *ngIf="!item.children" href="{{ item.url }}" target="_blank" (click)="aClick($event)">
        <fa-icon [icon]=this[item.icon]></fa-icon>
        {{ item.name }}
      </a>
    </ng-template>
    <ng-template [ngIf]="item.url.indexOf('http') == -1">
      <a *ngIf="!item.children" [routerLink]="item.url" (click)="aClick($event)">
        <fa-icon [icon]=this[item.icon]></fa-icon>
        {{ item.name }}
      </a>
    </ng-template>
  </li>
</ul>
