<!--
Eredmény_ng: <input type="text" class="form-control text" id="{{name}}" name="{{name}}" [(ngModel)]="result"/>
<button type="button" (click)="console_name()">Console: Name</button>
<button type="button" (click)="changeInputVar('asd')">Test Change</button>

<button type="button" (click)="console_name()">Console: Name,source</button>
<button type="button" (click)="loading()">Lista betöltése</button>


-->
<button *ngIf="mode == '1'" class="mat-raised-button mat-button-base mat-primary" (click)="treeControl.expandAll()">
  Mindegyik kibontása
</button>
<button *ngIf="mode == '1'" class="mat-raised-button mat-button-base mat-primary" (click)="treeControl.collapseAll()">
  Mindegyik összecsukása
</button>
<mat-checkbox class="checklist-leaf-node" (change)="search_checked(name, $event)" [checked]="selected_check">
  Kijelöltek
</mat-checkbox>
<input type="text" class="form-control text" style="margin-top: 5px; max-width: 300px" id="search_{{ name }}"
  name="{{ name }}" placeholder="szűrés" (input)="search(name, $event.target.value)" />

<mat-tree #tree [dataSource]="dataSource" [treeControl]="treeControl" id="tree_{{ name }}">
  <!--szülő-->
  <mat-tree-node class="szulo" *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename" class="tree-container">
      <mat-icon class="mat-icon-rtl-mirror">
        {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
      </mat-icon>
    </button>
    {{ node.item }}
  </mat-tree-node>
  <!--gyemekek:-->
  <mat-tree-node class="gyermek" *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
    <button mat-icon-button disabled></button>
    <mat-checkbox class="checklist-leaf-node" [checked]="node.checked" (change)="changeInputVar(node, $event)">
      {{ node.item }}
    </mat-checkbox>
  </mat-tree-node>
</mat-tree>