<div novalidate [formGroup]="screenFormGroup">
  <div class="flex-container-column" magic="{{ mgc.Beszallitovalasztas }}">
    <div #overlayheader class="my-overlay-header">
      <span>{{ mg.getValue("MG_FORMNAME") }}</span>
      <button class="input_button" magic="{{ mgc.MG_B_QUIT }}" [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)">
        <fa-icon [icon]="faWindowClose"></fa-icon>
      </button>
    </div>

    <!--vezérlés, keresés az ürlapon-->
    <div class="flex-container-row">
      <!--
          <label
            magic="{{ mgc.MG_FL004 }}"
            [style.visibility]="mg.getVisible(mgc.MG_FL004)"
            class="lable_overflow"
          >
            Típus
          </label>
          <mat-form-field [style.visibility]="mg.getVisible(mgc.MG_FD004)">
            <mat-select
              magic="{{ mgc.MG_FD004 }}"
              formControlName="{{ mgc.MG_FD004 }}"
            >
              <mat-option
                *ngFor="let o of mg.getItemListValues(mgc.MG_FD004)"
                [value]="o.index"
              >
                {{ o.displayValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          -->
      <mat-form-field class="MG_D_SEARCH-form-field" appearance="fill">
        <div>
          <mat-label></mat-label>
          <input matInput type="text" style="width:90%" magic="{{ mgc.MG_D_SEARCH }}" placeholder="Keresés"
            formControlName="{{ mgc.MG_D_SEARCH }}" mgFormat (keyup.enter)="onEnter()" [(ngModel)]="MG_D_SEARCH" />
          <button mat-button *ngIf="MG_D_SEARCH" matSuffix mat-icon-button aria-label="Clear" style="width:10%"
            (click)="MG_D_SEARCH=''">
            <mat-icon>close</mat-icon>
          </button>
          <mgError magic="{{ mgc.MG_D_SEARCH }}"> </mgError>
        </div>
      </mat-form-field>
      <!--
      <div>
        <mat-checkbox
          magic="{{ mgc.MG_CB_SELECTED }}"
          [style.visibility]="mg.getVisible(mgc.MG_CB_SELECTED)"
          formControlName="{{ mgc.MG_CB_SELECTED }}"
        >
          Kiválasztottak
        </mat-checkbox>
      </div>
      -->
      <button mat-raised-button color="primary" magic="{{ mgc.btn_Search2default }}"
        [style.visibility]="mg.getVisible(mgc.btn_Search2default)" [matTooltip]="mg.getTitle(mgc.btn_Search2default)">
        x
      </button>
      <button mat-raised-button color="primary" magic="{{ mgc.MG_B_SEARCH }}"
        [style.visibility]="mg.getVisible(mgc.MG_B_SEARCH)" [matTooltip]="mg.getTitle(mgc.MG_B_SEARCH)"
        id="beszallitoValasztas2_zoom">
        Keres
      </button>
    </div>

    <div class="flex-container-row" style="width: 100%">
      <div class="example-container mat-elevation-z8 MG_T01TableContainerProps" style="width: 100%">
        <mat-table #table [dataSource]="dataSource" class="container_border" matSort matSortDisableClear
          (matSortChange)="tableService.sortData($event)">
          <ng-container *ngFor="let column of columns; let i = index" magic="{{ mgc[column.field] }}"
            [matColumnDef]="column.field">
            <mat-header-cell *matHeaderCellDef cdkDropList cdkDropListLockAxis="x" cdkDropListOrientation="horizontal"
              (cdkDropListDropped)="dropListDropped($event, i)" cdkDrag (cdkDragStarted)="dragStarted($event, i)"
              [cdkDragData]="{ name: column.field, columIndex: i }" mat-sort-header
              [style.visibility]="mg.getVisible(mgc[column.field])">
              <ng-container *ngIf="column.name != 'Kiválaszt'">
                {{ column.name }}
              </ng-container>
              <ng-container *ngIf="column.name == 'Kiválaszt'">
                <mat-checkbox magic="{{ mgc.MG_FD006 }}" [style.visibility]="mg.getVisible(mgc.MG_FD006)"
                  formControlName="{{ mgc.MG_FD006 }}" style="margin: 12px">
                  <label magic="{{ mgc.MG_FD005 }}" [style.visibility]="mg.getVisible(mgc.MG_FD005)">
                    {{ mg.getValue(mgc.MG_FD005) }}
                  </label>
                </mat-checkbox>
              </ng-container>
            </mat-header-cell>
            <mat-cell *matCellDef="let row" [style.visibility]="mg.getVisible(mgc[column.field])"
              magicMark="magicTableRowContainer">
              <div class="flex-container-row" *ngIf="mg.ifRowCreated(row)">
                <div [formGroup]="mg.getFormGroupByRow(row.rowId)" class="flex-container-row">
                  <label *ngIf="column.name != 'Kiválaszt'" magic="{{ mgc[column.mezo] }}" [rowId]="row.rowId" class="{{
                      'altipus_' +
                        mg.getCustomProperty(mgc.NEV, 'altipus', row.rowId)
                    }}">
                    {{ mg.getValue(mgc[column.mezo], row.rowId) }}
                  </label>
                  <mat-checkbox *ngIf="column.name == 'Kiválaszt'" magic="{{ mgc[column.mezo] }}" [rowId]="row.rowId"
                    formControlName="{{ mgc[column.mezo] }}" style="margin: 12px">
                  </mat-checkbox>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <!--
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          -->
          <mat-row *matRowDef="let row; columns: displayedColumns" class="table_row"
            [ngClass]="{ selected: selection.isSelected(row) }" [magicRow]="row?.rowId"
            (click)="tableService.selectRow(row.rowId)">
          </mat-row>
        </mat-table>
        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"
          (page)="tableService.mgOnPaginateChange($event)">
        </mat-paginator>
      </div>
    </div>

    <!--lábléc-->
    <div class="flex-container-row">
      <label magic="{{ mgc.MG_FL001 }}" [style.visibility]="mg.getVisible(mgc.MG_FL001)" class="lable_overflow">
        Hosszú név
      </label>
      <!--
<div>
        <mat-checkbox
          magic="{{ mgc.MG_FD006 }}"
          [style.visibility]="mg.getVisible(mgc.MG_FD006)"
          formControlName="{{ mgc.MG_FD006 }}"
        >
        </mat-checkbox>
      </div>

      <label
        magic="{{ mgc.MG_FD005 }}"
        [style.visibility]="mg.getVisible(mgc.MG_FD005)"
      >
        {{ mg.getValue(mgc.MG_FD005) }}
      </label>

      -->
      <label magic="{{ mgc.MG_FD001 }}" [style.visibility]="mg.getVisible(mgc.MG_FD001)">
        {{ mg.getValue(mgc.MG_FD001) }}
      </label>
      <label magic="{{ mgc.MG_FD003 }}" [style.visibility]="mg.getVisible(mgc.MG_FD003)">
        {{ mg.getValue(mgc.MG_FD003) }}
      </label>
    </div>
    <div class="flex-container-row">
      <label magic="{{ mgc.MG_FL002 }}" [style.visibility]="mg.getVisible(mgc.MG_FL002)" class="lable_overflow">
        LN Név
      </label>
      <label magic="{{ mgc.MG_FD002 }}" [style.visibility]="mg.getVisible(mgc.MG_FD002)">
        {{ mg.getValue(mgc.MG_FD002) }}
      </label>
      <label magic="{{ mgc.Label10485768 }}" [style.visibility]="mg.getVisible(mgc.Label10485768)"
        class="lable_overflow">
        I_tmpID
      </label>
      <mat-form-field [style.visibility]="mg.getVisible(mgc.I_tmpID)">
        <div>
          <input matInput type="number" magic="{{ mgc.I_tmpID }}" formControlName="{{ mgc.I_tmpID }}" mgFormat />
          <mgError magic="{{ mgc.I_tmpID }}"> </mgError>
        </div>
      </mat-form-field>
      <button mat-raised-button color="primary" magic="{{ mgc.MG_B_SELECT }}"
        [matTooltip]="mg.getTitle(mgc.MG_B_SELECT)" id="beszallitoValasztas2_kivalaszt">
        Kiválaszt
      </button>
      <button mat-raised-button color="primary" magic="{{ mgc.MG_B_QUIT }}" [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)">
        Mégsem
      </button>
    </div>
  </div>
</div>