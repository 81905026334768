import { Component } from "@angular/core";

import { FormGroup } from "@angular/forms";
import { MgFormControlsAccessor, MgControlName } from "./Aruk.mg.controls.g";

import {
  BaseMatTableMagicComponent,
  matMagicProviders,
} from "@magic-xpa/angular-material-core";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "mga-Aruk",
  providers: [...matMagicProviders],
  templateUrl: "./Aruk.component.html",
  styleUrls: ["./Aruk.component.scss"],
})
export class Aruk extends BaseMatTableMagicComponent {
  mgc = MgControlName;
  mgfc: MgFormControlsAccessor;
  faWindowClose = faWindowClose;
  createFormControlsAccessor(formGroup: FormGroup) {
    this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
  }

  displayedColumns = ["Column10485762"];
}
