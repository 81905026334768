import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    Keszletlista = "Keszletlista",
        Table1 = "Table1",
        Column2 = "Column2",
        aru_kozp_id = "aru_kozp_id",
        Column3 = "Column3",
        arunev = "arunev",
        Column4 = "Column4",
        isbn = "isbn",
        Column5 = "Column5",
        szerzo = "szerzo",
        Column6 = "Column6",
        eredeti_kiado = "eredeti_kiado",
        Column7 = "Column7",
        afa = "afa",
        Column8 = "Column8",
        boltszam = "boltszam",
        Column9 = "Column9",
        boltnev = "boltnev",
        Column10 = "Column10",
        beszallito = "beszallito",
        Column11 = "Column11",
        forgalom = "forgalom",
        Column12 = "Column12",
        brlistaaron = "brlistaaron",
        Column13 = "Column13",
        nebeszaron = "nebeszaron",
}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get aru_kozp_id(): FormControl {
        return this.getTableChildFormControl(MgControlName.aru_kozp_id);
    }

    get arunev(): FormControl {
        return this.getTableChildFormControl(MgControlName.arunev);
    }

    get isbn(): FormControl {
        return this.getTableChildFormControl(MgControlName.isbn);
    }

    get szerzo(): FormControl {
        return this.getTableChildFormControl(MgControlName.szerzo);
    }

    get eredeti_kiado(): FormControl {
        return this.getTableChildFormControl(MgControlName.eredeti_kiado);
    }

    get afa(): FormControl {
        return this.getTableChildFormControl(MgControlName.afa);
    }

    get boltszam(): FormControl {
        return this.getTableChildFormControl(MgControlName.boltszam);
    }

    get boltnev(): FormControl {
        return this.getTableChildFormControl(MgControlName.boltnev);
    }

    get beszallito(): FormControl {
        return this.getTableChildFormControl(MgControlName.beszallito);
    }

    get forgalom(): FormControl {
        return this.getTableChildFormControl(MgControlName.forgalom);
    }

    get brlistaaron(): FormControl {
        return this.getTableChildFormControl(MgControlName.brlistaaron);
    }

    get nebeszaron(): FormControl {
        return this.getTableChildFormControl(MgControlName.nebeszaron);
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}
