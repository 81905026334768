import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

import {
  BaseMagicOverlayContainer,
  MagicModalInterface,
} from "@magic-xpa/angular";
import { ComponentFactoryResolver } from "@angular/core";

@Component({
  selector: "app-my-overlay-container",
  /*
  <div #overlayheader class="my-overlay-header">
        <span *ngIf="showtitle">{{ Formname }}</span>
        <button (click)="OnClose()">X</button>
      </div>

<div #overlayheader class="my-overlay-header">
      <button class="input_button" (click)="OnClose()"><fa-icon [icon]=faWindowClose></fa-icon></button>
      <span *ngIf="showtitle" id="Formname">{{ Formname }}</span>
    </div>
<button class="input_button" (click)="OnClose()"><fa-icon [icon]=faWindowClose></fa-icon></button>
  */
  template: `
    <div class="fullbg-overlay1"></div>
    <div class="overlay-box1">
      <div class="overlay-body1">
        <div #overlaybody></div>
      </div>
    </div>
  `,

  styleUrls: ["./MyOverlayContainer.scss"],
})
export class MyOverlayContainer
  extends BaseMagicOverlayContainer
  implements OnInit {
  Formname: string;
  showtitle: boolean;
  formwidth: string;

  faWindowClose = faWindowClose;

  /**
   * content will be displayed in this placeholder
   */
  @ViewChild("overlaybody", { read: ViewContainerRef, static: true })
  overlaybodyViewContainerRef;
  /**
   *
   */
  @Input() ModalComp = null;
  /**
   *
   */
  @Input() ModalCompParameters: any = {};
  /**
   *
   */
  @Output() onClose = new EventEmitter();

  /**
   *
   */
  private componentRef = null;

  /**
   *
   * @param componentFactoryResolver
   */
  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
    super();
  }

  /**
   *
   */
  ngOnInit() {
    const factory = this.componentFactoryResolver.resolveComponentFactory(
      this.ModalComp
    );
    this.componentRef = this.overlaybodyViewContainerRef.createComponent(
      factory
    );

    let magicModalInterface: MagicModalInterface = this.componentRef
      .instance as MagicModalInterface;
    this.Formname = magicModalInterface.FormName();
    //this.Formname = TaskBaseMagicComponent//getCustomProperty(FormName, CustomPropertyName );
    this.showtitle = magicModalInterface.ShowTitleBar();
    this.formwidth = magicModalInterface.Width();
    Object.assign(this.componentRef.instance, this.ModalCompParameters);

    //  modal.Width
  }

  getStyle() {
    let styles = {};
    let modal: MagicModalInterface = this.componentRef.instance;

    styles["width"] = modal.Width();
    styles["height"] = modal.Height();
    styles["top"] = modal.X() + "vh";
    styles["left"] = modal.Y() + "vw";

    // if(modal.Width()!='100%'){
    //   styles['border'] = '1px solid grey';
    // }

    return styles;
  }

  /**
   *
   */
  OnClose() {
    this.onClose.emit();
  }
}
