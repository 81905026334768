<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        class="flex-container-column"
        magic="{{mgc.testVariables}}"
    >
        <div class="flex-container-row">
            <mat-card>
                <magic-subform magic="{{mgc.Subform10485773}}">
                </magic-subform>
            </mat-card>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label10485777}}"
                class="lable_overflow mgFont1"
            >
                puref_N
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        type='number'
                        magic="{{mgc.puref_N}}"
                        formControlName="{{mgc.puref_N}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.puref_N}}> </mgError>
                </div>
            </mat-form-field>
            <label magic="{{mgc.RNEV}}">
                {{mg.getValue(mgc.RNEV) }}
            </label>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label5}}"
                class="lable_overflow"
                [attr.disabled]="mg.isDisabled(mgc.Label5 )"
            >
                masodik:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.masodik}}"
                        formControlName="{{mgc.masodik}}"
                        mgFormat
                        required
                    >
                    <mgError magic={{mgc.masodik}}> </mgError>
                </div>
            </mat-form-field>
            <label
                magic="{{mgc.Label9}}"
                class="lable_overflow mgFont1"
                [attr.disabled]="mg.isDisabled(mgc.Label9 )"
            >
                talalt
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.talalt}}"
                        formControlName="{{mgc.talalt}}"
                        mgFormat
                        rangevalidator
                    >
                    <mgError magic={{mgc.talalt}}> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label6}}"
                class="lable_overflow"
                [attr.disabled]="mg.isDisabled(mgc.Label6 )"
            >
                harmadik:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.harmadik}}"
                        [required]="mg.getMustInput(mgc.harmadik)"
                        formControlName="{{mgc.harmadik}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.harmadik}}> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div class="flex-container-row">
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.pu_nev}}"
                        formControlName="{{mgc.pu_nev}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.pu_nev}}> </mgError>
                </div>
            </mat-form-field>
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.punevbt}}"
            >
                ...
            </button>
        </div>
    </div>
</div>