import {
  Component
} from '@angular/core';

import {
  FormGroup
} from "@angular/forms";
import {
  MgFormControlsAccessor,
  MgControlName
} from "./Beszallitokiadoi.mg.controls.g";


import {
  TaskBaseMagicComponent,
  magicProviders
} from "@magic-xpa/angular";


import {
  MagicModalInterface
} from "@magic-xpa/angular";

import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'mga-Beszallitokiadoi',
  providers: [...magicProviders],
  templateUrl: './Beszallitokiadoi.component.html',
  styleUrls: ['./Beszallitokiadoi.component.scss']
})
export class Beszallitokiadoi extends TaskBaseMagicComponent implements MagicModalInterface {

  mgc = MgControlName;
  mgfc: MgFormControlsAccessor;
  faWindowClose = faWindowClose;

  createFormControlsAccessor(formGroup: FormGroup) {
    this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
  }
  private static readonly formName: string = "Beszallitokiadoi";
  private static readonly showTitleBar: boolean = true;
  private static readonly x: number = 0;
  private static readonly y: number = 0;
  private static readonly width: string = "300px";
  private static readonly height: string = "300px";
  private static readonly isCenteredToWindow: boolean = true;
  private static readonly shouldCloseOnBackgroundClick = true;
  X() {
    return Beszallitokiadoi.x;
  }
  Y() {
    return Beszallitokiadoi.y;
  }
  Width(): string {
    return Beszallitokiadoi.width;
  }
  Height(): string {
    return Beszallitokiadoi.height;
  }
  IsCenteredToWindow() {
    return Beszallitokiadoi.isCenteredToWindow;
  }
  FormName() {
    return Beszallitokiadoi.formName;
  }
  ShowTitleBar() {
    return Beszallitokiadoi.showTitleBar;
  }
  ShouldCloseOnBackgroundClick() {
    return Beszallitokiadoi.shouldCloseOnBackgroundClick;
  }

  todo = [];

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    //type data_content = { index: number, displayValue: string };
    let data: Object[] = this.todo;//event.container.data;
    let data_str: String = '';
    data.forEach(
      function (val) {
        data_str += val['index'] + ',';
      }
    );
    ((this.mg.getItemListValues(this.mgc.kiadok) as Object)as Object[]).forEach(
      function (val) {
        data_str += val['index'] + ',';
      }
    );
    //console.log();
    this.mg.setValueToControl(this.mgc.Kiadosorrend, data_str);
  }
}
