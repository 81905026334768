<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        class="flex-container-column"
        magic="{{mgc.form2}}"
    >
        <div class="flex-container-row">
            <div
                magic="{{mgc.Table1}}"
                class="container_border"
            >
                <table>
                    <th>
                        <label>
                            nev
                        </label>
                    </th>
                    <th>
                        <label>
                        </label>
                    </th>
                    <ng-container *ngFor="let row of task.Records.list">
                        <tr
                            *ngIf="mg.ifRowCreated(row) && !mg.isDataviewEmpty()"
                            id="row"
                            [formGroup]="mg.getFormGroupByRow(row.rowId)"
                            class="table_row"
                            [ngClass]="{ 'selected': mg.isRowSelected(mgc.Table1, row.rowId)}"
                            [class.selected]="tableService.getSelectedRow() === row.rowId"
                            (click)="tableService.selectRow(row.rowId)"
                            [magicRow]="row?.rowId"
                        >
                            <td>
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                magic="{{mgc.nev}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.nev}}"
                                            >
                                            <mgError
                                                magic={{mgc.nev}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.nev}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.nev, row.rowId)
}}
                                    </label>
                                </div>
                            </td>
                            <td>
                                <button
                                    mat-raised-button
                                    *ngIf="!mg.isRowInRowEditing(row)"
                                    color="primary"
                                    magic="{{mgc.EnterRowEditing}}"
                                    [rowId]="row.rowId"
                                >
                                    Edit
                                </button>
                                <button
                                    mat-raised-button
                                    *ngIf="mg.isRowInRowEditing(row)"
                                    color="primary"
                                    magic="{{mgc.SaveRowEditing}}"
                                    [rowId]="row.rowId"
                                >
                                    Save
                                </button>
                                <button
                                    mat-raised-button
                                    *ngIf="mg.isRowInRowEditing(row)"
                                    color="primary"
                                    magic="{{mgc.CancelRowEditing}}"
                                    [rowId]="row.rowId"
                                >
                                    Cancel
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                </table>
            </div>
        </div>
    </div>
</div>