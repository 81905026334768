<div novalidate [formGroup]="screenFormGroup">
  <div magic="{{ mgc.UjcikkContributors }}">
    <div magic="{{ mgc.Table10485761 }}" class="container_border" style="width: 100%">
      <table>
        <th *ngFor="
            let item of [
              { label: 'Típus', width: '10%' },
              { label: 'Fájlnév', width: '10%' },
              { label: 'Fájltípus', width: '10%' },
              { label: 'Méret', width: '10%' },
              { label: 'Leírás', width: '10%' },
              { label: '', width: '10%' },
              { label: '', width: '10%' }
            ]
          " style.width="{{ item.width }}">
          <label>
            {{ item.label }}
          </label>
        </th>
        <ng-container *ngFor="let row of task.Records.list; index as i">
          <tr *ngIf="mg.ifRowCreated(row) && !mg.isDataviewEmpty()" id="row"
            [formGroup]="mg.getFormGroupByRow(row.rowId)" class="table_row" [ngClass]="{
              selected: mg.isRowSelected(mgc.Table10485761, row.rowId)
            }" [class.selected]="tableService.getSelectedRow() === row.rowId"
            (click)="tableService.selectRow(row.rowId)" [magicRow]="row?.rowId">
            <td>
              <div class="input_text" style="display: flex" *ngIf="mg.isRowInRowEditing(row)">
                <!-- ROW editing mod -->
                <select magic="{{ mgc.Tipus }}" [rowId]="row.rowId" formControlName="{{ mgc.Tipus }}"
                  class="form-control select" [style.visibility]="mg.getVisible(mgc.Tipus, row.rowId)" style="flex: 1">
                  <option *ngFor="let o of mg.getItemListValues(mgc.Tipus, row.rowId)" [value]="o.index">
                    {{ o.displayValue }}
                  </option>
                </select>
              </div>
              <div class="input_text" *ngIf="!mg.isRowInRowEditing(row)">
                <!-- NO ROW editing mod -->
                <label *ngIf="!mg.isRowInRowEditing(row)" magic="{{ mgc.Tipus }}" [rowId]="row.rowId">
                  {{ mg.getDisplayValue(mgc.Tipus, row.rowId) }}
                </label>
              </div>
            </td>
            <td>
              <div class="input_text">
                <!--nincs row editingben-->
                <label magic="{{ mgc.fileName }}" [rowId]="row.rowId">
                  {{ mg.getValue(mgc.fileName, row.rowId) }}
                </label>
              </div>
            </td>
            <td>
              <div class="input_text" style="display: flex">
                <label magic="{{ mgc.fileType }}" [rowId]="row.rowId">
                  {{ mg.getValue(mgc.fileType, row.rowId) }}
                </label>
              </div>
            </td>
            <td>
              <div class="input_text" style="display: flex">
                <label magic="{{ mgc.fileSize }}" [rowId]="row.rowId">
                  {{ mg.getValue(mgc.fileSize, row.rowId) }}
                </label>
              </div>
            </td>
            <td>
              <div class="input_text" style="display: flex" *ngIf="mg.isRowInRowEditing(row)">
                <!-- ROW editing mod -->
                <input *ngIf="mg.isRowInRowEditing(row)" magic="{{ mgc.fileDescription }}" [rowId]="row.rowId"
                  [required]="mg.getMustInput(mgc.fileDescription, row.rowId)"
                  formControlName="{{ mgc.fileDescription }}" class="form-control text"
                  style="width: 80%; margin-left: 5px" />
                <mgError magic="{{ mgc.fileDescription }}" [rowId]="row.rowId">
                </mgError>
              </div>
              <div class="input_text" *ngIf="!mg.isRowInRowEditing(row)">
                <!--nincs row editingben-->
                <label *ngIf="!mg.isRowInRowEditing(row)" magic="{{ mgc.fileDescription }}" [rowId]="row.rowId">
                  {{ mg.getValue(mgc.fileDescription, row.rowId) }}
                </label>
              </div>
            </td>
            <td>
              <button *ngIf="!mg.isRowInRowEditing(row)" mat-raised-button color="primary"
                magic="{{ mgc.EnterRowEditing }}" [rowId]="row.rowId">
                Szerkesztés
              </button>
              <button *ngIf="mg.isRowInRowEditing(row)" mat-raised-button color="primary"
                magic="{{ mgc.SaveRowEditing }}" [rowId]="row.rowId" [style.visibility]="
                  mg.getVisible(mgc.SaveRowEditing, row.rowId)
                " [disabled]="mg.isDisabled(mgc.SaveRowEditing, row.rowId)">
                Mentés
              </button>
              <button *ngIf="mg.isRowInRowEditing(row)" mat-raised-button color="primary"
                magic="{{ mgc.CancelRowEditing }}" [rowId]="row.rowId">
                Mégsem
              </button>
              <button mat-raised-button color="primary" magic="{{ mgc.UploadImage }}" [rowId]="row.rowId"
                [style.visibility]="mg.getVisible(mgc.UploadImage, row.rowId)"
                [disabled]="mg.isDisabled(mgc.UploadImage, row.rowId)">
                Kép kiválasztása
              </button>
              <a href="javascript:;" magic="{{ mgc.DeleteRecord }}" [rowId]="row.rowId"
                [style.visibility]="mg.getVisible(mgc.DeleteRecord, row.rowId)"
                style="margin-left: 10px; color: #ec6b2c">
                <fa-icon [icon]="faTimes"></fa-icon>
              </a>
            </td>
            <td>
              <label magic="{{ mgc.fileData }}" [rowId]="row.rowId">
                <img *ngIf="!!mg.getValue(mgc.fileData, row.rowId)&&(mg.getValue(mgc.fileType, row.rowId)!='pdf')" style='display:block; width:100px;height:100px;' id='base64image'
                  [src]="'data:image/jpg;base64,'+ mg.getValue(mgc.fileData, row.rowId)" />
                 <!-- <a *ngIf="!!mg.getValue(mgc.fileData, row.rowId)&&(mg.getValue(mgc.fileType, row.rowId)=='pdf')" target="_blank" [href]="'data:application/pdf;base64,'+ mg.getValue(mgc.fileData, row.rowId)">megtekintés</a>
                 <iframe *ngIf="!!mg.getValue(mgc.fileData, row.rowId)&&(mg.getValue(mgc.fileType, row.rowId)=='pdf')" style='display:block; width:100px;height:100px;' [src]="'data:application/pdf;base64,'+ mg.getValue(mgc.fileData, row.rowId)"></iframe>-->
              </label>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
    <div class="flex-container-row">
      <button mat-raised-button color="primary" magic="{{ mgc.Create }}" [style.visibility]="mg.getVisible(mgc.Create)"
        [disabled]="mg.isDisabled(mgc.Create)">
        Új
      </button>
      <button style="display: none" mat-raised-button color="primary" magic="{{ mgc.New }}">
        Új(ablak)
      </button>
    </div>
  </div>
</div>
