import { FormControl, FormGroup } from "@angular/forms";
import { MagicServices } from "@magic-xpa/angular";
export enum MgControlName {
  Szerzovalasztasarumegtekint = "Szerzovalasztasarumegtekint",
  Label10485761 = "Label10485761",
  Szerzo = "Szerzo",
  keresNYG = "keresNYG",
  szerzok = "szerzok",
  MG_FORMNAME = "MG_FORMNAME",
  MG_B_QUIT="MG_B_QUIT",
}
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) {}

  get Szerzo(): FormControl {
    return this.fg.controls[MgControlName.Szerzo] as FormControl;
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(
      this.magicServices.tableService.getSelectedRow()
    ).controls[name] as FormControl;
  }
}
