<div #overlayheader class="urlap, form-style, my-overlay-header">
  <span>{{ mg.getValue("MG_FORMNAME") }}</span>
  <button class="input_button" magic="{{ mgc.MG_B_QUIT }}" [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)">
    <fa-icon [icon]="faWindowClose"></fa-icon>
  </button>
</div>
<div novalidate class="screenFormGroup" [formGroup]="screenFormGroup" magic="{{mgc.Beszallitokiadoi}}">
  <div class="container left" [style.visibility]="mg.getVisible(mgc.kiadok)">
    <h5>{{ mg.getValue(mgc.lkiadok) }}</h5>
    <div cdkDropList #doneList="cdkDropList" [cdkDropListData]="mg.getItemListValues(mgc.kiadok)" class="list"
      [cdkDropListConnectedTo]="[todoList]" [style.visibility]="mg.getVisible(mgc.kiadok)"
      (cdkDropListDropped)="drop($event)">
      <div class="box" *ngFor="let o of mg.getItemListValues(mgc.kiadok)" cdkDrag>{{ o.displayValue }}</div>
    </div>
  </div>
  <div class="container right" [style.visibility]="mg.getVisible(mgc.kiadok)">
    <h5>{{ mg.getValue(mgc.lkedvencek) }}</h5>
    <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="todo" class="list" [cdkDropListConnectedTo]="[doneList]"
      [style.visibility]="mg.getVisible(mgc.kiadok)" (cdkDropListDropped)="drop($event)">
      <div class="box" *ngFor="let item of todo" cdkDrag>{{ item.displayValue }}</div>
    </div>
  </div>
  <div class="container" [style.visibility]="mg.getVisible(mgc.Ujkiado)">
    <mat-checkbox magic="{{mgc.Ujkiado}}" [style.visibility]="mg.getVisible(mgc.Ujkiado)"
      formControlName="{{mgc.Ujkiado}}">
      Új
    </mat-checkbox>
  </div>
  <div class="container" [style.visibility]="mg.getVisible(mgc.Ujkiadonev)">
    <h5>Új kiadó megadása:</h5>
    <mat-form-field>
      <input matInput magic="{{mgc.Ujkiadonev}}" [placeholder]="mg.getPlaceholder(mgc.Ujkiadonev)"
        formControlName="{{mgc.Ujkiadonev}}" mgFormat>
      <mgError magic={{mgc.Ujkiadonev}}> </mgError>
    </mat-form-field>
  </div>
  <div class="container" [style.visibility]="mg.getVisible(mgc.ment)">
    <button mat-raised-button color="primary" magic="{{mgc.ment}}">
      Ment
    </button>
    <mat-form-field [style.visibility]="mg.getVisible(mgc.Kiadosorrend)">
      <div>
        <input matInput magic="{{mgc.Kiadosorrend}}" formControlName="{{mgc.Kiadosorrend}}" mgFormat>
        <mgError magic={{mgc.Kiadosorrend}}> </mgError>
      </div>
    </mat-form-field>
  </div>
</div>
