import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    Login = "Login",
        vUser = "vUser",
        vPass = "vPass",
        BTLogin = "BTLogin",
}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get vUser(): FormControl {
        return this.fg.controls[MgControlName.vUser] as FormControl;
    }

    get vPass(): FormControl {
        return this.fg.controls[MgControlName.vPass] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}