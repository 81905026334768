import {
  Component
} from '@angular/core';

import {
  FormGroup, FormControl
} from "@angular/forms";
import {
  MgFormControlsAccessor,
  MgControlName
} from "./FileTransfer.mg.controls.g";


import {
  TaskBaseMagicComponent,
  magicProviders
} from "@magic-xpa/angular";


import {
  MagicModalInterface
} from "@magic-xpa/angular";

import {
  faWindowClose
} from "@fortawesome/free-solid-svg-icons";


@Component({
  selector: 'mga-FileTransfer',
  providers: [...magicProviders],
  templateUrl: './FileTransfer.component.html'
})
export class FileTransfer extends TaskBaseMagicComponent implements MagicModalInterface {
  mgc = MgControlName;
  mgfc: MgFormControlsAccessor;
  //form = this.mgc.Ujcikk2;
  form_kiiras = "";
  faWindowClose = faWindowClose;

  createFormControlsAccessor(formGroup: FormGroup) {
    this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
  }
  private static readonly formName: string = "FileTransfer";
  private static readonly showTitleBar: boolean = false;
  private static readonly x: number = 0;
  private static readonly y: number = 0;
  private static readonly width: string = "0px";
  private static readonly height: string = "0px";
  private static readonly isCenteredToWindow: boolean = false;
  private static readonly shouldCloseOnBackgroundClick = true;
  X() {
    return FileTransfer.x;
  }
  Y() {
    return FileTransfer.y;
  }
  Width(): string {
    return FileTransfer.width;
  }
  Height(): string {
    return FileTransfer.height;
  }
  IsCenteredToWindow() {
    return FileTransfer.isCenteredToWindow;
  }
  FormName() {
    return FileTransfer.formName;
  }
  ShowTitleBar() {
    return FileTransfer.showTitleBar;
  }
  ShouldCloseOnBackgroundClick() {
    return FileTransfer.shouldCloseOnBackgroundClick;
  }

  ngOnInit() {
    super.ngOnInit();
    const group: FormGroup = this.screenFormGroup;
    (group.controls.Blob64AtServer as FormControl).registerOnChange(this.OnChange.bind(this)
    );
  }

  OnChange() {
    if (
      this.mg.getValue('Blob64AtServer') !== undefined &&
      this.mg.getValue('Blob64AtServer') !== null
    ) {
      this.uhgetfile();
    }
  }

  /*b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }*/

  hasSubArray(master) {
    let sub = [225, 185, 143];
    return sub.every((i => v => i = master.indexOf(v, i) + 1)(0));
  }

  // Namespace UTF8
  UTF8 = (function () {
    return {
      // Encodes UCS2 into UTF8
      // Returns an array of numbers (bytes)
      encode: function (str) {
        var len = str.length;
        var result = [];
        var code;
        var i;
        for (i = 0; i < len; i++) {
          code = str.charCodeAt(i);
          if (code <= 0x7f) {
            result.push(code);
          } else if (code <= 0x7ff) {                         // 2 bytes
            result.push(0xc0 | (code >>> 6 & 0x1f),
              0x80 | (code & 0x3f));
          } else if (code <= 0xd700 || code >= 0xe000) {      // 3 bytes
            result.push(0xe0 | (code >>> 12 & 0x0f),
              0x80 | (code >>> 6 & 0x3f),
              0x80 | (code & 0x3f));
          } else {                                            // 4 bytes, surrogate pair
            code = (((code - 0xd800) << 10) | (str.charCodeAt(++i) - 0xdc00)) + 0x10000;
            result.push(0xf0 | (code >>> 18 & 0x07),
              0x80 | (code >>> 12 & 0x3f),
              0x80 | (code >>> 6 & 0x3f),
              0x80 | (code & 0x3f));
          }
        }
        return result;
      },

      // Decodes UTF8 into UCS2
      // Returns a string
      decode: function (bytes) {
        var len = bytes.length;
        var result = "";
        var code;
        var i;
        for (i = 0; i < len; i++) {
          if (bytes[i] <= 0x7f) {
            result += String.fromCharCode(bytes[i]);
          } else if (bytes[i] >= 0xc0) {                                   // Mutlibytes
            if (bytes[i] < 0xe0) {                                       // 2 bytes
              code = ((bytes[i++] & 0x1f) << 6) |
                (bytes[i] & 0x3f);
            } else if (bytes[i] < 0xf0) {                                // 3 bytes
              code = ((bytes[i++] & 0x0f) << 12) |
                ((bytes[i++] & 0x3f) << 6) |
                (bytes[i] & 0x3f);
            } else {                                                     // 4 bytes
              // turned into two characters in JS as surrogate pair
              code = (((bytes[i++] & 0x07) << 18) |
                ((bytes[i++] & 0x3f) << 12) |
                ((bytes[i++] & 0x3f) << 6) |
                (bytes[i] & 0x3f)) - 0x10000;
              // High surrogate
              result += String.fromCharCode(((code & 0xffc00) >>> 10) + 0xd800);
              // Low surrogate
              code = (code & 0x3ff) + 0xdc00;
            }
            result += String.fromCharCode(code);
          } // Otherwise it's an invalid UTF-8, skipped.
        }
        return result;
      }
    };
  }());

  uhgetfile() {
    const base64 = this.mg.getValue('Blob64AtServer');
    //console.log('Kapott adat: '+this.mg.getValue('Blob64AtServer'));//magic által megrágott base64 kód
    if (base64 !== undefined || base64 !== null) {
      const byteCharacters = atob(base64);//base64 byte karakterekké való visszafejtése
      //const byteCharacters = this.UTF8.decode(this.UTF8.encode(atob(base64)));//bejövő adat konvertálása azonnal
      //const byteCharacters = base64;//közvetlen adat fogadása
      //console.log(byteCharacters);//ami jön
      //if (byteCharacters.substring(0, 3) === "\xEF\xBB\xBF") byteCharacters = byteCharacters.substring(3); // UTF-8 BOM ?
      //console.log(byteCharacters.substring(0, 3));// ezt kapuk ==> < ?
      //console.log(btoa(byteCharacters)); ==> kapott fájl visszakódolása base64-be
      const byteNumbers = new Array(byteCharacters.length);
      const byteNumbers_2 = new Array();
      let utfBOM = 0;
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
        //if (byteCharacters.charCodeAt(i) == 0) utfBOM++;//második bájt vizsgálata
        //asd='áÁéÉíÍóÓöÖőŐúÚüÜűŰ';//HUN problémás karakterek
        /*
        //két bájtos karaktertárolás támogatása
        if (i % 2 == 0) {
          if (byteCharacters.charCodeAt(i + 1) == 1) {
            //byteNumbers_2[byteNumbers_2.length] = byteCharacters.charCodeAt(i)+128;//ékezetes??? karakterek ASCII2
            byteNumbers_2[byteNumbers_2.length] = byteCharacters.charCodeAt(i);
            byteNumbers_2[byteNumbers_2.length] = 1;
          } else {
            byteNumbers_2[byteNumbers_2.length] = byteCharacters.charCodeAt(i);
            byteNumbers_2[byteNumbers_2.length] = 0;
          }
        }
        */
      }

      let byteArray = new Uint8Array(byteNumbers);//eredeti
      //const byteArray = (utfBOM < 16) ? new Uint8Array(byteNumbers) : new Uint8Array(byteNumbers_3);
      let byteNumbersBOM = new Array();
      if (utfBOM >= 16) {
        let BOM = [255, 254];
        for (var i = 0; i < BOM.length; i++) {
          byteNumbersBOM[byteNumbersBOM.length] = BOM[i];
        }
        for (i = 0; i < byteNumbers_2.length; i++) {
          byteNumbersBOM[byteNumbersBOM.length] = byteNumbers_2[i]
        }
        //console.log(byteNumbers2);
      }
      let byteArray2 = new Uint8Array(byteNumbersBOM);
      //let byteArray2 = new Uint8Array(byteNumbers_2);

      //console.log(byteArray);
      //console.log(byteArray2);

      //const byteArray = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), byteNumbers],{ type: "text/plain;charset=utf-8" });
      //MimeType(string pl. text/xml;charset=utf-8)
      const binLb2 = (utfBOM >= 16) ? new window.Blob([byteArray2], { type: 'text/xml;charset=utf-8' }) : new window.Blob([byteArray], { type: 'text/xml;charset=utf-8' });
      if (this.mg.getValue('vOpenOrDownload') === 'D') {
        if (binLb2.size !== 0) {
          const downloadLink = document.createElement('a');
          const fileName = this.mg.getValue('vfilename');
          const linkSource = window.URL.createObjectURL(binLb2);
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          window.URL.revokeObjectURL(linkSource);
        }
      } else {
        if (binLb2.size !== 0) {
          const fileUrl = URL.createObjectURL(binLb2);
          window.open(fileUrl);
        }
      }
    }
  }
}
