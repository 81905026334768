<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        class="flex-container-column"
        magic="{{mgc.Cikk}}"
    >
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label10485761}}"
                class="lable_overflow"
            >
                vonalkod
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.vonalkod}}"
                        formControlName="{{mgc.vonalkod}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.vonalkod}}> </mgError>
                </div>
            </mat-form-field>
            <label
                magic="{{mgc.Label10485763}}"
                class="lable_overflow"
            >
                aru nev:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.aru_nev_szures}}"
                        formControlName="{{mgc.aru_nev_szures}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.aru_nev_szures}}> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label10485765}}"
                class="lable_overflow"
            >
                tipus
            </label>
            <div>
                <mat-form-field>
                    <mat-select
                        magic="{{mgc.tipus}}"
                        formControlName="{{mgc.tipus}}"
                    >
                        <mat-option
                            *ngFor="let o of mg.getItemListValues(mgc.tipus);"
                            [value]="o.index"
                        >
                            {{o.displayValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <label
                magic="{{mgc.Label10485766}}"
                class="lable_overflow"
            >
                kategoriak:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.kategoriak}}"
                        formControlName="{{mgc.kategoriak}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.kategoriak}}> </mgError>
                </div>
            </mat-form-field>
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.katvg}}"
            >
                6
            </button>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label10485768}}"
                class="lable_overflow"
            >
                szerzo:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.szerzo}}"
                        formControlName="{{mgc.szerzo}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.szerzo}}> </mgError>
                </div>
            </mat-form-field>
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.szerzovg}}"
            >
                6
            </button>
            <label
                magic="{{mgc.Label10485770}}"
                class="lable_overflow"
            >
                kiado:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.kiado}}"
                        formControlName="{{mgc.kiado}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.kiado}}> </mgError>
                </div>
            </mat-form-field>
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.kiadovg}}"
            >
                6
            </button>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label10485772}}"
                class="lable_overflow"
            >
                sorozat:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.sorozat}}"
                        formControlName="{{mgc.sorozat}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.sorozat}}> </mgError>
                </div>
            </mat-form-field>
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.sorozatvg}}"
            >
                6
            </button>
            <label
                magic="{{mgc.Label10485774}}"
                class="lable_overflow"
            >
                isbn:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.isbn}}"
                        formControlName="{{mgc.isbn}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.isbn}}> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label10485777}}"
                class="lable_overflow"
            >
                kiadas eve-tol:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.kiadas_eve_tol}}"
                        formControlName="{{mgc.kiadas_eve_tol}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.kiadas_eve_tol}}> </mgError>
                </div>
            </mat-form-field>
            <label
                magic="{{mgc.Label10485778}}"
                class="lable_overflow"
            >
                kiadas eve-ig:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.kiadas_eve_ig}}"
                        formControlName="{{mgc.kiadas_eve_ig}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.kiadas_eve_ig}}> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label10485781}}"
                class="lable_overflow"
            >
                eredeti cim
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.eredeti_cim}}"
                        formControlName="{{mgc.eredeti_cim}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.eredeti_cim}}> </mgError>
                </div>
            </mat-form-field>
            <label
                magic="{{mgc.Label10485783}}"
                class="lable_overflow"
            >
                nyelv
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.nyelv}}"
                        formControlName="{{mgc.nyelv}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.nyelv}}> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label10485784}}"
                class="lable_overflow"
            >
                Cikk azonosito
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        type='number'
                        magic="{{mgc.Aru_recno}}"
                        formControlName="{{mgc.Aru_recno}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.Aru_recno}}> </mgError>
                </div>
            </mat-form-field>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.aru_nev}}"
                        formControlName="{{mgc.aru_nev}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.aru_nev}}> </mgError>
                </div>
            </mat-form-field>
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.aruvg}}"
            >
                6
            </button>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label10485790}}"
                class="lable_overflow"
            >
                Beszallito:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        type='number'
                        magic="{{mgc.beszallito}}"
                        formControlName="{{mgc.beszallito}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.beszallito}}> </mgError>
                </div>
            </mat-form-field>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.HNEV}}"
                        formControlName="{{mgc.HNEV}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.HNEV}}> </mgError>
                </div>
            </mat-form-field>
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.beszallitovg}}"
                [disabled]="mg.isDisabled(mgc.beszallitovg )"
            >
                6
            </button>
            <label
                magic="{{mgc.Label10485788}}"
                class="lable_overflow"
            >
                status
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        type='number'
                        magic="{{mgc.status}}"
                        formControlName="{{mgc.status}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.status}}> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label10485792}}"
                class="lable_overflow"
            >
                KIM
            </label>
            <div>
                <mat-form-field>
                    <mat-select
                        magic="{{mgc.KIM}}"
                        formControlName="{{mgc.KIM}}"
                    >
                        <mat-option
                            *ngFor="let o of mg.getItemListValues(mgc.KIM);"
                            [value]="o.index"
                        >
                            {{o.displayValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="flex-container-row">
            <div>
                <mat-checkbox
                    magic="{{mgc.reszletes}}"
                    formControlName="{{mgc.reszletes}}"
                >
                    Részletes eredmény tábla
                </mat-checkbox>
            </div>
        </div>
        <div class="flex-container-row">
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.beszallito_lista}}"
                [disabled]="mg.isDisabled(mgc.beszallito_lista )"
            >
                beszallito lista
            </button>
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.beszallito_lista_torles}}"
                [disabled]="mg.isDisabled(mgc.beszallito_lista_torles )"
            >
                beszallito lista torles
            </button>
        </div>
        <div class="flex-container-row">
            <mat-card>
                <magic-subform magic="{{mgc.beszallitotmp}}">
                </magic-subform>
            </mat-card>
        </div>
        <div class="flex-container-row">
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.Lekerdezes}}"
            >
                lekerdezes
            </button>
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.Excel}}"
            >
                excel
            </button>
        </div>
    </div>
</div>