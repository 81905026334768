<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        class="flex-container-column"
        magic="{{mgc.TextInput}}"
    >
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label3}}"
                class="lable_overflow"
            >
                result:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.result}}"
                        formControlName="{{mgc.result}}"
                        mgFormat
                        required
                    >
                    <mgError magic={{mgc.result}}> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label4}}"
                class="lable_overflow"
            >
                b:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.b}}"
                        formControlName="{{mgc.b}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.b}}> </mgError>
                </div>
            </mat-form-field>
        </div>
    </div>
</div>