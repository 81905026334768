<div novalidate [formGroup]="screenFormGroup">
  <div style="display: flex; flex-direction: column" magic="{{ mgc.UjcikkContributors2 }}">
    <div style="display: flex; flex-direction: row">
      <div magic="{{ mgc.Table10485761 }}" class="container_border" style="width: 100%; overflow: auto; max-height: 500px">
        <table>
          <th>
            <label> nev </label>
          </th>
          <th>
            <label> </label>
          </th>
          <th>
            <label> kozremukodes </label>
          </th>
          <th>
            <label> </label>
          </th>
          <ng-container *ngFor="let row of task.Records.list">
            <tr *ngIf="mg.ifRowCreated(row) && !mg.isDataviewEmpty()" id="row"
              [formGroup]="mg.getFormGroupByRow(row.rowId)" class="table_row" [ngClass]="{
                selected: mg.isRowSelected(mgc.Table10485761, row.rowId)
              }" [class.selected]="tableService.getSelectedRow() === row.rowId"
              (click)="tableService.selectRow(row.rowId)" [magicRow]="row?.rowId">
              <td>
                <div [formGroup]="mg.getFormGroupByRow(row.rowId)" style="display: flex; flex-direction: row">
                  <mat-checkbox [magic]="mgc.Kulfoldi" [rowId]="row.rowId"
                    [style.visibility]="mg.getVisible(mgc.Kulfoldi, row.rowId)"
                    [style.display]="mg.getVisible(mgc.Kulfoldi)=='visible'?'initial':'none'"
                    [formControlName]="mgc.Kulfoldi" style="padding: 7px">
                    Külföldi
                  </mat-checkbox>
                  <mat-form-field [style.visibility]="mg.getVisible(mgc.nev2, row.rowId)"
                    *ngIf="mg.isRowInRowEditing(row)">
                    <div>
                      <!--
                      <input *ngIf="mg.isRowInRowEditing(row)" matInput magic="{{ mgc.nev2 }}" [rowId]="row.rowId"
                        [required]="mg.getMustInput(mgc.nev2, row.rowId)" formControlName="{{ mgc.nev2 }}" mgFormat />
                        id="Contributors_nev_{{ i }}"
                      -->
                      <input magic="{{ mgc.nev2 }}" [rowId]="row.rowId"
                        [required]="mg.getMustInput(mgc.nev2, row.rowId)" formControlName="{{ mgc.nev2 }}" type="text"
                        class="form-control text" *ngIf="mg.isRowInRowEditing(row)"
                        [style.visibility]="mg.getVisible(mgc.nev2, row.rowId)"
                        [placeholder]="mg.getPlaceholder(mgc.nev2, row.rowId)" mgFormat/>
                      <mgError magic="{{ mgc.nev2 }}" [rowId]="row.rowId">
                      </mgError>
                    </div>
                  </mat-form-field>
                  <label *ngIf="!mg.isRowInRowEditing(row)" magic="{{ mgc.nev2 }}" [rowId]="row.rowId"
                    [style.visibility]="mg.getVisible(mgc.nev2, row.rowId)">
                    {{ mg.getValue(mgc.nev2, row.rowId) }}
                  </label>
                </div>
                <div>
                  <div *ngIf="mg.isRowInRowEditing(row)">
                    <mat-form-field [style.visibility]="mg.getVisible(mgc.nev, row.rowId)">
                      <mat-select magic="{{ mgc.nev }}" [rowId]="row.rowId" formControlName="{{ mgc.nev }}">
                        <mat-option *ngFor="
                            let o of mg.getItemListValues(mgc.nev, row.rowId)
                          " [value]="o.index">
                          {{ o.displayValue }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mgError magic="{{ mgc.nev }}"> </mgError>
                  </div>
                  <div *ngIf="!mg.isRowInRowEditing(row)">
                    <label magic="{{ mgc.nev }}" [rowId]="row.rowId"
                      [style.visibility]="mg.getVisible(mgc.nev, row.rowId)">
                      {{ mg.getDisplayValue(mgc.nev, row.rowId) }}
                    </label>
                  </div>
                </div>
                <button mat-raised-button color="primary" magic="{{ mgc.btnNev }}" [rowId]="row.rowId"
                  [style.visibility]="mg.getVisible(mgc.btnNev, row.rowId)">
                  ...
                </button>
              </td>
              <td>
                <div>
                  <mat-checkbox magic="{{ mgc.NewEnabled }}" [rowId]="row.rowId" [style.visibility]="
                      mg.getVisible(mgc.NewEnabled, row.rowId)
                    " formControlName="{{ mgc.NewEnabled }}">
                    Új
                  </mat-checkbox>
                </div>
              </td>
              <td>
                <div>
                  <div *ngIf="mg.isRowInRowEditing(row)">
                    <mat-form-field>
                      <mat-select magic="{{ mgc.kozremukodes }}" [rowId]="row.rowId"
                        formControlName="{{ mgc.kozremukodes }}">
                        <mat-option *ngFor="
                            let o of mg.getItemListValues(
                              mgc.kozremukodes,
                              row.rowId
                            )
                          " [value]="o.index">
                          {{ o.displayValue }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mgError magic="{{ mgc.kozremukodes }}"> </mgError>
                  </div>
                  <div *ngIf="!mg.isRowInRowEditing(row)">
                    <label magic="{{ mgc.kozremukodes }}" [rowId]="row.rowId">
                      {{ mg.getDisplayValue(mgc.kozremukodes, row.rowId) }}
                    </label>
                  </div>
                </div>
              </td>
              <td>
                <button mat-raised-button *ngIf="!mg.isRowInRowEditing(row)" color="primary"
                  magic="{{ mgc.EnterRowEditing }}" [rowId]="row.rowId">
                  Szerkesztés
                </button>
                <button mat-raised-button *ngIf="mg.isRowInRowEditing(row)" color="primary"
                  magic="{{ mgc.SaveRowEditing }}" [rowId]="row.rowId" [style.visibility]="
                    mg.getVisible(mgc.SaveRowEditing, row.rowId)
                  " [disabled]="mg.isDisabled(mgc.SaveRowEditing, row.rowId)">
                  Mentés
                </button>
                <button mat-raised-button *ngIf="mg.isRowInRowEditing(row)" color="primary"
                  magic="{{ mgc.CancelRowEditing }}" [rowId]="row.rowId">
                  Mégsem
                </button>
                <a href="javascript:;" magic="{{ mgc.DeleteRecord }}" [rowId]="row.rowId" [style.visibility]="
                    mg.getVisible(mgc.DeleteRecord, row.rowId)
                  ">
                  Törlés
                </a>
              </td>
            </tr>
          </ng-container>
        </table>
      </div>
    </div>
    <div style="display: flex; flex-direction: row">
      <a href="javascript:;" magic="{{ mgc.Create }}" [style.visibility]="mg.getVisible(mgc.Create)"
        [attr.disabled]="mg.isDisabled(mgc.Create)">
        Új
      </a>
    </div>
  </div>
</div>
