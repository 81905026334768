import { Component } from "@angular/core";

import { FormGroup } from "@angular/forms";
import {
  MgFormControlsAccessor,
  MgControlName,
  MgCustomProperties
} from "./ForgalomlistaOvrl.mg.controls.g";
//import { MgDisplayedColumns } from "./ForgalomlistaOvrlOvrl.mg.controls.g";

import {
  BaseMatTableMagicComponent,
  matMagicProviders,
} from "@magic-xpa/angular-material-core";
import { MagicModalInterface } from "@magic-xpa/angular";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

import {
  CdkDragStart,
  CdkDropList,
  moveItemInArray,
} from "@angular/cdk/drag-drop";
import { PageEvent } from "@angular/material/paginator";

import { registerLocaleData } from "@angular/common";
import hu from "@angular/common/locales/hu";

@Component({
  selector: "mga-ForgalomlistaOvrl",
  providers: [...matMagicProviders],
  templateUrl: "./ForgalomlistaOvrl.component.html",
  styleUrls: ["./Forgalomlista.component.scss"],
})
export class ForgalomlistaOvrl extends BaseMatTableMagicComponent implements MagicModalInterface {
   mgc = MgControlName;
  mgcp = MgCustomProperties;
  mgfc: MgFormControlsAccessor;
  //mgdp = MgDisplayedColumns;
  createFormControlsAccessor(formGroup: FormGroup) {
    this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
  }
  private static readonly formName: string = "ForgalomlistaOvrl";
  private static readonly showTitleBar: boolean = true;
  private static readonly x: number = 0;
  private static readonly y: number = 0;
  private static readonly width: string = "300px";
  private static readonly height: string = "300px";
  private static readonly isCenteredToWindow: boolean = true;
  private static readonly shouldCloseOnBackgroundClick = true;
  X() {
    return ForgalomlistaOvrl.x;
  }
  Y() {
    return ForgalomlistaOvrl.y;
  }
  Width(): string {
    return ForgalomlistaOvrl.width;
  }
  Height(): string {
    return ForgalomlistaOvrl.height;
  }
  IsCenteredToWindow() {
    return ForgalomlistaOvrl.isCenteredToWindow;
  }
  FormName() {
    return ForgalomlistaOvrl.formName;
  }
  ShowTitleBar() {
    return ForgalomlistaOvrl.showTitleBar;
  }
  ShouldCloseOnBackgroundClick() {
    return ForgalomlistaOvrl.shouldCloseOnBackgroundClick;
  }

  displayedColumns = [
    "Column10485765",
    "Column10485766",
    "Column10485767",
    "Column10485768",
    "Column10485769",
    "Column10485770",
    "Column10485771",
    "Column10485772",
    "Column10485773",
    "Column10485774",
    "Column10485775",
    "Column10485776",
    "Column10485777",
    "Column10485778",
    "Column10485779",
    "Column10485799",
    "Column10485807",
  ];

  //toNumberHU = (input_val:string): number => (Number(input_val)| number: "":"hu");

  previousIndex: number;
  columns: any[] = [
    { field: "Column10485765", name: "Dátum", mezo: "datum" },
    { field: "Column10485766", name: "Árukód", mezo: "aru_kozp_id" },
    { field: "Column10485767", name: "Áru név", mezo: "arunev" },
    { field: "Column10485768", name: "ISBN", mezo: "isbn" },
    { field: "Column10485769", name: "Szerző", mezo: "szerzo" },
    { field: "Column10485770", name: "Eredeti kiadó", mezo: "eredeti_kiado" },
    { field: "Column10485771", name: "ÁFA", mezo: "afa" },
    { field: "Column10485772", name: "Boltszám", mezo: "boltszam" },
    { field: "Column10485773", name: "Bolt név", mezo: "boltnev" },
    { field: "Column10485774", name: "Beszállító", mezo: "beszallito" },
    { field: "Column10485775", name: "Forgalom", mezo: "forgalom" },
    { field: "Column10485776", name: "Listaáron", mezo: "brlistaaron" },
    { field: "Column10485777", name: "Bt. Elad. áron", mezo: "brfogyaron" },
    { field: "Column10485778", name: "Nt. Elad. áron", mezo: "nefogyaron" },
    { field: "Column10485779", name: "Nt. Besz. áron", mezo: "nebeszaron" },
    { field: "Column10485799", name: "Készlet", mezo: "keszlet" },
    { field: "Column10485807", name: "Készlet listaáron", mezo: "klt_brlistaaron" },
  ]; //hosszú távon generáljuk23456789101112131415161718

  setDisplayedColumns() {
    this.columns.forEach((colunm, index) => {
      colunm.index = index;
      this.displayedColumns[index] = colunm.field;
    });
    console.log(this.displayedColumns);
  }

  dragStarted(event: CdkDragStart, index: number) {
    this.previousIndex = index;
  }

  dropListDropped(event: CdkDropList, index: number) {
    if (event) {
      moveItemInArray(this.columns, this.previousIndex, index);
      this.setDisplayedColumns();
    }
  }

  paginator_pageSize: number = 50;
  paginator_pageSizeOptions: string = "20,50,100";
  GETpageSize(): number {
    this.paginator_pageSize = this.mg.getValue(this.mgc.paginator_pageSize)
      ? Number(this.mg.getValue(this.mgc.paginator_pageSize))
      : this.paginator_pageSize;
    return this.paginator_pageSize;
  }

  SETpageSize(event: PageEvent) {
    this.tableService.mgOnPaginateChange(event);
    this.mg.setValueToControl(
      this.mgc.paginator_pageSize,
      Number(event.pageSize),
      false
    );
  }

  pageSizeOptions(): number[] {
    this.paginator_pageSizeOptions = this.mg.getCustomProperty(
      this.mgc.ForgalomlistaOvrl,
      "paginator_pageSizeOptions"
    )
      ? this.mg.getCustomProperty(
        this.mgc.ForgalomlistaOvrl,
        "paginator_pageSizeOptions"
      )
      : this.paginator_pageSizeOptions;
    return this.paginator_pageSizeOptions.split(",").map((str) => +str); //ha magicből jött adat akkor azt használjuk, ha nem akkor az alapértéket
  }
}
