import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    bolttmp = "bolttmp",
        MG_T01 = "MG_T01",
        Column10485762 = "Column10485762",
        HNEV = "HNEV",
}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get HNEV(): FormControl {
        return this.getTableChildFormControl(MgControlName.HNEV);
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}