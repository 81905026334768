<div novalidate [formGroup]="screenFormGroup">
  <div class="flex-container-column" magic="{{ mgc.Kiadokkonyvek }}">
    <!--
    <div #overlayheader class="my-overlay-header">
      <span>{{ mg.getValue("MG_FORMNAME") }}</span>
      <button
        class="input_button"
        magic="{{ mgc.MG_B_QUIT }}"
        [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)"
      >
        <fa-icon [icon]="faWindowClose"></fa-icon>
      </button>
    </div>
    -->
    <div class="flex-container-row">
      <mat-card magic="{{ mgc.MG_GR01 }}" class="container_border">
        <div class="flex-container-row">
          <button
            mat-raised-button
            color="primary"
            magic="{{ mgc.btn_View_Refresh }}"
            [matTooltip]="mg.getTitle(mgc.btn_View_Refresh)"
          >
            Frissít
          </button>
          <button
            mat-raised-button
            color="primary"
            magic="{{ mgc.MG_LX01_BT01 }}"
          >
            X
          </button>
        </div>
        <div class="flex-container-row">
          <div>
            <mat-selection-list
              multiple
              formControlName="{{ mgc.MG_LX01 }}"
              magic="{{ mgc.MG_LX01 }}"
              [tabIndex]="screenFormGroup.get(mgc.MG_LX01).disabled ? -1 : 0"
            >
              <mat-list-option
                *ngFor="let o of mg.getItemListValues(mgc.MG_LX01)"
                [value]="o.index"
              >
                {{ o.displayValue }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="flex-container-row">
      <mat-card magic="{{ mgc.MG_GR02 }}" class="container_border">
        <div class="flex-container-row">
          <div>
            <mat-selection-list
              multiple
              formControlName="{{ mgc.MG_LX02 }}"
              magic="{{ mgc.MG_LX02 }}"
              [tabIndex]="screenFormGroup.get(mgc.MG_LX02).disabled ? -1 : 0"
            >
              <mat-list-option
                *ngFor="let o of mg.getItemListValues(mgc.MG_LX02)"
                [value]="o.index"
              >
                {{ o.displayValue }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
