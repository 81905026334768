<div novalidate [formGroup]="screenFormGroup">
  <div class="flex-container-column" magic="{{ mgc.Kiadovalasztas }}">
    <div #overlayheader class="my-overlay-header">
      <span>{{ mg.getValue("MG_FORMNAME") }}</span>
      <button class="input_button" magic="{{ mgc.MG_B_QUIT }}" [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)">
        <fa-icon [icon]="faWindowClose"></fa-icon>
      </button>
    </div>
    <div class="flex-container-row">
      <mat-form-field class="MG_D_SEARCH-form-field" appearance="fill">
        <div>
          <mat-label></mat-label>
          <input matInput type="text" style="width:90%" magic="{{ mgc.MG_D_SEARCH }}" placeholder="Keresés"
            formControlName="{{ mgc.MG_D_SEARCH }}" mgFormat (keyup.enter)="onEnter()" [(ngModel)]="MG_D_SEARCH" />
          <button *ngIf="MG_D_SEARCH" matSuffix mat-icon-button aria-label="Clear" style="width:10%"
            (click)="MG_D_SEARCH=''">
            <mat-icon>close</mat-icon>
          </button>
          <mgError magic="{{ mgc.MG_D_SEARCH }}"> </mgError>
        </div>
      </mat-form-field>
      <button mat-raised-button color="primary" magic="{{ mgc.MG_B_SEARCH2DEF }}"
        [style.visibility]="mg.getVisible(mgc.MG_B_SEARCH2DEF)" [matTooltip]="mg.getTitle(mgc.MG_B_SEARCH2DEF)">
        x
      </button>
      <button mat-raised-button color="primary" magic="{{ mgc.MG_B_SEARCH }}"
        [style.visibility]="mg.getVisible(mgc.MG_B_SEARCH)" [matTooltip]="mg.getTitle(mgc.MG_B_SEARCH)">
        Keres
      </button>
    </div>
    <div class="flex-container-row">
      <mat-card>
        <magic-subform magic="{{ mgc.valasztas }}"> </magic-subform>
      </mat-card>
    </div>
  </div>
</div>