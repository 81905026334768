import { FormControl, FormGroup } from "@angular/forms";
import { MagicServices } from "@magic-xpa/angular";
export enum MgControlName {
  sbugyfelBeszallitovalasztas = "sbugyfelBeszallitovalasztas",
  Label10485761 = "Label10485761",
  Label10485762 = "Label10485762",
  Label10485763 = "Label10485763",
  Label10485764 = "Label10485764",
  nev = "nev",
  irsz = "irsz",
  varos = "varos",
  cim = "cim",
  keresNYG = "keresNYG",
  Beszallito_valasztas = "Beszallito_valasztas",
  MG_FORMNAME = "MG_FORMNAME",
  MG_B_QUIT="MG_B_QUIT",
}
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) {}

  get nev(): FormControl {
    return this.fg.controls[MgControlName.nev] as FormControl;
  }

  get irsz(): FormControl {
    return this.fg.controls[MgControlName.irsz] as FormControl;
  }

  get varos(): FormControl {
    return this.fg.controls[MgControlName.varos] as FormControl;
  }

  get cim(): FormControl {
    return this.fg.controls[MgControlName.cim] as FormControl;
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(
      this.magicServices.tableService.getSelectedRow()
    ).controls[name] as FormControl;
  }
}
