<div novalidate [formGroup]="screenFormGroup">
  <div class="flex-container-column" magic="{{ mgc.Beszallitovalasztas }}">
    <div #overlayheader class="my-overlay-header">
      <span>{{ mg.getValue("MG_FORMNAME") }}</span>
      <button
        class="input_button"
        magic="{{ mgc.MG_B_QUIT }}"
        [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)"
      >
        <fa-icon [icon]="faWindowClose"></fa-icon>
      </button>
    </div>
    <div class="flex-container-row">
      <div
        class="example-container mat-elevation-z8 Table10485763TableContainerProps"
      >
        <mat-table
          #table
          [dataSource]="dataSource"
          class="container_border"
          matSort
          matSortDisableClear
          (matSortChange)="tableService.sortData($event)"
        >
          <ng-container
            magic="{{ mgc.Column10485764 }}"
            matColumnDef="{{ mgc.Column10485764 }}"
          >
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              beszallito nev
            </mat-header-cell>
            <mat-cell *matCellDef="let row" magicMark="magicTableRowContainer">
              <div class="flex-container-row" *ngIf="mg.ifRowCreated(row)">
                <div
                  [formGroup]="mg.getFormGroupByRow(row.rowId)"
                  class="flex-container-row"
                >
                  <label magic="{{ mgc.beszallitonev }}" [rowId]="row.rowId">
                    {{ mg.getValue(mgc.beszallitonev, row.rowId) }}
                  </label>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container
            magic="{{ mgc.Column10485765 }}"
            matColumnDef="{{ mgc.Column10485765 }}"
          >
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              irsz
            </mat-header-cell>
            <mat-cell *matCellDef="let row" magicMark="magicTableRowContainer">
              <div class="flex-container-row" *ngIf="mg.ifRowCreated(row)">
                <div
                  [formGroup]="mg.getFormGroupByRow(row.rowId)"
                  class="flex-container-row"
                >
                  <label magic="{{ mgc.irsz }}" [rowId]="row.rowId">
                    {{ mg.getValue(mgc.irsz, row.rowId) }}
                  </label>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container
            magic="{{ mgc.Column10485766 }}"
            matColumnDef="{{ mgc.Column10485766 }}"
          >
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              varos
            </mat-header-cell>
            <mat-cell *matCellDef="let row" magicMark="magicTableRowContainer">
              <div class="flex-container-row" *ngIf="mg.ifRowCreated(row)">
                <div
                  [formGroup]="mg.getFormGroupByRow(row.rowId)"
                  class="flex-container-row"
                >
                  <label magic="{{ mgc.varos }}" [rowId]="row.rowId">
                    {{ mg.getValue(mgc.varos, row.rowId) }}
                  </label>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container
            magic="{{ mgc.Column10485767 }}"
            matColumnDef="{{ mgc.Column10485767 }}"
          >
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              cim
            </mat-header-cell>
            <mat-cell *matCellDef="let row" magicMark="magicTableRowContainer">
              <div class="flex-container-row" *ngIf="mg.ifRowCreated(row)">
                <div
                  [formGroup]="mg.getFormGroupByRow(row.rowId)"
                  class="flex-container-row"
                >
                  <label magic="{{ mgc.cim }}" [rowId]="row.rowId">
                    {{ mg.getValue(mgc.cim, row.rowId) }}
                  </label>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container
            magic="{{ mgc.Column10485768 }}"
            matColumnDef="{{ mgc.Column10485768 }}"
          >
            <mat-header-cell
              *matHeaderCellDef
              [style.visibility]="mg.getVisible(mgc.Column10485768)"
            >
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row"
              [style.visibility]="mg.getVisible(mgc.Column10485768)"
              magicMark="magicTableRowContainer"
            >
              <div class="flex-container-row" *ngIf="mg.ifRowCreated(row)">
                <div [formGroup]="mg.getFormGroupByRow(row.rowId)">
                  <mat-checkbox
                    magic="{{ mgc.kivalasztva }}"
                    [rowId]="row.rowId"
                    formControlName="{{ mgc.kivalasztva }}"
                  >
                  </mat-checkbox>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="table_row"
            [ngClass]="{ selected: selection.isSelected(row) }"
            [magicRow]="row?.rowId"
            (click)="tableService.selectRow(row.rowId)"
          >
          </mat-row>
        </mat-table>
        <mat-paginator
          #paginator
          [pageSize]="10"
          [pageSizeOptions]="[5, 10, 20]"
          [showFirstLastButtons]="true"
          (page)="tableService.mgOnPaginateChange($event)"
        >
        </mat-paginator>
      </div>
    </div>
    <div class="flex-container-row">
      <label magic="{{ mgc.Label10485761 }}" class="lable_overflow">
        RNEV
      </label>
      <label magic="{{ mgc.RNEV }}">
        {{ mg.getValue(mgc.RNEV) }}
      </label>
    </div>
    <div class="flex-container-row">
      <button mat-raised-button color="primary" magic="{{ mgc.KivalasztNYG }}">
        Kivalaszt
      </button>
      <button mat-raised-button color="primary" magic="{{ mgc.MegsemNYG }}">
        Megsem
      </button>
    </div>
  </div>
</div>
