import { Component } from '@angular/core';
import { setTheme } from 'ngx-bootstrap/utils';
import { faBook, faUser, faWrench, faStream, faTag, faGraduationCap, faChartPie, faUpload} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Jcbs2Jelentes';
 faBook=faBook;
  faUser=faUser;
  faWrench=faWrench;
  faStream=faStream;
  faTag=faTag;
  faGraduationCap=faGraduationCap;
  faChartPie=faChartPie;
  faUpload=faUpload;
  public overlayFormName = '';
  constructor() {
    setTheme('bs4'); // or 'bs4'
}
}
