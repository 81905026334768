import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    Forgalomlista = "Forgalomlista",
        MG_T01 = "MG_T01",
        Column10485762 = "Column10485762",
        datum = "datum",
        Column10485763 = "Column10485763",
        boltszam = "boltszam",
        Column10485764 = "Column10485764",
        boltnev = "boltnev",
        Column10485765 = "Column10485765",
        forgalom = "forgalom",
        Column10485766 = "Column10485766",
        aru_kozp_id = "aru_kozp_id",
        Column10485767 = "Column10485767",
        arunev = "arunev",
        Column10485768 = "Column10485768",
        isbn = "isbn",
        Column10485769 = "Column10485769",
        szerzo = "szerzo",
        Column10485770 = "Column10485770",
        eredeti_kiado = "eredeti_kiado",
        Column10485771 = "Column10485771",
        afa = "afa",
        Column10485772 = "Column10485772",
        beszallito = "beszallito",
        Column10485773 = "Column10485773",
        brlistaaron = "brlistaaron",
        Column10485774 = "Column10485774",
        brfogyaron = "brfogyaron",
        Column10485775 = "Column10485775",
        nefogyaron = "nefogyaron",
        Column10485776 = "Column10485776",
        nebeszaron = "nebeszaron",
}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get datum(): FormControl {
        return this.getTableChildFormControl(MgControlName.datum);
    }

    get boltszam(): FormControl {
        return this.getTableChildFormControl(MgControlName.boltszam);
    }

    get boltnev(): FormControl {
        return this.getTableChildFormControl(MgControlName.boltnev);
    }

    get forgalom(): FormControl {
        return this.getTableChildFormControl(MgControlName.forgalom);
    }

    get aru_kozp_id(): FormControl {
        return this.getTableChildFormControl(MgControlName.aru_kozp_id);
    }

    get arunev(): FormControl {
        return this.getTableChildFormControl(MgControlName.arunev);
    }

    get isbn(): FormControl {
        return this.getTableChildFormControl(MgControlName.isbn);
    }

    get szerzo(): FormControl {
        return this.getTableChildFormControl(MgControlName.szerzo);
    }

    get eredeti_kiado(): FormControl {
        return this.getTableChildFormControl(MgControlName.eredeti_kiado);
    }

    get afa(): FormControl {
        return this.getTableChildFormControl(MgControlName.afa);
    }

    get beszallito(): FormControl {
        return this.getTableChildFormControl(MgControlName.beszallito);
    }

    get brlistaaron(): FormControl {
        return this.getTableChildFormControl(MgControlName.brlistaaron);
    }

    get brfogyaron(): FormControl {
        return this.getTableChildFormControl(MgControlName.brfogyaron);
    }

    get nefogyaron(): FormControl {
        return this.getTableChildFormControl(MgControlName.nefogyaron);
    }

    get nebeszaron(): FormControl {
        return this.getTableChildFormControl(MgControlName.nebeszaron);
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}