import { Component } from "@angular/core";

import { FormGroup } from "@angular/forms";
import {
  MgFormControlsAccessor,
  MgControlName,
} from "./texteditor.mg.controls.g";

import { TaskBaseMagicComponent, magicProviders } from "@magic-xpa/angular";

import { MagicModalInterface } from "@magic-xpa/angular";

import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "mga-texteditor",
  providers: [...magicProviders],
  templateUrl: "./texteditor.component.html",
  styleUrls: ["./editor.scss"]
})
export class texteditor
  extends TaskBaseMagicComponent
  implements MagicModalInterface {
  mgc = MgControlName;
  mgfc: MgFormControlsAccessor;
  faWindowClose = faWindowClose;

  editorStyle = {
    height: '400px'
  };

  createFormControlsAccessor(formGroup: FormGroup) {
    this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
  }
  private static readonly formName: string = "texteditor";
  private static readonly showTitleBar: boolean = true;
  private static readonly x: number = 0;
  private static readonly y: number = 0;
  private static readonly width: string = "300px";
  private static readonly height: string = "300px";
  private static readonly isCenteredToWindow: boolean = true;
  private static readonly shouldCloseOnBackgroundClick = true;
  X() {
    return texteditor.x;
  }
  Y() {
    return texteditor.y;
  }
  Width(): string {
    return texteditor.width;
  }
  Height(): string {
    return texteditor.height;
  }
  IsCenteredToWindow() {
    return texteditor.isCenteredToWindow;
  }
  FormName() {
    return texteditor.formName;
  }
  ShowTitleBar() {
    return texteditor.showTitleBar;
  }
  ShouldCloseOnBackgroundClick() {
    return texteditor.shouldCloseOnBackgroundClick;
  }
}
