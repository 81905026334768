<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        class="flex-container-column"
        magic="{{mgc.editzoomWC}}"
    >
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label10485761}}"
                class="lable_overflow"
            >
                edit:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.edit}}"
                        formControlName="{{mgc.edit}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.edit}}> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label10485763}}"
                class="lable_overflow"
            >
                **UnSupported Control**
            </label>
        </div>
    </div>
</div>