<form name="screenFormGroup" novalidate [formGroup]="screenFormGroup">
  <div class="urlap, form-style" magic="{{ mgc.Login2 }}" style="width: initial">
    <h2>
      {{ mg.getValue("MG_FORMNAME") }}
    </h2>
    <!-- Beszállító megadása -->
    <div class="default_elements">
      <span class="element text" [style.visibility]="mg.getVisible(mgc.puref)"><span
          *ngIf="mg.getMustInput(mgc.puref) == 'true'">*</span>Beszállító</span>
      <div style="flex: 1 0 0; display: flex">
        <label style="flex: 1; padding: 5px" magic="{{ mgc.puref }}" [style.visibility]="mg.getVisible(mgc.puref)">
          {{ mg.getValue(mgc.puref) }}
        </label>
        <magic-subform style="flex: 5" [style.visibility]="mg.getVisible(mgc.BeszallitoSF)"
          magic="{{ mgc.BeszallitoSF }}">
        </magic-subform>
      </div>
    </div>
    <div class="default_elements">
      <mgError magic="{{ mgc.puref }}"> </mgError>
    </div>
    <!-- vezérlés a beszállítóhoz -->
    <div class="flex-container-row">
      <button mat-raised-button color="primary" magic="{{ mgc.BTLogin }}"
        [style.visibility]="mg.getVisible(mgc.BTLogin)" [disabled]="mg.isDisabled(mgc.BTLogin)">
        {{ mg.getValue(mgc.BTLogin) }}
      </button>
      <button mat-raised-button color="primary" magic="{{ mgc.btn_Back }}"
        [style.visibility]="mg.getVisible(mgc.btn_Back)" [disabled]="mg.isDisabled(mgc.btn_Back)">
        {{ mg.getValue(mgc.btn_Back) }}
      </button>
      <button mat-raised-button color="primary" magic="{{ mgc.MG_B_QUIT }}"
        [style.visibility]="mg.getVisible(mgc.MG_B_QUIT)" [disabled]="mg.isDisabled(mgc.MG_B_QUIT)"
        [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)">
        Mégsem
      </button>
    </div>
    <!-- Kiadók -->
    <fieldset style="display: block; margin-top: 50px;" [style.visibility]="mg.getVisible(mgc.Labelkiadok)">
      <legend> Beszállító kiadói </legend>
      <div class="card szint2">
        <div class="kiado">
          <div class="default_elements" style="display: flex;">
            <mat-checkbox [magic]="mgc.Ujkiado" [formControlName]="mgc.Ujkiado"
              [style.visibility]="mg.getVisible(mgc.Ujkiado)" style="margin-left: 10px; padding: 7px">
              Új
            </mat-checkbox>
            <select magic="{{ mgc.kiadok }}" formControlName="{{ mgc.kiadok }}" class="form-control"
              [style.display]="mg.getVisible(mgc.kiadok) == 'visible' ? 'initial' : 'none'" style="max-width: initial; flex: 1;">
              <option *ngFor="let o of mg.getItemListValues(mgc.kiadok)" [value]="o.index">
                {{ o.displayValue }}
              </option>
            </select>
            <div class="text" [style.display]="mg.getVisible(mgc.Ujkiadonev) == 'visible' ? 'initial' : 'none'" style="flex: 1;">
              <div class="default_elements" style="min-width: initial;">
                <input [magic]="mgc.Ujkiadonev" [formControlName]="mgc.Ujkiadonev" type="text"
                  class="form-control text" />
                <mgError magic="{{mgc.Ujkiadonev}}" class="mgError"></mgError>
              </div>
            </div>
            <button mat-raised-button color="primary" magic="{{ mgc.ment }}"
            [style.display]="mg.getVisible(mgc.ment) == 'visible' ? 'initial' : 'none'" [disabled]="mg.isDisabled(mgc.ment)" style="margin-top: 10px;">
              Ment
            </button>
          </div>
        </div>
      </div>
    </fieldset>
    <!-- vezérlés a kiadóhoz -->
    <div class="flex-container-row">

    </div>
  </div>
</form>
