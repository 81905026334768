import { FormControl, FormGroup } from "@angular/forms";
import { MagicServices } from "@magic-xpa/angular";
export enum MgControlName {
  UjcikkLinks = "UjcikkLinks",
  Table1 = "Table1",
  Column3 = "Column3",
  link = "link",
  Column14 = "Column14",
  megjegyzes = "megjegyzes",
  Column4 = "Column4",
  EnterRowEditing = "EnterRowEditing",
  SaveRowEditing = "SaveRowEditing",
  CancelRowEditing = "CancelRowEditing",
  DeleteRecord = "DeleteRecord",
  Create = "Create",
  New = "New",
}
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) {}

  get link(): FormControl {
    return this.getTableChildFormControl(MgControlName.link);
  }

  get megjegyzes(): FormControl {
    return this.getTableChildFormControl(MgControlName.megjegyzes);
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(
      this.magicServices.tableService.getSelectedRow()
    ).controls[name] as FormControl;
  }
}
