import { FormControl, FormGroup } from "@angular/forms";
import { MagicServices } from "@magic-xpa/angular";
export enum MgControlName {
  texteditor = "texteditor",
  MG_FORMNAME = "MG_FORMNAME",
  MG_B_QUIT = "MG_B_QUIT",
  output = "output",
  MG_B_OK = "MG_B_OK",
}
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) {}

  get MG_FORMNAME(): FormControl {
    return this.fg.controls[MgControlName.MG_FORMNAME] as FormControl;
  }

  get output(): FormControl {
    return this.fg.controls[MgControlName.output] as FormControl;
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(
      this.magicServices.tableService.getSelectedRow()
    ).controls[name] as FormControl;
  }
}
