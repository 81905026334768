import {
    Component
} from '@angular/core';

import {
    FormGroup
} from "@angular/forms";
import {
    MgFormControlsAccessor,
    MgControlName
} from "./bolttmp.mg.controls.g";


	import {
		BaseMatTableMagicComponent,
		matMagicProviders
	} from "@magic-xpa/angular-material-core";
	import { faWindowClose } from "@fortawesome/free-solid-svg-icons";


import {
    MagicModalInterface
} from "@magic-xpa/angular";

@Component({
    selector: 'mga-bolttmp',
    providers: [...matMagicProviders],
    templateUrl: './bolttmp.component.html'
})
export class bolttmp extends BaseMatTableMagicComponent implements MagicModalInterface {

    mgc = MgControlName;
    mgfc: MgFormControlsAccessor;
    createFormControlsAccessor(formGroup: FormGroup) {
        this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
    }
    private static readonly formName: string = "bolttmp";
    private static readonly showTitleBar: boolean = true;
    private static readonly x: number = 0;
    private static readonly y: number = 0;
    private static readonly width: string = "300px";
    private static readonly height: string = "300px";
    private static readonly isCenteredToWindow: boolean = true;
    private static readonly shouldCloseOnBackgroundClick = true;
    X() {
        return bolttmp.x;
    }
    Y() {
        return bolttmp.y;
    }
    Width(): string {
        return bolttmp.width;
    }
    Height(): string {
        return bolttmp.height;
    }
    IsCenteredToWindow() {
        return bolttmp.isCenteredToWindow;
    }
    FormName() {
        return bolttmp.formName;
    }
    ShowTitleBar() {
        return bolttmp.showTitleBar;
    }
    ShouldCloseOnBackgroundClick() {
        return bolttmp.shouldCloseOnBackgroundClick;
    }

    displayedColumns = [
        'Column2',
    ];

}
