import { FormControl, FormGroup } from "@angular/forms";
import { MagicServices } from "@magic-xpa/angular";
export enum MgControlName {
  Kiadovalasztas = "Kiadovalasztas",
  MG_D_SEARCH = "MG_D_SEARCH",
  MG_B_SEARCH2DEF = "MG_B_SEARCH2DEF",
  MG_B_SEARCH = "MG_B_SEARCH",
  valasztas = "valasztas",
  MG_FORMNAME = "MG_FORMNAME",
  MG_B_QUIT = "MG_B_QUIT",
}
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) {}

  get MG_D_SEARCH(): FormControl {
    return this.fg.controls[MgControlName.MG_D_SEARCH] as FormControl;
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(
      this.magicServices.tableService.getSelectedRow()
    ).controls[name] as FormControl;
  }
}
