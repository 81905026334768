<div novalidate [formGroup]="screenFormGroup">
  <div class="flex-container-column" magic="{{ mgc.NO }}">
    <div #overlayheader class="my-overlay-header">
      <span>{{ mg.getValue("MG_FORMNAME") }}</span>
      <button
        class="input_button"
        magic="{{ mgc.MG_B_QUIT }}"
        [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)"
      >
        <fa-icon [icon]="faWindowClose"></fa-icon>
      </button>
    </div>
    <div class="flex-container-row">
      <mat-form-field>
        <div>
          <input
            matInput
            type="number"
            magic="{{ mgc.bolt_recno }}"
            formControlName="{{ mgc.bolt_recno }}"
            mgFormat
            rangevalidator
          />
          <mgError magic="{{ mgc.bolt_recno }}"> </mgError>
        </div>
      </mat-form-field>
    </div>
  </div>
</div>
