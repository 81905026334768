<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        class="flex-container-column"
        magic="{{mgc.testVariables}}"
    >
        <div class="flex-container-row">
            <mat-form-field [style.visibility]="mg.getVisible(mgc.pu_nev)">
                <div>
                    <input
                        matInput
                        magic="{{mgc.pu_nev}}"
                        [required]="mg.getMustInput(mgc.pu_nev)"
                        formControlName="{{mgc.pu_nev}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.pu_nev}}> </mgError>
                </div>
            </mat-form-field>
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.punevbt}}"
            >
                ...
            </button>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.RNEV}}"
                [style.visibility]="mg.getVisible(mgc.RNEV)"
            >
                {{mg.getValue(mgc.RNEV) }}
            </label>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label10485774}}"
                class="lable_overflow mgFont1"
                [attr.disabled]="mg.isDisabled(mgc.Label10485774 )"
            >
                puref_N
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        type='number'
                        magic="{{mgc.puref_N}}"
                        formControlName="{{mgc.puref_N}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.puref_N}}> </mgError>
                </div>
            </mat-form-field>
            <label
                magic="{{mgc.Label9}}"
                class="lable_overflow mgFont1"
                [attr.disabled]="mg.isDisabled(mgc.Label9 )"
            >
                talalt
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.talalt}}"
                        formControlName="{{mgc.talalt}}"
                        mgFormat
                        rangevalidator
                    >
                    <mgError magic={{mgc.talalt}}> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label10485776}}"
                class="lable_overflow mgFont1"
                [attr.disabled]="mg.isDisabled(mgc.Label10485776 )"
            >
                sfexecmode
            </label>
            <label magic="{{mgc.sfexecmode}}">
                {{mg.getValue(mgc.sfexecmode) }}
            </label>
            <label
                magic="{{mgc.Label10485778}}"
                class="lable_overflow mgFont1"
                [attr.disabled]="mg.isDisabled(mgc.Label10485778 )"
            >
                visszadob
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.visszadob}}"
                        formControlName="{{mgc.visszadob}}"
                        mgFormat
                        rangevalidator
                    >
                    <mgError magic={{mgc.visszadob}}> </mgError>
                </div>
            </mat-form-field>
            <label
                magic="{{mgc.Label10485780}}"
                class="lable_overflow mgFont1"
                [attr.disabled]="mg.isDisabled(mgc.Label10485780 )"
            >
                vége
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.vege}}"
                        formControlName="{{mgc.vege}}"
                        mgFormat
                        rangevalidator
                    >
                    <mgError magic={{mgc.vege}}> </mgError>
                </div>
            </mat-form-field>
        </div>
    </div>
</div>