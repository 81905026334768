import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    Keszlet = "Keszlet",
        Label2 = "Label2",
        beszallito = "beszallito",
        HNEV = "HNEV",
        Label4 = "Label4",
        Aru_recno = "Aru_recno",
        aru_nev = "aru_nev",
        Label7 = "Label7",
        aru_nev_szures = "aru_nev_szures",
        Label11 = "Label11",
        isbn = "isbn",
        Label13 = "Label13",
        kiadas_eve_tol = "kiadas_eve_tol",
        kiadas_eve_ig = "kiadas_eve_ig",
        Label14 = "Label14",
        szerzo = "szerzo",
        Label16 = "Label16",
        kiado = "kiado",
        Label18 = "Label18",
        sorozat = "sorozat",
        Label20 = "Label20",
        kategoriak = "kategoriak",
        Label22 = "Label22",
        bolt_tipus = "bolt_tipus",
        nagyker = "nagyker",
        online = "online",
        nemonline = "nemonline",
        LiraLira_kiskerRozsavolgyi = "LiraLira_kiskerRozsavolgyi",
        Label23 = "Label23",
        bolt = "bolt",
        bolt_nev = "bolt_nev",
        Group44 = "Group44",
        bolt_0001 = "bolt_0001",
        qbeszallito = "qbeszallito",
        cikk = "cikk",
        listaar = "listaar",
        beszerzesi_ar = "beszerzesi_ar",
        Lekerdezes = "Lekerdezes",
        Excel = "Excel",
}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get beszallito(): FormControl {
        return this.fg.controls[MgControlName.beszallito] as FormControl;
    }

    get HNEV(): FormControl {
        return this.fg.controls[MgControlName.HNEV] as FormControl;
    }

    get Aru_recno(): FormControl {
        return this.fg.controls[MgControlName.Aru_recno] as FormControl;
    }

    get aru_nev(): FormControl {
        return this.fg.controls[MgControlName.aru_nev] as FormControl;
    }

    get aru_nev_szures(): FormControl {
        return this.fg.controls[MgControlName.aru_nev_szures] as FormControl;
    }

    get isbn(): FormControl {
        return this.fg.controls[MgControlName.isbn] as FormControl;
    }

    get kiadas_eve_tol(): FormControl {
        return this.fg.controls[MgControlName.kiadas_eve_tol] as FormControl;
    }

    get kiadas_eve_ig(): FormControl {
        return this.fg.controls[MgControlName.kiadas_eve_ig] as FormControl;
    }

    get szerzo(): FormControl {
        return this.fg.controls[MgControlName.szerzo] as FormControl;
    }

    get kiado(): FormControl {
        return this.fg.controls[MgControlName.kiado] as FormControl;
    }

    get sorozat(): FormControl {
        return this.fg.controls[MgControlName.sorozat] as FormControl;
    }

    get kategoriak(): FormControl {
        return this.fg.controls[MgControlName.kategoriak] as FormControl;
    }

    get bolt_tipus(): FormControl {
        return this.fg.controls[MgControlName.bolt_tipus] as FormControl;
    }

    get nagyker(): FormControl {
        return this.fg.controls[MgControlName.nagyker] as FormControl;
    }

    get online(): FormControl {
        return this.fg.controls[MgControlName.online] as FormControl;
    }

    get nemonline(): FormControl {
        return this.fg.controls[MgControlName.nemonline] as FormControl;
    }

    get LiraLira_kiskerRozsavolgyi(): FormControl {
        return this.fg.controls[MgControlName.LiraLira_kiskerRozsavolgyi] as FormControl;
    }

    get bolt(): FormControl {
        return this.fg.controls[MgControlName.bolt] as FormControl;
    }

    get bolt_nev(): FormControl {
        return this.fg.controls[MgControlName.bolt_nev] as FormControl;
    }

    get bolt_0001(): FormControl {
        return this.fg.controls[MgControlName.bolt_0001] as FormControl;
    }

    get qbeszallito(): FormControl {
        return this.fg.controls[MgControlName.qbeszallito] as FormControl;
    }

    get cikk(): FormControl {
        return this.fg.controls[MgControlName.cikk] as FormControl;
    }

    get listaar(): FormControl {
        return this.fg.controls[MgControlName.listaar] as FormControl;
    }

    get beszerzesi_ar(): FormControl {
        return this.fg.controls[MgControlName.beszerzesi_ar] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}