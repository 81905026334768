<div novalidate [formGroup]="screenFormGroup">
  <div
    class="flex-container-column"
    magic="{{ mgc.Szerzovalasztasarumegtekint }}"
  >
    <div #overlayheader class="my-overlay-header">
      <span>{{ mg.getValue("MG_FORMNAME") }}</span>
      <button
        class="input_button"
        magic="{{ mgc.MG_B_QUIT }}"
        [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)"
      >
        <fa-icon [icon]="faWindowClose"></fa-icon>
      </button>
    </div>
    <div class="flex-container-row">
      <label magic="{{ mgc.Label10485761 }}" class="lable_overflow">
        Szerzo
      </label>
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.Szerzo }}"
            formControlName="{{ mgc.Szerzo }}"
            mgFormat
          />
          <mgError magic="{{ mgc.Szerzo }}"> </mgError>
        </div>
      </mat-form-field>
      <button
        mat-button
        color="primary"
        magic="{{ mgc.keresNYG }}"
        class="button_image_background_size"
        [ngStyle]="{
          'background-image': mg.getStyle(
            mgc.keresNYG,
            magicProperties.ImageFile
          )
        }"
      >
        {{ mg.getValue(mgc.keresNYG) }}
      </button>
    </div>
    <div class="flex-container-row">
      <mat-card>
        <magic-subform magic="{{ mgc.szerzok }}"> </magic-subform>
      </mat-card>
    </div>
  </div>
</div>
