import { FormControl, FormGroup } from "@angular/forms";
import { MagicServices } from "@magic-xpa/angular";
export enum MgControlName {
  Aruvalasztas2 = "Aruvalasztas2",
  MG_FORMNAME = "MG_FORMNAME",
  Group10485767 = "Group10485767",
  MG_D_SEARCH = "MG_D_SEARCH",
  MG_CB_SELECTED = "MG_CB_SELECTED",
  MG_B_SEARCH2DEF = "MG_B_SEARCH2DEF",
  MG_B_SEARCH = "MG_B_SEARCH",
  MG_T01 = "MG_T01",
  Column10485773 = "Column10485773",
  MG_T01C001D001 = "MG_T01C001D001",
  Column10485774 = "Column10485774",
  MG_T01C002D001 = "MG_T01C002D001",
  Column10485775 = "Column10485775",
  MG_T01C003D001 = "MG_T01C003D001",
  Column10485776 = "Column10485776",
  MG_T01C004D001 = "MG_T01C004D001",
  Column10485777 = "Column10485777",
  MG_T01C005D001 = "MG_T01C005D001",
  Column10485778 = "Column10485778",
  MG_T01C006D001 = "MG_T01C006D001",
  Column10485779 = "Column10485779",
  MG_T01C007D001 = "MG_T01C007D001",
  MG_FL004 = "MG_FL004",
  MG_FD004 = "MG_FD004",
  MG_FL003 = "MG_FL003",
  MG_FD003 = "MG_FD003",
  MG_FD002 = "MG_FD002",
  MG_FD001 = "MG_FD001",
  MG_B_SELECT = "MG_B_SELECT",
  MG_B_QUIT = "MG_B_QUIT",
  NEV = "NEV",
  MG_B_KIADVANY="MG_B_KIADVANY"
}
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) {}

  get MG_FORMNAME(): FormControl {
    return this.fg.controls[MgControlName.MG_FORMNAME] as FormControl;
  }

  get MG_D_SEARCH(): FormControl {
    return this.fg.controls[MgControlName.MG_D_SEARCH] as FormControl;
  }

  get MG_CB_SELECTED(): FormControl {
    return this.fg.controls[MgControlName.MG_CB_SELECTED] as FormControl;
  }

  get MG_T01C001D001(): FormControl {
    return this.getTableChildFormControl(MgControlName.MG_T01C001D001);
  }

  get MG_T01C002D001(): FormControl {
    return this.getTableChildFormControl(MgControlName.MG_T01C002D001);
  }

  get MG_T01C003D001(): FormControl {
    return this.getTableChildFormControl(MgControlName.MG_T01C003D001);
  }

  get MG_T01C004D001(): FormControl {
    return this.getTableChildFormControl(MgControlName.MG_T01C004D001);
  }

  get MG_T01C005D001(): FormControl {
    return this.getTableChildFormControl(MgControlName.MG_T01C005D001);
  }

  get MG_T01C006D001(): FormControl {
    return this.getTableChildFormControl(MgControlName.MG_T01C006D001);
  }

  get MG_T01C007D001(): FormControl {
    return this.getTableChildFormControl(MgControlName.MG_T01C007D001);
  }

  get MG_FD004(): FormControl {
    return this.fg.controls[MgControlName.MG_FD004] as FormControl;
  }

  get MG_FD003(): FormControl {
    return this.fg.controls[MgControlName.MG_FD003] as FormControl;
  }

  get MG_FD002(): FormControl {
    return this.fg.controls[MgControlName.MG_FD002] as FormControl;
  }

  get MG_FD001(): FormControl {
    return this.fg.controls[MgControlName.MG_FD001] as FormControl;
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(
      this.magicServices.tableService.getSelectedRow()
    ).controls[name] as FormControl;
  }
}
