import { Component } from "@angular/core";

import { FormGroup } from "@angular/forms";
import { MgFormControlsAccessor, MgControlName } from "./Welcome.mg.controls.g";

import { TaskBaseMagicComponent, magicProviders } from "@magic-xpa/angular";
import { StartProgram } from "../StartProgram/StartProgram.component";

@Component({
  selector: "mga-Welcome",
  providers: [...magicProviders],
  templateUrl: "./Welcome.component.html",
})
export class Welcome extends TaskBaseMagicComponent {
  mgc = MgControlName;
  mgfc: MgFormControlsAccessor;

  createFormControlsAccessor(formGroup: FormGroup) {
    this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
  }

  ngAfterViewInit(): void {
    //console.log("ngAfterViewInit");
    //if (!this.mg.isLoggedIn()) console.log("logged");
    StartProgram.ToggleMenu('open');
  }
}
