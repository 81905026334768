<!--pureSF1-->
<div novalidate [formGroup]="screenFormGroup">
  <div class="text_zoom" magic="{{ mgc.purefSF }}">
    <div class="default_elements">
      <input
        type="text"
        class="form-control text"
        magic="{{ mgc.pu_nev }}"
        [required]="mg.getMustInput(mgc.pu_nev)"
        [placeholder]="mg.getPlaceholder(mgc.pu_nev)"
        formControlName="{{ mgc.pu_nev }}"
        (keyup.enter)="onEnter()"
        mgFormat
      />
      <button
        type="button"
        class="btn btn-primary btn-sm"
        magic="{{ mgc.punevbt }}"
        [matTooltip]="mg.getTitle(mgc.punevbt)"
        [disabled]="mg.isDisabled(mgc.punevbt)"
        id="pureSF1_zoom"
      >
        ...
      </button>
    </div>
  </div>
</div>
