import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    KTmshivo = "KTmshivo",
        languagesI = "languagesI",
        languagesA = "languagesA",
        languages = "languages",
        SFlanguages = "SFlanguages",
}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get languagesI(): FormControl {
        return this.fg.controls[MgControlName.languagesI] as FormControl;
    }

    get languagesA(): FormControl {
        return this.fg.controls[MgControlName.languagesA] as FormControl;
    }

    get languages(): FormControl {
        return this.fg.controls[MgControlName.languages] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}