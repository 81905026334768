<!--pureSF2-->
<div novalidate [formGroup]="screenFormGroup">
  <div class="flex-container-column" magic="{{ mgc.purefSF2 }}">
    <div class="flex-container-row">
      <div>
        <select
          magic="{{ mgc.Combo_box25 }}"
          formControlName="{{ mgc.Combo_box25 }}"
        >
          <option
            *ngFor="let o of mg.getItemListValues(mgc.Combo_box25)"
            [value]="o.index"
          >
            {{ o.displayValue }}
          </option>
        </select>

        <!--
                <mat-form-field [matTooltip]="mg.getTitle(mgc.Combo_box25)">
                    <mat-select magic="{{mgc.Combo_box25}}" formControlName="{{mgc.Combo_box25}}">
                        <mat-option *ngFor="let o of mg.getItemListValues(mgc.Combo_box25);" [value]="o.index">
                            {{o.displayValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
-->
      </div>
    </div>
  </div>
</div>
