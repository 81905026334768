import {
    Component
} from '@angular/core';

import {
    FormGroup
} from "@angular/forms";
import {
    MgFormControlsAccessor,
    MgControlName
} from "./Keszletlista.mg.controls.g";


	import {
		BaseMatTableMagicComponent,
		matMagicProviders
	} from "@magic-xpa/angular-material-core";
	import { faWindowClose } from "@fortawesome/free-solid-svg-icons";


import {
    MagicModalInterface
} from "@magic-xpa/angular";

@Component({
    selector: 'mga-Keszletlista',
    providers: [...matMagicProviders],
    templateUrl: './Keszletlista.component.html'
})
export class Keszletlista extends BaseMatTableMagicComponent implements MagicModalInterface {

    mgc = MgControlName;
    mgfc: MgFormControlsAccessor;
    createFormControlsAccessor(formGroup: FormGroup) {
        this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
    }
    private static readonly formName: string = "Keszletlista";
    private static readonly showTitleBar: boolean = true;
    private static readonly x: number = 0;
    private static readonly y: number = 0;
    private static readonly width: string = "300px";
    private static readonly height: string = "300px";
    private static readonly isCenteredToWindow: boolean = true;
    private static readonly shouldCloseOnBackgroundClick = true;
    X() {
        return Keszletlista.x;
    }
    Y() {
        return Keszletlista.y;
    }
    Width(): string {
        return Keszletlista.width;
    }
    Height(): string {
        return Keszletlista.height;
    }
    IsCenteredToWindow() {
        return Keszletlista.isCenteredToWindow;
    }
    FormName() {
        return Keszletlista.formName;
    }
    ShowTitleBar() {
        return Keszletlista.showTitleBar;
    }
    ShouldCloseOnBackgroundClick() {
        return Keszletlista.shouldCloseOnBackgroundClick;
    }

    displayedColumns = [
        'Column2',
        'Column3',
        'Column4',
        'Column5',
        'Column6',
        'Column7',
        'Column8',
        'Column9',
        'Column10',
        'Column11',
        'Column12',
        'Column13',
    ];

}
