import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    Kiadokkonyvek = "Kiadokkonyvek",
        MG_GR01 = "MG_GR01",
        MG_GR01_LB01 = "MG_GR01_LB01",
        btn_View_Refresh = "btn_View_Refresh",
        MG_LX01_BT01 = "MG_LX01_BT01",
        MG_RB01 = "MG_RB01",
        MG_GR02 = "MG_GR02",
        MG_GR02_LB01 = "MG_GR02_LB01",
        MG_LX02 = "MG_LX02",
        MG_FORMNAME = "MG_FORMNAME",
}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get MG_RB01(): FormControl {
        return this.fg.controls[MgControlName.MG_RB01] as FormControl;
    }

    get MG_LX02(): FormControl {
        return this.fg.controls[MgControlName.MG_LX02] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}
