import {
    Component
} from '@angular/core';

import {
    FormGroup
} from "@angular/forms";
import {
    MgFormControlsAccessor,
    MgControlName
} from "./TextInput.mg.controls.g";


import {
    TaskBaseMagicComponent,
    magicProviders
} from "@magic-xpa/angular";


@Component({
    selector: 'mga-TextInput',
    providers: [...magicProviders],
    templateUrl: './TextInput.component.html'
})
export class TextInput extends TaskBaseMagicComponent {

    mgc = MgControlName;
    mgfc: MgFormControlsAccessor;
    createFormControlsAccessor(formGroup: FormGroup) {
        this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
    }
}