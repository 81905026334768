import {
    Component
} from '@angular/core';

import {
    FormGroup
} from "@angular/forms";
import {
    MgFormControlsAccessor,
    MgControlName
} from "./fobesorolSF.mg.controls.g";


import {
    TaskBaseMagicComponent,
    magicProviders
} from "@magic-xpa/angular";


import {
    MagicModalInterface
} from "@magic-xpa/angular";
//import { Ujcikk } from '../Ujcikk.component';

@Component({
    selector: 'mga-fobesorolSF',
    providers: [...magicProviders],
    templateUrl: './fobesorolSF.component.html'
})
export class fobesorolSF extends TaskBaseMagicComponent implements MagicModalInterface {

    mgc = MgControlName;
    mgfc: MgFormControlsAccessor;
    createFormControlsAccessor(formGroup: FormGroup) {
        this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
    }
/*
    legend: Ujcikk;
    treerefresh(id = ''): void {
        Ujcikk.call.
        this.legend.treerefresh();
    }
*/
    private static readonly formName: string = "fobesorolSF";
    private static readonly showTitleBar: boolean = true;
    private static readonly x: number = 0;
    private static readonly y: number = 0;
    private static readonly width: string = "300px";
    private static readonly height: string = "300px";
    private static readonly isCenteredToWindow: boolean = true;
    private static readonly shouldCloseOnBackgroundClick = true;
    X() {
        return fobesorolSF.x;
    }
    Y() {
        return fobesorolSF.y;
    }
    Width(): string {
        return fobesorolSF.width;
    }
    Height(): string {
        return fobesorolSF.height;
    }
    IsCenteredToWindow() {
        return fobesorolSF.isCenteredToWindow;
    }
    FormName() {
        return fobesorolSF.formName;
    }
    ShowTitleBar() {
        return fobesorolSF.showTitleBar;
    }
    ShouldCloseOnBackgroundClick() {
        return fobesorolSF.shouldCloseOnBackgroundClick;
    }
}