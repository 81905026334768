<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        class="flex-container-column"
        magic="{{mgc.WebClientTreememory}}"
    >
        <div class="flex-container-row">
            <div
                magic="{{mgc.Table10485761}}"
                class="container_border"
            >
                <table>
                    <th>
                        <label>
                            TmpId
                        </label>
                    </th>
                    <th>
                        <label>
                            KTFcs
                        </label>
                    </th>
                    <th>
                        <label>
                            KTKodI
                        </label>
                    </th>
                    <th>
                        <label>
                            KTMegnevezes
                        </label>
                    </th>
                    <ng-container *ngFor="let row of task.Records.list">
                        <tr
                            *ngIf="mg.ifRowCreated(row) && !mg.isDataviewEmpty()"
                            id="row"
                            [formGroup]="mg.getFormGroupByRow(row.rowId)"
                            class="table_row"
                            [ngClass]="{ 'selected': mg.isRowSelected(mgc.Table10485761, row.rowId)}"
                            [class.selected]="tableService.getSelectedRow() === row.rowId"
                            (click)="tableService.selectRow(row.rowId)"
                            [magicRow]="row?.rowId"
                        >
                            <td>
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                type='number'
                                                magic="{{mgc.TmpId}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.TmpId}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.TmpId}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.TmpId}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.TmpId, row.rowId)
}}
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                type='number'
                                                magic="{{mgc.KTFcs}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.KTFcs}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.KTFcs}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.KTFcs}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.KTFcs, row.rowId)
}}
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                type='number'
                                                magic="{{mgc.KTKodI}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.KTKodI}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.KTKodI}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.KTKodI}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.KTKodI, row.rowId)
}}
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    class="flex-container-row"
                                >
                                    <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                magic="{{mgc.KTMegnevezes}}"
                                                [rowId]="row.rowId"
                                                formControlName="{{mgc.KTMegnevezes}}"
                                                mgFormat
                                            >
                                            <mgError
                                                magic={{mgc.KTMegnevezes}}
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        magic="{{mgc.KTMegnevezes}}"
                                        [rowId]="row.rowId"
                                    >
                                        {{mg.getValue(mgc.KTMegnevezes, row.rowId)
}}
                                    </label>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </table>
            </div>
        </div>
    </div>
</div>