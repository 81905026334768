<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        class="flex-container-column"
        magic="{{mgc.testtabcontrol}}"
    >
        <div>
            <mat-tab-group
                magic="{{mgc.Tab1}}"
                (selectedTabChange)="task.mgOnTabSelectionChanged(mgc.Tab1, $event.index)"
                [selectedIndex]="mg.getTabSelectedIndex(mgc.Tab1)"
            >
                <mat-tab
                    *ngFor="let o of mg.getItemListValues(mgc.Tab1)"
                    [label]="mg.getTabpageText(mgc.Tab1, o.index)"
                >
                </mat-tab>
            </mat-tab-group>
            <div
                class="tab_content"
                [style.display]="mg.isTabPageLayerSelected(mgc.Tab1, 1) ? 'flex' : 'none' "
                class="flex-direction-column"
            >
            </div>
            <div
                class="tab_content"
                [style.display]="mg.isTabPageLayerSelected(mgc.Tab1, 2) ? 'flex' : 'none' "
                class="flex-direction-column"
            >
            </div>
        </div>
    </div>
</div>