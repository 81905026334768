import { Component } from "@angular/core";
import {
  MgFormControlsAccessor,
  MgControlName,
} from "./StartProgram.mg.controls.g";
import { TaskBaseMagicComponent, magicProviders } from "@magic-xpa/angular";
import { faQuestionCircle, faHome } from '@fortawesome/free-solid-svg-icons';
import { vernum } from './version';

@Component({
  selector: "mga-StartProgram",
  providers: [...magicProviders],
  templateUrl: "./StartProgram.component.html",
  styleUrls: ["./StartProgram.component.css"],
})

export class StartProgram extends TaskBaseMagicComponent {
  mgc = MgControlName;
  mgfc: MgFormControlsAccessor;
  faQuestionCircle = faQuestionCircle;
  faHome = faHome;
  public static isExpanded: boolean;
  public version = vernum;
  static ToggleMenu(mode = "") {
    const menu = document.getElementById("Menu");
    const sideNav = document.getElementById("SideNav");
    const main = document.getElementById("Main");
    const sidenavimprintContent = document.getElementById("sidenavimprintContent");
    const SideNavImprint = document.getElementById("SideNavImprint");

    menu.classList.toggle("change");
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) {
      sideNav.style.width = "200px";
      SideNavImprint.style.width = "200px";
      SideNavImprint.style.border = "1px solid #041c2b";
      main.style.marginLeft = "200px";
      main.style.transition = "0.5s ease-out";
    } else if (mode != "open") {
      sideNav.style.width = "0";
      SideNavImprint.style.width = "0";
      SideNavImprint.style.bottom = "0px";
      SideNavImprint.style.border = "none";
      sidenavimprintContent.style.width = "0";
      sidenavimprintContent.style.height = "0";
      sidenavimprintContent.style.border = "none";
      //sidenavimprintContent.style.display = "none";
      main.style.marginLeft = "0";
    }
    else {}
  }

  menuclick() {
    StartProgram.ToggleMenu();
  }

  spanclick() {
    window.console.log(
      this.mg.getCustomProperty("StartProgram", "User").trim() == ""
    );
  }

  showContent(id: string, e: MouseEvent) {
    const item = document.getElementById(id);
    const SideNavImprint = document.getElementById("SideNavImprint");
    //console.log(item);
    //console.log(item.style.width);
    if (item.style.width.trim() == "" || (item.style.width == "0px")) {
      //item.style.display = "block";
      item.style.width = "200px";
      item.style.height = "400px";
      //item.style.top = "0";
      item.style.border = "1px solid #041c2b";
      SideNavImprint.style.bottom = "410px";
    }
    else {
      //item.style.display = "none";
      item.style.width = "0";
      item.style.height = "0";
      item.style.border = "1px none #041c2b";
      SideNavImprint.style.bottom = "0";
    }
    e.stopPropagation();
  }
}
