import { FormControl, FormGroup } from "@angular/forms";
import { MagicServices } from "@magic-xpa/angular";
export enum MgControlName {
  Login2 = "Login2",
  puref = "puref",
  BeszallitoSF = "BeszallitoSF",
  BTLogin = "BTLogin",
  btn_Back = "btn_Back",
  MG_FORMNAME = "MG_FORMNAME",
  MG_B_QUIT = "MG_B_QUIT",
  Labelkiadok="Labelkiadok",
  kiadok="kiadok",
  Ujkiadonev="Ujkiadonev",
  ment="ment",
  Ujkiado="Ujkiado",
}
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) {}

  get puref(): FormControl {
    return this.fg.controls[MgControlName.puref] as FormControl;
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(
      this.magicServices.tableService.getSelectedRow()
    ).controls[name] as FormControl;
  }
}
