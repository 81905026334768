import { FormControl, FormGroup } from "@angular/forms";
import { MagicServices } from "@magic-xpa/angular";
export enum MgControlName {
  texteditor = "texteditor",
  Label3 = "Label3",
  pIOblurb = "pIOblurb",
  MG_FORMNAME = "MG_FORMNAME",
  MG_B_QUIT = "MG_B_QUIT",
  output = "output",
  btn_gZoom = "btn_gZoom",
  btn_Quit = "btn_Quit",
}
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) {}

  get pIOblurb(): FormControl {
    return this.fg.controls[MgControlName.pIOblurb] as FormControl;
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(
      this.magicServices.tableService.getSelectedRow()
    ).controls[name] as FormControl;
  }
}
