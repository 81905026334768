import { Component } from "@angular/core";

import { FormGroup } from "@angular/forms";
import {
  MgFormControlsAccessor,
  MgControlName,
} from "./BoltTipusMSWC.mg.controls.g";

import { TaskBaseMagicComponent, magicProviders } from "@magic-xpa/angular";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "mga-BoltTipusMSWC",
  providers: [...magicProviders],
  templateUrl: "./BoltTipusMSWC.component.html",
  styleUrls: ["./BoltTipusMSWC.component.scss"],
})
export class BoltTipusMSWC extends TaskBaseMagicComponent {
  mgc = MgControlName;
  mgfc: MgFormControlsAccessor;
  faWindowClose = faWindowClose;
  createFormControlsAccessor(formGroup: FormGroup) {
    this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
  }
}
