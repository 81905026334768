import { Component } from '@angular/core';

import { FormGroup } from "@angular/forms";
import { MgFormControlsAccessor, MgControlName, MgCustomProperties } from "./CreateNewImageRecord.mg.controls.g";


import { TaskBaseMagicComponent, magicProviders } from "@magic-xpa/angular";


import { MagicModalInterface } from "@magic-xpa/angular";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
//kép metaadatok betöltése
import * as ExifReader from 'exifreader';

@Component({
  selector: 'mga-CreateNewImageRecord',
  providers: [...magicProviders],
  templateUrl: './CreateNewImageRecord.component.html'
})
export class CreateNewImageRecord extends TaskBaseMagicComponent implements MagicModalInterface {

  mgc = MgControlName;
  mgcp = MgCustomProperties;
  mgfc: MgFormControlsAccessor;
  faWindowClose = faWindowClose;
  progress: number = 0;
  progress_visible: string = "hidden";
  createFormControlsAccessor(formGroup: FormGroup) {
    this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
  }
  private static readonly formName: string = "Kép feltöltése";
  private static readonly showTitleBar: boolean = true;
  private static readonly x: number = 0;
  private static readonly y: number = 0;
  private static readonly width: string = "300px";
  private static readonly height: string = "300px";
  private static readonly isCenteredToWindow: boolean = true;
  private static readonly shouldCloseOnBackgroundClick = true;
  X() {
    return CreateNewImageRecord.x;
  }
  Y() {
    return CreateNewImageRecord.y;
  }
  Width(): string {
    return CreateNewImageRecord.width;
  }
  Height(): string {
    return CreateNewImageRecord.height;
  }
  IsCenteredToWindow() {
    return CreateNewImageRecord.isCenteredToWindow;
  }
  FormName() {
    return CreateNewImageRecord.formName;
  }
  ShowTitleBar() {
    return CreateNewImageRecord.showTitleBar;
  }
  ShouldCloseOnBackgroundClick() {
    return CreateNewImageRecord.shouldCloseOnBackgroundClick;
  }

  file_notevent = true;
  ngAfterViewChecked() {
    var file_button = document.getElementById('file') as HTMLInputElement;
    if ((file_button.accept != 'undefined') && this.file_notevent) {
      this.file_notevent = false;
      file_button.click();
    }
  }

  fileText: string | ArrayBuffer;
  fileName: string;
  fileExt: string;
  fileType: string;
  fileSize: number;
  fileColorModel: string;
  fileWidth: number;
  fileUpload(event) {
    const reader = new FileReader();
    reader.readAsDataURL(event.srcElement.files[0]);
    const type = event.srcElement.files[0].type;
    const num = 5 + type.length + 8;//data:(=5)image/jpeg(=type.length);base64,(=8)
    const me = this;
    var mgGetFile_button = document.getElementById('mgGetFile') as HTMLButtonElement;

    reader.onload = function () {
      //feltöltött fájl adatai:
      //console.log(reader.result);//feltöltött fájl objektum
      me.fileText = reader.result;//adattartalom
      //console.log(reader.result);
      me.fileName = event.srcElement.files[0].name;//teljes elérési útvonal
      me.fileExt = me.fileName.split('.').pop().toLowerCase();//kiterjesztés, AcceptedExt(vesszővel elválasztott string)
      me.fileType = event.srcElement.files[0].type;//fájl típusa
      me.fileSize = event.srcElement.files[0].size;//fájl mérete, AcceptedSize(szám bájtban)
      me.fileWidth = -1;

      //kép metaadtok betöltése
      var file = event.target.files[0];
      reader.readAsArrayBuffer(file);
      reader.onload = (event: any) => {//definiálni kell a típust, hogy ne adjon hibát(: any)
        //console.log(me.fileType);
        if (me.fileType == 'application/pdf') {
          me.fileColorModel = 'PDF';
        }
        else {
          var tags = ExifReader.load(event.target.result);
          //console.log(tags);
          me.fileWidth = Number(tags['Image Width'].value);
          //console.log(me.fileWidth);
          var colComp = tags['Color Components'].description as String;
          switch (colComp) {
            case '3': me.fileColorModel = 'RGB'; break
            case '4': me.fileColorModel = 'CMYK'; break
            default: console.log(colComp);
          }
        }
      }
    }
    reader.onerror = function (e) {
      alert("error " + e.target.error.code + " \n\n Permissions Error.");
    }

    reader.onprogress = function (data) {
      if (data.lengthComputable) {
        let progress: number = ((data.loaded / data.total) * 100);
        me.progress = progress;
        //console.log(data.loaded);
        //console.log(data.total);
      }
    }

    reader.onloadend = () => {//kétszer fut le mivel kétszer nyitjuk meg a fájlt
      //adatok kiírása:
      //console.log('Méret: ' + event.srcElement.files[0].size / 1024 / 1024 + "MB");
      if (me.fileColorModel !== undefined) {//csak akkor fut ha a color séma is megállapításra került
        //console.log(me.fileSize);
        //console.log(me.fileName);
        //console.log(me.fileText);
        //console.log(me.fileExt);
        //console.log(typeof me.fileColorModel);
        //console.log(me.fileWidth);
        me.progress = me.progress + (100 - me.progress) / 2;
        //console.log(me.progress);
        if (me.fileColorModel == 'CMYK') {
          alert('A CMYK színtérrel rendelkező képek feltöltése nem engedélyezett!');
        }
        else if (me.fileSize > Number(this.mg.getValue(this.mgc.AcceptedSize))) {
          alert('Túl nagy fájl, maximálisan megengedett fájlméret: ' + (Number(this.mg.getValue(this.mgc.AcceptedSize))) / 1024 / 1024 + 'MB');
        }
        else if(me.fileWidth > 0 && me.fileWidth < Number(1280)){
          alert('A kép szélességének legalább ' + (Number(1280)) + '-nak kell lennie!');
        }
        else {//ha minden jó
          me.mg.setValueToControl('vBlob64FromClient', me.fileText.slice(num));
          me.progress = me.progress + (100 - me.progress) / 2;
          //console.log(me.progress);
          me.mg.setValueToControl('vFileNameU', me.fileName);
          me.progress = me.progress + (100 - me.progress) / 2;
          //console.log(me.progress);
          me.mg.setValueToControl('vFileType', me.fileExt);
          mgGetFile_button.click();//feltöltés
          me.progress = 100;
          me.progress_visible = "visible";
        }
      }
    }
  }
}
