import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    Aruvalasztas = "Aruvalasztas",
        Table10485765 = "Table10485765",
        Column10485766 = "Column10485766",
        azonosito = "azonosito",
        Column10485767 = "Column10485767",
        aru_nev = "aru_nev",
        Column10485768 = "Column10485768",
        tematika = "tematika",
        Column10485769 = "Column10485769",
        beszallito = "beszallito",
        Column10485770 = "Column10485770",
        szerzo = "szerzo",
        Column10485771 = "Column10485771",
        cikcsoptip_nev = "cikcsoptip_nev",
        Column10485772 = "Column10485772",
        kivalasztva = "kivalasztva",
        Label10485761 = "Label10485761",
        tematika_nev = "tematika_nev",
        KivalasztNYG = "KivalasztNYG",
        MegsemNYG = "MegsemNYG",
        Label10485763 = "Label10485763",
        additional_ref = "additional_ref",
}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get azonosito(): FormControl {
        return this.getTableChildFormControl(MgControlName.azonosito);
    }

    get aru_nev(): FormControl {
        return this.getTableChildFormControl(MgControlName.aru_nev);
    }

    get tematika(): FormControl {
        return this.getTableChildFormControl(MgControlName.tematika);
    }

    get beszallito(): FormControl {
        return this.getTableChildFormControl(MgControlName.beszallito);
    }

    get szerzo(): FormControl {
        return this.getTableChildFormControl(MgControlName.szerzo);
    }

    get cikcsoptip_nev(): FormControl {
        return this.getTableChildFormControl(MgControlName.cikcsoptip_nev);
    }

    get kivalasztva(): FormControl {
        return this.getTableChildFormControl(MgControlName.kivalasztva);
    }

    get tematika_nev(): FormControl {
        return this.fg.controls[MgControlName.tematika_nev] as FormControl;
    }

    get additional_ref(): FormControl {
        return this.fg.controls[MgControlName.additional_ref] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}