import { Component } from '@angular/core';

import { FormGroup } from "@angular/forms";
import { MgFormControlsAccessor, MgControlName, MgCustomProperties } from "./TMPVArs.mg.controls.g";


import { TaskBaseMagicComponent, magicProviders } from "@magic-xpa/angular";


import { MagicModalInterface } from "@magic-xpa/angular";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'mga-TMPVArs',
  providers: [...magicProviders],
  templateUrl: './TMPVArs.component.html'
})
export class TMPVArs extends TaskBaseMagicComponent implements MagicModalInterface {

  mgc = MgControlName;
  mgcp = MgCustomProperties;
  mgfc: MgFormControlsAccessor;
  faWindowClose = faWindowClose;
  createFormControlsAccessor(formGroup: FormGroup) {
    this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
  }
  private static readonly formName: string = "TMPVArs";
  private static readonly showTitleBar: boolean = true;
  private static readonly x: number = 0;
  private static readonly y: number = 0;
  private static readonly width: string = "300px";
  private static readonly height: string = "300px";
  private static readonly isCenteredToWindow: boolean = true;
  private static readonly shouldCloseOnBackgroundClick = true;
  X() {
    return TMPVArs.x;
  }
  Y() {
    return TMPVArs.y;
  }
  Width(): string {
    return TMPVArs.width;
  }
  Height(): string {
    return TMPVArs.height;
  }
  IsCenteredToWindow() {
    return TMPVArs.isCenteredToWindow;
  }
  FormName() {
    return TMPVArs.formName;
  }
  ShowTitleBar() {
    return TMPVArs.showTitleBar;
  }
  ShouldCloseOnBackgroundClick() {
    return TMPVArs.shouldCloseOnBackgroundClick;
  }
}
