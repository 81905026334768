import { FormControl, FormGroup } from "@angular/forms";
import { MagicServices } from "@magic-xpa/angular";
export enum MgControlName {
  Boltvalasztas = "Boltvalasztas",
  FormName = "FormName",
  FormName2 = "FormName2",
  Label10485761 = "Label10485761",
  Tipus = "Tipus",
  Search = "Search",
  keresNYG = "keresNYG",
  Bolt_valasztas = "Bolt_valasztas",
  MG_FORMNAME = "MG_FORMNAME",
  MG_B_QUIT="MG_B_QUIT",
}
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) {}

  get FormName2(): FormControl {
    return this.fg.controls[MgControlName.FormName2] as FormControl;
  }

  get Tipus(): FormControl {
    return this.fg.controls[MgControlName.Tipus] as FormControl;
  }

  get Search(): FormControl {
    return this.fg.controls[MgControlName.Search] as FormControl;
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(
      this.magicServices.tableService.getSelectedRow()
    ).controls[name] as FormControl;
  }
}
