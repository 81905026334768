<div novalidate [formGroup]="screenFormGroup">
  <div class="flex-container-column" magic="{{ mgc.Boltvalasztas }}">
    <div #overlayheader class="my-overlay-header">
      <span>{{ mg.getValue("MG_FORMNAME") }}</span>
      <button
        class="input_button"
        magic="{{ mgc.MG_B_QUIT }}"
        [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)"
      >
        <fa-icon [icon]="faWindowClose"></fa-icon>
      </button>
    </div>
    <div class="flex-container-row">
      <label magic="{{ mgc.FormName }}" class="lable_overflow">
        Bolt választás
      </label>
      <label magic="{{ mgc.FormName2 }}">
        {{ mg.getValue(mgc.FormName2) }}
      </label>
    </div>
    <div class="flex-container-row">
      <label magic="{{ mgc.Label10485761 }}" class="lable_overflow">
        Bolt típus
      </label>
      <div>
        <mat-form-field>
          <mat-select magic="{{ mgc.Tipus }}" formControlName="{{ mgc.Tipus }}">
            <mat-option
              *ngFor="let o of mg.getItemListValues(mgc.Tipus)"
              [value]="o.index"
            >
              {{ o.displayValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.Search }}"
            placeholder="Keresés"
            formControlName="{{ mgc.Search }}"
            mgFormat
          />
          <mgError magic="{{ mgc.Search }}"> </mgError>
        </div>
      </mat-form-field>
      <button
        mat-raised-button
        color="primary"
        magic="{{ mgc.keresNYG }}"
        [matTooltip]="mg.getTitle(mgc.keresNYG)"
      >
        Keres
      </button>
    </div>
    <div class="flex-container-row">
      <mat-card>
        <magic-subform magic="{{ mgc.Bolt_valasztas }}"> </magic-subform>
      </mat-card>
    </div>
  </div>
</div>
