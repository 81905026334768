<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        class="flex-container-column"
        magic="{{mgc.callLista}}"
    >
        <div class="flex-container-row">
            <div>
                <mat-form-field>
                    <mat-select
                        magic="{{mgc.Combo_box4}}"
                        formControlName="{{mgc.Combo_box4}}"
                    >
                        <mat-option
                            *ngFor="let o of mg.getItemListValues(mgc.Combo_box4);"
                            [value]="o.index"
                        >
                            {{o.displayValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="flex-container-row">
            <div>
                <mat-checkbox
                    magic="{{mgc.Check_box5}}"
                    formControlName="{{mgc.Check_box5}}"
                >
                    Dátum
                </mat-checkbox>
            </div>
            <div>
                <mat-checkbox
                    magic="{{mgc.Check_box8}}"
                    formControlName="{{mgc.Check_box8}}"
                >
                    Bolt
                </mat-checkbox>
            </div>
            <div>
                <mat-checkbox
                    magic="{{mgc.Check_box11}}"
                    formControlName="{{mgc.Check_box11}}"
                >
                    Beszállító
                </mat-checkbox>
            </div>
            <div>
                <mat-checkbox
                    magic="{{mgc.Check_box13}}"
                    formControlName="{{mgc.Check_box13}}"
                >
                    Cikk
                </mat-checkbox>
            </div>
        </div>
        <div class="flex-container-row">
            <div>
                <mat-checkbox
                    magic="{{mgc.Check_box6}}"
                    formControlName="{{mgc.Check_box6}}"
                >
                    Mennyiség
                </mat-checkbox>
            </div>
            <div>
                <mat-checkbox
                    magic="{{mgc.Check_box9}}"
                    formControlName="{{mgc.Check_box9}}"
                >
                    Eladási ár
                </mat-checkbox>
            </div>
            <div>
                <mat-checkbox
                    magic="{{mgc.Check_box12}}"
                    formControlName="{{mgc.Check_box12}}"
                >
                    Listaár
                </mat-checkbox>
            </div>
            <div>
                <mat-checkbox
                    magic="{{mgc.Check_box14}}"
                    formControlName="{{mgc.Check_box14}}"
                >
                    Beszerzési ár
                </mat-checkbox>
            </div>
        </div>
        <div class="flex-container-row">
            <div>
                <mat-checkbox
                    magic="{{mgc.Check_box7}}"
                    formControlName="{{mgc.Check_box7}}"
                >
                    Készlet
                </mat-checkbox>
            </div>
            <div>
                <mat-checkbox
                    magic="{{mgc.Check_box10}}"
                    formControlName="{{mgc.Check_box10}}"
                >
                    Nullás készlet
                </mat-checkbox>
            </div>
        </div>
        <div class="flex-container-row">
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.btn_gZoom}}"
            >
                ...
            </button>
        </div>
    </div>
</div>