import {
    Routes,
    RouterModule
} from '@angular/router';

import {
    RouterContainerMagicComponent
} from "@magic-xpa/angular";
import {
    CommonModule
} from "@angular/common";
import {
    NgModule
} from '@angular/core';
export const routes: Routes = [

    {
        path: 'Login',
        component: RouterContainerMagicComponent,

    },
    {
        path: 'Login2',
        component: RouterContainerMagicComponent,

    },
    {
        path: 'Ujcikk',
        component: RouterContainerMagicComponent,

    },
    {
        path: 'Logout',
        component: RouterContainerMagicComponent,

    },
    {
        path: 'Forgalom',
        component: RouterContainerMagicComponent,

    },
    {
        path: 'Keszlet',
        component: RouterContainerMagicComponent,

    },
    {
        path: 'Welcome',
        component: RouterContainerMagicComponent,

    },
    {
        path: 'Search',
        component: RouterContainerMagicComponent,

    },

];

@NgModule({
    imports: [CommonModule,
        RouterModule.forRoot(routes)
    ],
    exports: [RouterModule]
})
export class MagicRoutingModule {}