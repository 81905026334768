import { Naptar as Naptar_Naptar } from "./Web Zoom/Naptar/Naptar.component";
import { Szerzovalasztasarumegtekint as Szerzo_valasztasAru_megtekint_Szerzovalasztasarumegtekint } from "./Web Zoom/Szerzo valasztasAru megtekint/Szerzovalasztasarumegtekint.component";
import { sbugyfelBeszallitovalasztas as Beszallito_valasztas_sbugyfelBeszallitovalasztas } from "./Web Zoom/Beszallito valasztas/sbugyfelBeszallitovalasztas.component";
import { Beszallitovalasztas as Beszallito_valasztas_Beszallito_valasztas_Beszallitovalasztas } from "./Web Zoom/Beszallito valasztas/Beszallito valasztas/Beszallitovalasztas.component";
import { Aruvalasztas as Aru_valasztas_Aruvalasztas } from "./Web Zoom/Aru valasztas/Aruvalasztas.component";
import { Aruvalasztas as Aru_valasztas_Aru_valasztas_Aruvalasztas } from "./Web Zoom/Aru valasztas/Aru valasztas/Aruvalasztas.component";
import { Kategoriavalasztas as Kategoria_valasztas_Kategoriavalasztas } from "./Web Zoom/Kategoria valasztas/Kategoriavalasztas.component";
import { Tipusvalasztas as Tipus_valasztas_Tipusvalasztas } from "./Web Zoom/Tipus valasztas/Tipusvalasztas.component";
import { Kiadovalasztas as Kiado_valasztas_Kiadovalasztas } from "./Web Zoom/Kiado valasztas/Kiadovalasztas.component";
import { Szerzovalasztas as SzerzoValasztas_Szerzovalasztas } from "./Web Zoom/SzerzoValasztas/Szerzovalasztas.component";
import { Kiadokkonyvek as SzerzoValasztas_KiadokKonyvek_Kiadokkonyvek } from "./Web Zoom/SzerzoValasztas/KiadokKonyvek/Kiadokkonyvek.component";
import { Kiadokkonyvek as SzerzoValasztas_KiadokKonyvek2_Kiadokkonyvek } from "./Web Zoom/SzerzoValasztas/KiadokKonyvek2/Kiadokkonyvek.component";
import { Szerzovalasztasarumegtekint as Sorozat_valasztas_Szerzovalasztasarumegtekint } from "./Web Zoom/Sorozat valasztas/Szerzovalasztasarumegtekint.component";
import { BoltTipusMSWC as BoltTipusMSWC_BoltTipusMSWC } from "./Web Zoom/BoltTipusMSWC/BoltTipusMSWC.component";
import { Boltvalasztas as Bolt_választásWC5_Boltvalasztas } from "./Web Zoom/Bolt választásWC5/Boltvalasztas.component";
import { NO as Bolt_választásWC5_NO } from "./Web Zoom/Bolt választásWC5/NO.component";
import { Boltvalasztas as Bolt_valasztas_save_Boltvalasztas } from "./Web Zoom/Bolt valasztas save/Boltvalasztas.component";
import { Boltvalasztas as Bolt_valasztas_save_Bolt_valasztas_Boltvalasztas } from "./Web Zoom/Bolt valasztas save/Bolt valasztas/Boltvalasztas.component";
import { Boltvalasztas as Bolt_választásWCsave_Boltvalasztas } from "./Web Zoom/Bolt választásWCsave/Boltvalasztas.component";
import { BoltvalasztasSF as Bolt_választásWCsave_Bolt_valasztas_BoltvalasztasSF } from "./Web Zoom/Bolt választásWCsave/Bolt valasztas/BoltvalasztasSF.component";
import { Zoomtest as Zoom_test_Zoomtest } from "./Web Zoom/Zoom test/Zoomtest.component";
import { UploadImage as UploadImage_UploadImage } from "./Web Zoom/UploadImage/UploadImage.component";
import { Beszallitovalasztas as Beszallitovalasztas2_Beszallitovalasztas } from "./Web Zoom/Beszallitovalasztas2/Beszallitovalasztas.component";
import { multiselectBesz as purefSF3_multiselectBesz } from "./Web Zoom/purefSF3/multiselectBesz.component";
import { purefSF2 as purefSF2_purefSF2 } from "./Web Zoom/purefSF2/purefSF2.component";
import { purefSF as purefSF_purefSF } from "./Web Zoom/purefSF/purefSF.component";
import { BeszallitoSF as BeszallitoSF_BeszallitoSF } from "./Web Zoom/BeszallitoSF/BeszallitoSF.component";
import { Aruvalasztas as AruValasztasLista2_Aruvalasztas } from "./Web Zoom/AruValasztasLista2/Aruvalasztas.component";
import { Aruvalasztas2 as Aru_valasztas2_Aruvalasztas2 } from "./Web Zoom/Aru valasztas2/Aruvalasztas2.component";
import { StartProgram as StartProgram_StartProgram } from "./Web/StartProgram/StartProgram.component";
import { Login as Login_Login } from "./Web/Login/Login.component";
import { Ujcikk as Ujcikk_Ujcikk } from "./Web/Ujcikk/Ujcikk.component";
import { texteditor as Ujcikk_texteditor_texteditor } from "./Web/Ujcikk/texteditor/texteditor.component";
import { fobesorolSF as Ujcikk_fobesorolSF_fobesorolSF } from "./Web/Ujcikk/fobesorolSF/fobesorolSF.component";
import { Forgalmak as Forgalmak_Forgalmak } from "./Web/Forgalmak/Forgalmak.component";
import { Forgalomlista as Forgalmak_Forgalom_lista_Forgalomlista } from "./Web/Forgalmak/Forgalom lista/Forgalomlista.component";
import { beszallitotmp as Forgalmak_beszallito_tmp_beszallitotmp } from "./Web/Forgalmak/beszallito tmp/beszallitotmp.component";
import { bolttmp as Forgalmak_bolt_tmp_bolttmp } from "./Web/Forgalmak/bolt tmp/bolttmp.component";
import { Keszlet as Keszlet_Keszlet } from "./Web/Keszlet/Keszlet.component";
import { Keszletlista as Keszlet_Keszlet_lista_Keszletlista } from "./Web/Keszlet/Keszlet lista/Keszletlista.component";
import { beszallitotmp as Keszlet_beszallito_tmp_beszallitotmp } from "./Web/Keszlet/beszallito tmp/beszallitotmp.component";
import { bolttmp as Keszlet_bolt_tmp_bolttmp } from "./Web/Keszlet/bolt tmp/bolttmp.component";
import { Cikk as Cikk_Cikk } from "./Web/Cikk/Cikk.component";
import { Cikklista as Cikk_Cikk_lista_Cikklista } from "./Web/Cikk/Cikk lista/Cikklista.component";
import { beszallitotmp as Cikk_beszallito_tmp_beszallitotmp } from "./Web/Cikk/beszallito tmp/beszallitotmp.component";
import { Welcome as Welcome_Welcome } from "./Web/Welcome/Welcome.component";
import { Search as Search_Search } from "./Web/Search/Search.component";
import { Logout as Logout_Logout } from "./Web/Logout/Logout.component";
import { UjcikkContributors as UjcikkContributors_UjcikkContributors } from "./Web/UjcikkContributors/UjcikkContributors.component";
import { UjcikkLinks as UjcikkLinks_UjcikkLinks } from "./Web/UjcikkLinks/UjcikkLinks.component";
import { ShowImagesTable as UjcikkImages_ShowImagesTable } from "./Web/UjcikkImages/ShowImagesTable.component";
import { CreateNewImageRecord as UjcikkImages_CreateNewImageRecord_CreateNewImageRecord } from "./Web/UjcikkImages/CreateNewImageRecord/CreateNewImageRecord.component";
import { testtabcontrol as testTabControl_testtabcontrol } from "./Web/testTabControl/testtabcontrol.component";
import { testAuthor as testAuthor_testAuthor } from "./Web/testAuthor/testAuthor.component";
import { form2 as testAuthor_form2 } from "./Web/testAuthor/form2.component";
import { testVariables as testVariables_testVariables } from "./Web/testVariables/testVariables.component";
import { testVariables as testVariables_purefzoom_testVariables } from "./Web/testVariables/purefzoom/testVariables.component";
import { editzoomWC as edit_zoom_WC_editzoomWC } from "./Web/edit zoom WC/editzoomWC.component";
import { multiselectWC2 as multiselectWC2_multiselectWC2 } from "./Web/multiselectWC2/multiselectWC2.component";
import { HOTFUDGE as Nyomtatott_file_megjelenites_HOTFUDGE } from "./Web/Nyomtatott file megjelenites/HOTFUDGE.component";
import { FileTransfer as FileTransfer_FileTransfer } from "./Web/FileTransfer/FileTransfer.component";
import { TextInput as TextInput_TextInput } from "./Web/TextInput/TextInput.component";
import { WebClientTreememory as WebClientTreememory_WebClientTreememory } from "./Web/WebClientTreememory/WebClientTreememory.component";
import { Forgalomlista as Forgalom2_lista_Forgalomlista } from "./Web/Forgalom2 lista/Forgalomlista.component";
import { ForgKeszlSF as ForgKeszlSF_ForgKeszlSF } from "./Web/ForgKeszlSF/ForgKeszlSF.component";
import { Forgalmak2 as Forgalmak2_Forgalmak2 } from "./Web/Forgalmak2/Forgalmak2.component";
import { Forgalomlista as Forgalmak2_Forgalom_lista_Forgalomlista } from "./Web/Forgalmak2/Forgalom lista/Forgalomlista.component";
import { Forgalomlista as Forgalmak2_Forgalom2_lista_Forgalomlista } from "./Web/Forgalmak2/Forgalom2 lista/Forgalomlista.component";
import { Forgalomlista as Forgalmak2_Forgalom_lista2_Forgalomlista } from "./Web/Forgalmak2/Forgalom lista2/Forgalomlista.component";
import { Forgalomlista as Forgalmak2_Forgalom_lista3_Forgalomlista } from "./Web/Forgalmak2/Forgalom lista3/Forgalomlista.component";
import { beszallitotmp as Forgalmak2_beszallito_tmp_beszallitotmp } from "./Web/Forgalmak2/beszallito tmp/beszallitotmp.component";
import { beszallitotmp as Forgalmak2_cikk_tmp_beszallitotmp } from "./Web/Forgalmak2/cikk tmp/beszallitotmp.component";
import { bolttmp as Forgalmak2_bolt_tmp_bolttmp } from "./Web/Forgalmak2/bolt tmp/bolttmp.component";
import { Keszlet2 as Keszlet2_Keszlet2 } from "./Web/Keszlet2/Keszlet2.component";
import { Keszletlista as Keszlet2_Keszlet_lista_Keszletlista } from "./Web/Keszlet2/Keszlet lista/Keszletlista.component";
import { beszallitotmp as Keszlet2_beszallito_tmp_beszallitotmp } from "./Web/Keszlet2/beszallito tmp/beszallitotmp.component";
import { bolttmp as Keszlet2_bolt_tmp_bolttmp } from "./Web/Keszlet2/bolt tmp/bolttmp.component";
import { Sorozat as Sorozat_valasztas_Sorozat_Sorozat } from "./Web Zoom/Sorozat valasztas/Sorozat/Sorozat.component";
import { Aruk as Sorozat_valasztas_Sorozat_Aruk_Aruk } from "./Web Zoom/Sorozat valasztas/Sorozat/Aruk/Aruk.component";
import { Lista as Kiado_valasztas_Lista_Lista } from "./Web Zoom/Kiado valasztas/Lista/Lista.component";
import { Kategoria as Kategoria_valasztas_Kategoria_Kategoria } from "./Web Zoom/Kategoria valasztas/Kategoria/Kategoria.component";
import { Cikktipusvalasztas as Tipus_valasztas_Cikktipus_valasztas_Cikktipusvalasztas } from "./Web Zoom/Tipus valasztas/Cikktipus valasztas/Cikktipusvalasztas.component";
import { Boltvalasztas as Bolt_valasztas_Boltvalasztas } from "./Web Zoom/Bolt valasztas/Boltvalasztas.component";
import { NO as Bolt_valasztas_NO } from "./Web Zoom/Bolt valasztas/NO.component";
import { callLista as callLista_callLista } from "./Web/callLista/callLista.component";
import { Forgalomlista2 as Forgalom2_lista_Forgalomlista2 } from "./Web/Forgalom2 lista/Forgalomlista2.component";
import { ForgKeszlSF as CikkKereses_ForgKeszlSF } from "./Web/CikkKereses/ForgKeszlSF.component";
import { CikkKereses as CikkKereses_CikkKereses } from "./Web/CikkKereses/CikkKereses.component";
import { ForgKeszlSF2 as ForgKeszlSF_ForgKeszlSF2 } from "./Web/ForgKeszlSF/ForgKeszlSF2.component";
import { Aruvalasztas2 as AruValasztasLista2_Aruvalasztas2 } from "./Web Zoom/AruValasztasLista2/Aruvalasztas2.component";
import { texteditor as Texteditor_texteditor } from "./Web Zoom/Texteditor/texteditor.component";
import { UjcikkContributors2 as UjcikkContributors_UjcikkContributors2 } from "./Web/UjcikkContributors/UjcikkContributors2.component";
import { Ujcikk2 as Ujcikk_Ujcikk2 } from "./Web/Ujcikk/Ujcikk2.component";
import { Keszletlista as Keszlet2_lista_Keszletlista } from "./Web/Keszlet2 lista/Keszletlista.component";
import { Keszletlista2 as Keszlet2_lista_Keszletlista2 } from "./Web/Keszlet2 lista/Keszletlista2.component";
//import { Login2 as Login_Login2 } from "./Web/Login/Login2.component";
import { CustomtreeComponent as CustomtreeComponent_CustomtreeComponent } from "./Web/customtree/customtree.component";
import { SideMenuComponent as SideMenuComponent_SideMenuComponent } from "./Web/side-menu/side-menu.component";
import { Form3 as KTmultiselectSF_Form3 } from './Web Zoom/KTmultiselectSF/Form3.component';
import { KTmshivo as KTmshivo_KTmshivo } from './Web Zoom/KTmshivo/KTmshivo.component';
import { Beszallitokiadoi as Beszallitokiadoi_Beszallitokiadoi } from './Web/Beszallitokiadoi/Beszallitokiadoi.component';
import { TMPVArs as Ujcikk_TMPVArs_TMPVArs } from './Web/Ujcikk/TMPVArs/TMPVArs.component';
import { Placeholder as PlaceholderTeszt_Placeholder } from './Web/PlaceholderTeszt/Placeholder.component';
//import { Login as Login2_Login } from './Web/Login2/Login.component';
import { Login2 as Login2_Login2 } from './Web/Login2/Login2.component';
import { ForgalomlistaOvrl as Forgalom2_lista_ForgalomlistaOvrl } from './Web/Forgalom2 lista/ForgalomlistaOvrl.component';
import { Forgalomlista2Ovrl as Forgalom2_lista_Forgalomlista2Ovrl } from './Web/Forgalom2 lista/Forgalomlista2Ovrl.component';
import { Forgalomlistasave as Forgalom2_lista_Forgalomlistasave } from './Web/Forgalom2 lista/Forgalomlistasave.component';
import { KiadvanyAdatok as KiadvanyAdatok_KiadvanyAdatok } from './Web/KiadvanyAdatok/KiadvanyAdatok.component';
import { ShowImagesTableOvrl as UjcikkImages_ShowImagesTableOvrl } from './Web/UjcikkImages/ShowImagesTableOvrl.component';
import { Form4 as KTmultiselectSF_Form4 } from './Web Zoom/KTmultiselectSF/Form4.component';

export const title = "";


/*Login2_Login:Login2_Login,
Login_Login: Login_Login,
              */

export const magicGenCmpsHash = {               KTmultiselectSF_Form4:KTmultiselectSF_Form4,
                      UjcikkImages_ShowImagesTableOvrl:UjcikkImages_ShowImagesTableOvrl,
                      KiadvanyAdatok_KiadvanyAdatok:KiadvanyAdatok_KiadvanyAdatok,
                      Forgalom2_lista_Forgalomlistasave:Forgalom2_lista_Forgalomlistasave,
                      Forgalom2_lista_Forgalomlista2Ovrl:Forgalom2_lista_Forgalomlista2Ovrl,
                      Forgalom2_lista_ForgalomlistaOvrl:Forgalom2_lista_ForgalomlistaOvrl,
                      
       
  PlaceholderTeszt_Placeholder: PlaceholderTeszt_Placeholder,
  Ujcikk_TMPVArs_TMPVArs: Ujcikk_TMPVArs_TMPVArs,
  Beszallitokiadoi_Beszallitokiadoi: Beszallitokiadoi_Beszallitokiadoi,
  KTmultiselectSF_Form3: KTmultiselectSF_Form3,
  KTmshivo_KTmshivo: KTmshivo_KTmshivo,

  Login_Login: Login_Login,
  Login2_Login2:Login2_Login2,
  CustomtreeComponent_CustomtreeComponent: CustomtreeComponent_CustomtreeComponent,
  SideMenuComponent_SideMenuComponent: SideMenuComponent_SideMenuComponent,
  Keszlet2_lista_Keszletlista: Keszlet2_lista_Keszletlista,
  Keszlet2_lista_Keszletlista2: Keszlet2_lista_Keszletlista2,
  Ujcikk_Ujcikk2: Ujcikk_Ujcikk2,
  UjcikkContributors_UjcikkContributors2: UjcikkContributors_UjcikkContributors2,
  Texteditor_texteditor: Texteditor_texteditor,
  AruValasztasLista2_Aruvalasztas2: AruValasztasLista2_Aruvalasztas2,
  ForgKeszlSF_ForgKeszlSF2: ForgKeszlSF_ForgKeszlSF2,
  CikkKereses_CikkKereses: CikkKereses_CikkKereses,
  CikkKereses_ForgKeszlSF: CikkKereses_ForgKeszlSF,
  Forgalom2_lista_Forgalomlista2: Forgalom2_lista_Forgalomlista2,
  callLista_callLista: callLista_callLista,
  Bolt_valasztas_Boltvalasztas: Bolt_valasztas_Boltvalasztas,
  Bolt_valasztas_NO: Bolt_valasztas_NO,
  Tipus_valasztas_Cikktipus_valasztas_Cikktipusvalasztas: Tipus_valasztas_Cikktipus_valasztas_Cikktipusvalasztas,
  Kategoria_valasztas_Kategoria_Kategoria: Kategoria_valasztas_Kategoria_Kategoria,
  Kiado_valasztas_Lista_Lista: Kiado_valasztas_Lista_Lista,
  Sorozat_valasztas_Sorozat_Aruk_Aruk: Sorozat_valasztas_Sorozat_Aruk_Aruk,
  Sorozat_valasztas_Sorozat_Sorozat: Sorozat_valasztas_Sorozat_Sorozat,
  Naptar_Naptar: Naptar_Naptar,
  Szerzo_valasztasAru_megtekint_Szerzovalasztasarumegtekint: Szerzo_valasztasAru_megtekint_Szerzovalasztasarumegtekint,
  Beszallito_valasztas_sbugyfelBeszallitovalasztas: Beszallito_valasztas_sbugyfelBeszallitovalasztas,
  Beszallito_valasztas_Beszallito_valasztas_Beszallitovalasztas: Beszallito_valasztas_Beszallito_valasztas_Beszallitovalasztas,
  Aru_valasztas_Aruvalasztas: Aru_valasztas_Aruvalasztas,
  Aru_valasztas_Aru_valasztas_Aruvalasztas: Aru_valasztas_Aru_valasztas_Aruvalasztas,
  Kategoria_valasztas_Kategoriavalasztas: Kategoria_valasztas_Kategoriavalasztas,
  Tipus_valasztas_Tipusvalasztas: Tipus_valasztas_Tipusvalasztas,
  Kiado_valasztas_Kiadovalasztas: Kiado_valasztas_Kiadovalasztas,
  SzerzoValasztas_Szerzovalasztas: SzerzoValasztas_Szerzovalasztas,
  SzerzoValasztas_KiadokKonyvek_Kiadokkonyvek: SzerzoValasztas_KiadokKonyvek_Kiadokkonyvek,
  SzerzoValasztas_KiadokKonyvek2_Kiadokkonyvek: SzerzoValasztas_KiadokKonyvek2_Kiadokkonyvek,
  Sorozat_valasztas_Szerzovalasztasarumegtekint: Sorozat_valasztas_Szerzovalasztasarumegtekint,
  BoltTipusMSWC_BoltTipusMSWC: BoltTipusMSWC_BoltTipusMSWC,
  Bolt_választásWC5_Boltvalasztas: Bolt_választásWC5_Boltvalasztas,
  Bolt_választásWC5_NO: Bolt_választásWC5_NO,
  Bolt_valasztas_save_Boltvalasztas: Bolt_valasztas_save_Boltvalasztas,
  Bolt_valasztas_save_Bolt_valasztas_Boltvalasztas: Bolt_valasztas_save_Bolt_valasztas_Boltvalasztas,
  Bolt_választásWCsave_Boltvalasztas: Bolt_választásWCsave_Boltvalasztas,
  Bolt_választásWCsave_Bolt_valasztas_BoltvalasztasSF: Bolt_választásWCsave_Bolt_valasztas_BoltvalasztasSF,
  Zoom_test_Zoomtest: Zoom_test_Zoomtest,
  UploadImage_UploadImage: UploadImage_UploadImage,
  Beszallitovalasztas2_Beszallitovalasztas: Beszallitovalasztas2_Beszallitovalasztas,
  purefSF3_multiselectBesz: purefSF3_multiselectBesz,
  purefSF2_purefSF2: purefSF2_purefSF2,
  purefSF_purefSF: purefSF_purefSF,
  BeszallitoSF_BeszallitoSF: BeszallitoSF_BeszallitoSF,
  AruValasztasLista2_Aruvalasztas: AruValasztasLista2_Aruvalasztas,
  Aru_valasztas2_Aruvalasztas2: Aru_valasztas2_Aruvalasztas2,
  StartProgram_StartProgram: StartProgram_StartProgram,
  Ujcikk_Ujcikk: Ujcikk_Ujcikk,
  Ujcikk_texteditor_texteditor: Ujcikk_texteditor_texteditor,
  Ujcikk_fobesorolSF_fobesorolSF: Ujcikk_fobesorolSF_fobesorolSF,
  Forgalmak_Forgalmak: Forgalmak_Forgalmak,
  Forgalmak_Forgalom_lista_Forgalomlista: Forgalmak_Forgalom_lista_Forgalomlista,
  Forgalmak_beszallito_tmp_beszallitotmp: Forgalmak_beszallito_tmp_beszallitotmp,
  Forgalmak_bolt_tmp_bolttmp: Forgalmak_bolt_tmp_bolttmp,
  Keszlet_Keszlet: Keszlet_Keszlet,
  Keszlet_Keszlet_lista_Keszletlista: Keszlet_Keszlet_lista_Keszletlista,
  Keszlet_beszallito_tmp_beszallitotmp: Keszlet_beszallito_tmp_beszallitotmp,
  Keszlet_bolt_tmp_bolttmp: Keszlet_bolt_tmp_bolttmp,
  Cikk_Cikk: Cikk_Cikk,
  Cikk_Cikk_lista_Cikklista: Cikk_Cikk_lista_Cikklista,
  Cikk_beszallito_tmp_beszallitotmp: Cikk_beszallito_tmp_beszallitotmp,
  Welcome_Welcome: Welcome_Welcome,
  Search_Search: Search_Search,
  Logout_Logout: Logout_Logout,
  UjcikkContributors_UjcikkContributors: UjcikkContributors_UjcikkContributors,
  UjcikkLinks_UjcikkLinks: UjcikkLinks_UjcikkLinks,
  UjcikkImages_ShowImagesTable: UjcikkImages_ShowImagesTable,
  UjcikkImages_CreateNewImageRecord_CreateNewImageRecord: UjcikkImages_CreateNewImageRecord_CreateNewImageRecord,
  testTabControl_testtabcontrol: testTabControl_testtabcontrol,
  testAuthor_testAuthor: testAuthor_testAuthor,
  testAuthor_form2: testAuthor_form2,
  testVariables_testVariables: testVariables_testVariables,
  testVariables_purefzoom_testVariables: testVariables_purefzoom_testVariables,
  edit_zoom_WC_editzoomWC: edit_zoom_WC_editzoomWC,
  multiselectWC2_multiselectWC2: multiselectWC2_multiselectWC2,
  Nyomtatott_file_megjelenites_HOTFUDGE: Nyomtatott_file_megjelenites_HOTFUDGE,
  FileTransfer_FileTransfer: FileTransfer_FileTransfer,
  TextInput_TextInput: TextInput_TextInput,
  WebClientTreememory_WebClientTreememory: WebClientTreememory_WebClientTreememory,
  Forgalom2_lista_Forgalomlista: Forgalom2_lista_Forgalomlista,
  ForgKeszlSF_ForgKeszlSF: ForgKeszlSF_ForgKeszlSF,
  Forgalmak2_Forgalmak2: Forgalmak2_Forgalmak2,
  Forgalmak2_Forgalom_lista_Forgalomlista: Forgalmak2_Forgalom_lista_Forgalomlista,
  Forgalmak2_Forgalom2_lista_Forgalomlista: Forgalmak2_Forgalom2_lista_Forgalomlista,
  Forgalmak2_Forgalom_lista2_Forgalomlista: Forgalmak2_Forgalom_lista2_Forgalomlista,
  Forgalmak2_Forgalom_lista3_Forgalomlista: Forgalmak2_Forgalom_lista3_Forgalomlista,
  Forgalmak2_beszallito_tmp_beszallitotmp: Forgalmak2_beszallito_tmp_beszallitotmp,
  Forgalmak2_cikk_tmp_beszallitotmp: Forgalmak2_cikk_tmp_beszallitotmp,
  Forgalmak2_bolt_tmp_bolttmp: Forgalmak2_bolt_tmp_bolttmp,
  Keszlet2_Keszlet2: Keszlet2_Keszlet2,
  Keszlet2_Keszlet_lista_Keszletlista: Keszlet2_Keszlet_lista_Keszletlista,
  Keszlet2_beszallito_tmp_beszallitotmp: Keszlet2_beszallito_tmp_beszallitotmp,
  Keszlet2_bolt_tmp_bolttmp: Keszlet2_bolt_tmp_bolttmp,
};
/*Login2_Login,
 ,*/
export const magicGenComponents = [ KTmultiselectSF_Form4 ,  UjcikkImages_ShowImagesTableOvrl ,  KiadvanyAdatok_KiadvanyAdatok ,  Forgalom2_lista_Forgalomlistasave ,  Forgalom2_lista_Forgalomlista2Ovrl ,  Forgalom2_lista_ForgalomlistaOvrl ,   PlaceholderTeszt_Placeholder, Ujcikk_TMPVArs_TMPVArs, Beszallitokiadoi_Beszallitokiadoi, KTmultiselectSF_Form3,
  KTmshivo_KTmshivo,
  Login_Login,
  Login2_Login2,
  CustomtreeComponent_CustomtreeComponent,
  SideMenuComponent_SideMenuComponent,
  Keszlet2_lista_Keszletlista,
  Keszlet2_lista_Keszletlista2,
  Ujcikk_Ujcikk2,
  UjcikkContributors_UjcikkContributors2,
  Texteditor_texteditor,
  AruValasztasLista2_Aruvalasztas2,
  ForgKeszlSF_ForgKeszlSF2,
  CikkKereses_CikkKereses,
  CikkKereses_ForgKeszlSF,
  Forgalom2_lista_Forgalomlista2,
  callLista_callLista,
  Bolt_valasztas_Boltvalasztas,
  Bolt_valasztas_NO,
  Tipus_valasztas_Cikktipus_valasztas_Cikktipusvalasztas,
  Kategoria_valasztas_Kategoria_Kategoria,
  Kiado_valasztas_Lista_Lista,
  Sorozat_valasztas_Sorozat_Aruk_Aruk,
  Sorozat_valasztas_Sorozat_Sorozat,
  Naptar_Naptar,
  Szerzo_valasztasAru_megtekint_Szerzovalasztasarumegtekint,
  Beszallito_valasztas_sbugyfelBeszallitovalasztas,
  Beszallito_valasztas_Beszallito_valasztas_Beszallitovalasztas,
  Aru_valasztas_Aruvalasztas,
  Aru_valasztas_Aru_valasztas_Aruvalasztas,
  Kategoria_valasztas_Kategoriavalasztas,
  Tipus_valasztas_Tipusvalasztas,
  Kiado_valasztas_Kiadovalasztas,
  SzerzoValasztas_Szerzovalasztas,
  SzerzoValasztas_KiadokKonyvek_Kiadokkonyvek,
  SzerzoValasztas_KiadokKonyvek2_Kiadokkonyvek,
  Sorozat_valasztas_Szerzovalasztasarumegtekint,
  BoltTipusMSWC_BoltTipusMSWC,
  Bolt_választásWC5_Boltvalasztas,
  Bolt_választásWC5_NO,
  Bolt_valasztas_save_Boltvalasztas,
  Bolt_valasztas_save_Bolt_valasztas_Boltvalasztas,
  Bolt_választásWCsave_Boltvalasztas,
  Bolt_választásWCsave_Bolt_valasztas_BoltvalasztasSF,
  Zoom_test_Zoomtest,
  UploadImage_UploadImage,
  Beszallitovalasztas2_Beszallitovalasztas,
  purefSF3_multiselectBesz,
  purefSF2_purefSF2,
  purefSF_purefSF,
  BeszallitoSF_BeszallitoSF,
  AruValasztasLista2_Aruvalasztas,
  Aru_valasztas2_Aruvalasztas2,
  StartProgram_StartProgram,
  Login_Login,
  Ujcikk_Ujcikk,
  Ujcikk_texteditor_texteditor,
  Ujcikk_fobesorolSF_fobesorolSF,
  Forgalmak_Forgalmak,
  Forgalmak_Forgalom_lista_Forgalomlista,
  Forgalmak_beszallito_tmp_beszallitotmp,
  Forgalmak_bolt_tmp_bolttmp,
  Keszlet_Keszlet,
  Keszlet_Keszlet_lista_Keszletlista,
  Keszlet_beszallito_tmp_beszallitotmp,
  Keszlet_bolt_tmp_bolttmp,
  Cikk_Cikk,
  Cikk_Cikk_lista_Cikklista,
  Cikk_beszallito_tmp_beszallitotmp,
  Welcome_Welcome,
  Search_Search,
  Logout_Logout,
  UjcikkContributors_UjcikkContributors,
  UjcikkLinks_UjcikkLinks,
  UjcikkImages_ShowImagesTable,
  UjcikkImages_CreateNewImageRecord_CreateNewImageRecord,
  testTabControl_testtabcontrol,
  testAuthor_testAuthor,
  testAuthor_form2,
  testVariables_testVariables,
  testVariables_purefzoom_testVariables,
  edit_zoom_WC_editzoomWC,
  multiselectWC2_multiselectWC2,
  Nyomtatott_file_megjelenites_HOTFUDGE,
  FileTransfer_FileTransfer,
  TextInput_TextInput,
  WebClientTreememory_WebClientTreememory,
  Forgalom2_lista_Forgalomlista,
  ForgKeszlSF_ForgKeszlSF,
  Forgalmak2_Forgalmak2,
  Forgalmak2_Forgalom_lista_Forgalomlista,
  Forgalmak2_Forgalom2_lista_Forgalomlista,
  Forgalmak2_Forgalom_lista2_Forgalomlista,
  Forgalmak2_Forgalom_lista3_Forgalomlista,
  Forgalmak2_beszallito_tmp_beszallitotmp,
  Forgalmak2_cikk_tmp_beszallitotmp,
  Forgalmak2_bolt_tmp_bolttmp,
  Keszlet2_Keszlet2,
  Keszlet2_Keszlet_lista_Keszletlista,
  Keszlet2_beszallito_tmp_beszallitotmp,
  Keszlet2_bolt_tmp_bolttmp,
];

export const LazyLoadModulesMap = {};
