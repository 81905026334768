import { Component } from '@angular/core';

import { FormGroup } from "@angular/forms";
import { MgFormControlsAccessor, MgControlName, MgCustomProperties } from "./ShowImagesTable.mg.controls.g";


import { TaskBaseMagicComponent, magicProviders } from "@magic-xpa/angular";

import { faTimes,faList } from '@fortawesome/free-solid-svg-icons';

import {
  MagicModalInterface
} from "@magic-xpa/angular";

@Component({
    selector: 'mga-ShowImagesTable',
    providers: [...magicProviders],
    templateUrl: './ShowImagesTable.component.html',
    styleUrls: ['./ShowImagesTable.component.scss']
})
export class ShowImagesTable extends TaskBaseMagicComponent{

    mgc = MgControlName;
    mgcp = MgCustomProperties;
    mgfc: MgFormControlsAccessor;
faTimes = faTimes;
    faList=faList;
    createFormControlsAccessor(formGroup: FormGroup) {
        this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
    }
}
