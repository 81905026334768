import {
  FormControl,
  FormGroup
} from "@angular/forms";
import {
  MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
  CreateNewImageRecord = "CreateNewImageRecord",
  Label22 = "Label22",
  vImageDate = "vImageDate",
  MG_B_QUIT = "MG_B_QUIT",
  Label23 = "Label23",
  vImageTime = "vImageTime",
  Label24 = "Label24",
  vDescription = "vDescription",
  Label25 = "Label25",
  vImagePath = "vImagePath",
  Label34 = "Label34",
  vFileNameU = "vFileNameU",
  vBlob64FromClient = "vBlob64FromClient",
  SaveNewImage = "SaveNewImage",
  mgGetFile = "mgGetFile",
  Label10485784 = "Label10485784",
  vfilename = "vfilename",
  vServerFile = "vServerFile",
  vFileType = "vFileType",
  MG_FORMNAME = "MG_FORMNAME",
  AcceptedExt = "AcceptedExt",
  AcceptedSize = "AcceptedSize",
  //Bővítés
  Cancel = "Cancel",
  Label30 = "Label30",
  Label32 = "Label32",
  Label36 = "Label36",
  UploadImage = "UploadImage"
}
export enum MgCustomProperties { }
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) { }

  get vImageDate(): FormControl {
    return this.fg.controls[MgControlName.vImageDate] as FormControl;
  }

  get vImageTime(): FormControl {
    return this.fg.controls[MgControlName.vImageTime] as FormControl;
  }

  get vDescription(): FormControl {
    return this.fg.controls[MgControlName.vDescription] as FormControl;
  }

  get vImagePath(): FormControl {
    return this.fg.controls[MgControlName.vImagePath] as FormControl;
  }

  get vFileNameU(): FormControl {
    return this.fg.controls[MgControlName.vFileNameU] as FormControl;
  }

  get vBlob64FromClient(): FormControl {
    return this.fg.controls[MgControlName.vBlob64FromClient] as FormControl;
  }

  get vfilename(): FormControl {
    return this.fg.controls[MgControlName.vfilename] as FormControl;
  }

  get vServerFile(): FormControl {
    return this.fg.controls[MgControlName.vServerFile] as FormControl;
  }

  get vFileType(): FormControl {
    return this.fg.controls[MgControlName.vFileType] as FormControl;
  }

  get MG_FORMNAME(): FormControl {
    return this.fg.controls[MgControlName.MG_FORMNAME] as FormControl;
  }

  get AcceptedExt(): FormControl {
    return this.fg.controls[MgControlName.AcceptedExt] as FormControl;
  }

  get AcceptedSize(): FormControl {
    return this.fg.controls[MgControlName.AcceptedSize] as FormControl;
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
  }
}
