import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    KiadvanyAdatok = "KiadvanyAdatok",
        MG_FORMNAME = "MG_FORMNAME",
        MG_B_QUIT = "MG_B_QUIT",
        Konyvadat = "Konyvadat",
        ElsoAtdolgMasik = "ElsoAtdolgMasik",
        EredetiPld = "EredetiPld",
        EredetiMagyarPld = "EredetiMagyarPld",
        LUtannyomas = "LUtannyomas",
        UtanNyomasSzam = "UtanNyomasSzam",
        UnyRadio = "UnyRadio",
        LbPeldanyszam = "LbPeldanyszam",
        Peldanyszam = "Peldanyszam",
        ItemList = "ItemList",
        DisplayList = "DisplayList",
        Forditas = "Forditas",
        LbEredetiNyelv = "LbEredetiNyelv",
        CBEredetiNyelv = "CBEredetiNyelv",
        SFMasNyelvek = "SFMasNyelvek",
        LbMasNyelvek = "LbMasNyelvek",
        MasNyelvek = "MasNyelvek",
        LbOrszagErt = "LbOrszagErt",
        OrszagErt = "OrszagErt",
        LbOrszagNemErt = "LbOrszagNemErt",
        OrszagNemErt = "OrszagNemErt",
        CBFilmKeszult = "CBFilmKeszult",
        LbFilmCim = "LbFilmCim",
        FilmCim = "FilmCim",
        LbFilmDatum = "LbFilmDatum",
        FilmDatum = "FilmDatum",
        LbFilmGyarto = "LbFilmGyarto",
        FilmGyarto = "FilmGyarto",
        LbFilmFoszereplo = "LbFilmFoszereplo",
        FilmFoszereplo = "FilmFoszereplo",
        CBFilmTerv = "CBFilmTerv",
        LbFilmCimTerv = "LbFilmCimTerv",
        FilmCimTerv = "FilmCimTerv",
        LbFilmDatumTerv = "LbFilmDatumTerv",
        FilmDatumTerv = "FilmDatumTerv",
        LbFilmKiegTerv = "LbFilmKiegTerv",
        FilmKiegTerv = "FilmKiegTerv",
        LbSzerzKorabbanDb = "LbSzerzKorabbanDb",
        SzerzKorabbanDb = "SzerzKorabbanDb",
        LbSzerzKorabbanUtolsoEv = "LbSzerzKorabbanUtolsoEv",
        SzerzKorabbanUtolsoEv = "SzerzKorabbanUtolsoEv",
        BTNSEND = "BTNSEND",
}
export enum MgCustomProperties {}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get MG_FORMNAME(): FormControl {
        return this.fg.controls[MgControlName.MG_FORMNAME] as FormControl;
    }

    get Konyvadat(): FormControl {
        return this.fg.controls[MgControlName.Konyvadat] as FormControl;
    }

    get ElsoAtdolgMasik(): FormControl {
        return this.fg.controls[MgControlName.ElsoAtdolgMasik] as FormControl;
    }

    get EredetiMagyarPld(): FormControl {
        return this.fg.controls[MgControlName.EredetiMagyarPld] as FormControl;
    }

    get UtanNyomasSzam(): FormControl {
        return this.fg.controls[MgControlName.UtanNyomasSzam] as FormControl;
    }

    get UnyRadio(): FormControl {
        return this.fg.controls[MgControlName.UnyRadio] as FormControl;
    }

    get Peldanyszam(): FormControl {
        return this.fg.controls[MgControlName.Peldanyszam] as FormControl;
    }

    get ItemList(): FormControl {
        return this.fg.controls[MgControlName.ItemList] as FormControl;
    }

    get DisplayList(): FormControl {
        return this.fg.controls[MgControlName.DisplayList] as FormControl;
    }

    get Forditas(): FormControl {
        return this.fg.controls[MgControlName.Forditas] as FormControl;
    }

    get CBEredetiNyelv(): FormControl {
        return this.fg.controls[MgControlName.CBEredetiNyelv] as FormControl;
    }

    get MasNyelvek(): FormControl {
        return this.fg.controls[MgControlName.MasNyelvek] as FormControl;
    }

    get OrszagErt(): FormControl {
        return this.fg.controls[MgControlName.OrszagErt] as FormControl;
    }

    get OrszagNemErt(): FormControl {
        return this.fg.controls[MgControlName.OrszagNemErt] as FormControl;
    }

    get CBFilmKeszult(): FormControl {
        return this.fg.controls[MgControlName.CBFilmKeszult] as FormControl;
    }

    get FilmCim(): FormControl {
        return this.fg.controls[MgControlName.FilmCim] as FormControl;
    }

    get FilmDatum(): FormControl {
        return this.fg.controls[MgControlName.FilmDatum] as FormControl;
    }

    get FilmGyarto(): FormControl {
        return this.fg.controls[MgControlName.FilmGyarto] as FormControl;
    }

    get FilmFoszereplo(): FormControl {
        return this.fg.controls[MgControlName.FilmFoszereplo] as FormControl;
    }

    get CBFilmTerv(): FormControl {
        return this.fg.controls[MgControlName.CBFilmTerv] as FormControl;
    }

    get FilmCimTerv(): FormControl {
        return this.fg.controls[MgControlName.FilmCimTerv] as FormControl;
    }

    get FilmDatumTerv(): FormControl {
        return this.fg.controls[MgControlName.FilmDatumTerv] as FormControl;
    }

    get FilmKiegTerv(): FormControl {
        return this.fg.controls[MgControlName.FilmKiegTerv] as FormControl;
    }

    get SzerzKorabbanDb(): FormControl {
        return this.fg.controls[MgControlName.SzerzKorabbanDb] as FormControl;
    }

    get SzerzKorabbanUtolsoEv(): FormControl {
        return this.fg.controls[MgControlName.SzerzKorabbanUtolsoEv] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}