import { NgModule, NgModuleRef, OnInit } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
//import { MatSelectSearchModule } from 'mat-select-search';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { DynamicModule } from "ng-dynamic-component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";

import {
  ComponentListMagicService,
  MagicModule,
  ExitMagicService,
} from "@magic-xpa/angular";
import {
  magicGenComponents,
  magicGenCmpsHash,
  title, LazyLoadModulesMap,
} from "./component-list.g";
import { MagicAngularMaterialModule } from "@magic-xpa/angular-material-core";

//bms informatika
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { DemoMaterialModule } from "./addbms/material-module";
import { DragDropModule } from "@angular/cdk/drag-drop";
//magyar lapozó
import {
  MatPaginatorModule,
  MatPaginatorIntl,
} from "@angular/material/paginator";
import { getHuPaginatorIntl } from "./addbms/hu-paginator-intl";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { CollapseModule } from "ngx-bootstrap/collapse";
//import { SideMenuComponent } from "./web/side-menu/side-menu.component";
//import { CustomtreeComponent } from "./web/customtree/customtree.component";
import { NgxMaskModule } from "ngx-mask";

import { QuillModule } from "ngx-quill";
//import {QuillInitializeService} from './services/quillInitialize.service';
//import 'quill-mention';
//import 'quill-emoji';

/*

npm install ngx-quill
 npm install @angular/core
 npm install @angular/common
 npm install @angular/forms
 npm install @angular/platform-browser
 npm install quill
 npm install rxjs — peer dependencies of rxjs-quill

 https://stackoverflow.com/questions/57631897/integrating-quill-text-editor-in-an-angular-application

*/

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [...magicGenComponents],
  exports: [...magicGenComponents, MagicModule],
  imports: [
    // Angular Modules
    CommonModule,
    ReactiveFormsModule,
    RouterModule,

    // Magic Modules
    MagicModule,
    DynamicModule,
    InfiniteScrollModule,

    // Material Modules
    MatTableModule,
    MatPaginatorModule,
    MatInputModule,
    MatButtonModule,
    MatListModule,
    MatCheckboxModule,
    MatTabsModule,
    MatDialogModule,
    MatSelectModule,
    MatSortModule,
    MatTooltipModule,
    MatCardModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    //MatSelectSearchModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgxMatSelectSearchModule,

    MagicAngularMaterialModule,
    //bms informatika
    FormsModule,
    NgxMaskModule.forRoot(),
    FontAwesomeModule,
    CollapseModule.forRoot(),
    DemoMaterialModule,
    DragDropModule,
    QuillModule.forRoot(),
  ],
  entryComponents: [],
  bootstrap: [],
  providers: [
    ExitMagicService,
    { provide: MAT_DATE_LOCALE, useValue: "hu-HU" },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "fill" },
    },
    { provide: MatPaginatorIntl, useValue: getHuPaginatorIntl() },
  ],
})
export class MagicGenLibModule{
  constructor(
    componentList: ComponentListMagicService,
    private moduleRef: NgModuleRef<any>
  ) {
    componentList.addComponents(magicGenCmpsHash, moduleRef);
    componentList.title = title;
    componentList.lazyLoadModulesMap = LazyLoadModulesMap;
    componentList.lazyLoadModulesMap = LazyLoadModulesMap;
    componentList.lazyLoadModulesMap = LazyLoadModulesMap;
  }
}
