import { Component } from '@angular/core';

import { FormGroup } from "@angular/forms";
import { MgFormControlsAccessor, MgControlName, MgCustomProperties } from "./KiadvanyAdatok.mg.controls.g";


import { TaskBaseMagicComponent, magicProviders } from "@magic-xpa/angular";

import {
  faBook,
  faUser,
  faWrench,
  faStream,
  faTag,
  faGraduationCap,
  faChartPie,
  faUpload,
  faCaretDown,
  faCaretUp,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";


@Component({
  selector: 'mga-KiadvanyAdatok',
  providers: [...magicProviders],
  templateUrl: "../../../templates/_form_overlay.html", //'./KiadvanyAdatok.component.html',//
  styleUrls: ["./KiadvanyAdatok.component.scss"],
})
export class KiadvanyAdatok extends TaskBaseMagicComponent {

  mgc = MgControlName;
  mgcp = MgCustomProperties;
  mgfc: MgFormControlsAccessor;

  createFormControlsAccessor(formGroup: FormGroup) {
    this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
  }
  private static readonly formName: string = "KiadvanyAdatok";
  private static readonly showTitleBar: boolean = true;
  private static readonly x: number = 0;
  private static readonly y: number = 0;
  private static readonly width: string = "1000px";
  private static readonly height: string = "300px";
  private static readonly isCenteredToWindow: boolean = true;
  private static readonly shouldCloseOnBackgroundClick = true;
  X() {
    return KiadvanyAdatok.x;
  }
  Y() {
    return KiadvanyAdatok.y;
  }
  Width(): string {
    return KiadvanyAdatok.width;
  }
  Height(): string {
    return KiadvanyAdatok.height;
  }
  IsCenteredToWindow() {
    return KiadvanyAdatok.isCenteredToWindow;
  }
  FormName() {
    return KiadvanyAdatok.formName;
  }
  ShowTitleBar() {
    return KiadvanyAdatok.showTitleBar;
  }
  ShouldCloseOnBackgroundClick() {
    return KiadvanyAdatok.shouldCloseOnBackgroundClick;
  }

  form = this.mgc.KiadvanyAdatok;
  form_kiiras = "";

  faBook = faBook;
  faUser = faUser;
  faWrench = faWrench;
  faStream = faStream;
  faTag = faTag;
  faGraduationCap = faGraduationCap;
  faChartPie = faChartPie;
  faUpload = faUpload;
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;
  faWindowClose = faWindowClose;

  element: HTMLInputElement;
  refresh(id: string, magic): void {
    this.element = document.getElementById(id) as HTMLInputElement;
    this.mg.setValueToControl(magic, this.element.value, true);
    //console.log("NG:"+this.element.value);
    //console.log("MG:"+this.mg.getValue(this.mgc.ISBN));
    //console.log("MG_new:"+this.mg.getValue(magic));
    console.log("futtatas");
  }

  /*public get_mgparameter(id: string): string {
    //return this.mg.getCustomProperty(this.mgc.Ujcikk,'tree');
    return this.mg.getCustomProperty(this.mgc.ForgKeszlSF2, id);
  }*/

  //tree01=this.mg.getCustomProperty('Ujcikk','TREE01');//this.TREE_DATA;//
  public mask_10 = {
    guide: true,
    showMask: true,
    mask: [
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
    ],
  };
  public mask_13 = {
    guide: true,
    showMask: true,
    mask: [
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
    ],
  };
  public mask = this.mask_10;

  /*public subjects = this.mgc.subjects;
  public interests = this.mgc.interests;
  public educations = this.mgc.educations;

  public treerefresh(id = ""): void {
    this.element = document.getElementById("subjects") as HTMLInputElement;
    this.mg.setValueToControl(this.mgc.subjects, this.element.value, true);

    this.element = document.getElementById("interests") as HTMLInputElement;
    this.mg.setValueToControl(this.mgc.interests, this.element.value, true);

    this.element = document.getElementById("educations") as HTMLInputElement;
    this.mg.setValueToControl(this.mgc.educations, this.element.value, true);
  }*/

  public checkValue(event: any) {
    console.log(event);
  }

  public onChecked(obj: any, isChecked: boolean) {
    console.log(obj, isChecked); // {}, true || false
  }

  public contributorsArray: Number[] = [];
  public contributorsType: string[] = [
    "Szerző|A01",
    "Forgatókönyv|A03",
    "Librettó|A04",
    "Tervező, designer|A11",
    "Illusztrátor|A12",
    "Fényképész, fotós|A13",
    "Előszó szerzője|A15",
    "Utószó szerzője|A19",
    "Jegyzetek|A20",
    "Előszó szerzője|A23",
    "Bevezető szerzője|A24",
    "Bevezetés és jegyzetek|A29",
    "Függelék|A33",
    "Rajz|A35",
    "Borítótervező|A36",
    "Térkép|A39",
    "Riporter|A43",
    "Szerkesztő|B01",
    "Lektor|B02",
    "Adaptáció készítője|B05",
    "Fordító|B06",
    "Sorozatszerkesztő|B09",
    "Szerkesztette és fordította|B10",
    "Főszerkesztő|B11",
    "Felelős szerkesztő|B16",
    "Sajtó alá rendezte|B18",
    "Összeállította|C01",
    "Válogatás|C02",
    "Producer|D01",
    "Rendezte|D02",
    "Felolvasó|E07",
    "Közreműködő|Z99",
  ];

  public csakszamok = {
    "0": { pattern: new RegExp("[0-9]") },
    A: { pattern: new RegExp("[0-9a-zA-Z]") },
  };

  public contributorsAdd() {
    this.contributorsArray.push(this.contributorsArray.length + 1);
    //console.log("szerző hozzáadása");
  }
  public contributorsClick() {
    let result: string[] = [];
    let contributorName: string;
    let contributorType: string;

    this.contributorsArray.forEach((element) => {
      contributorName = (<HTMLInputElement>(
        document.getElementById("contributorName" + element)
      )).value;
      contributorType = (<HTMLInputElement>(
        document.getElementById("contributorType" + element)
      )).value;
      result.push(contributorName + contributorType);
    });

    //(<HTMLInputElement>document.getElementById("contributors")).value=result;
    let contributors: HTMLInputElement; // = document.getElementById("contributors");
    //this.contributors=document.getElementById("contributors");
    //console.log(result);
  }

  public sectionClick(i: number, label?: string) {
    if (label !== 'Excel') this.form_array[i].collapsed = !this.form_array[i].collapsed;
    //this.treerefresh();
  }

  public theCheckbox = true;

  public form_array = [
    {
      //s01: alapadatok
      id: "section_01",
      type: "section", //olyan tipus ami több elemett csoportosít
      icon: this.faBook,
      label: this.mgc.Konyvadat, //elem kiírás szövege
      perm: "",
      collapsed: false,
      description: "",
      magic: "",
      visible: "visible",
      value:
        [
          {
            //column_01_01
            id: "column_01_01",
            type: "column",
            label: "column_01",
            perm: "",
            description: "",
            magic: "",
            icon: "",
            visible: "visible",
            value: [
              {
                id: "section_01_01",
                type: "section", //olyan tipus ami több elemett csoportosít
                label: "Kiadás adatok", //elem kiírás szövege
                perm: "",
                description: "",
                magic: "",
                icon: "",
                visible: "visible",
                value:
                  [
                    {
                      id: "section_01_01_01",
                      type: "radio_group_nolabel", //becsukja az ablakot
                      label: '',//this.mgc.Konyvadat,//felesleges: {{mg.getValue(mgc.Konyvadat) }}
                      description: "",
                      magic: this.mgc.ElsoAtdolgMasik,//érték visszadás
                      icon: "",
                      visible: this.mgc.ElsoAtdolgMasik,
                      value: this.mgc.ElsoAtdolgMasik,//érték kondiciók, pl. mező lista betöltése
                      disable: this.mgc.ElsoAtdolgMasik,
                    },
                    {
                      id: "section_01_01_05",
                      type: "number", //becsukja az ablakot
                      label: "Eredeti magyar példányszám",
                      description: "",
                      magic: this.mgc.EredetiMagyarPld,
                      icon: "",
                      visible: this.mgc.EredetiMagyarPld,
                      value: this.mgc.EredetiMagyarPld,
                      disable: this.mgc.EredetiMagyarPld,
                    },
                    {
                      id: "section_01_01_02",
                      type: "number", //becsukja az ablakot
                      label: "Utánnyomások száma",
                      description: "",
                      magic: this.mgc.UtanNyomasSzam,
                      icon: "",
                      visible: this.mgc.UtanNyomasSzam,
                      value: "",
                      disable: this.mgc.UtanNyomasSzam,
                    },
                    {
                      id: "section_01_01_03",
                      type: "radio_group_nolabel", //becsukja az ablakot
                      label: '',//this.mgc.UnyRadio,
                      description: "",
                      magic: this.mgc.UnyRadio,
                      icon: "",
                      visible: this.mgc.UnyRadio,
                      value: this.mgc.UnyRadio,//érték kondiciók, pl. mező lista betöltése
                      disable: this.mgc.UnyRadio,
                    },
                    {
                      id: "section_01_01_04",
                      type: "number", //becsukja az ablakot
                      label: "Példányszám",
                      description: "",
                      magic: this.mgc.Peldanyszam,
                      icon: "",
                      visible: this.mgc.Peldanyszam,
                      value: "",
                      disable: this.mgc.Peldanyszam,
                    },

                  ]
              },
              {
                id: "section_01_02",
                type: "section", //olyan tipus ami több elemett csoportosít
                label: "Nyelvek, országok", //elem kiírás szövege
                perm: "",
                description: "",
                magic: "",
                icon: "",
                visible: "visible",
                value:
                  [
                    {
                      id: "section_01_02_01",
                      type: "checkbox", //becsukja az ablakot
                      label: "Fordítás",
                      description: "",
                      magic: this.mgc.Forditas,
                      icon: "",
                      visible: this.mgc.Forditas,
                      value: "",
                      disable: this.mgc.Forditas,
                    },
                    {
                      id: "section_01_02_02",
                      type: "select_mat", //becsukja az ablakot
                      label: "Eredeti kiadás nyelve?",
                      description: "",
                      magic: this.mgc.CBEredetiNyelv,
                      icon: "",
                      visible: this.mgc.CBEredetiNyelv,
                      value: this.mgc.CBEredetiNyelv,
                      disable: this.mgc.CBEredetiNyelv,
                    },
                    {
                      id: "section_01_02_03",
                      type: "select_vector_multiple_nostar", //becsukja az ablakot
                      label: "Milyen nyelveken jelent már meg?",
                      description: "",
                      magic: this.mgc.SFMasNyelvek,
                      icon: "",
                      visible: this.mgc.SFMasNyelvek,
                      value: this.mgc.MasNyelvek,
                      disable: this.mgc.SFMasNyelvek,
                    },
                    {
                      id: "section_01_02_05",
                      type: "number", //becsukja az ablakot
                      label: "Országok száma, ahol már értékesítik a könyvet",
                      description: "",
                      magic: this.mgc.OrszagErt,
                      icon: "",
                      visible: this.mgc.OrszagErt,
                      value: "",
                      disable: this.mgc.OrszagErt,
                    },
                    {
                      id: "section_01_02_03",
                      type: "number", //becsukja az ablakot
                      label: "Országok száma, ahol megtörtént a kiadási jog eladás és nem indult még el az értékesítés",
                      description: "",
                      magic: this.mgc.OrszagNemErt,
                      icon: "",
                      visible: this.mgc.OrszagNemErt,
                      value: "",
                      disable: this.mgc.OrszagNemErt,
                    },
                  ]
              },

            ]
          },
          {
            //column_01_01
            id: "column_01_02",
            type: "column",
            label: "column_02",
            perm: "",
            description: "",
            magic: "",
            icon: "",
            visible: "visible",
            value: [
              {
                id: "section_01_03",
                id_ch: "section_01_03_ch",
                type: "section_ch",
                label: "Film, tv játék, stb. készült-e már belőle?",
                perm: "",
                description: "",
                magic: this.mgc.CBFilmKeszult,
                magic_ch: this.mgc.CBFilmKeszult,
                icon: "",
                visible: this.mgc.CBFilmKeszult,
                value: [
                  {
                    id: "section_01_03_01",
                    type: "text", //becsukja az ablakot
                    label: "Film címe",
                    description: "",
                    magic: this.mgc.FilmCim,
                    icon: "",
                    visible: this.mgc.FilmCim,
                    value: "",
                    disable: this.mgc.FilmCim,
                  },
                  {
                    id: "section_01_03_02",
                    type: "date", //becsukja az ablakot
                    label: "Megjelenés dátuma",
                    description: "",
                    magic: this.mgc.FilmDatum,
                    icon: "",
                    visible: this.mgc.FilmDatum,
                    value: "",
                    disable: this.mgc.FilmDatum,
                  },
                  {
                    id: "section_01_03_03",
                    type: "text", //becsukja az ablakot
                    label: "Gyártó",
                    description: "",
                    magic: this.mgc.FilmGyarto,
                    icon: "",
                    visible: this.mgc.FilmGyarto,
                    value: "",
                    disable: this.mgc.FilmGyarto,
                  },
                  {
                    id: "section_01_03_04",
                    type: "text", //becsukja az ablakot
                    label: "Főszereplő",
                    description: "",
                    magic: this.mgc.FilmFoszereplo,
                    icon: "",
                    visible: this.mgc.FilmFoszereplo,
                    value: "",
                    disable: this.mgc.FilmFoszereplo,
                  },
                ],
              },
              {
                id: "section_01_04",
                id_ch: "section_01_04_ch",
                type: "section_ch",
                label: "Terveznek-e filmet, tv játékot, stb. készíteni belőle?",
                perm: "",
                description: "",
                magic: this.mgc.CBFilmTerv,
                magic_ch: this.mgc.CBFilmTerv,
                icon: "",
                visible: this.mgc.CBFilmTerv,
                value: [
                  {
                    id: "section_01_04_01",
                    type: "text", //becsukja az ablakot
                    label: "Film címe",
                    description: "",
                    magic: this.mgc.FilmCimTerv,
                    icon: "",
                    visible: this.mgc.FilmCimTerv,
                    value: "",
                    disable: this.mgc.FilmCimTerv,
                  },
                  {
                    id: "section_01_04_02",
                    type: "date", //becsukja az ablakot
                    label: "Tervezett megjelenés dátuma",
                    description: "",
                    magic: this.mgc.FilmDatumTerv,
                    icon: "",
                    visible: this.mgc.FilmDatumTerv,
                    value: "",
                    disable: this.mgc.FilmDatumTerv,
                  },
                  {
                    id: "section_01_04_03",
                    type: "text", //becsukja az ablakot
                    label: "Kiegészítő információk",
                    description: "",
                    magic: this.mgc.FilmKiegTerv,
                    icon: "",
                    visible: this.mgc.FilmKiegTerv,
                    value: "",
                    disable: this.mgc.FilmKiegTerv,
                  },
                ],
              },
              {
                id: "section_01_05",
                type: "section", //olyan tipus ami több elemett csoportosít
                label: "Korábbi megjelenés", //elem kiírás szövege
                perm: "",
                description: "",
                magic: "",
                icon: "",
                visible: "visible",
                value:
                  [
                    {
                      id: "section_01_05_01",
                      type: "number", //becsukja az ablakot
                      label: "Hány korábban megjelent könyve volt már a szerző(k)nek Magyarországon?",
                      description: "",
                      magic: this.mgc.SzerzKorabbanDb,
                      icon: "",
                      visible: this.mgc.SzerzKorabbanDb,
                      value: "",
                      disable: this.mgc.SzerzKorabbanDb,
                    },
                    {
                      id: "section_01_05_02",
                      type: "number", //becsukja az ablakot
                      label: "A korábban megjelent művek utolsó kiadási éve",
                      description: "",
                      magic: this.mgc.SzerzKorabbanUtolsoEv,
                      icon: "",
                      visible: this.mgc.SzerzKorabbanUtolsoEv,
                      value: "",
                      disable: this.mgc.SzerzKorabbanUtolsoEv,
                    },
                    {
                      id: "section_01_05_03",
                      type: "text", //becsukja az ablakot
                      label: "mgc.ItemList",
                      description: "",
                      magic: this.mgc.ItemList,
                      icon: "",
                      visible: this.mgc.ItemList,
                      value: "",
                      disable: this.mgc.ItemList,
                    },
                    {
                      id: "section_01_05_04",
                      type: "text", //becsukja az ablakot
                      label: "mgc.ItemList",
                      description: "",
                      magic: this.mgc.DisplayList,
                      icon: "",
                      visible: this.mgc.DisplayList,
                      value: "",
                      disable: this.mgc.DisplayList,
                    }
                  ]
              },
            ]//end colum content array
          },//end column
        ]
    },
    {
      //küldés
      id: "send",
      type: "button",
      label: "Mentés",
      perm: "",
      description: "",
      magic: this.mgc.BTNSEND, //majd javítandó rendes controlra
      icon: "",
      visible: "visible",
      value: "",
    },
  ];

  get_text(x: any, y: number): string {
    //szövegek szétszedése a | karakter mentén, ezzel a főszöveg és a hozzátartozó leírás külön kezelhető
    if(typeof this.mg.getValue(x) == 'string') return (y==0)?this.mg.getValue(x):'';
    return x.split("|")[y];
  }
  getCollapsed(x: string): boolean {
    //összezártság lekérdezése
    return this.form_array[x].collapsed;
  }
}
