import { FormControl, FormGroup } from "@angular/forms";
import { MagicServices } from "@magic-xpa/angular";
export enum MgControlName {
  ForgKeszlSF2 = "ForgKeszlSF2",
  MG_FORMNAME = "MG_FORMNAME",
  CikkSF = "CikkSF",
  ListaSF = "ListaSF",
  beagyazott = "beagyazott",
  Lekerdezes = "Lekerdezes",
  Excel = "Excel",

  Sablon = "Sablon",
  Ujragyujt = "Ujragyujt",

  Sablonment = "Sablonment",
  megjegyzes = "megjegyzes",
  nev = "nev",
  Ujcikk = "Ujcikk",
  Label117 = "Label117",
  pubtn = "pubtn",
  puref = "puref",
  puname = "puname",
  BeszallitoSF = "BeszallitoSF",
  Label117a = "Label117a",
  publisherBTN = "publisherBTN",
  publisherRef = "publisherRef",
  publisherName = "publisherName",
  Label64 = "Label64",
  isbn = "isbn",
  isbnis13 = "isbnis13",
  isbn13 = "isbn13",
  Label65 = "Label65",
  barcode = "barcode",
  Label67 = "Label67",
  title = "title",
  Label68 = "Label68",
  subTitle = "subTitle",
  Label69 = "Label69",
  originalTitle = "originalTitle",
  Label70 = "Label70",
  publicTitle = "publicTitle",
  Label71 = "Label71",
  languages = "languages",
  Label72 = "Label72",
  publisher = "publisher",
  Label73 = "Label73",
  publishingPlace = "publishingPlace",
  Label74 = "Label74",
  publishingYear = "publishingYear",
  Label75 = "Label75",
  publishingDate = "publishingDate",
  Label76 = "Label76",
  editionNumber = "editionNumber",
  Label77 = "Label77",
  seriesEnabled = "seriesEnabled",
  seriesEnabled2 = "seriesEnabled2",
  Label78 = "Label78",
  seriesTitle = "seriesTitle",
  seriesTitle2 = "seriesTitle2",
  Label79 = "Label79",
  issn = "issn",
  Label80 = "Label80",
  volumeNumber = "volumeNumber",
  Label81 = "Label81",
  Radio_contributor = "Radio_contributor",
  Label82 = "Label82",
  contributors = "contributors",
  contributorsForm = "contributorsForm",
  DiscountCategory = "DiscountCategory",
  productType = "productType",
  Label66 = "Label66",
  customsTariffNumber = "customsTariffNumber",
  productTab = "productTab",
  Label84 = "Label84",
  productFormCode = "productFormCode",
  Label85 = "Label85",
  numberOfPages = "numberOfPages",
  Label86 = "Label86",
  productFormDetail = "productFormDetail",
  Label87 = "Label87",
  bookwidth = "bookwidth",
  Label88 = "Label88",
  bookheight = "bookheight",
  Label89 = "Label89",
  bookthickness = "bookthickness",
  Label90 = "Label90",
  bookweight = "bookweight",
  illustrations = "illustrations",
  Label92 = "Label92",
  numberOfIllustrations = "numberOfIllustrations",
  Label93 = "Label93",
  audioBookType = "audioBookType",
  Label94 = "Label94",
  numberOfDiscs = "numberOfDiscs",
  Label95 = "Label95",
  sizeOfAudioBook = "sizeOfAudioBook",
  Label96 = "Label96",
  duration = "duration",
  Label97 = "Label97",
  primarySubject = "primarySubject",
  Label98 = "Label98",
  subjects = "subjects",
  Label99b = "Label99b",
  PrimarySubj = "PrimarySubj",
  Label110 = "Label110",
  oldCategory = "oldCategory",
  Label99i = "Label99i",
  interests = "interests",
  Label100 = "Label100",
  educations = "educations",
  Label101p = "Label101p",
  price = "price",
  Label102 = "Label102",
  taxRate = "taxRate",
  Label103 = "Label103",
  miscIdentifier = "miscIdentifier",
  blurTabControl = "blurTabControl",
  Label10485877 = "Label10485877",
  blurb = "blurb",
  Label101b = "Label101b",
  blurb2 = "blurb2",
  btnEditBlurb2 = "btnEditBlurb2",
  Label105 = "Label105",
  slogan = "slogan",
  Label106 = "Label106",
  links = "links",
  linksForm = "linksForm",
  imagesForm = "imagesForm",
  Label108 = "Label108",
  tmp_control = "tmp_control",
  Label109 = "Label109",
  cTree2 = "cTree2",
  coverFile = "coverFile",
  samplePageFile = "samplePageFile",
  BTNSEND = "BTNSEND",
  form_kiiras = "form_kiiras",
  frissitvg = "frissitvg",
  hozzaad = "hozzaad",
  SelectedCountTmp = "SelectedCountTmp",
  valaszttmpvg = "valaszttmpvg",
  SelectedCount = "SelectedCount",
  valasztvg = "valasztvg",
  treerefresh = "treerefresh",
  Edit10485761 = "Edit10485761",
  Label104 = "Label104",
  Label10485813 = "Label10485813",
  BontasTipus = "BontasTipus",
  evhonap = "evhonap",
  bolt = "bolt",
  beszallito = "beszallito",
  cikk = "cikk",
  mennyiseg = "mennyiseg",
  eladasiAr = "eladasiAr",
  listaar = "listaar",
  beszerzesiAr = "beszerzesiAr",
  Keszlet = "Keszlet",
  NullasKeszlet = "NullasKeszlet",
  EgyszeruOsszetett = "EgyszeruOsszetett",

  EANgeneralas="EANgeneralas",
}
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) {}

  get MG_FORMNAME(): FormControl {
    return this.fg.controls[MgControlName.MG_FORMNAME] as FormControl;
  }

  get beagyazott(): FormControl {
    return this.fg.controls[MgControlName.beagyazott] as FormControl;
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(
      this.magicServices.tableService.getSelectedRow()
    ).controls[name] as FormControl;
  }
}
