import { SelectionModel } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import {
  Component,
  Injectable,
  Input,
  OnInit,
  SimpleChanges,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from "@angular/core";
import { MatCheckbox } from "@angular/material/checkbox";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from "@angular/material/tree";
import { BehaviorSubject } from "rxjs";

export class TodoItemNode {
  //ág
  children: TodoItemNode[];
  item: string;
  id: string;
  //checked: boolean = false;
}

export class TodoItemFlatNode {
  //levelek
  item: string;
  level: number;
  id: string;
  expandable: boolean;
  checked: boolean;
}

@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<TodoItemNode[]>([]); //új BehaviorSubject ami TodoItemNode elemeket tartalmazó tömbökből áll
  constructor() {
    this.initialize({}); //üres adatbázis létrehozása
  }
  public initialize(treedata: object, inputData: string = "", productType: string = "default") {
    //productType --> könyv vagy játék, azt határozza meg melyik fa legyen megjelenítve
    switch (productType.toString()) {
      case '7':
        var root = "game"
        break;
      case 'default':
      default:
        var root = 'default';
        break;
    }
    console.log("productType ::: "+productType);
    console.log("root ::: "+root);
    const data = (typeof treedata[root] !== 'undefined') ? this.buildFileTree(treedata[root], 0, inputData) : this.buildFileTree(treedata, 0, inputData);//treeadatbázis megépítése
    this.dataChange.next(data); //objektumot ad vissza és egy állapotot arról, hogy befejezte-e; utolsónak: "Object { value: undefined, done: true }"
  }

  buildFileTree(
    obj: { [key: string]: any },
    level: number,
    inputData: string = ""
  ): TodoItemNode[] {
    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      //reduce-addig hívja rekurzívan, míg teljesen a véig nem jutunk
      const value = obj[key];
      const node = new TodoItemNode();
      node.item = key;
      if (typeof value === "object") {
        //ameddig objektummal dolgozunk
        if (!value.element) {
          //ha nem elem akkor kategória
          node.children = this.buildFileTree(value, level + 1, inputData);
        } else {
          //alap elemek, levelek
          node.item = value.name;
          node.id = value.id;
        }
      }
      return accumulator.concat(node); //az accumulator array-hoz hozzáfüzöm az aktuális node array-t és visszaadom a hívó függvénynek
    }, []); //visszaadom az így megépült objektumot
  }
}

/**
 * @title Tree with checkboxes
 */
@Component({
  selector: "customtree",
  templateUrl: "customtree.component.html",
  styleUrls: ["customtree.component.scss"],
  providers: [ChecklistDatabase],
})
export class CustomtreeComponent implements OnInit {
  //a CustomtreeComponent bemeneti paraméterei, ezekre direktben tudsz hivatkozni a html elemin
  @Input() name: string; //ürlapelem neve amihez a tree control tartozik
  @Input() source: string; //tree control forrása
  @Input() inputData: string; //beérkező adat, alapértelmezett adatok beállításához
  @Input() mode: string; //vannak-e subkatergóriák?
  @Input() submode: string; // jelenleg a bejövő paraméterekhez, 'KT'
  @Input() productType: string;

  /*
  @Output() outputData = new EventEmitter<string>();
  */

  test: boolean = false; //tezstelés
  result = "none"; //eredmény visszaadásához, changeInputVar függvény
  element: HTMLInputElement;
  form_load = false;
  selected_check = false;

  //Objektum élete angularban: constructor < ngOnChanges < ngOnInit < ngDoCheck [ < ngAfterContentInit < ngAfterContentChecked < ngAfterViewInit < ngAfterViewChecked ] < ngOnDestroy

  constructor(private _database: ChecklistDatabase) {
    //console.log(this.productType);//undefined
    //console.log(this.mode);//undefined
    this.treeFlattener = new MatTreeFlattener(
      this.transformer, //ágvégekből levelek készítése
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );

    //this.element = document.getElementById(this.name) as HTMLInputElement;
    //this.element.value = this.inputData;

    //if (this.test) console.log("constructor" + this.source);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.source == undefined) {
      //ha a beérkezett adat jelenleg üres akkor az egy üres objektum legyen
      this.source = "{}";
    }
    //this.inputData="DN;FP;FM;FU;FW;FV;VXH";//tesztelés
    //console.log(this.inputData);//amit kapunk,
    this.initialize(JSON.parse(this.source), this.name, this.inputData, this.productType); //átadjuk a meglévő objektumot és az aktuális ürlap nevét
    //console.log("productType :::" + this.productType);//undefined
    //console.log("mode :::" + this.mode);//undefined
    //console.log(this._database);

    //if (this.test) console.log("ngOnChanges" + this.source);

    //debugger;
  }

  ngOnInit(): void {
    //az init elején az objektumok egy része még nem jött létre, sőt a bérkező paraméterek is megbizhattalanok
    //if (this.test) console.log("ngOnInit" + this.source);
  }

  ngDoCheck(): void {
    //if (this.test) console.log("ngDoCheck" + this.source);
  }

  ngAfterContentInit(): void {
    //debugger;
    //if (this.test) console.log("ngAfterContentInit" + this.source);
  }

  ngAfterContentChecked(): void {
    //if (this.test) console.log("ngAfterContentChecked" + this.source);
  }

  ngAfterViewInit(): void {
    //if (this.test) console.log("ngAfterViewInit" + this.source);
  }

  ngAfterViewChecked(): void {
    //debugger;
    //if (this.test) console.log("ngAfterViewChecked" + this.source);
    if ((typeof (this.submode) == 'string') && (this.submode != '') && (this.form_load == false)) {
      //console.log('DIV shown is ' + this.mg.getValue(this.mgc.submode));
      if (this.submode == 'KT') {
        //console.log(this.submode);
        this.selected_check = true;
        this.treeControl.expandAll();
        this.search_checked(this.name, { checked: true });
      }
      else {
        this.selected_check = false;
      }
      //console.log(this.submode);
      //console.log(this.form_load);
      this.form_load = true;
      //console.log(this.selected_check);
    }
  }

  ngOnDestroy(): void {
    //if (this.test) console.log("ngOnDestroy" + this.source);
  }

  //alap objektumok elkészítése
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();
  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
  treeControl: FlatTreeControl<TodoItemFlatNode>;
  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;
  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;
  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(
    true /* multiple */
  );

  initialize(source: object, name: string, inputData: string, productType: string): void {
    this._database.initialize(source, inputData, productType); //inicializáljuk a beérkezett objektumot, ekkor jön létre a tree controlhoz az adatbázis
    this._database.dataChange.subscribe((data) => {
      //ha a datachange végrehajtódik akkor
      this.dataSource.data = data;
    });
    //console.log(this._database.dataChange.value);//itt már létrejött a jó objektum
  }

  //adatlekérő függvények:
  getLevel = (node: TodoItemFlatNode) => node.level;
  isExpandable = (node: TodoItemFlatNode) => node.expandable;
  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;
  getID = (node: TodoItemNode) => node.id;
  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;
  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) =>
    _nodeData.item === "";

  /*setoutputData(){
    this.outputData.emit(this.inputData);
  }*/

  getChecked = (node: TodoItemFlatNode): boolean => node.checked; //adott levél checkelési állapota, {{getChecked(node)}}
  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void { //(change)="todoLeafItemSelectionToggle(node)"
    this.checklistSelection.toggle(node);
    //node.checked = this.checklistSelection.isSelected(node);
    this.checkAllParentsSelection(node);//össze kijelölése nem kell
  }

  changeInputVar(node?: TodoItemFlatNode, event?: any): void {
    //checkboxra kattintáskor
    //console.log(node);
    //console.log(this.name);
    if (this.result == "none") this.result = this.inputData; //alapértékek
    //console.log(node.checked);
    //console.log(node.checked);
    //console.log(this.checklistSelection.isSelected(node));
    //console.log(node.checked==this.checklistSelection.isSelected(node));
    //this.checklistSelection.isSelected(node)
    if (node.checked == this.checklistSelection.isSelected(node)) {
      //Casting: <number><any>a ^ <number><any>b; Negated equality: a !== b
      //ha be van chekkolva
      if (typeof this.result === 'undefined') this.result = '';
      //console.log(this.result);
      this.result += node.id + ";";//elem hozzáadása
      //console.log(this.result);
      node.checked = true;
    } else {
      //ha nincs becekkolva
      let result_array = this.result.split(';');
      //console.log(result_array);
      result_array = result_array.filter(obj => obj !== node.id);
      //console.log(result_array);
      this.result = result_array.toString().split(',').join(';');//replaceAll(",", ";"); helyett
      console.log("result :::" + this.result);
      node.checked = false;
    }

    //result lementése az ürlapra
    this.element = document.getElementById(this.name) as HTMLInputElement;
    this.element.value = this.result; //értékek kimentése a name alapján megadott html elembe
    //console.log(this.element.value);
    //console.log(this.inputData); //beérkezett adatok kiíratása
  }

  //keresés
  tree_item: HTMLElement;
  search(name?: string, search?: string): void {
    //console.log(name);

    this.tree_item = document.getElementById("tree_" + name) as HTMLElement;
    this.treeControl.expandAll();
    //console.log(this.tree_item);

    for (let i = 0; i < this.tree_item.children.length; i++) {
      //let children:HTMLElement;
      //children= this.tree_item.children[i]
      if (
        (<any>this.tree_item.children[i]).innerText
          .toUpperCase()
          .includes(search.toUpperCase())
      ) {
        //console.log((<any>this.tree_item.children[i]).innerText);
        this.tree_item.children[i].setAttribute("style", "display:inherit");
      } else {
        this.tree_item.children[i].setAttribute("style", "display:none");
      }
      //children.style.color=(children.innerText.includes(search))?'blue':'';
      //console.log(this.tree_item.children[i].setAttribute("style","color:blue"))
    }
  }

  search_checked(name?: string, search?: any) {
    this.tree_item = document.getElementById("tree_" + name) as HTMLElement;
    for (let i = 0; i < this.tree_item.children.length; i++) {
      if (search.checked) {
        //eltüntetjük a false értékeket
        this.treeControl.expandAll();//mindent kinyitunk
        if ((this.tree_item.children[i].childNodes[1].nodeType == 1) && ((<any>this.tree_item.children[i].childNodes[1].childNodes[0].childNodes[0].childNodes[0]).checked == false)) {//ha checkable node item és unchecked akkor elrejtjük
          this.tree_item.children[i].setAttribute("style", "display:none");
        }
        if (this.tree_item.children[i].childNodes[1].nodeType == 3) {
          //this.tree_item.children[i].setAttribute("style", "display:none");
        }
      }
      else {//minden item megjelenítése
        //minden bezárása
        this.treeControl.collapseAll();
        this.tree_item.children[i].setAttribute("style", "display:inherit");
      }
    }
  }

  //node-k átkonvertálása flatnodekká, itt lehet a leveleket manupilálni
  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item
        ? existingNode
        : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.id = node.id;
    /*
    if(typeof (this.inputData) == 'string'){
      flatNode.checked = this.inputData.split(";").includes(node.id) ? true : false; //legújabb, ("DN;FP;FM;FU;FW;FV;VXH;P;").split(";").includes(node.id)
    }
    else{
      flatNode.checked = this.inputData ? this.inputData.includes(node.id) : false;//régi stabil
    }
    */
    flatNode.checked = this.inputData ? this.inputData.split(";").includes(node.id) : false;

    //
    //flatNode.checked = this.inputData.includes(node.id) ? true : false; //csalás ahhoz, hogy jók legyennek bechekkolva
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    //console.log(flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }
  //minden checkbox selectalasa
  descendantsAllSelected2(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    let allselected = descendants.every((child) =>
      this.checklistSelection.isSelected(child)
    );
    if (allselected) this.checklistSelection.select(node);
    else this.checklistSelection.deselect(node);
    return allselected;
  }

  //sajat selectalas
  descendantsAllSelected3(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    let allselected = descendants.every((child) =>
      this.checklistSelection.isSelected(child)
    );
    if (allselected) this.checklistSelection.select(node);
    else this.checklistSelection.deselect(node);
    return allselected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.checklistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
}
