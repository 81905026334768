<div #overlayheader class="urlap, form-style, my-overlay-header">
  <span>{{ mg.getValue("MG_FORMNAME") }}</span>
  <!--<span>{{ form_kiiras }}</span>-->
  <button class="input_button" magic="{{ mgc.MG_B_QUIT }}" [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)">
    <fa-icon [icon]="faWindowClose"></fa-icon>
  </button>
</div>
<div novalidate [formGroup]="screenFormGroup">
  <div class="flex-container-column" magic="{{mgc.FileTransfer}}">
    <div class="flex-container-row" [style.display]="'none'">
      <mat-form-field>
        <div>
          <input matInput magic="{{mgc.Blob64AtServer}}" formControlName="{{mgc.Blob64AtServer}}">
          <mgError magic={{mgc.Blob64AtServer}}> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row" [style.display]="'none'">
      <mat-form-field>
        <div>
          <input matInput magic="{{mgc.vfilename}}" formControlName="{{mgc.vfilename}}" mgFormat>
          <mgError magic={{mgc.vfilename}}> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row" [style.display]="'none'">
      <mat-form-field>
        <div>
          <input matInput magic="{{mgc.vOpenOrDownload}}" formControlName="{{mgc.vOpenOrDownload}}" mgFormat>
          <mgError magic={{mgc.vOpenOrDownload}}> </mgError>
        </div>
      </mat-form-field>
    </div>
  </div>
</div>
