<div novalidate [formGroup]="screenFormGroup">
  <div class="flex-container-column" magic="{{ mgc.Aruk }}">
    <!--
    <div #overlayheader class="my-overlay-header">
      <span>{{ mg.getValue("MG_FORMNAME") }}</span>
      <button
        class="input_button"
        magic="{{ mgc.MG_B_QUIT }}"
        [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)"
      >
        <fa-icon [icon]="faWindowClose"></fa-icon>
      </button>
    </div>
    -->
    <div class="flex-container-row">
      <div class="example-container mat-elevation-z8 MG_T01TableContainerProps">
        <mat-table
          #table
          [dataSource]="dataSource"
          class="container_border"
          matSort
          matSortDisableClear
          (matSortChange)="tableService.sortData($event)"
        >
          <ng-container
            magic="{{ mgc.Column10485762 }}"
            matColumnDef="{{ mgc.Column10485762 }}"
          >
            <mat-header-cell *matHeaderCellDef> Cikk név </mat-header-cell>
            <mat-cell *matCellDef="let row" magicMark="magicTableRowContainer">
              <div class="flex-container-row" *ngIf="mg.ifRowCreated(row)">
                <div
                  [formGroup]="mg.getFormGroupByRow(row.rowId)"
                  class="flex-container-row"
                >
                  <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                    <div>
                      <input
                        *ngIf="mg.isRowInRowEditing(row)"
                        matInput
                        magic="{{ mgc.MG_T01C001D001 }}"
                        [rowId]="row.rowId"
                        formControlName="{{ mgc.MG_T01C001D001 }}"
                        mgFormat
                      />
                      <mgError
                        magic="{{ mgc.MG_T01C001D001 }}"
                        [rowId]="row.rowId"
                      >
                      </mgError>
                    </div>
                  </mat-form-field>
                  <label
                    *ngIf="!mg.isRowInRowEditing(row)"
                    magic="{{ mgc.MG_T01C001D001 }}"
                    [rowId]="row.rowId"
                  >
                    {{ mg.getValue(mgc.MG_T01C001D001, row.rowId) }}
                  </label>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="table_row"
            [ngClass]="{ selected: selection.isSelected(row) }"
            [magicRow]="row?.rowId"
            (click)="tableService.selectRow(row.rowId)"
          >
          </mat-row>
        </mat-table>
        <mat-paginator
          #paginator
          [pageSize]="10"
          [pageSizeOptions]="[5, 10, 20]"
          [showFirstLastButtons]="true"
          (page)="tableService.mgOnPaginateChange($event)"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
