<div novalidate [formGroup]="screenFormGroup">
  <div class="flex-container-column" magic="{{ mgc.Aruvalasztas2 }}">
    <div #overlayheader class="my-overlay-header">
      <span>{{ mg.getValue("MG_FORMNAME") }}</span>
      <button
        class="input_button"
        magic="{{ mgc.MG_B_QUIT }}"
        [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)"
      >
        <fa-icon [icon]="faWindowClose"></fa-icon>
      </button>
    </div>
    <div class="flex-container-row">
      <label magic="{{ mgc.Label10485761 }}" class="lable_overflow">
        Cikkszám
      </label>
      <label magic="{{ mgc.Label10485762 }}" class="lable_overflow">
        Név
      </label>
      <label magic="{{ mgc.Label10485763 }}" class="lable_overflow">
        Beszállító
      </label>
      <label magic="{{ mgc.Label10485764 }}" class="lable_overflow">
        Szerző
      </label>
      <label magic="{{ mgc.Label10485765 }}" class="lable_overflow">
        Tematika
      </label>
      <label magic="{{ mgc.Label10485766 }}" class="lable_overflow">
        Cikk típus
      </label>
    </div>
    <div class="flex-container-row">
      <mat-form-field>
        <div>
          <input
            matInput
            type="number"
            magic="{{ mgc.azonosito }}"
            formControlName="{{ mgc.azonosito }}"
            mgFormat
          />
          <mgError magic="{{ mgc.azonosito }}"> </mgError>
        </div>
      </mat-form-field>
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.nev }}"
            formControlName="{{ mgc.nev }}"
            mgFormat
          />
          <mgError magic="{{ mgc.nev }}"> </mgError>
        </div>
      </mat-form-field>
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.beszallito }}"
            formControlName="{{ mgc.beszallito }}"
            mgFormat
          />
          <mgError magic="{{ mgc.beszallito }}"> </mgError>
        </div>
      </mat-form-field>
      <button
        mat-raised-button
        color="primary"
        magic="{{ mgc.beszallitovg }}"
        [disabled]="mg.isDisabled(mgc.beszallitovg)"
      >
        ...
      </button>
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.szerzo }}"
            formControlName="{{ mgc.szerzo }}"
            mgFormat
          />
          <mgError magic="{{ mgc.szerzo }}"> </mgError>
        </div>
      </mat-form-field>
      <button
        mat-raised-button
        color="primary"
        magic="{{ mgc.szerzovg }}"
        [disabled]="mg.isDisabled(mgc.szerzovg)"
      >
        ...
      </button>
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.tematika }}"
            formControlName="{{ mgc.tematika }}"
            mgFormat
          />
          <mgError magic="{{ mgc.tematika }}"> </mgError>
        </div>
      </mat-form-field>
      <button
        mat-raised-button
        color="primary"
        magic="{{ mgc.tematikavg }}"
        [disabled]="mg.isDisabled(mgc.tematikavg)"
      >
        ...
      </button>
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.cikktipus }}"
            formControlName="{{ mgc.cikktipus }}"
            mgFormat
          />
          <mgError magic="{{ mgc.cikktipus }}"> </mgError>
        </div>
      </mat-form-field>
      <button
        mat-raised-button
        color="primary"
        magic="{{ mgc.cikktipusvg }}"
        [disabled]="mg.isDisabled(mgc.cikktipusvg)"
      >
        ...
      </button>
      <button
        mat-raised-button
        color="primary"
        magic="{{ mgc.MG_B_SEARCH }}"
        [matTooltip]="mg.getTitle(mgc.MG_B_SEARCH)"
      >
        Keres
      </button>
    </div>
    <div class="flex-container-row">
      <label magic="{{ mgc.Label10485773 }}" class="lable_overflow">
        ISBN
      </label>
    </div>
    <div class="flex-container-row">
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.isbn_0001 }}"
            formControlName="{{ mgc.isbn_0001 }}"
            mgFormat
          />
          <mgError magic="{{ mgc.isbn_0001 }}"> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <mat-card>
        <magic-subform magic="{{ mgc.Aru_valasztas }}"> </magic-subform>
      </mat-card>
    </div>
  </div>
</div>
