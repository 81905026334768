<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        style="display: flex; flex-direction: column"
        [magic]="mgc.Placeholder"
    >
        <div style="display: flex; flex-direction: row">
            <label
                [magic]="mgc.Label10485802"
                class="lable_overflow mgFont1"
            >
                mezo
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        [magic]="mgc.mezo"
                        [placeholder]="mg.getPlaceholder(mgc.mezo)"
                        [formControlName]="mgc.mezo"
                        mgFormat
                    >
                    <mgError [magic]=mgc.mezo> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div style="display: flex; flex-direction: row">
            <div
                [magic]="mgc.Table10485792"
                class="container_border"
            >
                <table>
                    <th>
                        <label>
                            kulfoldi
                        </label>
                    </th>
                    <th>
                        <label>
                            nev
                        </label>
                    </th>
                    <th>
                        <label>
                        </label>
                    </th>
                    <ng-container *ngFor="let row of task.Records.list">
                        <tr
                            *ngIf="mg.ifRowCreated(row) && !mg.isDataviewEmpty()"
                            id="row"
                            [formGroup]="mg.getFormGroupByRow(row.rowId)"
                            class="table_row"
                            [ngClass]="{ 'selected': mg.isRowSelected(mgc.Table10485792, row.rowId)}"
                            [class.selected]="tableService.getSelectedRow() === row.rowId"
                            (click)="tableService.selectRow(row.rowId)"
                            [magicRow]="row?.rowId"
                        >
                            <td>
                                <div>
                                    <mat-checkbox
                                        [magic]="mgc.kulfoldi"
                                        [rowId]="row.rowId"
                                        [formControlName]="mgc.kulfoldi"
                                    >
                                    </mat-checkbox>
                                </div>
                            </td>
                            <td>
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <mat-form-field
                                        [style.visibility]="mg.getVisible(mgc.nev, row.rowId)"
                                        *ngIf="mg.isRowInRowEditing(row)"
                                    >
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                [magic]="mgc.nev"
                                                [rowId]="row.rowId"
                                                placeholder="Keresztnév Családnév"
                                                [formControlName]="mgc.nev"
                                                mgFormat
                                            >
                                            <mgError
                                                [magic]=mgc.nev
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        [magic]="mgc.nev"
                                        [rowId]="row.rowId"
                                        [style.visibility]="mg.getVisible(mgc.nev, row.rowId)"
                                    >
                                        {{mg.getValue(mgc.nev, row.rowId)
}}
                                    </label>
                                </div>
                                <div
                                    [formGroup]="mg.getFormGroupByRow(row.rowId)"
                                    style="display: flex; flex-direction: row"
                                >
                                    <mat-form-field
                                        [style.visibility]="mg.getVisible(mgc.belfoldinev, row.rowId)"
                                        *ngIf="mg.isRowInRowEditing(row)"
                                    >
                                        <div>
                                            <input
                                                *ngIf="mg.isRowInRowEditing(row)"
                                                matInput
                                                [magic]="mgc.belfoldinev"
                                                [rowId]="row.rowId"
                                                placeholder="Családnév Keresztnév"
                                                [formControlName]="mgc.belfoldinev"
                                                mgFormat
                                            >
                                            <mgError
                                                [magic]=mgc.belfoldinev
                                                [rowId]="row.rowId"
                                            > </mgError>
                                        </div>
                                    </mat-form-field>
                                    <label
                                        *ngIf="!mg.isRowInRowEditing(row)"
                                        [magic]="mgc.belfoldinev"
                                        [rowId]="row.rowId"
                                        [style.visibility]="mg.getVisible(mgc.belfoldinev, row.rowId)"
                                    >
                                        {{mg.getValue(mgc.belfoldinev, row.rowId)
}}
                                    </label>
                                </div>
                            </td>
                            <td>
                                <button
                                    mat-raised-button
                                    *ngIf="!mg.isRowInRowEditing(row)"
                                    color="primary"
                                    [magic]="mgc.EnterRowEditing"
                                    [rowId]="row.rowId"
                                >
                                    Edit
                                </button>
                                <button
                                    mat-raised-button
                                    *ngIf="mg.isRowInRowEditing(row)"
                                    color="primary"
                                    [magic]="mgc.SaveRowEditing"
                                    [rowId]="row.rowId"
                                >
                                    Save
                                </button>
                                <button
                                    mat-raised-button
                                    *ngIf="mg.isRowInRowEditing(row)"
                                    color="primary"
                                    [magic]="mgc.CancelRowEditing"
                                    [rowId]="row.rowId"
                                >
                                    Cancel
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                </table>
            </div>
        </div>
    </div>
</div>