import { FormControl, FormGroup } from "@angular/forms";
import { MagicServices } from "@magic-xpa/angular";
export enum MgControlName {
  Szerzovalasztas = "Szerzovalasztas",
  MG_D_SEARCH = "MG_D_SEARCH",
  MG_B_SEARCH2DEF = "MG_B_SEARCH2DEF",
  MG_B_SEARCH = "MG_B_SEARCH",
  MG_T01 = "MG_T01",
  Column10485765 = "Column10485765",
  MG_T01C001D001 = "MG_T01C001D001",
  Column10485766 = "Column10485766",
  MG_T01C002D001 = "MG_T01C002D001",
  Column10485767 = "Column10485767",
  MG_T01C003D001 = "MG_T01C003D001",
  MG_SF01 = "MG_SF01",
  MG_B_SELECT = "MG_B_SELECT",
  MG_B_QUIT = "MG_B_QUIT",
  MG_FORMNAME = "MG_FORMNAME",
}
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) {}

  get MG_D_SEARCH(): FormControl {
    return this.fg.controls[MgControlName.MG_D_SEARCH] as FormControl;
  }

  get MG_T01C001D001(): FormControl {
    return this.getTableChildFormControl(MgControlName.MG_T01C001D001);
  }

  get MG_T01C002D001(): FormControl {
    return this.getTableChildFormControl(MgControlName.MG_T01C002D001);
  }

  get MG_T01C003D001(): FormControl {
    return this.getTableChildFormControl(MgControlName.MG_T01C003D001);
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(
      this.magicServices.tableService.getSelectedRow()
    ).controls[name] as FormControl;
  }
}
