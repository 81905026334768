import { Component, Injectable } from "@angular/core";
import { MyOverlayContainer } from "./MyOverlayContainer";

import {
  BaseMatTableMagicComponent,
  matMagicProviders,
} from "@magic-xpa/angular-material-core";
// change this to correct path depending on where you place MyOverlayContainer.ts
@Injectable()
export class MyOverlayContainerProvider {

  //private static readonly formName: string = this.mg.getValue("MG_FORMNAME");

  getComponent() {
    return MyOverlayContainer;
  }
}
