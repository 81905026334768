import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    UjcikkContributors2 = "UjcikkContributors2",
        Table10485761 = "Table10485761",
        Column10485762 = "Column10485762",
        nev2 = "nev2",
        nev = "nev",
        btnNev = "btnNev",
        Column10485773 = "Column10485773",
        NewEnabled = "NewEnabled",
        Column14 = "Column14",
        kozremukodes = "kozremukodes",
        Column4 = "Column4",
        EnterRowEditing = "EnterRowEditing",
        SaveRowEditing = "SaveRowEditing",
        CancelRowEditing = "CancelRowEditing",
        DeleteRecord = "DeleteRecord",
        Create = "Create",
        Kulfoldi="Kulfoldi"
}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get nev2(): FormControl {
        return this.getTableChildFormControl(MgControlName.nev2);
    }

    get nev(): FormControl {
        return this.getTableChildFormControl(MgControlName.nev);
    }

    get NewEnabled(): FormControl {
        return this.getTableChildFormControl(MgControlName.NewEnabled);
    }

    get kozremukodes(): FormControl {
        return this.getTableChildFormControl(MgControlName.kozremukodes);
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}
