<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        class="flex-container-column"
        magic="{{mgc.Search}}"
    >
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label20}}"
                class="lable_overflow"
            >
                Vonalkód(EAN):
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.vEAN}}"
                        formControlName="{{mgc.vEAN}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.vEAN}}> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label21}}"
                class="lable_overflow"
            >
                Típus:
            </label>
            <div>
                <mat-selection-list
                    multiple
                    formControlName="{{mgc.vType}}"
                    magic="{{mgc.vType}}"
                    [tabIndex]="screenFormGroup.get(mgc.vType).disabled ? -1 : 0"
                >
                    <mat-list-option
                        *ngFor="let o of mg.getItemListValues(mgc.vType);"
                        [value]="o.index"
                    >
                        {{o.displayValue}}
                    </mat-list-option>
                </mat-selection-list>
            </div>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label22}}"
                class="lable_overflow"
            >
                Szerző:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.vAuthor}}"
                        formControlName="{{mgc.vAuthor}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.vAuthor}}> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label23}}"
                class="lable_overflow"
            >
                Sorozat:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.vSeries}}"
                        formControlName="{{mgc.vSeries}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.vSeries}}> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label24}}"
                class="lable_overflow"
            >
                Megjelenés -tól:
            </label>
            <mat-form-field
                magic="{{mgc.vDateStart}}"
                [eventsOnly]=true
            >
                <input
                    matInput
                    [matDatepicker]="vDateStart"
                    magic="{{mgc.vDateStart}}"
                    formControlName="{{mgc.vDateStart}}"
                >
                <mat-datepicker-toggle
                    matSuffix
                    [for]="vDateStart"
                    *ngIf="mg.checkIsReadOnly(mgc.vDateStart)"
                >
                </mat-datepicker-toggle>
                <mat-datepicker #vDateStart></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label25}}"
                class="lable_overflow"
            >
                Megjelenés -ig:
            </label>
            <mat-form-field
                magic="{{mgc.vDateEnd}}"
                [eventsOnly]=true
            >
                <input
                    matInput
                    [matDatepicker]="vDateEnd"
                    magic="{{mgc.vDateEnd}}"
                    formControlName="{{mgc.vDateEnd}}"
                >
                <mat-datepicker-toggle
                    matSuffix
                    [for]="vDateEnd"
                    *ngIf="mg.checkIsReadOnly(mgc.vDateEnd)"
                >
                </mat-datepicker-toggle>
                <mat-datepicker #vDateEnd></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label26}}"
                class="lable_overflow"
            >
                Eredeti cím:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.vTitle}}"
                        formControlName="{{mgc.vTitle}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.vTitle}}> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label27}}"
                class="lable_overflow"
            >
                Cikk azonosító:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.vID}}"
                        formControlName="{{mgc.vID}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.vID}}> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label28}}"
                class="lable_overflow"
            >
                Beszállító:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.vVar01}}"
                        formControlName="{{mgc.vVar01}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.vVar01}}> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label29}}"
                class="lable_overflow"
            >
                KIM:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.vKIM}}"
                        formControlName="{{mgc.vKIM}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.vKIM}}> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label30}}"
                class="lable_overflow"
            >
                Megnevezés(Cím):
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.vName}}"
                        formControlName="{{mgc.vName}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.vName}}> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label31}}"
                class="lable_overflow"
            >
                Kategória neve:
            </label>
            <div>
                <mat-selection-list
                    multiple
                    formControlName="{{mgc.vCathegory}}"
                    magic="{{mgc.vCathegory}}"
                    [tabIndex]="screenFormGroup.get(mgc.vCathegory).disabled ? -1 : 0"
                >
                    <mat-list-option
                        *ngFor="let o of mg.getItemListValues(mgc.vCathegory);"
                        [value]="o.index"
                    >
                        {{o.displayValue}}
                    </mat-list-option>
                </mat-selection-list>
            </div>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label32}}"
                class="lable_overflow"
            >
                Kiadó:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.vKiado}}"
                        formControlName="{{mgc.vKiado}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.vKiado}}> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label33}}"
                class="lable_overflow"
            >
                ISBN:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.vISBN}}"
                        formControlName="{{mgc.vISBN}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.vISBN}}> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label34}}"
                class="lable_overflow"
            >
                Nyelv:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.vLanguage}}"
                        formControlName="{{mgc.vLanguage}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.vLanguage}}> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label35}}"
                class="lable_overflow"
            >
                Minimum raktárkészlet:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        type='number'
                        magic="{{mgc.vMinCount}}"
                        formControlName="{{mgc.vMinCount}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.vMinCount}}> </mgError>
                </div>
            </mat-form-field>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label36}}"
                class="lable_overflow"
            >
                Státusz:
            </label>
            <div>
                <mat-selection-list
                    multiple
                    formControlName="{{mgc.vStatus}}"
                    magic="{{mgc.vStatus}}"
                    [tabIndex]="screenFormGroup.get(mgc.vStatus).disabled ? -1 : 0"
                >
                    <mat-list-option
                        *ngFor="let o of mg.getItemListValues(mgc.vStatus);"
                        [value]="o.index"
                    >
                        {{o.displayValue}}
                    </mat-list-option>
                </mat-selection-list>
            </div>
        </div>
        <div class="flex-container-row">
            <div>
                <mat-checkbox
                    magic="{{mgc.vVar02}}"
                    formControlName="{{mgc.vVar02}}"
                >
                    vVar02
                </mat-checkbox>
            </div>
        </div>
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label37}}"
                class="lable_overflow"
            >
                Keres:
            </label>
            <button
                mat-raised-button
                color="primary"
                magic="{{mgc.vbtn_search}}"
            >
                {{mg.getValue(mgc.vbtn_search)}}
            </button>
        </div>
    </div>
</div>