import { Component } from "@angular/core";

import { FormGroup } from "@angular/forms";
import {
  MgFormControlsAccessor,
  MgControlName,
} from "./Beszallitovalasztas.mg.controls.g";

import {
  BaseMatTableMagicComponent,
  matMagicProviders,
} from "@magic-xpa/angular-material-core";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "mga-Beszallitovalasztas",
  providers: [...matMagicProviders],
  templateUrl: "./Beszallitovalasztas.component.html",
})
export class Beszallitovalasztas extends BaseMatTableMagicComponent {
  mgc = MgControlName;
  mgfc: MgFormControlsAccessor;
  faWindowClose = faWindowClose;
  createFormControlsAccessor(formGroup: FormGroup) {
    this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
  }

  displayedColumns = [
    "Column10485764",
    "Column10485765",
    "Column10485766",
    "Column10485767",
    "Column10485768",
  ];
}
