import {
  FormControl,
  FormGroup
} from "@angular/forms";
import {
  MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
  ShowImagesTableOvrl = "ShowImagesTableOvrl",
  Table10485761 = "Table10485761",
  Column10485787 = "Column10485787",
  Tipus = "Tipus",
  Column10485763 = "Column10485763",
  fileName = "fileName",
  Column19 = "Column19",
  fileType = "fileType",
  Column10485788 = "Column10485788",
  fileSize = "fileSize",
  Column22 = "Column22",
  fileDescription = "fileDescription",
  Column10485769 = "Column10485769",
  EnterRowEditing = "EnterRowEditing",
  SaveRowEditing = "SaveRowEditing",
  CancelRowEditing = "CancelRowEditing",
  UploadImage = "UploadImage",
  DeleteRecord = "DeleteRecord",
  Column29 = "Column29",
  fileData = "fileData",
  Create = "Create",
  New = "New",
  UjcikkContributors = "UjcikkContributors"
}
export enum MgCustomProperties { }
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) { }

  get Tipus(): FormControl {
    return this.getTableChildFormControl(MgControlName.Tipus);
  }

  get fileName(): FormControl {
    return this.getTableChildFormControl(MgControlName.fileName);
  }

  get fileType(): FormControl {
    return this.getTableChildFormControl(MgControlName.fileType);
  }

  get fileSize(): FormControl {
    return this.getTableChildFormControl(MgControlName.fileSize);
  }

  get fileDescription(): FormControl {
    return this.getTableChildFormControl(MgControlName.fileDescription);
  }

  get fileData(): FormControl {
    return this.getTableChildFormControl(MgControlName.fileData);
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
  }
}
