import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    callLista = "callLista",
        Combo_box4 = "Combo_box4",
        Check_box5 = "Check_box5",
        Check_box8 = "Check_box8",
        Check_box11 = "Check_box11",
        Check_box13 = "Check_box13",
        Check_box6 = "Check_box6",
        Check_box9 = "Check_box9",
        Check_box12 = "Check_box12",
        Check_box14 = "Check_box14",
        Check_box7 = "Check_box7",
        Check_box10 = "Check_box10",
        btn_gZoom = "btn_gZoom",
}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get Combo_box4(): FormControl {
        return this.fg.controls[MgControlName.Combo_box4] as FormControl;
    }

    get Check_box5(): FormControl {
        return this.fg.controls[MgControlName.Check_box5] as FormControl;
    }

    get Check_box8(): FormControl {
        return this.fg.controls[MgControlName.Check_box8] as FormControl;
    }

    get Check_box11(): FormControl {
        return this.fg.controls[MgControlName.Check_box11] as FormControl;
    }

    get Check_box13(): FormControl {
        return this.fg.controls[MgControlName.Check_box13] as FormControl;
    }

    get Check_box6(): FormControl {
        return this.fg.controls[MgControlName.Check_box6] as FormControl;
    }

    get Check_box9(): FormControl {
        return this.fg.controls[MgControlName.Check_box9] as FormControl;
    }

    get Check_box12(): FormControl {
        return this.fg.controls[MgControlName.Check_box12] as FormControl;
    }

    get Check_box14(): FormControl {
        return this.fg.controls[MgControlName.Check_box14] as FormControl;
    }

    get Check_box7(): FormControl {
        return this.fg.controls[MgControlName.Check_box7] as FormControl;
    }

    get Check_box10(): FormControl {
        return this.fg.controls[MgControlName.Check_box10] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}