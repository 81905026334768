<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        class="flex-container-column"
        magic="{{mgc.Logout}}"
    >
        <div class="flex-container-row">
            <label
                magic="{{mgc.Label2}}"
                class="lable_overflow"
            >
                vUser:
            </label>
            <mat-form-field>
                <div>
                    <input
                        matInput
                        magic="{{mgc.vUser}}"
                        formControlName="{{mgc.vUser}}"
                        mgFormat
                    >
                    <mgError magic={{mgc.vUser}}> </mgError>
                </div>
            </mat-form-field>
        </div>
    </div>
</div>