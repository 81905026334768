<div #overlayheader class="urlap, form-style, my-overlay-header">
  <span>{{ mg.getValue("MG_FORMNAME") }}</span>
  <button
    class="input_button"
    magic="{{ mgc.MG_B_QUIT }}"
    [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)"
  >
    <fa-icon [icon]="faWindowClose"></fa-icon>
  </button>
</div>
<div novalidate [formGroup]="screenFormGroup">
  <div class="flex-container-column" magic="{{ mgc.Aruvalasztas }}">
    <!--
    régi kód
    <div #overlayheader class="my-overlay-header">
      <button class="input_button" (click)="OnClose()">
        <fa-icon [icon]="faWindowClose"></fa-icon>
      </button>
      <span *ngIf="showtitle" id="Formname">{{ mg.getValue("MG_FORMNAME") }}</span>
    </div>

    <div #overlayheader class="my-overlay-header">
      <span>{{ mg.getValue("MG_FORMNAME") }}</span>
      <button
        class="input_button"
        magic="{{ mgc.MG_B_QUIT }}"
        [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)"
      >
        <fa-icon [icon]="faWindowClose"></fa-icon>
      </button>
    </div>
    -->
    <div class="flex-container-row">
      <label magic="{{ mgc.Label10485761 }}" class="lable_overflow">
        cikkszam
      </label>
      <label magic="{{ mgc.Label10485762 }}" class="lable_overflow">
        nev
      </label>
      <label magic="{{ mgc.Label10485763 }}" class="lable_overflow">
        beszallito
      </label>
      <label magic="{{ mgc.Label10485764 }}" class="lable_overflow">
        szerzo
      </label>
      <label magic="{{ mgc.Label10485765 }}" class="lable_overflow">
        tematika
      </label>
      <label magic="{{ mgc.Label10485766 }}" class="lable_overflow">
        cikk tipus
      </label>
    </div>
    <div class="flex-container-row">
      <mat-form-field>
        <div>
          <input
            matInput
            type="number"
            magic="{{ mgc.azonosito }}"
            formControlName="{{ mgc.azonosito }}"
            mgFormat
          />
          <mgError magic="{{ mgc.azonosito }}"> </mgError>
        </div>
      </mat-form-field>
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.nev }}"
            formControlName="{{ mgc.nev }}"
            mgFormat
          />
          <mgError magic="{{ mgc.nev }}"> </mgError>
        </div>
      </mat-form-field>
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.beszallito }}"
            formControlName="{{ mgc.beszallito }}"
            mgFormat
          />
          <mgError magic="{{ mgc.beszallito }}"> </mgError>
        </div>
      </mat-form-field>
      <button mat-raised-button color="primary" magic="{{ mgc.B_beszallito }}">
        6
      </button>
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.szerzo }}"
            formControlName="{{ mgc.szerzo }}"
            mgFormat
          />
          <mgError magic="{{ mgc.szerzo }}"> </mgError>
        </div>
      </mat-form-field>
      <button mat-raised-button color="primary" magic="{{ mgc.B_szerzo }}">
        6
      </button>
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.tematika }}"
            formControlName="{{ mgc.tematika }}"
            mgFormat
          />
          <mgError magic="{{ mgc.tematika }}"> </mgError>
        </div>
      </mat-form-field>
      <button mat-raised-button color="primary" magic="{{ mgc.B_tematika }}">
        6
      </button>
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.cikktipus }}"
            formControlName="{{ mgc.cikktipus }}"
            mgFormat
          />
          <mgError magic="{{ mgc.cikktipus }}"> </mgError>
        </div>
      </mat-form-field>
      <button mat-raised-button color="primary" magic="{{ mgc.B_cikktipus }}">
        6
      </button>
      <button
        mat-button
        color="primary"
        magic="{{ mgc.MG_B_SEARCH }}"
        [matTooltip]="mg.getTitle(mgc.MG_B_SEARCH)"
        class="button_image_background_size"
        [ngStyle]="{
          'background-image': mg.getStyle(
            mgc.MG_B_SEARCH,
            magicProperties.ImageFile
          )
        }"
      >
        {{ mg.getValue(mgc.MG_B_SEARCH) }}
      </button>
    </div>
    <div class="flex-container-row">
      <label magic="{{ mgc.Label10485773 }}" class="lable_overflow">
        ISBN
      </label>
    </div>
    <div class="flex-container-row">
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.isbn_0001 }}"
            formControlName="{{ mgc.isbn_0001 }}"
            mgFormat
          />
          <mgError magic="{{ mgc.isbn_0001 }}"> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <mat-card>
        <magic-subform magic="{{ mgc.Aru_valasztas }}"> </magic-subform>
      </mat-card>
    </div>
  </div>
</div>
