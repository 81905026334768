import { FormControl, FormGroup } from "@angular/forms";
import { MagicServices } from "@magic-xpa/angular";
export enum MgControlName {
  Aruvalasztas2 = "Aruvalasztas2",
  Label10485761 = "Label10485761",
  Label10485762 = "Label10485762",
  Label10485763 = "Label10485763",
  Label10485764 = "Label10485764",
  Label10485765 = "Label10485765",
  Label10485766 = "Label10485766",
  azonosito = "azonosito",
  nev = "nev",
  beszallito = "beszallito",
  beszallitovg = "beszallitovg",
  szerzo = "szerzo",
  szerzovg = "szerzovg",
  tematika = "tematika",
  tematikavg = "tematikavg",
  cikktipus = "cikktipus",
  cikktipusvg = "cikktipusvg",
  MG_B_SEARCH = "MG_B_SEARCH",
  Label10485773 = "Label10485773",
  isbn_0001 = "isbn_0001",
  Aru_valasztas = "Aru_valasztas",
  MG_FORMNAME = "MG_FORMNAME",
  MG_B_QUIT="MG_B_QUIT",
}
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) {}

  get azonosito(): FormControl {
    return this.fg.controls[MgControlName.azonosito] as FormControl;
  }

  get nev(): FormControl {
    return this.fg.controls[MgControlName.nev] as FormControl;
  }

  get beszallito(): FormControl {
    return this.fg.controls[MgControlName.beszallito] as FormControl;
  }

  get szerzo(): FormControl {
    return this.fg.controls[MgControlName.szerzo] as FormControl;
  }

  get tematika(): FormControl {
    return this.fg.controls[MgControlName.tematika] as FormControl;
  }

  get cikktipus(): FormControl {
    return this.fg.controls[MgControlName.cikktipus] as FormControl;
  }

  get isbn_0001(): FormControl {
    return this.fg.controls[MgControlName.isbn_0001] as FormControl;
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(
      this.magicServices.tableService.getSelectedRow()
    ).controls[name] as FormControl;
  }
}
