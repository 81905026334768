import { FormControl, FormGroup } from "@angular/forms";
import { MagicServices } from "@magic-xpa/angular";
export enum MgControlName {
  tmp_control = "tmp_control",
  ForgKeszlSF = "ForgKeszlSF",
  Edit10485761 = "Edit10485761",
  BeszallitoSF = "BeszallitoSF",
  ListaSF = "ListaSF",
  Group10485774 = "Group10485774",
  Label10485775 = "Label10485775",
  aruNevSzures = "aruNevSzures",
  Label10485777 = "Label10485777",
  isbn = "isbn",
  Label10485786 = "Label10485786",
  kiadasEveTol = "kiadasEveTol",
  Label10485784 = "Label10485784",
  kiadasEveIg = "kiadasEveIg",
  Label10485781 = "Label10485781",
  ElsoBeszalltol_0001 = "ElsoBeszalltol_0001",
  Label10485785 = "Label10485785",
  ElsoBeszalltig_0001 = "ElsoBeszalltig_0001",
  Label10485787 = "Label10485787",
  szerzo = "szerzo",
  szerzovg = "szerzovg",
  Label10485789 = "Label10485789",
  kiado = "kiado",
  kiadovg = "kiadovg",
  Label10485791 = "Label10485791",
  sorozat = "sorozat",
  sorozatvg = "sorozatvg",
  Label10485793 = "Label10485793",
  kategoriak = "kategoriak",
  katvg = "katvg",
  frissitvg = "frissitvg",
  hozzaad = "hozzaad",
  SelectedCountTmp = "SelectedCountTmp",
  valaszttmpvg = "valaszttmpvg",
  SelectedCount = "SelectedCount",
  valasztvg = "valasztvg",
  Label10485806 = "Label10485806",
  Group10485811 = "Group10485811",
  Label10485813 = "Label10485813",
  Label10485762 = "Label10485762",
  Label10485764 = "Label10485764",
  Label10485766 = "Label10485766",
  tmpidMain = "tmpidMain",
  tmpidTmp = "tmpidTmp",
  Label10485770 = "Label10485770",
  tmpidCurr = "tmpidCurr",
  csaklista = "csaklista",
  CikkSF = "CikkSF",
  Boltgrp = "Boltgrp",
  Label10485768 = "Label10485768",
  multiselect = "multiselect",
  boltAzon = "boltAzon",
  boltNev = "boltNev",
  boltvg = "boltvg",
  Group10485773 = "Group10485773",
  EgyszeruOsszetett = "EgyszeruOsszetett",
  BontasTipus = "BontasTipus",
  beagyazott = "beagyazott",
  evhonap = "evhonap",
  bolt = "bolt",
  beszallito = "beszallito",
  cikk = "cikk",
  mennyiseg = "mennyiseg",
  eladasiAr = "eladasiAr",
  listaar = "listaar",
  beszerzesiAr = "beszerzesiAr",
  Keszlet = "Keszlet",
  NullasKeszlet = "NullasKeszlet",
  Label10485761 = "Label10485761",
  Datumtol = "Datumtol",
  Label10485763 = "Label10485763",
  Datumig = "Datumig",
  Lekerdezes = "Lekerdezes",
  Excel = "Excel",
  MG_FORMNAME = "MG_FORMNAME",
  mg_formname_CP = "mg_formname_CP",

  Sablon = "Sablon",
  Ujragyujt = "Ujragyujt",

  Sablonment = "Sablonment",
  megjegyzes = "megjegyzes",
  nev = "nev",
  Ujcikk = "Ujcikk",
  Label117 = "Label117",
  pubtn = "pubtn",
  puref = "puref",
  puname = "puname",
  Label117a = "Label117a",
  publisherBTN = "publisherBTN",
  publisherRef = "publisherRef",
  publisherName = "publisherName",
  Label64 = "Label64",
  isbnis13 = "isbnis13",
  isbn13 = "isbn13",
  Label65 = "Label65",
  barcode = "barcode",
  Label67 = "Label67",
  title = "title",
  Label68 = "Label68",
  subTitle = "subTitle",
  Label69 = "Label69",
  originalTitle = "originalTitle",
  Label70 = "Label70",
  publicTitle = "publicTitle",
  Label71 = "Label71",
  languages = "languages",
  Label72 = "Label72",
  publisher = "publisher",
  Label73 = "Label73",
  publishingPlace = "publishingPlace",
  Label74 = "Label74",
  publishingYear = "publishingYear",
  Label75 = "Label75",
  publishingDate = "publishingDate",
  Label76 = "Label76",
  editionNumber = "editionNumber",
  Label77 = "Label77",
  seriesEnabled = "seriesEnabled",
  seriesEnabled2 = "seriesEnabled2",
  Label78 = "Label78",
  seriesTitle = "seriesTitle",
  seriesTitle2 = "seriesTitle2",
  Label79 = "Label79",
  issn = "issn",
  Label80 = "Label80",
  volumeNumber = "volumeNumber",
  Label81 = "Label81",
  Radio_contributor = "Radio_contributor",
  Label82 = "Label82",
  contributors = "contributors",
  contributorsForm = "contributorsForm",
  DiscountCategory = "DiscountCategory",
  productType = "productType",
  Label66 = "Label66",
  customsTariffNumber = "customsTariffNumber",
  productTab = "productTab",
  Label84 = "Label84",
  productFormCode = "productFormCode",
  Label85 = "Label85",
  numberOfPages = "numberOfPages",
  Label86 = "Label86",
  productFormDetail = "productFormDetail",
  Label87 = "Label87",
  bookwidth = "bookwidth",
  Label88 = "Label88",
  bookheight = "bookheight",
  Label89 = "Label89",
  bookthickness = "bookthickness",
  Label90 = "Label90",
  bookweight = "bookweight",
  illustrations = "illustrations",
  Label92 = "Label92",
  numberOfIllustrations = "numberOfIllustrations",
  Label93 = "Label93",
  audioBookType = "audioBookType",
  Label94 = "Label94",
  numberOfDiscs = "numberOfDiscs",
  Label95 = "Label95",
  sizeOfAudioBook = "sizeOfAudioBook",
  Label96 = "Label96",
  duration = "duration",
  Label97 = "Label97",
  primarySubject = "primarySubject",
  Label98 = "Label98",
  subjects = "subjects",
  Label99b = "Label99b",
  PrimarySubj = "PrimarySubj",
  Label110 = "Label110",
  oldCategory = "oldCategory",
  Label99i = "Label99i",
  interests = "interests",
  Label100 = "Label100",
  educations = "educations",
  Label101p = "Label101p",
  price = "price",
  Label102 = "Label102",
  taxRate = "taxRate",
  Label103 = "Label103",
  miscIdentifier = "miscIdentifier",
  blurTabControl = "blurTabControl",
  Label10485877 = "Label10485877",
  blurb = "blurb",
  Label101b = "Label101b",
  blurb2 = "blurb2",
  btnEditBlurb2 = "btnEditBlurb2",
  Label105 = "Label105",
  slogan = "slogan",
  Label106 = "Label106",
  links = "links",
  linksForm = "linksForm",
  imagesForm = "imagesForm",
  Label108 = "Label108",
  Label109 = "Label109",
  cTree2 = "cTree2",
  coverFile = "coverFile",
  samplePageFile = "samplePageFile",
  BTNSEND = "BTNSEND",
  Label104 = "Label104",

  EANgeneralas="EANgeneralas",
}
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) {}

  get Edit10485761(): FormControl {
    return this.fg.controls[MgControlName.Edit10485761] as FormControl;
  }

  get aruNevSzures(): FormControl {
    return this.fg.controls[MgControlName.aruNevSzures] as FormControl;
  }

  get isbn(): FormControl {
    return this.fg.controls[MgControlName.isbn] as FormControl;
  }

  get kiadasEveTol(): FormControl {
    return this.fg.controls[MgControlName.kiadasEveTol] as FormControl;
  }

  get kiadasEveIg(): FormControl {
    return this.fg.controls[MgControlName.kiadasEveIg] as FormControl;
  }

  get ElsoBeszalltol_0001(): FormControl {
    return this.fg.controls[MgControlName.ElsoBeszalltol_0001] as FormControl;
  }

  get ElsoBeszalltig_0001(): FormControl {
    return this.fg.controls[MgControlName.ElsoBeszalltig_0001] as FormControl;
  }

  get szerzo(): FormControl {
    return this.fg.controls[MgControlName.szerzo] as FormControl;
  }

  get kiado(): FormControl {
    return this.fg.controls[MgControlName.kiado] as FormControl;
  }

  get sorozat(): FormControl {
    return this.fg.controls[MgControlName.sorozat] as FormControl;
  }

  get kategoriak(): FormControl {
    return this.fg.controls[MgControlName.kategoriak] as FormControl;
  }

  get hozzaad(): FormControl {
    return this.fg.controls[MgControlName.hozzaad] as FormControl;
  }

  get SelectedCountTmp(): FormControl {
    return this.fg.controls[MgControlName.SelectedCountTmp] as FormControl;
  }

  get SelectedCount(): FormControl {
    return this.fg.controls[MgControlName.SelectedCount] as FormControl;
  }

  get boltAzon(): FormControl {
    return this.fg.controls[MgControlName.boltAzon] as FormControl;
  }

  get boltNev(): FormControl {
    return this.fg.controls[MgControlName.boltNev] as FormControl;
  }

  get BontasTipus(): FormControl {
    return this.fg.controls[MgControlName.BontasTipus] as FormControl;
  }

  get evhonap(): FormControl {
    return this.fg.controls[MgControlName.evhonap] as FormControl;
  }

  get bolt(): FormControl {
    return this.fg.controls[MgControlName.bolt] as FormControl;
  }

  get beszallito(): FormControl {
    return this.fg.controls[MgControlName.beszallito] as FormControl;
  }

  get cikk(): FormControl {
    return this.fg.controls[MgControlName.cikk] as FormControl;
  }

  get mennyiseg(): FormControl {
    return this.fg.controls[MgControlName.mennyiseg] as FormControl;
  }

  get eladasiAr(): FormControl {
    return this.fg.controls[MgControlName.eladasiAr] as FormControl;
  }

  get listaar(): FormControl {
    return this.fg.controls[MgControlName.listaar] as FormControl;
  }

  get beszerzesiAr(): FormControl {
    return this.fg.controls[MgControlName.beszerzesiAr] as FormControl;
  }

  get Keszlet(): FormControl {
    return this.fg.controls[MgControlName.Keszlet] as FormControl;
  }

  get NullasKeszlet(): FormControl {
    return this.fg.controls[MgControlName.NullasKeszlet] as FormControl;
  }

  get Datumtol(): FormControl {
    return this.fg.controls[MgControlName.Datumtol] as FormControl;
  }

  get Datumig(): FormControl {
    return this.fg.controls[MgControlName.Datumig] as FormControl;
  }

  get tmpidMain(): FormControl {
    return this.fg.controls[MgControlName.tmpidMain] as FormControl;
  }

  get tmpidTmp(): FormControl {
    return this.fg.controls[MgControlName.tmpidTmp] as FormControl;
  }

  get tmpidCurr(): FormControl {
    return this.fg.controls[MgControlName.tmpidCurr] as FormControl;
  }

  get csaklista(): FormControl {
    return this.fg.controls[MgControlName.csaklista] as FormControl;
  }

  get beagyazott(): FormControl {
    return this.fg.controls[MgControlName.beagyazott] as FormControl;
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(
      this.magicServices.tableService.getSelectedRow()
    ).controls[name] as FormControl;
  }
}
