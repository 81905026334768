import { FormControl, FormGroup } from "@angular/forms";
import { MagicServices } from "@magic-xpa/angular";
export enum MgControlName {
  Boltvalasztas = "Boltvalasztas",
  Table10485761 = "Table10485761",
  Column10485762 = "Column10485762",
  azonosito = "azonosito",
  Column10485763 = "Column10485763",
  bolt_nev = "bolt_nev",
  Column10485764 = "Column10485764",
  irsz = "irsz",
  Column10485765 = "Column10485765",
  varos = "varos",
  Column10485766 = "Column10485766",
  cim = "cim",
  Column10485767 = "Column10485767",
  kivalasztva = "kivalasztva",
  KivalasztNYG = "KivalasztNYG",
  MegsemNYG = "MegsemNYG",
  MG_FORMNAME = "MG_FORMNAME",
  MG_B_QUIT="MG_B_QUIT",
}
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) {}

  get azonosito(): FormControl {
    return this.getTableChildFormControl(MgControlName.azonosito);
  }

  get bolt_nev(): FormControl {
    return this.getTableChildFormControl(MgControlName.bolt_nev);
  }

  get irsz(): FormControl {
    return this.getTableChildFormControl(MgControlName.irsz);
  }

  get varos(): FormControl {
    return this.getTableChildFormControl(MgControlName.varos);
  }

  get cim(): FormControl {
    return this.getTableChildFormControl(MgControlName.cim);
  }

  get kivalasztva(): FormControl {
    return this.getTableChildFormControl(MgControlName.kivalasztva);
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(
      this.magicServices.tableService.getSelectedRow()
    ).controls[name] as FormControl;
  }
}
