<div novalidate [formGroup]="screenFormGroup">
  <div class="flex-container-column" magic="{{ mgc.Aruvalasztas2 }}">
    <div class="flex-container-row">
      <mat-card magic="{{ mgc.Group10485767 }}" class="container_border">
        <div class="flex-container-row">
          <!--vezérlés, keresés az ürlapon-->
          <mat-form-field class="MG_D_SEARCH-form-field" appearance="fill">
            <div>
              <mat-label></mat-label>
              <input matInput type="text" style="width:90%" magic="{{ mgc.MG_D_SEARCH }}" placeholder="Keresés"
                formControlName="{{ mgc.MG_D_SEARCH }}" mgFormat (keyup.enter)="onEnter()" [(ngModel)]="MG_D_SEARCH" />
              <button *ngIf="MG_D_SEARCH" matSuffix mat-icon-button aria-label="Clear" style="width:10%"
                (click)="MG_D_SEARCH=''">
                <mat-icon>close</mat-icon>
              </button>
              <mgError magic="{{ mgc.MG_D_SEARCH }}"> </mgError>
            </div>
          </mat-form-field>
          <div>
            <mat-checkbox magic="{{ mgc.MG_CB_SELECTED }}" [style.visibility]="mg.getVisible(mgc.MG_CB_SELECTED)"
              formControlName="{{ mgc.MG_CB_SELECTED }}">
              Kiválasztottak
            </mat-checkbox>
          </div>
          <button mat-raised-button color="primary" magic="{{ mgc.MG_B_SEARCH2DEF }}"
            [matTooltip]="mg.getTitle(mgc.MG_B_SEARCH2DEF)">
            x
          </button>
          <button mat-raised-button color="primary" magic="{{ mgc.MG_B_SEARCH }}"
            [matTooltip]="mg.getTitle(mgc.MG_B_SEARCH)">
            Keres
          </button>
        </div>
      </mat-card>
    </div>


    <div class="flex-container-row" style="width: 100%">
      <div class="example-container mat-elevation-z8 MG_T01TableContainerProps" style="width: 100%">
        <mat-table #table [dataSource]="dataSource" class="container_border" matSort matSortDisableClear
          (matSortChange)="tableService.sortData($event)">
          <ng-container *ngFor="let column of columns; let i = index" magic="{{ mgc[column.field] }}"
            [matColumnDef]="column.field">
            <mat-header-cell *matHeaderCellDef cdkDropList cdkDropListLockAxis="x" cdkDropListOrientation="horizontal"
              (cdkDropListDropped)="dropListDropped($event, i)" cdkDrag (cdkDragStarted)="dragStarted($event, i)"
              [cdkDragData]="{ name: column.field, columIndex: i }" mat-sort-header
              [style.visibility]="mg.getVisible(mgc[column.field])">
              <ng-container *ngIf="column.name != 'Kiválaszt'">
                {{ column.name }}
              </ng-container>
              <ng-container *ngIf="column.name == 'Kiválaszt'">
                <mat-checkbox magic="{{ mgc.MG_FD001 }}" [style.visibility]="mg.getVisible(mgc.MG_FD001)"
                  formControlName="{{ mgc.MG_FD001 }}" style="margin: 12px">
                  <label magic="{{ mgc.MG_FD002 }}">
                    {{ mg.getValue(mgc.MG_FD002) }}
                  </label>
                </mat-checkbox>
              </ng-container>
            </mat-header-cell>
            <mat-cell *matCellDef="let row" [style.visibility]="mg.getVisible(mgc[column.field])"
              magicMark="magicTableRowContainer">
              <div class="flex-container-row" *ngIf="mg.ifRowCreated(row)">
                <div [formGroup]="mg.getFormGroupByRow(row.rowId)" class="flex-container-row">
                  <label *ngIf="column.name != 'Kiválaszt'" magic="{{ mgc[column.mezo] }}" [rowId]="row.rowId" class="{{
                      'altipus_' +
                        mg.getCustomProperty(mgc.NEV, 'altipus', row.rowId)
                    }}">
                    {{ mg.getValue(mgc[column.mezo], row.rowId) }}
                  </label>
                  <mat-checkbox *ngIf="column.name == 'Kiválaszt'" magic="{{ mgc[column.mezo] }}" [rowId]="row.rowId"
                    formControlName="{{ mgc[column.mezo] }}" style="margin: 12px">
                  </mat-checkbox>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"
          (page)="tableService.mgOnPaginateChange($event)">
        </mat-paginator>
      </div>
    </div>


    <div class="flex-container-row">
      <label magic="{{ mgc.MG_FL004 }}" class="lable_overflow">
        Tematika
      </label>
      <mat-form-field>
        <div>
          <input matInput magic="{{ mgc.MG_FD004 }}" formControlName="{{ mgc.MG_FD004 }}" mgFormat />
          <mgError magic="{{ mgc.MG_FD004 }}"> </mgError>
        </div>
      </mat-form-field>
      <label magic="{{ mgc.MG_FL003 }}" class="lable_overflow">
        Egyéb azonosító
      </label>
      <mat-form-field>
        <div>
          <input matInput magic="{{ mgc.MG_FD003 }}" formControlName="{{ mgc.MG_FD003 }}" mgFormat />
          <mgError magic="{{ mgc.MG_FD003 }}"> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <button mat-raised-button color="primary" magic="{{ mgc.MG_B_SELECT }}"
        [matTooltip]="mg.getTitle(mgc.MG_B_SELECT)">
        {{ mg.getValue(mgc.MG_B_SELECT) }}
      </button>
      <button mat-raised-button color="primary" [magic]="mgc.MG_B_KIADVANY"
        [style.visibility]="mg.getVisible(mgc.MG_B_KIADVANY)" [disabled]="mg.isDisabled(mgc.MG_B_KIADVANY )">
        Kiadvány adatok
      </button>
      <button mat-raised-button color="primary" magic="{{ mgc.MG_B_QUIT }}" [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)">
        Mégsem
      </button>
    </div>
  </div>
</div>