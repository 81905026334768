import { Component } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
  MgFormControlsAccessor,
  MgControlName,
} from "./ForgKeszlSF.mg.controls.g";
import { TaskBaseMagicComponent, magicProviders } from "@magic-xpa/angular";
import {
  faBook,
  faUser,
  faWrench,
  faStream,
  faTag,
  faGraduationCap,
  faChartPie,
  faUpload,
  faCaretDown,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "mga-ForgKeszlSF",
  providers: [...magicProviders],
  templateUrl: "./ForgKeszlSF.component.html",
  styleUrls: ["./CikkKereses.scss"],
})
export class ForgKeszlSF extends TaskBaseMagicComponent {
  mgc = MgControlName;
  mgfc: MgFormControlsAccessor;

  faBook = faBook;
  faUser = faUser;
  faWrench = faWrench;
  faStream = faStream;
  faTag = faTag;
  faGraduationCap = faGraduationCap;
  faChartPie = faChartPie;
  faUpload = faUpload;
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;

  createFormControlsAccessor(formGroup: FormGroup) {
    this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
  }

  onEnter(id:string) {
    var button = document.getElementById(id+"_zoom") as HTMLInputElement;
    button.click();
    //console.log(id);
  }

  element: HTMLInputElement;
  refresh(id: string, magic): void {
    this.element = document.getElementById(id) as HTMLInputElement;
    this.mg.setValueToControl(magic, this.element.value, true);
    //console.log("NG:"+this.element.value);
    //console.log("MG:"+this.mg.getValue(this.mgc.ISBN));
    //console.log("MG_new:"+this.mg.getValue(magic));
    console.log("futtatas");
  }

  public get_mgparameter(id: string): string {
    //return this.mg.getCustomProperty(this.mgc.Ujcikk,'tree');
    return this.mg.getCustomProperty(this.mgc.ForgKeszlSF, id);
  }

  //tree01=this.mg.getCustomProperty('Ujcikk','TREE01');//this.TREE_DATA;//
  public mask_10 = {
    guide: true,
    showMask: true,
    mask: [
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
    ],
  };
  public mask_13 = {
    guide: true,
    showMask: true,
    mask: [
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
    ],
  };
  public mask = this.mask_10;

  public treerefresh(id = ""): void {
    //csak kompatibilitás miatt
  }

  public checkValue(event: any) {
    console.log(event);
  }

  public onChecked(obj: any, isChecked: boolean) {
    console.log(obj, isChecked); // {}, true || false
  }

  public contributorsArray: Number[] = [];
  public contributorsType: string[] = [
    "Szerző|A01",
    "Forgatókönyv|A03",
    "Librettó|A04",
    "Tervező, designer|A11",
    "Illusztrátor|A12",
    "Fényképész, fotós|A13",
    "Előszó szerzője|A15",
    "Utószó szerzője|A19",
    "Jegyzetek|A20",
    "Előszó szerzője|A23",
    "Bevezető szerzője|A24",
    "Bevezetés és jegyzetek|A29",
    "Függelék|A33",
    "Rajz|A35",
    "Borítótervező|A36",
    "Térkép|A39",
    "Riporter|A43",
    "Szerkesztő|B01",
    "Lektor|B02",
    "Adaptáció készítője|B05",
    "Fordító|B06",
    "Sorozatszerkesztő|B09",
    "Szerkesztette és fordította|B10",
    "Főszerkesztő|B11",
    "Felelős szerkesztő|B16",
    "Sajtó alá rendezte|B18",
    "Összeállította|C01",
    "Válogatás|C02",
    "Producer|D01",
    "Rendezte|D02",
    "Felolvasó|E07",
    "Közreműködő|Z99",
  ];

  public csakszamok = {
    "0": { pattern: new RegExp("[0-9]") },
    A: { pattern: new RegExp("[0-9a-zA-Z]") },
  };

  public contributorsAdd() {
    this.contributorsArray.push(this.contributorsArray.length + 1);
    //console.log("szerző hozzáadása");
  }
  public contributorsClick() {
    let result: string[] = [];
    let contributorName: string;
    let contributorType: string;

    this.contributorsArray.forEach((element) => {
      contributorName = (<HTMLInputElement>(
        document.getElementById("contributorName" + element)
      )).value;
      contributorType = (<HTMLInputElement>(
        document.getElementById("contributorType" + element)
      )).value;
      result.push(contributorName + contributorType);
    });

    //(<HTMLInputElement>document.getElementById("contributors")).value=result;
    let contributors: HTMLInputElement; // = document.getElementById("contributors");
    //this.contributors=document.getElementById("contributors");
    //console.log(result);
  }

  public sectionClick(i: number, label?: string) {
    if(label!=='Excel') this.form_array[i].collapsed = !this.form_array[i].collapsed;
    //this.treerefresh();
  }

  public theCheckbox = true;

  public form_array = [
    {
      //s01: alapadatok
      id: "section_01",
      type: "section", //olyan tipus ami több elemett csoportosít
      icon: this.faBook,
      label: "Paraméterek|", //elem kiírás szövege
      perm: "",
      collapsed: false,
      description: "",
      magic: "",
      value: [
        {
          //Beszállító
          id: "section_01_01",
          type: "section", //olyan tipus ami több elemett csoportosít
          label: "Beszállító", //elem kiírás szövege
          perm: "",
          description: "",
          magic: "",
          icon: "",
          value: [
            {
              id: "section_01_01_01",
              type: "subform", //"beszallito",
              label: "Beszállító", //this.mg.getValue(this.mgc.Edit1),
              perm: "",
              description: "",
              magic: this.mgc.BeszallitoSF,
              icon: "",
              value: "",
              visible: "visible",
              disable: "",
            },
          ],
        },
        {
          //Cikk
          id: "section_01_02",
          type: "section", //olyan tipus ami több elemett csoportosít
          label: "Cikk", //elem kiírás szövege
          perm: "",
          description: "",
          magic: "",
          icon: "",
          value: [
            {
              id: "section_01_02_01",
              type: "text",
              label: "Cím",
              perm: "",
              description: "",
              magic: this.mgc.aruNevSzures,
              icon: "",
              value: "",
              visible: "visible",
              disable: "",
            },
            {
              id: "section_01_02_07",
              type: "text_zoom",
              label: "Kiadó",
              perm: "",
              description: "",
              magic: this.mgc.kiado,
              magic_zoom: this.mgc.kiadovg,
              icon: "",
              value: "",
              visible: "visible",
              disable: "",
            },
            {
              id: "section_01_02_08",
              type: "text_zoom",
              label: "Szerző",
              perm: "",
              description: "",
              magic: this.mgc.szerzo,
              magic_zoom: this.mgc.szerzovg,
              icon: "",
              value: "",
              visible: "visible",
              disable: "",
            },
            {
              id: "section_01_02_09",
              type: "text_zoom",
              label: "Sorozat",
              perm: "",
              description: "",
              magic: this.mgc.sorozat,
              magic_zoom: this.mgc.sorozatvg,
              icon: "",
              value: "",
              visible: "visible",
              disable: "",
            },
            {
              id: "section_01_02_10",
              type: "text_zoom",
              label: "Kategóriák",
              perm: "",
              description: "",
              magic: this.mgc.kategoriak,
              magic_zoom: this.mgc.katvg,
              icon: "",
              value: "",
              visible: "visible",
              disable: "",
            },
            {
              id: "section_01_02_02",
              type: "text",
              label: "ISBN",
              perm: "",
              description: "",
              magic: this.mgc.isbn,
              icon: "",
              value: "",
              visible: "visible",
              disable: "",
            },
            {
              id: "section_01_02_03",
              type: "select_between",
              label: "Állapot",
              perm: "",
              description: "",
              magic: ["allapot", "allapotig"],
              icon: "",
              value: "",
              visible: "visible",
              disable: "",
            },
            {
              id: "section_01_02_04",
              type: "number_between",
              label: "Kiadás éve",
              perm: "",
              description: "",
              magic: ["kiadasEveTol", "kiadasEveIg"], //this.mgc.kiadasEveTol,//this.mgc.kiadasEveIg,
              icon: "",
              value: "",
              visible: "visible",
              disable: "",
            },
            {
              id: "section_01_02_05",
              type: "date_between",
              label: "Első beszállítás",
              perm: "",
              description: "",
              magic: ["ElsoBeszalltol_0001", "ElsoBeszalltig_0001"],
              icon: "",
              value: "",
              visible: "visible",
              disable: "",
            },
            {
              id: "section_02_02_06",
              type: "select_mat", //becsukja az ablakot
              label: "Kiadvány adat",
              perm: "",
              description: "",
              magic: this.mgc.CBVanKiadv,
              icon: "",
              value: this.mgc.CBVanKiadv,
              visible: this.mgc.CBVanKiadv,
              disable: this.mgc.CBVanKiadv,
            },
            {
              id: "section_01_02_11",
              type: "cikk_kezelo",
              label: "",
              perm: "",
              description: "",
              magic: "",
              icon: "",
              value: "",
              visible: "visible",
              disable: "",
            },
          ],
        },
      ],
    },
  ];

  get_text(x: string, y: number): string {
    //szövegek szétszedése a | karakter mentén, ezzel a főszöveg és a hozzátartozó leírás külön kezelhető
    return x.split("|")[y];
  }
  getCollapsed(x: string): boolean {
    //összezártság lekérdezése
    return this.form_array[x].collapsed;
  }
}
