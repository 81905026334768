import { FormControl, FormGroup } from "@angular/forms";
import { MagicServices } from "@magic-xpa/angular";
export enum MgControlName {
  StartProgram = "StartProgram",
  gUserName = "gUserName",
  MainOutlet = "MainOutlet",
  btn_gZoom = "btn_gZoom",
  Appname = "Appname"
}
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) {}

  get gUserName(): FormControl {
    return this.fg.controls[MgControlName.gUserName] as FormControl;
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(
      this.magicServices.tableService.getSelectedRow()
    ).controls[name] as FormControl;
  }
}
