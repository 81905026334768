<div novalidate [formGroup]="screenFormGroup">
  <div class="flex-container-column" magic="{{ mgc.Naptar }}">
    <div #overlayheader class="my-overlay-header">
      <span>{{ mg.getValue("MG_FORMNAME") }}</span>
      <button
        class="input_button"
        magic="{{ mgc.MG_B_QUIT }}"
        [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)"
      >
        <fa-icon [icon]="faWindowClose"></fa-icon>
      </button>
    </div>
  </div>
</div>
