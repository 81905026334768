<div novalidate [formGroup]="screenFormGroup">
  <div class="flex-container-column" magic="{{mgc.Keszlet}}">
    <div class="flex-container-row">
      <label magic="{{mgc.Label2}}" class="lable_overflow">
        Beszallito:
      </label>
      <mat-form-field>
        <div>
          <input matInput type='number' magic="{{mgc.beszallito}}" formControlName="{{mgc.beszallito}}" mgFormat>
          <mgError magic={{mgc.beszallito}}> </mgError>
        </div>
      </mat-form-field>
      <mat-form-field>
        <div>
          <input matInput magic="{{mgc.HNEV}}" formControlName="{{mgc.HNEV}}" mgFormat>
          <mgError magic={{mgc.HNEV}}> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <label magic="{{mgc.Label4}}" class="lable_overflow">
        Konyv:
      </label>
      <mat-form-field>
        <div>
          <input matInput type='number' magic="{{mgc.Aru_recno}}" formControlName="{{mgc.Aru_recno}}" mgFormat>
          <mgError magic={{mgc.Aru_recno}}> </mgError>
        </div>
      </mat-form-field>
      <mat-form-field>
        <div>
          <input matInput magic="{{mgc.aru_nev}}" formControlName="{{mgc.aru_nev}}" mgFormat>
          <mgError magic={{mgc.aru_nev}}> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <label magic="{{mgc.Label7}}" class="lable_overflow">
        aru nev:
      </label>
      <mat-form-field>
        <div>
          <input matInput magic="{{mgc.aru_nev_szures}}" formControlName="{{mgc.aru_nev_szures}}" mgFormat>
          <mgError magic={{mgc.aru_nev_szures}}> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <label magic="{{mgc.Label11}}" class="lable_overflow">
        isbn:
      </label>
      <mat-form-field>
        <div>
          <input matInput magic="{{mgc.isbn}}" formControlName="{{mgc.isbn}}" mgFormat>
          <mgError magic={{mgc.isbn}}> </mgError>
        </div>
      </mat-form-field>
      <label magic="{{mgc.Label13}}" class="lable_overflow">
        kiadas eve:
      </label>
      <mat-form-field>
        <div>
          <input matInput magic="{{mgc.kiadas_eve_tol}}" formControlName="{{mgc.kiadas_eve_tol}}" mgFormat>
          <mgError magic={{mgc.kiadas_eve_tol}}> </mgError>
        </div>
      </mat-form-field>
      <mat-form-field>
        <div>
          <input matInput magic="{{mgc.kiadas_eve_ig}}" formControlName="{{mgc.kiadas_eve_ig}}" mgFormat>
          <mgError magic={{mgc.kiadas_eve_ig}}> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <label magic="{{mgc.Label14}}" class="lable_overflow">
        szerzo:
      </label>
      <mat-form-field>
        <div>
          <input matInput magic="{{mgc.szerzo}}" formControlName="{{mgc.szerzo}}" mgFormat>
          <mgError magic={{mgc.szerzo}}> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <label magic="{{mgc.Label16}}" class="lable_overflow">
        kiado:
      </label>
      <mat-form-field>
        <div>
          <input matInput magic="{{mgc.kiado}}" formControlName="{{mgc.kiado}}" mgFormat>
          <mgError magic={{mgc.kiado}}> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <label magic="{{mgc.Label18}}" class="lable_overflow">
        sorozat:
      </label>
      <mat-form-field>
        <div>
          <input matInput magic="{{mgc.sorozat}}" formControlName="{{mgc.sorozat}}" mgFormat>
          <mgError magic={{mgc.sorozat}}> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <label magic="{{mgc.Label20}}" class="lable_overflow">
        kategoriak:
      </label>
      <mat-form-field>
        <div>
          <input matInput magic="{{mgc.kategoriak}}" formControlName="{{mgc.kategoriak}}" mgFormat>
          <mgError magic={{mgc.kategoriak}}> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <label magic="{{mgc.Label22}}" class="lable_overflow">
        bolt tipus:
      </label>
      <div>
        <mat-form-field>
          <mat-select magic="{{mgc.bolt_tipus}}" formControlName="{{mgc.bolt_tipus}}">
            <mat-option *ngFor="let o of mg.getItemListValues(mgc.bolt_tipus);" [value]="o.index">
              {{o.displayValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-checkbox magic="{{mgc.nagyker}}" formControlName="{{mgc.nagyker}}">
          nagyker
        </mat-checkbox>
      </div>
      <div>
        <mat-checkbox magic="{{mgc.online}}" formControlName="{{mgc.online}}">
          online
        </mat-checkbox>
      </div>
      <div>
        <mat-checkbox magic="{{mgc.nemonline}}" formControlName="{{mgc.nemonline}}">
          nemonline?
        </mat-checkbox>
      </div>
      <div>
        <mat-form-field>
          <mat-select magic="{{mgc.LiraLira_kiskerRozsavolgyi}}" formControlName="{{mgc.LiraLira_kiskerRozsavolgyi}}">
            <mat-option *ngFor="let o of mg.getItemListValues(mgc.LiraLira_kiskerRozsavolgyi);" [value]="o.index">
              {{o.displayValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="flex-container-row">
      <label magic="{{mgc.Label23}}" class="lable_overflow">
        bolt
      </label>
      <mat-form-field>
        <div>
          <input matInput magic="{{mgc.bolt}}" formControlName="{{mgc.bolt}}" mgFormat>
          <mgError magic={{mgc.bolt}}> </mgError>
        </div>
      </mat-form-field>
      <mat-form-field>
        <div>
          <input matInput magic="{{mgc.bolt_nev}}" formControlName="{{mgc.bolt_nev}}" mgFormat>
          <mgError magic={{mgc.bolt_nev}}> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <mat-card magic="{{mgc.Group44}}" class="container_border">
        <div class="flex-container-row">
          <div>
            <mat-checkbox magic="{{mgc.bolt_0001}}" formControlName="{{mgc.bolt_0001}}">
              bolt?
            </mat-checkbox>
          </div>
          <div>
            <mat-checkbox magic="{{mgc.qbeszallito}}" formControlName="{{mgc.qbeszallito}}">
              beszallito?
            </mat-checkbox>
          </div>
          <div>
            <mat-checkbox magic="{{mgc.cikk}}" formControlName="{{mgc.cikk}}">
              cikk?
            </mat-checkbox>
          </div>
        </div>
        <div class="flex-container-row">
          <div>
            <mat-checkbox magic="{{mgc.listaar}}" [style.visibility]="mg.getVisible(mgc.listaar)"
              formControlName="{{mgc.listaar}}">
              listaar?
            </mat-checkbox>
          </div>
          <div>
            <mat-checkbox magic="{{mgc.beszerzesi_ar}}" [style.visibility]="mg.getVisible(mgc.beszerzesi_ar)"
              formControlName="{{mgc.beszerzesi_ar}}">
              beszerzesi ar?
            </mat-checkbox>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="flex-container-row">
      <button mat-raised-button color="primary" magic="{{mgc.Lekerdezes}}">
        lekerdezes
      </button>
      <button mat-raised-button color="primary" magic="{{mgc.Excel}}">
        excel
      </button>
    </div>
  </div>
</div>
