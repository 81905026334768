<div #overlayheader class="urlap, form-style, my-overlay-header">
  <span>{{ mg.getValue("MG_FORMNAME") }}</span>
  <button
    class="input_button"
    magic="{{ mgc.MG_B_QUIT }}"
    [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)"
  >
    <fa-icon [icon]="faWindowClose"></fa-icon>
  </button>
</div>
<div novalidate [formGroup]="screenFormGroup">
  <div class="flex-container-column" [magic]="mgc.Forgalomlista2Ovrl">
    <div class="flex-container-row">
      <button
        mat-raised-button
        color="primary"
        magic="{{ mgc.btn_Print_Data }}"
        [style.visibility]="mg.getVisible(mgc.btn_Print_Data)"
        class="mat-raised-button mat-button-base mat-primary {{
          mg.getVisible(mgc.btn_Print_Data)
        }}"
      >
        Export
      </button>
      <mat-checkbox
        magic="{{ mgc.notsortable }}"
        formControlName="{{ mgc.notsortable }}"
        [style.visibility]="mg.getVisible(mgc.notsortable)"
      >
      notsortable{{ mg.getValue(mgc.notsortable) }}
      </mat-checkbox>
    </div>
    <div class="flex-container-row" style="width: 100%">
      <div
        class="example-container mat-elevation-z8 MG_T01TableContainerProps"
        style="width: 100%"
      >
        <mat-table
          #table
          [dataSource]="dataSource"
          class="container_border"
          matSort
          matSortDisableClear
          (matSortChange)="tableService.sortData($event)"
          style="overflow: scroll;
          max-height: 450px;"
        >
          <ng-container
            magic="{{ mgc.Column10485765 }}"
            matColumnDef="{{ mgc.Column10485765 }}"
          >
            <mat-header-cell
              *matHeaderCellDef
              [style.visibility]="mg.getVisible(mgc.Column10485765)"
            >
              <div class="flex-container-row">
                <button
                  mat-raised-button
                  color="primary"
                  magic="{{ mgc.bt_mind_pm }}"
                  [style.visibility]="mg.getVisible(mgc.bt_mind_pm)"
                  [disabled]="mg.isDisabled(mgc.bt_mind_pm)"
                  class="mat-raised-button mat-button-base mat-primary openclose {{
                    mg.getVisible(mgc.bt_mind_pm)
                  }}"
                  (click)="console()"
                >
                  {{ mg.getValue(mgc.bt_mind_pm) }}
                </button>
              </div>
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row"
              [style.visibility]="mg.getVisible(mgc.Column10485765)"
              magicMark="magicTableRowContainer"
            >
              <div class="flex-container-row" *ngIf="mg.ifRowCreated(row)">
                <button
                  mat-raised-button
                  color="primary"
                  magic="{{ mgc.bt_valszthato }}"
                  [rowId]="row.rowId"
                  [disabled]="mg.isDisabled(mgc.bt_valszthato, row.rowId)"
                  [style.visibility]="
                    mg.getVisible(mgc.bt_valszthato, row.rowId)
                  "
                  class="mat-raised-button mat-button-base mat-primary openclose {{
                    mg.getVisible(mgc.bt_valszthato, row.rowId)
                  }}"
                  (click)="console()"
                >
                  {{ mg.getValue(mgc.bt_valszthato, row.rowId) }}
                </button>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container
            magic="{{ mgc.Column10485766 }}"
            matColumnDef="{{ mgc.Column10485766 }}"
          >
            <mat-header-cell
              *matHeaderCellDef
              [style.visibility]="mg.getVisible(mgc.Column10485766)"
              mat-sort-header
              [disabled]="mg.getValue(mgc.notsortable)"
            >
              Név,ISBN,Szerző,Kiadó
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row"
              [style.visibility]="mg.getVisible(mgc.Column10485766)"
              magicMark="magicTableRowContainer"
            >
              <div
                *ngIf="mg.ifRowCreated(row)"
                class="flex-container-row altip_{{
                  mg.getValue(mgc.altip, row.rowId)
                }} elso"
              >
                <div
                  [formGroup]="mg.getFormGroupByRow(row.rowId)"
                  class="flex-container-row"
                >
                  <label magic="{{ mgc.NEV }}" [rowId]="row.rowId">
                    {{ mg.getValue(mgc.NEV, row.rowId) }}
                  </label>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container
            magic="{{ mgc.Column10485767 }}"
            matColumnDef="{{ mgc.Column10485767 }}"
          >
            <mat-header-cell
              *matHeaderCellDef
              [style.visibility]="mg.getVisible(mgc.Column10485767)"
              mat-sort-header
              [disabled]="mg.getValue(mgc.notsortable)"
            >
              Azonosító
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row"
              [style.visibility]="mg.getVisible(mgc.Column10485767)"
              magicMark="magicTableRowContainer"
            >
              <div
                *ngIf="mg.ifRowCreated(row)"
                class="flex-container-row altip_{{
                  mg.getValue(mgc.altip, row.rowId)
                }}"
              >
                <div
                  [formGroup]="mg.getFormGroupByRow(row.rowId)"
                  class="flex-container-row"
                >
                  <label magic="{{ mgc.ID }}" [rowId]="row.rowId">
                    {{ mg.getValue(mgc.ID, row.rowId) }}
                  </label>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container
            magic="{{ mgc.Column10485768 }}"
            matColumnDef="{{ mgc.Column10485768 }}"
          >
            <mat-header-cell
              *matHeaderCellDef
              [style.visibility]="mg.getVisible(mgc.Column10485768)"
              mat-sort-header
              [disabled]="mg.getValue(mgc.notsortable)"
            >
              Forgalom<br />
              &Sigma; {{ mg.getValue(mgc.eladas_sz) | number: "":"hu" }}
              {{ mg.getValue(mgc.MG_SETUP_DB) }}
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row"
              [style.visibility]="mg.getVisible(mgc.Column10485768)"
              magicMark="magicTableRowContainer"
            >
              <div
                *ngIf="mg.ifRowCreated(row)"
                class="flex-container-row altip_{{
                  mg.getValue(mgc.altip, row.rowId)
                }} tabulalt"
              >
                <div
                  [formGroup]="mg.getFormGroupByRow(row.rowId)"
                  class="flex-container-row"
                >
                  <label magic="{{ mgc.forgalom }}" [rowId]="row.rowId">
                    {{ mg.getValue(mgc.forgalom, row.rowId) | number: "":"hu" }}
                    {{ mg.getValue(mgc.MG_SETUP_DB) }}
                  </label>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container
            magic="{{ mgc.Column10485774 }}"
            matColumnDef="{{ mgc.Column10485774 }}"
          >
            <mat-header-cell
              *matHeaderCellDef
              [style.visibility]="mg.getVisible(mgc.Column10485774)"
              mat-sort-header
              [disabled]="mg.getValue(mgc.notsortable)"
            >
              Listaáron<br />
              &Sigma; {{ mg.getValue(mgc.brlistaaron_sz) | number: "":"hu" }}
              {{ mg.getValue(mgc.MG_SETUP_DEVIZA) }}
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row"
              [style.visibility]="mg.getVisible(mgc.Column10485774)"
              magicMark="magicTableRowContainer"
            >
              <div
                *ngIf="mg.ifRowCreated(row)"
                class="flex-container-row altip_{{
                  mg.getValue(mgc.altip, row.rowId)
                }} tabulalt"
              >
                <div
                  [formGroup]="mg.getFormGroupByRow(row.rowId)"
                  class="flex-container-row"
                >
                  <label magic="{{ mgc.brlistaaron }}" [rowId]="row.rowId">
                    {{
                      mg.getValue(mgc.brlistaaron, row.rowId) | number: "":"hu"
                    }}
                    {{ mg.getValue(mgc.MG_SETUP_DEVIZA) }}
                  </label>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container
            magic="{{ mgc.Column10485779 }}"
            matColumnDef="{{ mgc.Column10485779 }}"
          >
            <mat-header-cell
              *matHeaderCellDef
              [style.visibility]="mg.getVisible(mgc.Column10485779)"
              mat-sort-header
              [disabled]="mg.getValue(mgc.notsortable)"
            >
              Készlet<br />
              &Sigma; {{ mg.getValue(mgc.keszlet_sz) | number: "":"hu" }}
              {{ mg.getValue(mgc.MG_SETUP_DB) }}
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row"
              [style.visibility]="mg.getVisible(mgc.Column10485779)"
              magicMark="magicTableRowContainer"
            >
              <div
                *ngIf="mg.ifRowCreated(row)"
                class="flex-container-row altip_{{
                  mg.getValue(mgc.altip, row.rowId)
                }}"
              >
                <div
                  [formGroup]="mg.getFormGroupByRow(row.rowId)"
                  class="flex-container-row"
                >
                  <label magic="{{ mgc.keszlet }}" [rowId]="row.rowId">
                    <ng-container *ngIf="mg.getValue(mgc.keszlet, row.rowId)">
                      {{
                        mg.getValue(mgc.keszlet, row.rowId) | number: "":"hu"
                      }}
                      {{ mg.getValue(mgc.MG_SETUP_DB) }}
                    </ng-container>
                  </label>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container
            magic="{{ mgc.Column10485788 }}"
            matColumnDef="{{ mgc.Column10485788 }}"
          >
            <mat-header-cell
              *matHeaderCellDef
              [style.visibility]="mg.getVisible(mgc.Column10485788)"
              mat-sort-header
              [disabled]="mg.getValue(mgc.notsortable)"
            >
              Készlet listaáron<br />
              &Sigma;
              {{ mg.getValue(mgc.klt_brlistaaron_sz) | number: "":"hu" }}
              {{ mg.getValue(mgc.MG_SETUP_DEVIZA) }}
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row"
              [style.visibility]="mg.getVisible(mgc.Column10485788)"
              magicMark="magicTableRowContainer"
            >
              <div
                style="display: flex; flex-direction: row"
                *ngIf="mg.ifRowCreated(row)"
                class="flex-container-row altip_{{
                  mg.getValue(mgc.altip, row.rowId)
                }}"
              >
                <div
                  [formGroup]="mg.getFormGroupByRow(row.rowId)"
                  style="display: flex; flex-direction: row"
                >
                  <mat-form-field *ngIf="mg.isRowInRowEditing(row)">
                    <div>
                      <input
                        *ngIf="mg.isRowInRowEditing(row)"
                        matInput
                        type="number"
                        magic="{{ mgc.klt_brlistaaron }}"
                        [rowId]="row.rowId"
                        formControlName="{{ mgc.klt_brlistaaron }}"
                        mgFormat
                      />
                      <mgError
                        magic="{{ mgc.klt_brlistaaron }}"
                        [rowId]="row.rowId"
                      >
                      </mgError>
                    </div>
                  </mat-form-field>
                  <label
                    *ngIf="!mg.isRowInRowEditing(row)"
                    magic="{{ mgc.klt_brlistaaron }}"
                    [rowId]="row.rowId"
                  >
                    <ng-container
                      *ngIf="mg.getValue(mgc.klt_brlistaaron, row.rowId)"
                    >
                      {{
                        mg.getValue(mgc.klt_brlistaaron, row.rowId)
                          | number: "":"hu"
                      }}
                      {{ mg.getValue(mgc.MG_SETUP_DEVIZA) }}
                    </ng-container>
                  </label>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container
            magic="{{ mgc.Column10485782 }}"
            matColumnDef="{{ mgc.Column10485782 }}"
          >
            <mat-header-cell
              *matHeaderCellDef
              [style.visibility]="mg.getVisible(mgc.Column10485782)"
            >
              altip
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row"
              [style.visibility]="mg.getVisible(mgc.Column10485782)"
              magicMark="magicTableRowContainer"
            >
              <div class="flex-container-row" *ngIf="mg.ifRowCreated(row)">
                <div
                  [formGroup]="mg.getFormGroupByRow(row.rowId)"
                  class="flex-container-row"
                >
                  <label magic="{{ mgc.altip }}" [rowId]="row.rowId">
                    {{ mg.getValue(mgc.altip, row.rowId) }}
                  </label>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumns; let index = index"
            (click)="console(index)"
          ></mat-row>
        </mat-table>
        <mat-paginator
          #paginator
          [pageSize]="GETpageSize()"
          [pageSizeOptions]="pageSizeOptions()"
          (page)="SETpageSize($event)"
          [showFirstLastButtons]="true"
        >
        </mat-paginator>
      </div>
    </div>
    <!--
      <div class="flex-container-row" style="width: 100%">
      <input
        matInput
        magic="{{ mgc.paginator_pageSize }}"
        formControlName="{{ mgc.paginator_pageSize }}"
        mgFormat
      />
      <mgError magic="{{ mgc.paginator_pageSize }}"> </mgError>

      (click)="SETpageSize($event)"

        <div>CP: {{mg.getCustomProperty(mgc.Forgalomlista2,'paginator_pageSizeOptions')}}</div>
        <div *ngIf="tableService.mgOnPaginateChange($event)">
        <h5>Page Change Event Properties</h5>
        <div>List length: {{pageEvent.length}}</div>
        <div>Page size: {{pageEvent.pageSize}}</div>
        <div>Page index: {{pageEvent.pageIndex}}</div>
    </div>
    -->
  </div>
</div>
