<div novalidate [formGroup]="screenFormGroup">
  <div magic="{{ mgc.UjcikkContributors }}">
    <div [magic]="mgc.Table10485761" class="table_scrollBar container_border" infinite-scroll
      [infiniteScrollDistance]="2" [infiniteScrollThrottle]="500" (scrolled)="tableService.onScrollDown()"
      [scrollWindow]="false" style="width: 100%; height: initial; max-height: 300px; overflow: auto;">
      <table style="width: 100%">
        <tr>
          <th style="width: 33%; min-width: 300px">
            <label> Szerző / közreműködő neve </label>
          </th>
          <th style="width: 20%; min-width: 170px">
            <label> Közreműködés módja </label>
          </th>
          <th>
            <label> &nbsp; </label>
          </th>
        </tr>
        <ng-container *ngFor="let row of task.Records.list; index as i">
          <tr *ngIf="mg.ifRowCreated(row) && !mg.isDataviewEmpty()" id="row"
            [formGroup]="mg.getFormGroupByRow(row.rowId)" class="table_row" [ngClass]="{
              selected: mg.isRowSelected(mgc.Table10485761, row.rowId)
            }" [class.selected]="tableService.getSelectedRow() === row.rowId"
            (click)="tableService.selectRow(row.rowId)" [magicRow]="row?.rowId">
            <td>
              <!-- ROW editing mod -->
              <div class="input_text" style="display: flex" *ngIf="mg.isRowInRowEditing(row)">
                <mat-checkbox [magic]="mgc.Kulfoldi" [rowId]="row.rowId"
                  [style.visibility]="mg.getVisible(mgc.Kulfoldi, row.rowId)"
                  [style.display]="mg.getVisible(mgc.Kulfoldi, row.rowId)=='visible'?'initial':'none'"
                  [matTooltip]="mg.getTitle(mgc.Kulfoldi, row.rowId)" [formControlName]="mgc.Kulfoldi"
                  style="padding: 7px">
                  Külföldi
                </mat-checkbox>
                <mat-form-field [style.visibility]="mg.getVisible(mgc.nev2B, row.rowId)"
                  [style.display]="mg.getVisible(mgc.nev2B, row.rowId)=='visible'?'initial':'none'" style="flex: 1"
                  [matTooltip]="mg.getTitle(mgc.nev2B, row.rowId)" *ngIf="mg.isRowInRowEditing(row)">
                  <div>
                    <input *ngIf="mg.isRowInRowEditing(row)" matInput [magic]="mgc.nev2B" [rowId]="row.rowId"
                      [required]="mg.getMustInput(mgc.nev2B, row.rowId)" placeholder="Családnév Keresztnév"
                      [formControlName]="mgc.nev2B" mgFormat>
                    <mgError [magic]=mgc.nev2B [rowId]="row.rowId"> </mgError>
                  </div>
                </mat-form-field>
                <mat-form-field [style.visibility]="mg.getVisible(mgc.nev2K, row.rowId)"
                  [style.display]="mg.getVisible(mgc.nev2K, row.rowId)=='visible'?'initial':'none'" style="flex: 1"
                  [matTooltip]="mg.getTitle(mgc.nev2K, row.rowId)" *ngIf="mg.isRowInRowEditing(row)">
                  <div>
                    <input *ngIf="mg.isRowInRowEditing(row)" matInput [magic]="mgc.nev2K" [rowId]="row.rowId"
                      placeholder="Keresztnév Családnév" [formControlName]="mgc.nev2K" mgFormat>
                    <mgError [magic]=mgc.nev2K [rowId]="row.rowId"> </mgError>
                  </div>
                </mat-form-field>

                <!-- SZERZŐ NEVE:
                <mat-option>
                      <ngx-mat-select-search [formControl]="filterListaCtrl"></ngx-mat-select-search>
                    </mat-option>


                    <mat-option>
                      <lib-mat-select-search
                        [list]="filteredList"
                        [searchProperties]="['displayValue']"
                        (filtered)="szurtLista = $event">
                      </lib-mat-select-search>
                    </mat-option>
                -->
                <mat-form-field [style.visibility]="mg.getVisible(mgc.nev, row.rowId)"
                  [style.display]="mg.getVisible(mgc.nev, row.rowId)=='visible'?'initial':'none'" style="flex: 1"
                  [matTooltip]="mg.getTitle(mgc.nev, row.rowId)">
                  <mat-select [magic]="mgc.nev" [rowId]="row.rowId" [formControlName]="mgc.nev"
                    (click)="filterLista(mg.getItemListValues(mgc.nev, row.rowId))"
                    (ngModelChange)="filterLista(mg.getItemListValues(mgc.nev, row.rowId))">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="filterListaCtrl" placeholderLabel="Keresés..."
                        noEntriesFoundLabel="Szerző nem található">
                        <mat-icon ngxMatSelectSearchClear>x</mat-icon>
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let o of this.filteredList;" [value]="o.index">
                      {{o.displayValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <button class="btn btn-primary btn-sm" [magic]="mgc.btnNev" [rowId]="row.rowId"
                  [style.visibility]="mg.getVisible(mgc.btnNev, row.rowId)" style="margin-bottom: 1.34375em;"
                  [matTooltip]="mg.getTitle(mgc.btnNev, row.rowId)"
                  (click)="filterLista(mg.getItemListValues(mgc.nev, row.rowId))">
                  <i>
                    <fa-icon [icon]="faList"></fa-icon>
                  </i>
                </button>
                <!--
                <mgError magic="{{ mgc.nev2 }}" [rowId]="row.rowId"> </mgError>
                -->
                <mat-checkbox [magic]="mgc.NewEnabled" [rowId]="row.rowId"
                  [style.visibility]="mg.getVisible(mgc.NewEnabled, row.rowId)" [formControlName]="mgc.NewEnabled"
                  [matTooltip]="mg.getTitle(mgc.NewEnabled, row.rowId)" style="padding: 7px">
                  Új
                </mat-checkbox>
              </div>
              <div class="input_text" *ngIf="!mg.isRowInRowEditing(row)">
                <label magic="{{ mgc.nev }}" [rowId]="row.rowId" [style.visibility]="mg.getVisible(mgc.nev, row.rowId)">
                  {{ mg.getDisplayValue(mgc.nev, row.rowId) }}
                </label>
                <!--
                <label *ngIf="!mg.isRowInRowEditing(row)" [magic]="mgc.nev2B" [rowId]="row.rowId"
                  [style.visibility]="mg.getVisible(mgc.nev2B, row.rowId)">
                  {{mg.getValue(mgc.nev2B, row.rowId)
                  }}
                </label>
                -->
                <label *ngIf="!mg.isRowInRowEditing(row)" [magic]="mgc.nev2B" [rowId]="row.rowId"
                  [style.visibility]="mg.getVisible(mgc.nev2B, row.rowId)"
                  [matTooltip]="mg.getTitle(mgc.nev2B, row.rowId)">
                  {{mg.getValue(mgc.nev2B, row.rowId)
                  }}
                </label>

                <!--
                <label *ngIf="!mg.isRowInRowEditing(row)" [magic]="mgc.nev2K" [rowId]="row.rowId"
                  [style.visibility]="mg.getVisible(mgc.nev2K, row.rowId)">
                  {{mg.getValue(mgc.nev2K, row.rowId)
                  }}
                </label>
                -->
                <label *ngIf="!mg.isRowInRowEditing(row)" [magic]="mgc.nev2K" [rowId]="row.rowId"
                  [style.visibility]="mg.getVisible(mgc.nev2K, row.rowId)"
                  [matTooltip]="mg.getTitle(mgc.nev2K, row.rowId)">
                  {{mg.getValue(mgc.nev2K, row.rowId)
                  }}
                </label>
              </div>
            </td>
            <td>
              <mat-form-field *ngIf="mg.isRowInRowEditing(row)" [matTooltip]="mg.getTitle(mgc.kozremukodes, row.rowId)"
                class="form-control select" style="margin-right: 5px; display: contents;">
                <mat-select [magic]="mgc.kozremukodes" [rowId]="row.rowId" [formControlName]="mgc.kozremukodes"
                  id="Contributors_kozremukodes_{{ i }}">
                  <mat-option *ngFor="let o of mg.getItemListValues(mgc.kozremukodes, row.rowId);" [value]="o.index">
                    {{o.displayValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <label *ngIf="!mg.isRowInRowEditing(row)" magic="{{ mgc.kozremukodes }}" [rowId]="row.rowId"
                [matTooltip]="mg.getTitle(mgc.kozremukodes, row.rowId)">
                <!--{{mg.getValue(mgc.kozremukodes, row.rowId)}}-->
                {{ mg.getDisplayValue(mgc.kozremukodes, row.rowId) }}
              </label>
            </td>
            <td>
              <button *ngIf="!mg.isRowInRowEditing(row)" class="mat-raised-button mat-button-base mat-primary"
                magic="{{ mgc.EnterRowEditing }}" [rowId]="row.rowId"
                (click)="filterLista(mg.getItemListValues(mgc.nev, row.rowId))">
                Szerkesztés
              </button>
              <button *ngIf="mg.isRowInRowEditing(row)" class="mat-raised-button mat-button-base mat-primary"
                [magic]="mgc.SaveRowEditing" [rowId]="row.rowId"
                [style.visibility]="mg.getVisible(mgc.SaveRowEditing, row.rowId)"
                [matTooltip]="mg.getTitle(mgc.SaveRowEditing, row.rowId)"
                [disabled]="mg.isDisabled(mgc.SaveRowEditing, row.rowId)"
                (click)="filterLista(mg.getItemListValues(mgc.nev, row.rowId))">
                Mentés
              </button>
              <button *ngIf="mg.isRowInRowEditing(row)" class="mat-raised-button mat-button-base mat-primary"
                [magic]="mgc.CancelRowEditing" [rowId]="row.rowId"
                [matTooltip]="mg.getTitle(mgc.CancelRowEditing, row.rowId)">
                Mégsem
              </button>
              <a href="javascript:;" magic="{{ mgc.DeleteRecord }}" [rowId]="row.rowId"
                [style.visibility]="mg.getVisible(mgc.DeleteRecord, row.rowId)"
                style="margin-left: 10px; color: #ec6b2c" [matTooltip]="mg.getTitle(mgc.DeleteRecord, row.rowId)">
                <fa-icon [icon]="faTimes"></fa-icon>
              </a>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
    <div style="text-align: center">
      <button mat-raised-button color="primary" [magic]="mgc.Create" [style.visibility]="mg.getVisible(mgc.Create)"
        [disabled]="mg.isDisabled(mgc.Create)" [matTooltip]="mg.getTitle(mgc.Create)">
        Új közreműködő hozzáadása
      </button>
      <button mat-raised-button color="primary" [magic]="mgc.Refresh" [style.visibility]="mg.getVisible(mgc.Refresh)"
        [disabled]="mg.isDisabled(mgc.Refresh)" [matTooltip]="mg.getTitle(mgc.Refresh)">
        Frissít
      </button>
    </div>
  </div>
</div>
