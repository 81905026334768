import { FormControl, FormGroup } from "@angular/forms";
import { MagicServices } from "@magic-xpa/angular";
export enum MgControlName {
  BeszallitoSF = "BeszallitoSF",
  Label10485761 = "Label10485761",
  puref_N = "puref_N",
  punevek = "punevek",
  PurefSF = "PurefSF",
  Mind = "Mind",
  MG_B_SEARCH = "MG_B_SEARCH",
  MG_FORMNAME = "MG_FORMNAME",
}
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) {}

  get puref_N(): FormControl {
    return this.fg.controls[MgControlName.puref_N] as FormControl;
  }

  get punevek(): FormControl {
    return this.fg.controls[MgControlName.punevek] as FormControl;
  }

  get Mind(): FormControl {
    return this.fg.controls[MgControlName.Mind] as FormControl;
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(
      this.magicServices.tableService.getSelectedRow()
    ).controls[name] as FormControl;
  }
}
