<div novalidate [formGroup]="screenFormGroup">
  <div
    class="flex-container-column"
    magic="{{ mgc.sbugyfelBeszallitovalasztas }}"
  >
    <div #overlayheader class="my-overlay-header">
      <span>{{ mg.getValue("MG_FORMNAME") }}</span>
      <button
        class="input_button"
        magic="{{ mgc.MG_B_QUIT }}"
        [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)"
      >
        <fa-icon [icon]="faWindowClose"></fa-icon>
      </button>
    </div>
    <div class="flex-container-row">
      <label magic="{{ mgc.Label10485761 }}" class="lable_overflow">
        nev
      </label>
      <label magic="{{ mgc.Label10485762 }}" class="lable_overflow">
        irsz
      </label>
      <label magic="{{ mgc.Label10485763 }}" class="lable_overflow">
        varos
      </label>
      <label magic="{{ mgc.Label10485764 }}" class="lable_overflow">
        cim
      </label>
    </div>
    <div class="flex-container-row">
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.nev }}"
            formControlName="{{ mgc.nev }}"
            mgFormat
          />
          <mgError magic="{{ mgc.nev }}"> </mgError>
        </div>
      </mat-form-field>
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.irsz }}"
            formControlName="{{ mgc.irsz }}"
            mgFormat
          />
          <mgError magic="{{ mgc.irsz }}"> </mgError>
        </div>
      </mat-form-field>
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.varos }}"
            formControlName="{{ mgc.varos }}"
            mgFormat
          />
          <mgError magic="{{ mgc.varos }}"> </mgError>
        </div>
      </mat-form-field>
      <mat-form-field>
        <div>
          <input
            matInput
            magic="{{ mgc.cim }}"
            formControlName="{{ mgc.cim }}"
            mgFormat
          />
          <mgError magic="{{ mgc.cim }}"> </mgError>
        </div>
      </mat-form-field>
      <button
        mat-button
        color="primary"
        magic="{{ mgc.keresNYG }}"
        class="button_image_background_size"
        id="beszallitoValasztas_zoom"
        [ngStyle]="{
          'background-image': mg.getStyle(
            mgc.keresNYG,
            magicProperties.ImageFile
          )
        }"
      >
        {{ mg.getValue(mgc.keresNYG) }}
      </button>
    </div>
    <div class="flex-container-row">
      <mat-card>
        <magic-subform magic="{{ mgc.Beszallito_valasztas }}"> </magic-subform>
      </mat-card>
    </div>
  </div>
</div>
