<div novalidate [formGroup]="screenFormGroup">
  <div class="flex-container-column" magic="{{ mgc.Keszletlista }}">
    <div class="flex-container-row">
      <button
        mat-raised-button
        color="primary"
        magic="{{ mgc.btn_Print_Data }}"
        [style.visibility]="mg.getVisible(mgc.btn_Print_Data)"
        class="btn btn-primary btn-sm {{ mg.getVisible(mgc.btn_Print_Data) }}"
      >
        Export
      </button>
    </div>
    <div class="flex-container-row" style="width: 100%">
      <div
        class="example-container mat-elevation-z8 MG_T01TableContainerProps"
        style="width: 100%"
      >
        <mat-table
          #table
          [dataSource]="dataSource"
          class="container_border"
          matSort
          matSortDisableClear
          (matSortChange)="tableService.sortData($event)"
        >
          <ng-container
            *ngFor="let column of columns; let i = index"
            magic="{{ mgc[column.field] }}"
            [matColumnDef]="column.field"
          >
            <mat-header-cell
              *matHeaderCellDef
              cdkDropList
              cdkDropListLockAxis="x"
              cdkDropListOrientation="horizontal"
              (cdkDropListDropped)="dropListDropped($event, i)"
              cdkDrag
              (cdkDragStarted)="dragStarted($event, i)"
              [cdkDragData]="{ name: column.field, columIndex: i }"
              mat-sort-header
              [style.visibility]="mg.getVisible(mgc[column.field])"
              class="{{ mg.getVisible(mgc[column.field]) }}"
            >
              {{ column.name }}
              <ng-container *ngIf="column.mezo == 'forgalom'"
                ><br />&Sigma;
                {{ mg.getValue(mgc.eladas_sz) | number: "":"hu" }}
                {{ mg.getValue(mgc.MG_SETUP_DB) }}
              </ng-container>
              <ng-container *ngIf="column.mezo == 'brlistaaron'"
                ><br />&Sigma;
                {{ mg.getValue(mgc.brlistaaron_sz) | number: "":"hu" }}
                {{ mg.getValue(mgc.MG_SETUP_DEVIZA) }}
              </ng-container>
              <ng-container *ngIf="column.mezo == 'brfogyaron'"
                ><br />&Sigma;
                {{ mg.getValue(mgc.brfogyaron_sz) | number: "":"hu" }}
                {{ mg.getValue(mgc.MG_SETUP_DEVIZA) }}
              </ng-container>
              <ng-container *ngIf="column.mezo == 'nefogyaron'"
                ><br />&Sigma;
                {{ mg.getValue(mgc.nefogyaron_sz) | number: "":"hu" }}
                {{ mg.getValue(mgc.MG_SETUP_DEVIZA) }}
              </ng-container>
              <ng-container *ngIf="column.mezo == 'nebeszaron'"
                ><br />&Sigma;
                {{ mg.getValue(mgc.nebeszaron_sz) | number: "":"hu" }}
                {{ mg.getValue(mgc.MG_SETUP_DEVIZA) }}
              </ng-container>
              <ng-container *ngIf="column.mezo == 'keszlet'"
                ><br />&Sigma;
                {{ mg.getValue(mgc.keszlet_sz) | number: "":"hu" }}
                {{ mg.getValue(mgc.MG_SETUP_DB) }}
              </ng-container>
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row"
              [style.visibility]="mg.getVisible(mgc[column.field])"
              magicMark="magicTableRowContainer"
              class="{{ mg.getVisible(mgc[column.field]) }}"
            >
              <div class="flex-container-row" *ngIf="mg.ifRowCreated(row)">
                <div
                  [formGroup]="mg.getFormGroupByRow(row.rowId)"
                  class="flex-container-row"
                >
                  <label
                    magic="{{ mgc[column.mezo] }}"
                    [rowId]="row.rowId"
                    class="{{
                      'altipus_' +
                        mg.getCustomProperty(mgc.NEV, 'altipus', row.rowId)
                    }}"
                  >
                    <ng-container
                      *ngIf="
                        column.mezo == 'forgalom' ||
                          column.mezo == 'brlistaaron' ||
                          column.mezo == 'brfogyaron' ||
                          column.mezo == 'nefogyaron' ||
                          column.mezo == 'nebeszaron' ||
                          column.mezo == 'keszlet';
                        then thenBlock;
                        else elseBlock
                      "
                    ></ng-container>
                    <ng-template #thenBlock>
                      {{
                        mg.getValue(mgc[column.mezo], row.rowId)
                          | number: "":"hu"
                      }}
                      <ng-container
                        *ngIf="
                          column.mezo == 'forgalom' || column.mezo == 'keszlet'
                        "
                      >
                        {{ mg.getValue(mgc.MG_SETUP_DB) }}
                      </ng-container>
                      <ng-container
                        *ngIf="
                          column.mezo == 'brlistaaron' ||
                          column.mezo == 'brfogyaron' ||
                          column.mezo == 'nefogyaron' ||
                          column.mezo == 'nebeszaron'
                        "
                      >
                        {{ mg.getValue(mgc.MG_SETUP_DEVIZA) }}
                      </ng-container>
                    </ng-template>
                    <ng-template #elseBlock>{{
                      mg.getValue(mgc[column.mezo], row.rowId)
                    }}</ng-template>
                  </label>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator
          #paginator
          [pageSize]="GETpageSize()"
          [pageSizeOptions]="pageSizeOptions()"
          (page)="SETpageSize($event)"
          [showFirstLastButtons]="true"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
