<div
    novalidate
    [formGroup]="screenFormGroup"
>
    <div
        class="flex-container-column"
        magic="{{mgc.fobesorolSF}}"
    >
        <div class="flex-container-row">
            <div>
                <mat-form-field style="width: 200px;">
                    <mat-select
                        magic="{{mgc.Combo_box10485761}}"
                        formControlName="{{mgc.Combo_box10485761}}"
                    >
                        <mat-option
                            *ngFor="let o of mg.getItemListValues(mgc.Combo_box10485761);"
                            [value]="o.index"
                        >
                            {{o.displayValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
