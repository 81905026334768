<div novalidate [formGroup]="screenFormGroup">
  <div class="flex-container-column" magic="{{ mgc.Boltvalasztas }}">
    <div #overlayheader class="my-overlay-header">
      <span>{{ mg.getValue("MG_FORMNAME") }}</span>
      <button class="input_button" magic="{{ mgc.MG_B_QUIT }}" [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)">
        <fa-icon [icon]="faWindowClose"></fa-icon>
      </button>
    </div>
    <div class="flex-container-row">
      <label magic="{{ mgc.MG_FL001 }}" class="lable_overflow">
        Bolt típus
      </label>
      <div>
        <mat-form-field>
          <mat-select magic="{{ mgc.MG_FD001 }}" formControlName="{{ mgc.MG_FD001 }}">
            <mat-option *ngFor="let o of mg.getItemListValues(mgc.MG_FD001)" [value]="o.index">
              {{ o.displayValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-form-field class="MG_D_SEARCH-form-field" appearance="fill">
        <div>
          <mat-label></mat-label>
          <input matInput type="text" style="width:90%" magic="{{ mgc.MG_D_SEARCH }}" placeholder="Keresés"
            formControlName="{{ mgc.MG_D_SEARCH }}" mgFormat (keyup.enter)="onEnter()" [(ngModel)]="MG_D_SEARCH" />
          <button *ngIf="MG_D_SEARCH" matSuffix mat-icon-button aria-label="Clear" style="width:10%"
            (click)="MG_D_SEARCH=''">
            <mat-icon>close</mat-icon>
          </button>
          <mgError magic="{{ mgc.MG_D_SEARCH }}"> </mgError>
        </div>
      </mat-form-field>
      <button mat-raised-button color="primary" magic="{{ mgc.MG_B_SEARCH2DEF }}"
        [matTooltip]="mg.getTitle(mgc.MG_B_SEARCH2DEF)">
        x
      </button>
      <button mat-raised-button color="primary" magic="{{ mgc.MG_B_SEARCH }}"
        [matTooltip]="mg.getTitle(mgc.MG_B_SEARCH)">
        Keres
      </button>
    </div>
    <div class="flex-container-row">
      <div class="example-container mat-elevation-z8 MG_T01TableContainerProps">
        <mat-table #table [dataSource]="dataSource" class="container_border" matSort matSortDisableClear
          (matSortChange)="tableService.sortData($event)">
          <ng-container magic="{{ mgc.MG_T01C001 }}" matColumnDef="{{ mgc.MG_T01C001 }}">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Azonositó
            </mat-header-cell>
            <mat-cell *matCellDef="let row" magicMark="magicTableRowContainer">
              <div class="flex-container-row" *ngIf="mg.ifRowCreated(row)">
                <div [formGroup]="mg.getFormGroupByRow(row.rowId)" class="flex-container-row">
                  <label magic="{{ mgc.MG_T01C001D001 }}" [rowId]="row.rowId">
                    {{ mg.getValue(mgc.MG_T01C001D001, row.rowId) }}
                  </label>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container magic="{{ mgc.MG_T01C002 }}" matColumnDef="{{ mgc.MG_T01C002 }}">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Bolt név
            </mat-header-cell>
            <mat-cell *matCellDef="let row" magicMark="magicTableRowContainer">
              <div class="flex-container-row" *ngIf="mg.ifRowCreated(row)">
                <div [formGroup]="mg.getFormGroupByRow(row.rowId)" class="flex-container-row">
                  <label magic="{{ mgc.MG_T01C002D001 }}" [rowId]="row.rowId">
                    {{ mg.getValue(mgc.MG_T01C002D001, row.rowId) }}
                  </label>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container magic="{{ mgc.MG_T01C003 }}" matColumnDef="{{ mgc.MG_T01C003 }}">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Irányítószám
            </mat-header-cell>
            <mat-cell *matCellDef="let row" magicMark="magicTableRowContainer">
              <div class="flex-container-row" *ngIf="mg.ifRowCreated(row)">
                <div [formGroup]="mg.getFormGroupByRow(row.rowId)" class="flex-container-row">
                  <label magic="{{ mgc.MG_T01C003D001 }}" [rowId]="row.rowId">
                    {{ mg.getValue(mgc.MG_T01C003D001, row.rowId) }}
                  </label>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container magic="{{ mgc.MG_T01C004 }}" matColumnDef="{{ mgc.MG_T01C004 }}">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Település
            </mat-header-cell>
            <mat-cell *matCellDef="let row" magicMark="magicTableRowContainer">
              <div class="flex-container-row" *ngIf="mg.ifRowCreated(row)">
                <div [formGroup]="mg.getFormGroupByRow(row.rowId)" class="flex-container-row">
                  <label magic="{{ mgc.MG_T01C004D001 }}" [rowId]="row.rowId">
                    {{ mg.getValue(mgc.MG_T01C004D001, row.rowId) }}
                  </label>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container magic="{{ mgc.MG_T01C005 }}" matColumnDef="{{ mgc.MG_T01C005 }}">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Cím
            </mat-header-cell>
            <mat-cell *matCellDef="let row" magicMark="magicTableRowContainer">
              <div class="flex-container-row" *ngIf="mg.ifRowCreated(row)">
                <div [formGroup]="mg.getFormGroupByRow(row.rowId)" class="flex-container-row">
                  <label magic="{{ mgc.MG_T01C005D001 }}" [rowId]="row.rowId">
                    {{ mg.getValue(mgc.MG_T01C005D001, row.rowId) }}
                  </label>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container magic="{{ mgc.MG_T01C006 }}" matColumnDef="{{ mgc.MG_T01C006 }}">
            <mat-header-cell *matHeaderCellDef [style.visibility]="mg.getVisible(mgc.MG_T01C006)">
              Kiválaszt
            </mat-header-cell>
            <mat-cell *matCellDef="let row" [style.visibility]="mg.getVisible(mgc.MG_T01C006)"
              magicMark="magicTableRowContainer">
              <div class="flex-container-row" *ngIf="mg.ifRowCreated(row)">
                <div [formGroup]="mg.getFormGroupByRow(row.rowId)">
                  <mat-checkbox magic="{{ mgc.MG_T01C006D001 }}" [rowId]="row.rowId"
                    formControlName="{{ mgc.MG_T01C006D001 }}">
                  </mat-checkbox>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns" class="table_row"
            [ngClass]="{ selected: selection.isSelected(row) }" [magicRow]="row?.rowId"
            (click)="tableService.selectRow(row.rowId)">
          </mat-row>
        </mat-table>
        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"
          (page)="tableService.mgOnPaginateChange($event)">
        </mat-paginator>
      </div>
    </div>
    <div class="flex-container-row">
      <button mat-raised-button color="primary" magic="{{ mgc.MG_B_SELECT }}"
        [matTooltip]="mg.getTitle(mgc.MG_B_SELECT)">
        Kiválaszt
      </button>
      <button mat-raised-button color="primary" magic="{{ mgc.MG_B_QUIT }}" [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)">
        Mégsem
      </button>
    </div>
  </div>
</div>