import { Component } from "@angular/core";

import { FormGroup } from "@angular/forms";
import { MgFormControlsAccessor, MgControlName } from "./Naptar.mg.controls.g";

import { TaskBaseMagicComponent, magicProviders } from "@magic-xpa/angular";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "mga-Naptar",
  providers: [...magicProviders],
  templateUrl: "./Naptar.component.html",
})
export class Naptar extends TaskBaseMagicComponent {
  mgc = MgControlName;
  mgfc: MgFormControlsAccessor;
  faWindowClose = faWindowClose;
  createFormControlsAccessor(formGroup: FormGroup) {
    this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
  }
}
