import {
    Component
} from '@angular/core';

import {
    FormGroup
} from "@angular/forms";
import {
    MgFormControlsAccessor,
    MgControlName
} from "./bolttmp.mg.controls.g";


	import {
		BaseMatTableMagicComponent,
		matMagicProviders
	} from "@magic-xpa/angular-material-core";
	import { faWindowClose } from "@fortawesome/free-solid-svg-icons";


@Component({
    selector: 'mga-bolttmp',
    providers: [...matMagicProviders],
    templateUrl: './bolttmp.component.html'
})
export class bolttmp extends BaseMatTableMagicComponent {

    mgc = MgControlName;
    mgfc: MgFormControlsAccessor;
    createFormControlsAccessor(formGroup: FormGroup) {
        this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
    }

    displayedColumns = [
        'Column10485762',
    ];

}
