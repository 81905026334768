import { Component } from "@angular/core";

import { FormGroup } from "@angular/forms";
import {
  MgFormControlsAccessor,
  MgControlName,
} from "./Beszallitovalasztas.mg.controls.g";

import {
  BaseMatTableMagicComponent,
  matMagicProviders,
} from "@magic-xpa/angular-material-core";

import { MagicModalInterface } from "@magic-xpa/angular";

import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import {
  CdkDragStart,
  CdkDropList,
  moveItemInArray,
} from "@angular/cdk/drag-drop";

@Component({
  selector: "mga-Beszallitovalasztas",
  providers: [...matMagicProviders],
  templateUrl: "./Beszallitovalasztas.component.html",
  styleUrls: ['./Beszallitovalasztas.component.scss']
})
export class Beszallitovalasztas
  extends BaseMatTableMagicComponent
  implements MagicModalInterface {
  mgc = MgControlName;
  mgfc: MgFormControlsAccessor;
  faWindowClose = faWindowClose;
  MG_D_SEARCH="";

  createFormControlsAccessor(formGroup: FormGroup) {
    this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
  }

  onEnter() {
    var button = document.getElementById("beszallitoValasztas2_zoom") as HTMLInputElement;
    //var last=this.dataSource.filteredData.length;
    button.click();
    //console.log("enter ütöttem");
    // : HTMLButtonElement
    //var select = document.getElementById("beszallitoValasztas2_kivalaszt") as HTMLInputElement;
    //if(last == this.dataSource.filteredData.length) select.click();
  }

  private static readonly formName: string = "Beszállító választás";
  private static readonly showTitleBar: boolean = true;
  private static readonly x: number = 0;
  private static readonly y: number = 0;
  private static readonly width: string = "300px";
  private static readonly height: string = "300px";
  private static readonly isCenteredToWindow: boolean = true;
  private static readonly shouldCloseOnBackgroundClick = true;
  X() {
    return Beszallitovalasztas.x;
  }
  Y() {
    return Beszallitovalasztas.y;
  }
  Width(): string {
    return Beszallitovalasztas.width;
  }
  Height(): string {
    return Beszallitovalasztas.height;
  }
  IsCenteredToWindow() {
    return Beszallitovalasztas.isCenteredToWindow;
  }
  FormName() {
    return Beszallitovalasztas.formName;
  }
  ShowTitleBar() {
    return Beszallitovalasztas.showTitleBar;
  }
  ShouldCloseOnBackgroundClick() {
    return Beszallitovalasztas.shouldCloseOnBackgroundClick;
  }

  displayedColumns = [
    "Column10485777",
    "Column10485778",
    "Column10485779",
    "Column10485780",
    "Column10485781",
    "Column10485782",
    "Column10485783",
  ];

  previousIndex: number;
  columns: any[] = [
    { field: "Column10485777", name: "Azonositó", mezo: "MG_T01C001D001" },
    { field: "Column10485778", name: "Név", mezo: "MG_T01C002D001" },
    { field: "Column10485779", name: "Irányítószám", mezo: "MG_T01C003D001" },
    { field: "Column10485780", name: "Település", mezo: "MG_T01C004D001" },
    { field: "Column10485781", name: "Cím", mezo: "MG_T01C005D001" },
    { field: "Column10485782", name: "BMS kód", mezo: "MG_T01C006D001" },
    { field: "Column10485783", name: "Kiválaszt", mezo: "MG_T01C007D001" },
  ]; //hosszú távon generáljuk

  setDisplayedColumns() {
    this.columns.forEach((colunm, index) => {
      colunm.index = index;
      this.displayedColumns[index] = colunm.field;
    });
    console.log(this.displayedColumns);
  }

  dragStarted(event: CdkDragStart, index: number) {
    this.previousIndex = index;
  }

  dropListDropped(event: CdkDropList, index: number) {
    if (event) {
      moveItemInArray(this.columns, this.previousIndex, index);
      this.setDisplayedColumns();
    }
  }
}
