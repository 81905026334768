<div #overlayheader class="urlap, form-style, my-overlay-header" style="min-width: 200px;">
  <span>{{ mg.getValue("MG_FORMNAME") }}</span>
  <button class="input_button" [style.visibility]="mg.getVisible(mgc.MG_B_QUIT)"
    [style.display]="mg.getVisible(mgc.MG_B_QUIT) == 'visible' ? 'flex' : 'none'" magic="{{ mgc.MG_B_QUIT }}"
    [matTooltip]="mg.getTitle(mgc.MG_B_QUIT)">
    <fa-icon [icon]="faWindowClose"></fa-icon>
  </button>
</div>
<div novalidate [formGroup]="screenFormGroup">
  <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
  <mat-progress-bar mode="indeterminate" [style.visibility]="progress_visible"></mat-progress-bar>
<!--
<div>
  AcceptedExt:{{mg.getValue(mgc.AcceptedExt)}}| AcceptedSize:{{mg.getValue(mgc.AcceptedSize)}}
</div>
-->
  <div class="flex-container-column" magic="{{mgc.CreateNewImageRecord}}">
    <div class="flex-container-row" [style.display]="mg.getVisible(mgc.vImageDate) == 'visible' ? 'flex' : 'none'">
      <label magic="{{mgc.Label22}}" class="lable_overflow" [style.visibility]="mg.getVisible(mgc.Label22)"
        [style.display]="mg.getVisible(mgc.Label22) == 'visible' ? 'flex' : 'none'">
        Dátum:
      </label>
      <mat-form-field magic="{{mgc.vImageDate}}" [eventsOnly]=true [style.visibility]="mg.getVisible(mgc.vImageDate)"
        [style.display]="mg.getVisible(mgc.vImageDate) == 'visible' ? 'block' : 'none'" style="width: 100%;">
        <input matInput [matDatepicker]="vImageDate" magic="{{mgc.vImageDate}}" formControlName="{{mgc.vImageDate}}">
        <mat-datepicker-toggle matSuffix [for]="vImageDate" *ngIf="mg.checkIsReadOnly(mgc.vImageDate)">
        </mat-datepicker-toggle>
        <mat-datepicker #vImageDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="flex-container-row" [style.display]="mg.getVisible(mgc.vImageTime) == 'visible' ? 'flex' : 'none'">
      <label magic="{{mgc.Label23}}" class="lable_overflow" [style.visibility]="mg.getVisible(mgc.Label23)"
        [style.display]="mg.getVisible(mgc.Label23) == 'visible' ? 'flex' : 'none'">
        Idő:
      </label>
      <mat-form-field [style.visibility]="mg.getVisible(mgc.vImageTime)"
        [style.display]="mg.getVisible(mgc.vImageTime) == 'visible' ? 'block' : 'none'" style="width: 100%;">
        <div>
          <input matInput type='time' step=1 magic="{{mgc.vImageTime}}" formControlName="{{mgc.vImageTime}}">
          <mgError magic={{mgc.vImageTime}}> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row" [style.display]="mg.getVisible(mgc.vImageTime) == 'visible' ? 'flex' : 'none'">
      <label magic="{{mgc.Label24}}" class="lable_overflow" [style.visibility]="mg.getVisible(mgc.Label24)"
        [style.display]="mg.getVisible(mgc.Label24) == 'visible' ? 'flex' : 'none'">
        Leírás:
      </label>
      <mat-form-field [style.visibility]="mg.getVisible(mgc.vDescription)"
        [style.display]="mg.getVisible(mgc.vDescription) == 'visible' ? 'block' : 'none'" style="width: 100%;">
        <div>
          <input matInput magic="{{mgc.vDescription}}" formControlName="{{mgc.vDescription}}" mgFormat>
          <mgError magic={{mgc.vDescription}}> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row" [style.display]="mg.getVisible(mgc.vImagePath) == 'visible' ? 'flex' : 'none'">
      <label magic="{{mgc.Label25}}" class="lable_overflow" [style.visibility]="mg.getVisible(mgc.Label25)"
        [style.display]="mg.getVisible(mgc.Label25) == 'visible' ? 'flex' : 'none'">
        Elérési útvonal:
      </label>
      <mat-form-field [style.visibility]="mg.getVisible(mgc.vImagePath)"
        [style.display]="mg.getVisible(mgc.vImagePath) == 'visible' ? 'block' : 'none'" style="width: 100%;">
        <div>
          <input matInput magic="{{mgc.vImagePath}}" [placeholder]="mg.getPlaceholder(mgc.vImagePath)"
            formControlName="{{mgc.vImagePath}}" mgFormat>
          <mgError magic={{mgc.vImagePath}}> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row" [style.display]="mg.getVisible(mgc.vFileNameU) == 'visible' ? 'flex' : 'none'">
      <label magic="{{mgc.Label34}}" [style.visibility]="mg.getVisible(mgc.Label34)"
        [style.display]="mg.getVisible(mgc.Label34) == 'visible' ? 'flex' : 'none'" class="lable_overflow mgFont1">
        Fájl neve
      </label>
      <mat-form-field [style.visibility]="mg.getVisible(mgc.vFileNameU)"
        [style.display]="mg.getVisible(mgc.vFileNameU) == 'visible' ? 'block' : 'none'" style="width: 100%;">
        <div>
          <input matInput magic="{{mgc.vFileNameU}}" formControlName="{{mgc.vFileNameU}}" mgFormat>
          <mgError magic={{mgc.vFileNameU}}> </mgError>
        </div>
      </mat-form-field>
      <mat-form-field [style.visibility]="mg.getVisible(mgc.vBlob64FromClient)"
        [style.display]="mg.getVisible(mgc.vBlob64FromClient) == 'visible' ? 'block' : 'none'" style="width: 100%;">
        <div>
          <input matInput magic="{{mgc.vBlob64FromClient}}" formControlName="{{mgc.vBlob64FromClient}}">
          <mgError magic={{mgc.vBlob64FromClient}}> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row">
      <button mat-raised-button color="primary" style="display: none;" magic="{{mgc.SaveNewImage}}">
        Mentés
      </button>
      <button mat-raised-button color="primary" style="display: none;" magic="{{mgc.Cancel}}">
        Mégsem
      </button>
      <button mat-raised-button color="primary" [style.visibility]="mg.getVisible(mgc.UploadImage)"
        [disabled]="mg.isDisabled(mgc.UploadImage)" magic="{{ mgc.UploadImage }}">
        <label>
          Másik kép választása
          <!-- AcceptedExt
          .jpg, .jpeg, .jpe, .jif, .jfif, .jfi
          -->
          <input id="file" name="file" type="file" [accept]="mg.getValue(mgc.AcceptedExt)" style="display: none;"
            (change)="fileUpload($event)" />
        </label>
      </button>
      <button mat-raised-button color="primary" id="mgGetFile" name="mgGetFile" style="display: none;"
        magic="{{mgc.mgGetFile}}">
        Feltöltés
      </button>
    </div>
    <div class="flex-container-row" [style.display]="mg.getVisible(mgc.vServerFile) == 'visible' ? 'flex' : 'none'">
      <label magic="{{mgc.Label30}}" [style.visibility]="mg.getVisible(mgc.Label30)"
        [style.display]="mg.getVisible(mgc.Label30) == 'visible' ? 'flex' : 'none'" class="lable_overflow mgFont1">
        vServerFile
      </label>
      <mat-form-field [style.visibility]="mg.getVisible(mgc.vServerFile)"
        [style.display]="mg.getVisible(mgc.vServerFile) == 'visible' ? 'block' : 'none'" style="width: 100%;">
        <div>
          <input matInput magic="{{mgc.vServerFile}}" formControlName="{{mgc.vServerFile}}" mgFormat>
          <mgError magic={{mgc.vServerFile}}> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row" [style.display]="mg.getVisible(mgc.vfilename) == 'visible' ? 'flex' : 'none'">
      <label magic="{{mgc.Label32}}" [style.visibility]="mg.getVisible(mgc.Label32)"
        [style.display]="mg.getVisible(mgc.Label32) == 'visible' ? 'flex' : 'none'" class="lable_overflow mgFont1">
        vfilename
      </label>
      <mat-form-field [style.visibility]="mg.getVisible(mgc.vfilename)"
        [style.display]="mg.getVisible(mgc.vfilename) == 'visible' ? 'block' : 'none'" style="width: 100%;">
        <div>
          <input matInput magic="{{mgc.vfilename}}" formControlName="{{mgc.vfilename}}" mgFormat>
          <mgError magic={{mgc.vfilename}}> </mgError>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container-row" [style.display]="mg.getVisible(mgc.vFileType) == 'visible' ? 'flex' : 'none'">
      <label magic="{{mgc.Label36}}" [style.visibility]="mg.getVisible(mgc.Label36)"
        [style.display]="mg.getVisible(mgc.Label36) == 'visible' ? 'flex' : 'none'" class="lable_overflow mgFont1">
        vFileType
      </label>
      <mat-form-field [style.visibility]="mg.getVisible(mgc.vFileType)"
        [style.display]="mg.getVisible(mgc.vFileType) == 'visible' ? 'block' : 'none'" style="width: 100%;">
        <div>
          <input matInput magic="{{mgc.vFileType}}" formControlName="{{mgc.vFileType}}" mgFormat>
          <mgError magic={{mgc.vFileType}}> </mgError>
        </div>
      </mat-form-field>
    </div>
  </div>
</div>
