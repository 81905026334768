import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    Forgalmak = "Forgalmak",
        Label1 = "Label1",
        Datumtol = "Datumtol",
        Label4 = "Label4",
        Datumig = "Datumig",
        Label6 = "Label6",
        beszallito = "beszallito",
        HNEV = "HNEV",
        beszallitovg = "beszallitovg",
        beszallito_lista = "beszallito_lista",
        beszallito_lista_torles = "beszallito_lista_torles",
        Label8 = "Label8",
        Label11 = "Label11",
        Label15 = "Label15",
        Label18 = "Label18",
        Label20 = "Label20",
        Label22 = "Label22",
        Aru_recno = "Aru_recno",
        aru_nev_szures = "aru_nev_szures",
        isbn = "isbn",
        szerzo = "szerzo",
        kiado = "kiado",
        sorozat = "sorozat",
        aru_nev = "aru_nev",
        Label17 = "Label17",
        kiadas_eve_tol = "kiadas_eve_tol",
        kiadas_eve_ig = "kiadas_eve_ig",
        kiadovg = "kiadovg",
        sorozatvg = "sorozatvg",
        aruvg = "aruvg",
        szerzovg = "szerzovg",
        beszallitotmp = "beszallitotmp",
        Label24 = "Label24",
        kategoriak = "kategoriak",
        katvg = "katvg",
        Label26 = "Label26",
        bolt_tipus = "bolt_tipus",
        nagyker = "nagyker",
        online = "online",
        nemonline = "nemonline",
        LiraLira_kiskerRozsavolgyi = "LiraLira_kiskerRozsavolgyi",
        bolt_lista = "bolt_lista",
        bolt_lista_torles = "bolt_lista_torles",
        Label27 = "Label27",
        bolt = "bolt",
        bolt_nev = "bolt_nev",
        boltvg = "boltvg",
        bolttmp = "bolttmp",
        Group48 = "Group48",
        datum = "datum",
        bolt_0001 = "bolt_0001",
        qbeszallito = "qbeszallito",
        cikk = "cikk",
        mennyiseg = "mennyiseg",
        eladasi_ar = "eladasi_ar",
        listaar = "listaar",
        beszerzesi_ar = "beszerzesi_ar",
        Lekerdezes = "Lekerdezes",
        Excel = "Excel",
}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get Datumtol(): FormControl {
        return this.fg.controls[MgControlName.Datumtol] as FormControl;
    }

    get Datumig(): FormControl {
        return this.fg.controls[MgControlName.Datumig] as FormControl;
    }

    get beszallito(): FormControl {
        return this.fg.controls[MgControlName.beszallito] as FormControl;
    }

    get HNEV(): FormControl {
        return this.fg.controls[MgControlName.HNEV] as FormControl;
    }

    get Aru_recno(): FormControl {
        return this.fg.controls[MgControlName.Aru_recno] as FormControl;
    }

    get aru_nev_szures(): FormControl {
        return this.fg.controls[MgControlName.aru_nev_szures] as FormControl;
    }

    get isbn(): FormControl {
        return this.fg.controls[MgControlName.isbn] as FormControl;
    }

    get szerzo(): FormControl {
        return this.fg.controls[MgControlName.szerzo] as FormControl;
    }

    get kiado(): FormControl {
        return this.fg.controls[MgControlName.kiado] as FormControl;
    }

    get sorozat(): FormControl {
        return this.fg.controls[MgControlName.sorozat] as FormControl;
    }

    get aru_nev(): FormControl {
        return this.fg.controls[MgControlName.aru_nev] as FormControl;
    }

    get kiadas_eve_tol(): FormControl {
        return this.fg.controls[MgControlName.kiadas_eve_tol] as FormControl;
    }

    get kiadas_eve_ig(): FormControl {
        return this.fg.controls[MgControlName.kiadas_eve_ig] as FormControl;
    }

    get kategoriak(): FormControl {
        return this.fg.controls[MgControlName.kategoriak] as FormControl;
    }

    get bolt_tipus(): FormControl {
        return this.fg.controls[MgControlName.bolt_tipus] as FormControl;
    }

    get nagyker(): FormControl {
        return this.fg.controls[MgControlName.nagyker] as FormControl;
    }

    get online(): FormControl {
        return this.fg.controls[MgControlName.online] as FormControl;
    }

    get nemonline(): FormControl {
        return this.fg.controls[MgControlName.nemonline] as FormControl;
    }

    get LiraLira_kiskerRozsavolgyi(): FormControl {
        return this.fg.controls[MgControlName.LiraLira_kiskerRozsavolgyi] as FormControl;
    }

    get bolt(): FormControl {
        return this.fg.controls[MgControlName.bolt] as FormControl;
    }

    get bolt_nev(): FormControl {
        return this.fg.controls[MgControlName.bolt_nev] as FormControl;
    }

    get datum(): FormControl {
        return this.fg.controls[MgControlName.datum] as FormControl;
    }

    get bolt_0001(): FormControl {
        return this.fg.controls[MgControlName.bolt_0001] as FormControl;
    }

    get qbeszallito(): FormControl {
        return this.fg.controls[MgControlName.qbeszallito] as FormControl;
    }

    get cikk(): FormControl {
        return this.fg.controls[MgControlName.cikk] as FormControl;
    }

    get mennyiseg(): FormControl {
        return this.fg.controls[MgControlName.mennyiseg] as FormControl;
    }

    get eladasi_ar(): FormControl {
        return this.fg.controls[MgControlName.eladasi_ar] as FormControl;
    }

    get listaar(): FormControl {
        return this.fg.controls[MgControlName.listaar] as FormControl;
    }

    get beszerzesi_ar(): FormControl {
        return this.fg.controls[MgControlName.beszerzesi_ar] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}