import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    Search = "Search",
        Label20 = "Label20",
        vEAN = "vEAN",
        Label21 = "Label21",
        vType = "vType",
        Label22 = "Label22",
        vAuthor = "vAuthor",
        Label23 = "Label23",
        vSeries = "vSeries",
        Label24 = "Label24",
        vDateStart = "vDateStart",
        Label25 = "Label25",
        vDateEnd = "vDateEnd",
        Label26 = "Label26",
        vTitle = "vTitle",
        Label27 = "Label27",
        vID = "vID",
        Label28 = "Label28",
        vVar01 = "vVar01",
        Label29 = "Label29",
        vKIM = "vKIM",
        Label30 = "Label30",
        vName = "vName",
        Label31 = "Label31",
        vCathegory = "vCathegory",
        Label32 = "Label32",
        vKiado = "vKiado",
        Label33 = "Label33",
        vISBN = "vISBN",
        Label34 = "Label34",
        vLanguage = "vLanguage",
        Label35 = "Label35",
        vMinCount = "vMinCount",
        Label36 = "Label36",
        vStatus = "vStatus",
        vVar02 = "vVar02",
        Label37 = "Label37",
        vbtn_search = "vbtn_search",
}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get vEAN(): FormControl {
        return this.fg.controls[MgControlName.vEAN] as FormControl;
    }

    get vType(): FormControl {
        return this.fg.controls[MgControlName.vType] as FormControl;
    }

    get vAuthor(): FormControl {
        return this.fg.controls[MgControlName.vAuthor] as FormControl;
    }

    get vSeries(): FormControl {
        return this.fg.controls[MgControlName.vSeries] as FormControl;
    }

    get vDateStart(): FormControl {
        return this.fg.controls[MgControlName.vDateStart] as FormControl;
    }

    get vDateEnd(): FormControl {
        return this.fg.controls[MgControlName.vDateEnd] as FormControl;
    }

    get vTitle(): FormControl {
        return this.fg.controls[MgControlName.vTitle] as FormControl;
    }

    get vID(): FormControl {
        return this.fg.controls[MgControlName.vID] as FormControl;
    }

    get vVar01(): FormControl {
        return this.fg.controls[MgControlName.vVar01] as FormControl;
    }

    get vKIM(): FormControl {
        return this.fg.controls[MgControlName.vKIM] as FormControl;
    }

    get vName(): FormControl {
        return this.fg.controls[MgControlName.vName] as FormControl;
    }

    get vCathegory(): FormControl {
        return this.fg.controls[MgControlName.vCathegory] as FormControl;
    }

    get vKiado(): FormControl {
        return this.fg.controls[MgControlName.vKiado] as FormControl;
    }

    get vISBN(): FormControl {
        return this.fg.controls[MgControlName.vISBN] as FormControl;
    }

    get vLanguage(): FormControl {
        return this.fg.controls[MgControlName.vLanguage] as FormControl;
    }

    get vMinCount(): FormControl {
        return this.fg.controls[MgControlName.vMinCount] as FormControl;
    }

    get vStatus(): FormControl {
        return this.fg.controls[MgControlName.vStatus] as FormControl;
    }

    get vVar02(): FormControl {
        return this.fg.controls[MgControlName.vVar02] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}